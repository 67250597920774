import React, {useState, useCallback, useEffect} from 'react'
import Axios from '../../axios-proas';
import { Notyf } from 'notyf';
import { useTranslation } from 'react-i18next';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { SGBody, SGCol, SGColTitle, SGContainer, SGFormGroup, SGSection } from './Settings.styled';
import { BtnOutlineAction, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';
import { useTheme } from 'styled-components';
import Select from '../../components/UI/Select/Select';
import {Option, SelectFilter} from '../../styles/Table.styled';
import DocumentSignatories from '../../components/DocumentSignatories/DocumentSignatories'
import AddIcon from '@mui/icons-material/Add';
import { removeObject } from '../../shared/utility';

function Settings() {
    const [settings, setSettings] = useState(null);
    const [updated , setUpdated] = useState(false);
    const [documentsType, setDocumentsTypes] = useState([])
    const [documentSignatories, setDocumentSignatories] = useState([])
    const [selectedDocType, setSelectedDocType] = useState(1)
    const [selectedSignatories, setSelectedSignatories] = useState()
    const notyf = new Notyf();
    const {t} = useTranslation();
    const theme = useTheme()


    useEffect(() => {
        Axios.get('/settings').then(response => {
            setDocumentsTypes(response?.data.documentsType);
            setDocumentSignatories(response?.data?.documentSignatories);
            setSelectedSignatories(response?.data?.documentSignatories.filter(signatory => signatory.documentType == selectedDocType ))
            let tsettings = response?.data?.tsettings;
            let tsettingsMail = response?.data?.tsettingsMail;
            let newTSettings = {}
            let newTSettingsM = {}
            tsettings.map(row => {
                let key = row.key;
                let value = row.value
                newTSettings = {
                    ...newTSettings,
                    [key]: value
                }
            })
            tsettingsMail.map(row => {
                let key = row.key;
                let fr_value = row.fr_value;
                let en_value = row.en_value;
                let de_value = row.de_value;
                let it_value = row.it_value;
                let es_value = row.es_value;
                newTSettingsM = {
                    ...newTSettingsM,
                    [key]: {
                        de_value,
                        en_value,
                        fr_value,
                        it_value,
                        es_value
                    }
                }
            })
            setSettings({
                tsettings : newTSettings, 
                tsettingsMail:newTSettingsM,
                documentsType: [],
                documentSignatories: response?.data?.documentSignatories
            })
        })
        
        
    }, [updated])


    useEffect(() => {
        setDocumentSignatories(documentSignatories);
        setSelectedSignatories( _.sortBy(documentSignatories.filter(signatory => signatory.documentType == selectedDocType ), "order"))
    }, [documentSignatories])
    




    const updateSignatoriesHandler = (signatoriesToUpdate) => {
        console.log("updateSignatoriesHandler")
        console.log(documentSignatories)
        let tempDocumentSigs = documentSignatories
        signatoriesToUpdate.map(signatoryToUp =>{
            tempDocumentSigs.map(signatory => {
                if(signatoryToUp.signatory == signatory.signatory && signatoryToUp.documentType == signatory.documentType)
                    signatory.order = signatoryToUp.order 
            })
        })
        // console.log("order")        
        // console.log( _.sortBy( tempDocumentSigs, 'order' ))
        setDocumentSignatories(tempDocumentSigs)
        setSelectedSignatories(_.sortBy( signatoriesToUpdate, 'order' ))
        console.log(tempDocumentSigs)
    }

    const handleDocumentTypeChange = (e) => {
        let documentType = e?.target?.value
        setSelectedDocType(documentType)
        setSelectedSignatories( _.sortBy((documentSignatories.filter(signatory => signatory.documentType == documentType)), "order" ) )
    }

    const deleteSignatoryHandler = (signatoryToDelete) => {
        // console.log(signatoryToDelete)
        // let temp = {...documentSignatories}
        //removeObject(temp ,signatoryToDelete)
        let sigIndex = -1
        let signatoriesTemp = [...documentSignatories];
        documentSignatories.map( (sig, index) =>  { if( sig.uid == signatoryToDelete.uid ) sigIndex = index  })
        signatoriesTemp.splice(sigIndex, 1)
        let signatoriesToUpdate = signatoriesTemp.map((signatory,index) => {
            let signatoryTemp = {...signatory, order : index}
            return signatoryTemp
        })
        // if(sigIndex != -1){
        //     let tsettingsNew = {
        //         ...settings,
        //             documentSignatoriesToDelete : signatoryToDelete
        //     }
        //     setSettings( tsettingsNew )
        // }
        setDocumentSignatories(signatoriesToUpdate)
        setSettings({
            ...settings,
                documentSignatories: signatoriesToUpdate
        })
        //updateSignatoriesHandler(signatoriesToUpdate)
        // if(!signatoryToDelete.hasOwnProperty("agencyUid"))
        //     deleteMandat(signatoryToDelete.uid)  
    }

    const options = [
        {label: t("contract:MSEPA", "Mandats Sepa") , value: 'MSEPA'},
        {label: t("contract:CF", "Contrat de franchise"), value: 'CF'},
        {label: t("contract:DIP", "Document d'information pré-contractuelle"), value: 'DIP'}
        // {label: t("contract:CSCF", "Caution solidaire"), value: 'CSCF'}
        
    ];

    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let tsettingsNew = settings?.tsettings ? {
            ...settings.tsettings,
            [name] : value
        } : {[name] : value }
        setSettings({
            ...settings,
            tsettings: tsettingsNew 
        })
    }

    const editSettings = useCallback((e,settings, newLogo) => {
        e.preventDefault()
        Axios.put('/settings//settings', settings).then(response => {
            setUpdated(true)
            notyf.success(t("settings:savedSettings"));
        })
    }, [settings])

    const signatoryAlreadyIn = (signatory) => {
        let exist = false
        documentSignatories.map( sig =>  { 
            if( sig.documentType == selectedDocType && sig.signatory == signatory ) exist = true  
        })
        return exist
    }

    const getMaxOrder = (tab) => {
        var max = 0
        //sss = Math.max(tab.map(sig => sig.order)) 
        tab.map(element => {
            // console.log(element.order)
            element.order > max ? max = parseInt(element.order) : null
        });
        console.log("max :"+max)
        return max
    }

    const addSignatoryHandler = ( e => {
        let trouve = signatoryAlreadyIn(e?.target?.getAttribute('name'))
        if(!trouve){
            let signatoriesTemp = Array.from(documentSignatories)
            let temp = documentSignatories.filter( signatory=> signatory.documentType == selectedDocType )
            console.log(temp)
            let orderNumber =  temp.length > 0 ? (getMaxOrder(temp) + 1) : 0
            console.log("lastOrderNumber: "+ orderNumber) 


            let signatory = {
                creationDate: null,
                documentType: selectedDocType,
                id: random_integer(3),
                order: orderNumber,
                signatory: e?.target?.getAttribute('name'),
                uid: random_hexadecimal(20)
            }
            signatoriesTemp.push(signatory)
            // console.log("addSignatoryHandler")
            // console.log(signatoriesTemp)
            setDocumentSignatories(signatoriesTemp)
            setSelectedSignatories( _.sortBy(documentSignatories.filter( signatory=> signatory.documentType == selectedDocType ), "order" ))
            setSettings({
                ...settings,
                    documentSignatories: signatoriesTemp
            })
        }else
            notyf.error("signatory already exist");

    })
    
    function random_hexadecimal(length) {
        var result = '';
        var characters = 'abcdef0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    function random_integer(length) {
        var result = '';
        var characters = '123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return parseInt(result);
    }


    return (
        <>
            <SGContainer>
                <TitleHelmet title={"OPTICAL CENTER | Emails"} />
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("navbar:settingConf")}</HeaderLocation>
                    </HeaderInfos>
                    <HeaderActions>
                        <BtnOutlineAction
                            color={theme.colors.success}
                            border={theme.colors.success}
                            onClick={(e) => editSettings(e,settings)}
                        >
                            <span>{t("global:save")}</span>
                        </BtnOutlineAction>
                    </HeaderActions>
                </Header>

                <SGBody>
                    <SGSection style={{width: '55%'}}>
                        <SGCol>
                            <SGColTitle>{t("settings:smtpAccess")}</SGColTitle>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpLogin" >{t("settings:smtpLogin")} </FormLabel>
                                <FormInput 
                                    id="smtpLogin"
                                    type="text" 
                                    placeHolder={t("settings:smtpLogin")} 
                                    value={settings?.tsettings.smtpLogin || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpLogin" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpPassword" >{t("settings:smtpPassword")} </FormLabel>
                                <FormInput 
                                    id="smtpPassword"
                                    type="password" 
                                    placeHolder={t("settings:smtpPassword")} 
                                    value={settings?.tsettings.smtpPassword || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpPassword" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpPort" >{t("settings:smtpPort")} </FormLabel>
                                <FormInput 
                                    id="smtpPort"
                                    type="text" 
                                    placeHolder={t("settings:smtpPort")} 
                                    value={settings?.tsettings.smtpPort || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpPort" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="smtpHost" >{t("settings:smtpHost")} </FormLabel>
                                <FormInput 
                                    id="smtpHost"
                                    type="text" 
                                    placeHolder={t("settings:smtpHost")} 
                                    value={settings?.tsettings.smtpHost || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="smtpHost" 
                                />
                            </SGFormGroup>
                            <SGFormGroup>
                                <FormLabel htmlFor="emailFrom" >{t("settings:sourceEmail")} </FormLabel>
                                <FormInput 
                                    id="emailFrom"
                                    type="text" 
                                    placeHolder={t("settings:sourceEmail")} 
                                    value={settings?.tsettings.emailFrom || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="emailFrom" 
                                />
                            </SGFormGroup>
                        </SGCol>
                        <SGCol>
                            <SGColTitle>{t("settings:manageReminders")}</SGColTitle>
                            <SGFormGroup>
                                <FormLabel htmlFor="reminder" >{t("settings:reminders")} </FormLabel>
                                <FormInput 
                                    id="reminder"
                                    type="text" 
                                    placeHolder={t("settings:reminders")} 
                                    value={settings?.tsettings.reminder || ''} 
                                    onChange={(e) => inputChangeHandler(e)} 
                                    name="reminder" 
                                />
                            </SGFormGroup>
                        </SGCol>
                        {/* <SGCol style={{minWidth: "398px"}}>
                            <SGColTitle>{t("documentDetails:docsType", "")}</SGColTitle>
                            <div style={{display: "flex"}}>
                                <SelectFilter
                                    onChange={(e) => handleDocumentTypeChange(e)}
                                    value={selectedDocType}
                                    >
                                    {
                                        documentsType.map(docType => {
                                            return <Option value={docType.id}>{docType.type}</Option>
                                        })
                                    } */}
                                    {/* <Option value="ACTIVE">{t("refClientFields:actif")}</Option>
                                    <Option value="INACTIVE">{t("refClientFields:inactif")}</Option>
                                    <Option value="INPROGRESS"> {t("refClientFields:inprogress")}</Option>
                                    <Option value="ARCHIVED">{t("refClientFields:archive")}</Option> */}
                                {/* </SelectFilter>
                                <div className="btn-group" role="group">
                                    <AddIcon 
                                            style={{cursor: "pointer"}}
                                            className=" dropdown-toggle"
                                            data-bs-toggle="dropdown" 
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        />
                                    <div className="dropdown-menu">
                                        <span className="dropdown-item" name="Tiers Franchisé" value={1} onClick={e => addSignatoryHandler(e)}>{t("documentDetails:franchise", "Tiers Franchisé")}</span>
                                        <span className="dropdown-item" name="Co-gérant tiers franchisé" value={2} onClick={e => addSignatoryHandler(e)}>{t("documentDetails:franchise", "Tiers Franchisé")} </span>
                                        <span className="dropdown-item" name="OCF" value={3} onClick={e => addSignatoryHandler(e)}>OCF</span>
                                        <span className="dropdown-item" name="LLRV" value={4} onClick={e => addSignatoryHandler(e)}>LLRV</span>
                                    </div>
                                </div>
                            </div>
                            <DocumentSignatories
                                selectedSignatories={selectedSignatories}
                                signatories={documentSignatories}
                                updateSignatories={updateSignatoriesHandler}
                                deleteSignatory={deleteSignatoryHandler}
                            />
                        </SGCol> */}
                    </SGSection>
                </SGBody>
            </SGContainer>
    </>
    )
}

export default Settings
