import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 25,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'creationDate',
    reverse: {
        creationDate: false
    },
    density: "2.25rem"
}

const contractsStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const contractsSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const contractsFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const contractsSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const contractsSetDensity = (state, action) => updateObject(state, {
    density: action.density
})

const contractsSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const contractsSetSortQuery = (state, action) => updateObject(state, {
    sortQuery: action.sortQuery
})

const contractsSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: { ...action.reverse }
    }
}

const contractsUpdateShowFilters = (state, action) => {
    return updateObject(state, { showFilters: action.showFilters })
}

const contractsSetFiltersQuery = (state, action) => updateObject(state, {
    filtersQuery: {
        ...action.filters
    },
    filtering: action.filtering
}
)

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONTRACTS_START: return contractsStart(state, action)
        case actionTypes.CONTRACTS_SUCCESS: return contractsSuccess(state, action)
        case actionTypes.CONTRACTS_FAIL: return contractsFail(state, action)
        case actionTypes.CONTRACTS_SET_PAGE: return contractsSetPage(state, action)
        case actionTypes.CONTRACTS_SET_PAGESIZE: return contractsSetPageSize(state, action)
        case actionTypes.CONTRACTS_SET_FILTERSQUERY: return contractsSetFiltersQuery(state, action)
        case actionTypes.CONTRACTS_SET_SORTQUERY: return contractsSetSortQuery(state, action)
        case actionTypes.CONTRACTS_SET_REVERSESORT: return contractsSetReverseSort(state, action)
        case actionTypes.CONTRACTS_UPDATE_SHOWFILTERS: return contractsUpdateShowFilters(state, action)
        case actionTypes.CONTRACTS_SET_DENSITY: return contractsSetDensity(state, action)
        default:
            return state
    }
}
export default reducer