import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from '../ExtractionItem/ExtractionItem.styled';

function ConfirmDelete({handleClose, show,item, deleteExtraction}) {
    const {t} = useTranslation();

    const updateClickHandler = () => {         
        deleteExtraction(item)
        handleClose();
    }

    const cancelClickHandler = () => { 
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                       voulez vous vraiment supprimer cette extraction ?
                    </p>
                </Modal.Body>
                <Modal.Footer>
               

                <CIDeleteBtn style={{fontSize: "1.3rem"}}
                             onClick={cancelClickHandler}
                              >
                             {t('default:cancel', "Annuler")} 
                </CIDeleteBtn>

                <CIDeleteBtn    style={{fontSize: "1.3rem"}}
                                onClick={()=>updateClickHandler()}
                                color="#482D1C" border="#482D1C">
                             {t('default:confirm', "Confirmer")}
                </CIDeleteBtn>

                   

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmDelete
