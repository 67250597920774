import React, { useEffect, useState } from 'react';
import Axios from '../../../axios-proas';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { EventsContainer, EventsHeader, EventsTitle, EventsList, EventsItem, EventColor, EventValue, EventTitle, EventDate, EventAction, EventVal, EventMessage, EventFilterItem } from './Events.styled';
import { formatDate, getTimeDate } from '../../../shared/utility'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Spinner from 'react-bootstrap/Spinner'
import DropDown from '../../UI/DropDown/DropDown';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EventsFilter from './EventsFilter/EntityFilter';
import { useTranslation } from 'react-i18next';

// const eventsList = [
//     {color: theme.colors.success , value: 'Fournisseur 1', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Campagne 1', date: '5 minuites'},
//     {color: theme.colors.danger, value: 'Transdev', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Transdev', date: '5 minuites'},
// ]
function Events() {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [eventsList, setEventsList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(null)
    const [filteredEvents, setFilteredEvents] = useState()

    const getEventEntity = () => {
        setLoading(true)
        Axios.get("/user//contractEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getEventEntity();
    }, [])

    const getInvoiceEventColor = (event) => {
        switch (event?.snapshot) {
            case "INPROGRESS":
                return theme.colors.warning;
            case "COMPLETED":
                return theme.colors.success;
            case "SCHEDULED":
                return theme.colors.success;
            case "REFUSED":
                return theme.colors.danger;
            default:
                return theme.colors.success;
        }
    }

    const getEntityEventColor = (event) => {
        switch (event?.type) {
            case "STEP":
                switch (event?.message) {
                    case "INPROGRESS":
                        return theme.colors.warning;
                    case "ACTIVE":
                        return theme.colors.success;
                    default:
                        return theme.colors.warning;
                }
            case "DOCS":
                return theme.colors.warning;
            case "ERROR_FIELD":
                return theme.colors.danger;
            default:
                return theme.colors.warning;
        }
    }

    const getInvoiceEventMessage = (event) => {
        switch (event?.snapshot) {
            case "COMPLETED":
                return t("accueil:isfinished");
            case "REFUSED":
                return t("accueil:isrefused");
            case "INPROGRESS":
                return t("accueil:isinprogress");
            case "SCHEDULED":
                return t("accueil:issent");
            default:
                return "";
        }
    }

    const getEntityEventMessage = (event) => {
        switch (event?.message) {
            case "email":
                return `Champs email erroné`;
            case "INPROGRESS":
                return `Demande de validation`;
            case "ACTIVE":
                return `Fournisseur activé`;
            case "DOCUMENT":
                return `Nouveau document ${event.snapshot}`;
            default:
                return "";
        }
    }

    const updateFilterHandler = (newFitler) => {
        let filterTemp = null;
        switch (newFitler) {
            case "contract":
                if (filter === "entity") {
                    filterTemp = null;
                } else if (filter === "contract" || filter === null) {
                    filterTemp = "entity";
                }
                break;
            case "entity":
                if (filter === "contract") {
                    filterTemp = null;
                } else if (filter === "entity" || filter === null) {
                    filterTemp = "contract";
                }
                break;
            default:
                filterTemp = null;
                break;
        }
        let filteredEventsTemp = filterTemp ? eventsList.filter(event => event?.eventType === filterTemp) : eventsList;
        setFilter(filterTemp)
        setFilteredEvents(filteredEventsTemp)
    }
    const navigateToEntity = (uid, type) => {
        if (type === "DOCS") {
            localStorage.setItem("show", "document");
        }
        navigate("/entity/" + uid)
    }

    let eventsJsx = null;

    if (loading)
        eventsJsx = (<Spinner />)
    else if (filteredEvents && filteredEvents?.length > 0) {
        eventsJsx = filteredEvents?.map((eventTmp, index) => {
            var eventJsx = null;
            let isLast = index === 0
            let color = theme.colors.warning;
            let title = "";
            let message = "";
            let date = "";
            let action = null;

            switch (eventTmp?.eventType) {
                case "contract":
                    color = getInvoiceEventColor(eventTmp);
                    title = eventTmp?.data?.title ? t("contract:contract") + ": " + eventTmp?.data?.title : eventTmp?.user?.first_name;
                    message = getInvoiceEventMessage(eventTmp);
                    date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                    action = (eventTmp.hasOwnProperty("data") && eventTmp?.data) ? (
                        <EventAction onClick={() => navigate("/contractForm/" + eventTmp?.data?.contractUid)}>
                            <span>{t("accueil:check")}</span>
                            <ArrowForwardIosIcon />
                        </EventAction>
                    ) : null;
                    break;
                case "entity":
                    color = getEntityEventColor(eventTmp);
                    title = eventTmp?.name;
                    message = getEntityEventMessage(eventTmp);
                    date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                    action = (
                        <EventAction onClick={() => navigateToEntity(eventTmp.entityUid, eventTmp.type)}>
                            <span>{t("accueil:check")}</span>
                            <ArrowForwardIosIcon />
                        </EventAction>
                    );
                    break;
                default:
                    break;
            }
            return (
                <EventsItem key={index}>
                    <EventColor color={color} />
                    <EventValue>
                        <EventVal>
                            {title}
                        </EventVal>
                        {/* <EventMessage title={message} >
                            {message}
                        </EventMessage> */}
                        <EventDate>
                            {date} {message}
                        </EventDate>
                    </EventValue>
                    {action}
                </EventsItem>
            )
        })
    }
    return (
        <>
            <EventsContainer>
                <EventsHeader>
                    <EventsTitle>
                        {t("accueil:events")}
                    </EventsTitle>
                    {/* <EventsFilter
                        filter={filter}
                        updateFilter={updateFilterHandler}
                    /> */}
                </EventsHeader>
                <EventsList>
                    {
                        eventsJsx
                    }
                </EventsList>
            </EventsContainer>
        </>
    );
}

export default Events;
