import React, { useEffect, useMemo, useState } from 'react';
import { formatDate } from '../../../shared/utility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import { SIProgressLine, SIProgressLineBar, SIProgressLineItem, SIProgressLineItemTitle, SIProgressLineBarSteps } from './StatusProgressLine.styled';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

function StatusProgressLine({ fileType, step, status, userType, nbTags, signatoriesOrder }) {
    const theme = useTheme();
    const { t } = useTranslation()
    const [statusList, setStatusList] = useState([
        { title: t("contractList:scheduled", "Programmé"), value: "scheduled" },
        { title: t("contractList:inProgress", "En cours"), value: "inProgress" },
        { title: t("contractList:completedSingle", "Terminé"), value: "completed" }
    ])
    console.log(signatoriesOrder)
    useEffect(() => {
        let statusListTmp = [...statusList];
        if (status === 'REFUSED')
            statusListTmp = [...statusList, { title: t("contractList:refused", "Refusé"), value: "refused" }]

        setStatusList(statusListTmp)
    }, [status])

    const [stepTotal, setStepTotal] = useState();
    const [px, setPx] = useState("150px");

    useEffect(() => {
        if (fileType == 'MSEPA') {
            setStepTotal(1);
            setPx("150px");
        }
        if (fileType == 'CF') {
            setStepTotal(4);
            setPx("37.5px");
        }
        if (fileType == 'FREE') {
            let nbSignatories = signatoriesOrder?.length
            setStepTotal(nbSignatories);
            if(nbSignatories==1) setPx("150px")
            if(nbSignatories==2) setPx("75px")
            if(nbSignatories==3) setPx("50px")
            if(nbSignatories==4) setPx("37.5px")
            console.log("je suis ks")
            // setPx("37.5px");
        }
        if (fileType == 'CSCF') {
            setStepTotal(4);
            setPx("75px");
        }
        if (fileType == 'AOCM' || fileType == 'DIP') {
            setStepTotal(2);
            setPx("75px");
        }

        let stepListTmp = [];
        if (fileType == "DIVERS")
            stepListTmp = [...stepListTmp, { title: t("stepList:divers", "divers"), value: "1" }];
        if (fileType == 'MSEPA' || fileType == 'CF' || fileType == 'DIP' || fileType == 'CSCF' || fileType == 'AOCM')
            stepListTmp = [...stepListTmp, { title: t("stepList:gerant", "gerant"), value: "1" }];
        if (fileType == 'CSCF')
            stepListTmp = [...stepListTmp, { title: t("stepList:cog", "cog"), value: "2" }];
        if (fileType == 'AOCM')
            stepListTmp = [...stepListTmp, { title: t("stepList:SAS", "SAS"), value: "2" }];
        if (fileType == 'DIP')
            stepListTmp = [...stepListTmp, { title: t("stepList:LL", "LL"), value: "2" }];
        if (fileType == 'CF')
            stepListTmp = [...stepListTmp, { title: t("stepList:cog", "cog"), value: "2" }, { title: t("stepList:SAS", "SAS"), value: "3" }, { title: t("stepList:LL", "LL"), value: "4" }];
        if(fileType == "FREE"){
            let tempTab = {SIGNATUREGERANT: "gerant", SIGNATURECOGERANT:"cog", SIGNATURESAS: "SAS", SIGNATURELL: "LL"}
            signatoriesOrder?.map(userSignature => {
                let temptitle = tempTab[userSignature?.entityTagSignature]
                stepListTmp = [...stepListTmp, 
                    { title: t("stepList:".temptitle, temptitle), value: userSignature?.signatureOrder }
                ];  
                console.log(stepListTmp)
            });
            // console?.log(stepListTmp)
        }
        
        setStepList(stepListTmp)
    }, [])


    const getStatusColor = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value?.toUpperCase() === status.toUpperCase())

        if (status.toUpperCase() === "refused".toUpperCase())
            return theme.colors.danger;

        if (status.toUpperCase() === "completed".toUpperCase())
            return theme.colors.success;

        if (value.toUpperCase() === "scheduled".toUpperCase()) {
            status = 'INPROGRESS';
            value = 'inprogress';
            index = 1;
            return theme.colors.success;
        }

        if (value.toUpperCase() === "inprogress".toUpperCase())
            return theme.colors.warning;

        if (value.toUpperCase() === status.toUpperCase())
            return theme.colors.warning;

        if (index < statusIndex)
            return theme.colors.success;

        return theme.colors.secondary;
    }

    const [stepList, setStepList] = useState([]);

    const getStepColor = (item, index2) => {
        // Caution Solidaire
        //    value = 2
        //    title = cog

        const stepIndex = stepList.findIndex(item => item.value === step)
        if (index2 <= stepIndex) {
            return (
                theme.colors.success
            );
        }
        return theme.colors.warning;
    }

    const getClassname = (item, index2) => {
        // Caution Solidaire
        //    value = 2
        //    title = cog
        const stepIndex = stepList.findIndex(item => item.value === step)
        if (index2 <= stepIndex) {
            return (
                ''
            );
        }
        return 'progress progress-bar progress-bar-striped progress-bar-animated';
    }

    const showProgressLineBar = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase());
        return index <= statusIndex;
    }

    return (
        <SIProgressLine>
            {
                statusList.map((item, index) => (
                    <SIProgressLineItem
                        key={index}
                    >
                        <SIProgressLineItemTitle
                            color={getStatusColor(item.value, index)}
                        >
                            <span>
                                {userType == "owner" && item.value == "scheduled" ?
                                    t("contractList:scheduledowner")
                                    :
                                    t("contractList:" + (item.value == "completed" ? "completedSingle" : item.value))
                                }
                            </span>

                            {status == "REFUSED" ?
                                <DoNotTouchIcon fontSize="small" />
                                :
                                null
                            }
                            {status == "COMPLETED" ?
                                <CheckCircleOutlineIcon fontSize="small" />
                                :
                                null
                            }
                            {status == "INPROGRESS" ?
                                index == 1 ?
                                    <AccessTimeIcon fontSize="small" />
                                    : null
                                : null
                            }
                            {status == "INPROGRESS" ?
                                index == 0 ?
                                    <CheckCircleOutlineIcon fontSize="small" />
                                    : null
                                : null
                            }
                            {status == "SCHEDULED" ?
                                index == 0 ?
                                    <CheckCircleOutlineIcon fontSize="small" />
                                    : null
                                : null
                            }
                        </SIProgressLineItemTitle>
                        {
                            showProgressLineBar(item.value, index) ?
                                index !== 1 ?
                                    (
                                        <SIProgressLineBar
                                            isFirst={index === 0}
                                            isCurrent={item.value.toUpperCase() === status.toUpperCase()}
                                            color={getStatusColor(item.value, index)}
                                        >
                                        </SIProgressLineBar>
                                    )
                                    : null
                                : null

                        }
                        {index == 1 ?
                            <div style={{ width: "150px", display: "flex" }}>
                                {stepList.map((item, index2) => (
                                    <div style={{ borderRadius: "18px 18px 18px 18px" }}>
                                        <SIProgressLineBarSteps
                                            className={status == "INPROGRESS" ? getClassname(item.value, index2) : null}
                                            style={{ width: px }}
                                            isFirst={index === 0}
                                            isCurrent={item.value.toUpperCase() === status.toUpperCase()}
                                            color={status == "INPROGRESS" ? getStepColor(item.value, index2) : getStatusColor(item.value, index2)}>
                                        </SIProgressLineBarSteps>
                                    </div>
                                ))}
                            </div>
                            : null
                        }

                    </SIProgressLineItem>
                ))
            }
        </SIProgressLine>
    );
}

export default StatusProgressLine;
