import styled from 'styled-components';

export const KSContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const KSBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const KSCards = styled.div`
    width: 100%;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const KSStats = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
`


