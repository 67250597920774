import styled from 'styled-components/macro';

export const HomeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const HomeHeader = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 3px solid #F5FBFF;
`

export const HeaderInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
`

export const HeaderLocation = styled.h3`
    font-size: 1.5rem;
    font-weight: 500;
    /* color: #000000; */
    margin-bottom: 0.5rem;
`

export const HeaderTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    color: #482D1C;
`

export const HeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const HeaderAction = styled.div`
    /* width: 100%; */
    height: 50px;
    margin-left: 1rem;
`;




export const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;

    &:focus-within {
        border: 2px solid #2174B9;
        color: #2174B9;
    }
`

export const SearchInput = styled.input`
    border: none;
    outline: none;
    color: black;
    font-size: 0.9rem;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: black;
    }
`

export const ActionWrapper = styled.div`
    display: flex;
    justify-content: space-between ;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;
    cursor: pointer;
    flex-wrap: wrap;
`

export const ActionLabel = styled.h4`
    font-size: 0.9rem;
    color: black;
    margin: 0 0.5rem 0 0;
`
export const HomeBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const HomeCards = styled.div`
    width: 100%;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* width: 240px;
        min-width: 240px; */
    }
`

export const HomeCardsLeftSide = styled.div`
    width: 70%;
    margin: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* width: 240px;
        min-width: 240px; */
    }
`
export const HomeCardsRightSide = styled.div`
    width: 25%;
`

export const HomeStats = styled.div`
    width: 100%;
    height: fit-content;
    /* height: 400px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2rem 0;
`

export const HomeInvoices = styled.div`
    width: 100%;
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    padding: 2rem 1rem;
`

