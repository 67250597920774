import React, { useEffect, useMemo, useState } from 'react';
import logo from '../../assets/images/optical-center-logo.png';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import { NavBarContainer, NavHeader, NavLogo, ReduceIcon, NavMenu, NavItem, NavLink, NavFooter, NavIcon, NavBtn } from './NavBar.styled.js';
import SubNav from './SubNav/SubNav';
import { useNavigate, useLocation } from 'react-router-dom';

function NavBar({toggleNav, items}) {
    const {t} = useTranslation();
    const [activeNav, setActiveNav] = useState(localStorage.getItem('activeNav') ? localStorage.getItem('activeNav') : '');
    const navigate = useNavigate();
    let location = useLocation()

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key].find(p => value.includes(p.path)));
    }

    function getKeyByPath(array, pathValue) {
        for (let index = 0; index < array.length; index++) {
            const item = array[index];
            if(item.subMenu.find(e => pathValue.includes(e.path)))  
                return item.key;
        }
        return null
    }
    //reset active nav when changing route
    useEffect(() => {
        let btnItems = items.filter(item => !item?.isLink)
        getKeyByPath(btnItems, location.pathname) ? setActiveNav(getKeyByPath(btnItems, location.pathname)) : setActiveNav('');
    }, [location.pathname, items])

    const navBtnClickHandler = (navTo) => {
        const isActive = activeNav === navTo;
        if(!isActive){
            let subMenuItems = items.find(item => item?.key === navTo).subMenu;
            localStorage.setItem('activeNav', navTo);
            setActiveNav(navTo);
            navigate(subMenuItems[0].path);
        }
    }

  return (
      <>
        <NavBarContainer>
            <NavHeader>
                <NavLogo to={{pathname: "/"}}>
                    <img src={logo} alt="logo" />
                </NavLogo>
                <ReduceIcon onClick={toggleNav}>
                    <CloseFullscreenIcon />
                </ReduceIcon>
            </NavHeader>
            <NavMenu>
                {
                    (items && items.length > 0) && items.map((item, index) => {
                        if(item?.isLink)
                            return (
                                <NavItem key={index}>
                                    <NavLink to={item.pathname} activeClassName="active">
                                        <NavIcon>
                                            {item.icon}
                                        </NavIcon>
                                        <span>{item.label}</span>
                                    </NavLink>
                                </NavItem>
                            )
                        else
                            return (
                                <NavItem key={index}>
                                    <NavBtn isActive={activeNav === item.key} onClick={() => navBtnClickHandler(item.key)}>
                                        <NavIcon>{item.icon}</NavIcon>
                                        <span>{item.label}</span>
                                    </NavBtn>
                                    {
                                        activeNav === item.key && <SubNav menuItems={item.subMenu} />
                                    }
                                </NavItem>
                            )
                    })
                }
            </NavMenu>
            <NavFooter>
                <p>
                    {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                </p>
            </NavFooter>
        </NavBarContainer>
      </>
  );
}

export default NavBar;
