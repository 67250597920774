import React, { useState, useRef } from 'react';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import Axios from "../../axios-proas";
import {Notyf} from "notyf";
import { Viewer, pdfjs, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import InfoModal from '../../components/UI/InfoModal/InfoModal';
import { BtnAction, FormGroup, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { useTheme } from 'styled-components';
import { DocVisu, UIBody, UICol, UIContainer, UISection } from './UploadInvoice.styled';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import { useNavigate } from 'react-router-dom';


function UploadInvoice(props) {
    const theme = useTheme()
    const notyf = new Notyf();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const uploader = useRef(null);
    const uploaderAttach = useRef(null);
    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState(null)
    const [pdfPages, setPdfPages] = useState(0)
    const [scrolledEnd, setScrolledEnd] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [url, setUrl] = useState('');
    const [fields, setFields] = useState({field1: "", field2: "", field3: ""});

    const open_file = () => {
        document?.getElementById('getFile')?.click();
    }

    const handleFileChange = e => {
        console.log(e.target.value)
        if (e.target.files && e.target.files[0] && e.target.name === "file"){
            setUrl(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0])
            setFileName(e.target.files[0].name);
            // setPdfPages(e.target.files[0].numPages)
            // setScrolledEnd(e.target.files[0].numPages == 1 ? false : true)
        }
    }

    const uploadInvoiceHandler = () => {
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('attachment', uploaderAttach.current.files[0]);
        // formData.append('field1', fields.field1);
        // formData.append('field2', fields.field2);
        // formData.append('field3', fields.field3);
        Axios.post("/invoice", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {
            if (response.data.message) {
                notyf.success(t("invoiceList:uploadSuccess"));
                navigate(-1);
                // setShowModal(true)
            }
        }).catch(err => {
            const response = err.response.data;
            const exist = response?.hasOwnProperty('exist');
            if(exist) {
                notyf.error(t("invoiceList:exist", "Une facture avec le même nom existe déjà"));
            } else 
                notyf.error(t("invoiceList:uploadFailed"));
        }).finally(() => {
            // setFile(null);
            // setFields({field1: "", field2: "", field3: ""});
            // setUrl("");
            // uploader.current.value = "";
            // uploaderAttach.current.value = "";
        })
    }

    const confirmClickHandler = () => {
        if(file){
            uploadInvoiceHandler()
        } else {
            notyf.error(t("invoiceList:emptyFile", "Veuillez sélectionner un fichier"));
        }
    }

    const inputChangeHandler = (e) => {
        const key = e.target.name;
        const newForm = {
            ...fields,
            [key]: e.target.value
        }
        setFields(newForm);
    }

    return (
        <>
            <UIContainer>
                <TitleHelmet title={"OPTICAL CENTER | Envoyer un document"}  />
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("global:addDocument", "Ajouter un document ")}</HeaderLocation>
                        <HeaderTitle>Suivi des factures</HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <BtnAction 
                            color={theme.colors.primary}
                            onClick={() => navigate(-1)}
                        >
                            <span>Retour</span>
                        </BtnAction>
                        <BtnAction
                            color={theme.colors.success}
                            bg={theme.colors.greenLight}
                            onClick={() => confirmClickHandler()}
                        >
                            Ajouter
                        </BtnAction>
                    </HeaderActions>
                </Header>
                <UIBody>
                    <UISection>
                        {
                            url ? (
                                <DocVisu>
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                    <Viewer fileUrl={url} />
                                </DocVisu>
                            ) : (
                                <DocVisu>
                                    {t('global:previewArea')}
                                </DocVisu>
                            )
                        }

                        <UICol>
                            <FormGroup>
                                <FileUploaderBtn
                                    btnLabel="Choisir un fichier"
                                    handleChange={handleFileChange}
                                    name="file"
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <FormLabel htmlFor="field1" >Champ 1</FormLabel>
                                <FormInput 
                                    id="field1"
                                    type="text" 
                                    placeHolder="Champ 1" 
                                    value={fields.field1} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field1" 
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="field2" >Champ 2</FormLabel>
                                <FormInput 
                                    id="field2"
                                    type="text" 
                                    placeHolder="Champ 2"  
                                    value={fields.field2} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field2"  
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="field3" >Champ 3</FormLabel>
                                <FormInput 
                                    id="field3"
                                    type="text" 
                                    placeHolder="Champ 3"
                                    value={fields.field3} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field3" 
                                />
                            </FormGroup>
                            <FormGroup>
                                <FileUploaderBtn
                                    btnLabel="Piéce jointe"
                                    handleChange={handleFileChange}
                                    name="attachment"
                                />
                            </FormGroup> */}
                        </UICol>
                    </UISection>
                </UIBody>
                <InfoModal show={showModal} handleClose={() => setShowModal(false)} title={"Facture importée"} content={"Votre facture est en cours de traitement, vous pourrez visualiser son cycle de vie dans le suivi de vos factures."}></InfoModal>
            </UIContainer>
        </>
    )
}
export default UploadInvoice
