import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import PdfViewer from './PdfViewer/PdfViewer';
import * as actions from '../../../../../store/actions/index';
import { useParams } from 'react-router-dom';
import { getNotyfObject } from '../../../../../shared/utility';
import XMLViewer from 'react-xml-viewer'
import { IVContainer } from './InvoiceView.styled';

function InvoiceView({loadingPdf, pdfFile, xmlFile, errorPdf, pdfFileName, mimeType,  getInvoicePdf, getInvoicePdfByToken}) {

    let {uid, token} = useParams();
    const notyf = getNotyfObject();
    const customTheme = {
        "attributeKeyColor": "#FF0000",
        "attributeValueColor": "#000FF",
        "overflowBreak" : true
    }
    useEffect(() => {
        if(token)
            getInvoicePdfByToken(token)
        else
            getInvoicePdf(uid)
    }, [uid, token])

    
    let admView = null;

    // if(error) {
    //     notyf.error(t("mandat:generatedErrorMessage"));
    // }
    if(loadingPdf) admView = (<spinner />)
    
    else{
        if (pdfFile || xmlFile)
            admView = (
                <>
                    {
                        mimeType === "application/pdf" ? 
                            <PdfViewer 
                                pdfFile={pdfFile}
                                pdfFileName={pdfFileName}
                            />           
                        : xmlFile ? 
                            <XMLViewer 
                                xml={xmlFile} 
                                theme={customTheme}
                            />
                            : null
                    }
                </>
            )
        else 
            admView = 
                <>
                    <h5>Cette facture ne contient pas de PDF</h5>
                </>
    } 
        

    return (
        <>
            <IVContainer accessToken={token}>
                {admView}
            </IVContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingPdf: state.invoiceDetail.loadingPdf,
    pdfFile: state.invoiceDetail.pdfFile?.pdfFile || null,
    xmlFile: state.invoiceDetail.pdfFile?.xmlFile || null,
    errorPdf: state.invoiceDetail.errorPdf,
    pdfFileName: state.invoiceDetail?.pdfFile?.docFile || "mandat.pdf",
    mimeType : state.invoiceDetail?.pdfFile?.mimeType
})
const mapDispatchToProps = dispatch => ({
    getInvoicePdf : (invoiceUid) => dispatch(actions.getInvoicePdf(invoiceUid))//,
 //   getInvoicePdfByToken : (invoiceToken) => dispatch(actions.getInvoicePdfByToken(invoiceToken))
})
export default connect(mapStateToProps, mapDispatchToProps) (InvoiceView)
