import React, { useState } from 'react'
import NavBar from '../../components/NavBar/NavBar';
import SmallNavBar from '../../components/SmallNavBar/SmallNavBar';

function Menu({items}) {
    const [activeNav, setActiveNav] = useState(localStorage.getItem('activeNavBar') || 'navBar');

    const toggleNav = () => {
        switch (activeNav) {
            case 'navBar':
                localStorage.setItem('activeNavBar', 'smallNavBar');
                setActiveNav('smallNavBar');
                break;
            case 'smallNavBar':
                localStorage.setItem('activeNavBar', 'navBar');
                setActiveNav('navBar');
                break;
            default:
                break;
        }
    }

  return (
      <>
        {
            activeNav === 'navBar' ? <NavBar toggleNav={toggleNav} items={items} /> : <SmallNavBar toggleNav={toggleNav} items={items}/>
        }
      </>
  )
}

export default Menu