import React, { useState, useEffect, useCallback } from "react";
import Axios from "../../axios-proas";
import { Notyf } from "notyf";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TitleHelmet from "../../components/UI/TitleHelmet/TitleHelmet";
import {
  BtnAction,
  BtnIcon,
  BtnOutlineAction,
  BtnOutlineLink,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  SearchInput,
  SearchInputContainer,
} from "../../styles/Common";
import {
  ESBody,
  ESCol,
  ESContainer,
  ESContent,
  ESForm,
  ESSubject,
  ESTitle,
  ESView,
  ESViewActions,
  ESViewHeader,
  ToggleInput,
  Coselect,
  ESViewSmal,
  ESViewActionsBtn
} from "./EmailsSettings.styled";
import { useTheme } from "styled-components";
import { ToggleIcon, ToggleLabel } from "../CampaignForm/CampaignForm.styled";
import EmailEditor from "../../components/EmailsSettings/EmailEditor/EmailEditor";
import AutoComplete from "../../components/UI/AutoComplete/AutoComplete";
import { Spinner } from "react-bootstrap";
import EmailPreview from "../../components/EmailsSettings/EmailPreview/EmailPreview";

import { CTooltip } from '../../components/UI/CTooltip/CTooltip'
import { getNotyfObject, getLabelWithKey } from "../../shared/utility";

export default function EmailsSettings({fromOrigin}) {

  const [emailTypes, setEmailTypes] = useState([]);
  const [emailTypesForSelect, setEmailTypesForSelect] = useState([]);
  const [emailTypesSuggestions, setEmailTypesSuggestions] = useState([]);
  const [emailTitles, setEmailTitles] = useState([]);
  const [titlesForSelect, setTitlesForSelect] = useState([]);
  const [eventMail, setEventMail] = useState([]);;
  const [eventForSelect, setEventForSelect] = useState([]);
  const [showModalParametre, setShowModalParametre] = useState();
  const [emailParams, setEmailParams] = useState();
  const [imageFooter, setImageFooter] = useState();
  const [imageHeader, setImageHeader] = useState();
  const [eventForExistingEmail, setEventForExistingEmail] = useState([])
  const [eventslist, setEventsList] = useState([])
  const [email, setEmail] = useState({
    mailType: "",
    title: "",
    fr_subject: "",
    fr_value: "",
    forAdministration: 0,
    forCustomerEntity: 1,
    forIndustrie: 0,
    forService: 0,
    forSupplierEntity: 0,
  });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewingEmail, setViewingEmail] = useState(false);
  const notyf = new Notyf();
  const { t } = useTranslation();
  const theme = useTheme();

  const dataforreactSelect = (data, type) => {
    let li = [];
    data.map((item, i) => {
      if (type == "titles") {
        let p = {
          value: item.title,
          label: item.title,
        };
        li = li.concat(p);
      } else if (type == "event") {
          let p = {
            value: item.event,
            label: getLabelWithKey(item.event),
          };
          li = li.concat(p);
      } else if (type == "trad") {
          let p = {
            value: item.event,
            label: t(`emailConfig:${getLabelWithKey(item.event)}`),
          };
          li = li.concat(p);
      } else {
          let p = {
            value: item.events,
            label: t(`emailConfig:${getLabelWithKey(item.events)}`),
          };
          li = li.concat(p);
      }
    });

    return li;
  };

  const getEmailsTypes = useCallback(() => {
    Axios.get("/settingsemail//mailTypes")
      .then((res) => {
        const types = res?.data && res.data.length > 0 ? res.data : [];
        setEmailTypes(types);

        // let li=[];
        // types.map((item,i)=>{

        //     let p={
        //         value: item.mailType,
        //         label:item.mailType
        //     }
        //     li=li.concat(p)  
        //  })

        setEmailTypesForSelect(dataforreactSelect(types, "mailType"));
      })
      .catch((err) => {
        notyf.error(t("global:error", "Une erreur s'est produite"));
      });
  }, []);

  // const getEmailsTypesSuggestions = useCallback(
  //   (type) => {
  //     Axios.get("/settingsemail//mailTypesSuggestions", {
  //       params: {
  //         type: type,
  //       },
  //     })
  //       .then((res) => {
  //         const types = res?.data && res.data.length > 0 ? res.data : [];
  //         setEmailTypesSuggestions(types);
  //       })
  //       .catch((err) => {
  //         notyf.error(t("global:error", "Une erreur s'est produite"));
  //       });
  //   },
  //   [email?.mailType]
  // );
  const getEventEmailType = useCallback(
    (type) => {
      Axios.get("/settingsemail//EventEmailType", {
        params: {
          mailType: type,
        },
      })
        .then((res) => {
          const events = res?.data && res.data.length > 0 ? res.data : [];
          setEventMail(events);
          setEventForSelect(dataforreactSelect(events, "trad"));
          console.log(dataforreactSelect(events, "event"))
        })
        .catch((err) => {
          notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    }, [email?.mailType]);

  const getEmailsTitles = useCallback(
    (type) => {
      Axios.get("/settingsemail//mailTitles", {
        params: {
          mailType: type,
        },
      })
        .then((res) => {
          const titles = res?.data && res.data.length > 0 ? res.data : [];
          setEmailTitles(titles);
          setTitlesForSelect(dataforreactSelect(titles, "titles"));
        })
        .catch((err) => {
          notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    }, [email?.mailType]);

  const getEmailByParams = useCallback(
    (type, title) => {
      Axios.get("/settingsemail//emailByParams", {
        params: {
          mailType: type,
          mailTitle: title,
        },
      })
        .then((res) => {
          const email = res?.data?.email ? res.data?.email : null;
          const eventForExistingEmail = res?.data?.variable ? res.data?.variable : null;
          setEmail(email);
          setEventForExistingEmail(eventForExistingEmail);
          console.log(res?.data?.variable)
        })
        .catch((err) => {
          notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    },
    [email?.mailType, email?.title]
  );

  const checkExistEmail = useCallback(
    (title) => {
      Axios.get("/settingsemail//checkExistEmail", {
        params: {
          mailTitle: title,
        },
      })
        .then((res) => {
          const exist = res?.data?.exist ? res.data?.exist : false;
          if (exist) {
            setErrors([
              ...errors,
              t("global:errorTitle", "Ce titre existe déjà"),
            ]);
          } else {
            setErrors([]);
          }
        })
        .catch((err) => {
          notyf.error(t("global:error", "Une erreur s'est produite"));
        });
    },
    [email?.title]
  );

  useEffect(() => {
    // getparamsEmail();
    console.log(email)
    getEmailsTypes();
    setViewingEmail(false);
  }, [t]);

  const emailChangeHandler = (e) => {
    const { name, value } = e.target;
    setErrors([]);
    setEmail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const emailTypeChangeHandler = (e) => {
    // const {value} = e.target;
    const value = e.value;
    setErrors([]);
    console.log(email);
    setEmail((prevState) => ({
      ...prevState,
      mailType: value,
      title: "",
      event: "",
      fr_subject: "",
      fr_value: "",
    }));
    getEmailsTitles(value);
    getEventEmailType(value);
  };

  const typeACChangeHandler = (e) => {
    //     const {value} = e.target;
    //     setErrors([]);
    //     if(value?.length > 2){
    //         getEmailsTypesSuggestions(value);
    //     }else {
    //         setEmailTypesSuggestions([]);
    //     }
    // setEmail((prevState) => ({
    //         ...prevState,
    //   mailType: value,
    //     }));
    const value = e.value;
    setErrors([]);
    setEmail((prevState) => ({
      ...prevState,
      mailType: value,
      title: "",
      event: "",
      fr_subject: "",
      fr_value: "",
    }));
    getEmailsTitles(value);
    getEventEmailType(value)
  };

  // const onSelectTypeACHandler = (value) => {
  //   setErrors([]);
  //   setEmail((prevState) => ({
  //     ...prevState,
  //     mailType: value,
  //   }));
  //   setEmailTypesSuggestions([]);
  // };

  const titleChangeHandler = (e) => {
    const value = e.value;
    setViewingEmail(false);
    setErrors([]);
    setEmail((prevState) => ({
      ...prevState,
      title: value,
    }));
    getEmailByParams(email?.mailType, value);
  };

  const getEventByType = (event) => {
    Axios.get("/settingsemail//eventByType", {
      params: {
        event: event,
      },
    })
      .then((res) => {
        console.log(res?.data?.variable)
        //const evt = res?.data?.variable && res.data?.variable?.length > 0 ? res.data.variable : ["a"];
        //setEventslist(res?.data?.variable);
        setEventsList(res?.data?.variable);
      })
      .catch((err) => {
        notyf.error("Une erreuite");
      });
  }
  const eventChangeHandler = (e) => {
    const value = e.value;
    setViewingEmail(false);
    setErrors([]);
    setEmail((prevState) => ({
      ...prevState,
      event: value,
    }));
    getEventByType(value);
  }

  const checkExistEmailHandler = (e) => {
    const { value } = e.target;
    if (value && value.length > 0) {
      checkExistEmail(value);
    }
  };

  const emailContentChangeHandler = (content) => {
    setEmail((prevState) => ({
      ...prevState,
      fr_value: content,
    }));
  };

  const newEmailClickHandler = () => {
    setEmail({
      new: true,
      mailType: "",
      title: "",
      fr_subject: "",
      fr_value: "",
      forAdministration: 0,
      forCustomerEntity: 0,
      forIndustrie: 0,
      forService: 0,
      forSupplierEntity: 0,
    });
  };

  const addEmail = () => {
    Axios.post("/settingsemail//email", email)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          notyf.success(t("global:success", "Email ajouté avec succès"));
          setEmail({
            new: false,
            mailType: "",
            title: "",
            fr_subject: "",
            fr_value: "",
            forAdministration: 0,
            forCustomerEntity: 0,
            forIndustrie: 0,
            forService: 0,
            forSupplierEntity: 0,
          });
          getEmailsTypes();
        } else {
          notyf.error(t("global:error", "Une erreur s'est produite"));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notyf.error(t("global:error", "Une erreur s'est produite"));
      });
  };

  const editEmail = () => {
    Axios.put(`/settingsemail/${email?.uid}/email`, email)
      .then((res) => {
        setIsLoading(false);
        notyf.success(
          t("global:success", "La modification a été effectuée avec succès")
        );
      })
      .catch((err) => {
        setIsLoading(false);
        notyf.error(t("global:error", "Une erreur s'est produite"));
      });
  };

  //   const sendEmail = () => {
  //   Axios.put(`/settingsemail//sendEmail`)
  //     .then((res) => {
  //       notyf.success(
  //         t("global:success", "La modification a été effectuée avec succès")
  //       );
  //     })
  //     .catch((err) => {
  //       notyf.error(t("global:error", "Une erreur s'est produite"));
  //       });
  // };

  const saveEmailClickHandler = () => {
    let errors = checkEmailForm();
    if (errors.length > 0) setErrors(errors);
    else if (email?.new) {
      setErrors([]);
      setIsLoading(true);
      addEmail();
    } else {
      setIsLoading(true);
      setErrors([]);
      editEmail();
    }
  };

  const checkEmailForm = () => {
    let errors = [];
    if (email?.title === "") {
      errors.push(t("global:errorTitle", "Le titre est obligatoire"));
    }
    if (email?.fr_subject === "") {
      errors.push(t("global:errorSubject", "Le sujet est obligatoire"));
    }
    if (email?.fr_value === "") {
      errors.push(t("global:errorContent", "Le contenu est obligatoire"));
    }
    if (email?.mailType === "") {
      errors.push(t("global:errorType", "Le type est obligatoire"));
    }
    return errors;
  };

  // const optionsChangeHandler = (key, value) => {
  //   setEmail((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };

  // function _base64ToArrayBuffer(base64) {
  //   var binary_string = window.atob(base64);
  //   var len = binary_string.length;
  //   var bytes = new Uint8Array(len);
  //   for (var i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   return bytes.buffer;
  // }

  // const getparamsEmail = useCallback(() => {
  //   Axios.get("/documentation//mailParams")
  //     .then((res) => {
  //       const params = res?.data?.data;
  //       console.log(params);
  //       setEmailParams(params);
  //       console.log(res?.data?.images?.imageHeader);

  //       setImageFooter(res?.data?.images?.imageFooter);
  //       setImageHeader(res?.data?.images?.imageHeader);
  //     })
  //     .catch((err) => {
  //       notyf.error(t("global:error", "Une erreur s'est produite"));
  //     });
  // }, []);
  const deleteTitle = () => {


    Axios.delete(`settingsemail/${email?.uid}/deleteTitle`).then(response => {
      getCampaigns()
      console.log(Uid)
      setConfirmationModalShow(false)
      notyf.success("La compagne a bien été supprimé")
    }).catch(response => {

      notyf.error("generatedErrorMessage")
    })

  }

  return (
    <>
      <ESContainer>
        <TitleHelmet title={"OPTICAL CENTER | Emails"} />
        <Header>
          <HeaderInfos>
            <HeaderLocation>
              {fromOrigin !== "emailsSettings" ? t("navbar:PersonnalisationDeMail", "Edition des modéles") : t("navbar:settingConf", "")}
            </HeaderLocation>
          </HeaderInfos>
          <HeaderActions>
            <BtnOutlineLink
              color={theme.colors.primary}
              border={theme.colors.primary}
              to="/emailsTemplate"
            >
              {t("global:template", "Template")}
            </BtnOutlineLink>
            <BtnOutlineAction onClick={() => newEmailClickHandler()}
                color={theme.colors.primary}
                border={theme.colors.primary}>
              <span>{t("btn:Nouveau")}</span>
              {/* <BtnIcon>
                                <AddIcon fontSize="small" />
                            </BtnIcon> */}
            </BtnOutlineAction>
            {isLoading ? (
              <Spinner />
            ) : (
              <BtnOutlineAction onClick={saveEmailClickHandler}
                color={theme.colors.success}
                border={theme.colors.success}>
                <span>{t("btn:Save")}</span>
              </BtnOutlineAction>
            )}
          </HeaderActions>
        </Header>
        <ESBody>
          <ESContent>
            {errors && errors.length > 0 ? (
              <div
                className="alert alert-danger"
                style={{ fontSize: "0.9rem" }}
                role="alert"
              >
                <ul>
                  {errors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            ) : null}
            <ESForm>
              <ESCol>
                <FormGroup>
                  <FormLabel htmlFor="mailType" >{t("emailSetting:Type")} :</FormLabel>
                  {email?.new ? (
                    <Coselect
                      name="mailType"
                      // value={email?.mailType || ""}
                      onChange={(e) => typeACChangeHandler(e)}
                      // suggestions={emailTypesSuggestions}
                      // onSelect={onSelectTypeACHandler}
                      isLoading={!email?.mailType ? true : false}
                      isClearable={true}
                      isRtl={false}
                      options={emailTypesForSelect}
                    />
                  ) : (
                    <Coselect
                      id="mailType1"
                      name="mailType"
                      placeholder={t("emailSetting:SelectionnerUnType")}
                      onChange={(e) => emailTypeChangeHandler(e)}
                      isLoading={!email?.mailType ? true : false}
                      isClearable={true}
                      isRtl={false}
                      options={emailTypesForSelect}
                    >
                    </Coselect>
                  )}
                </FormGroup>
                <FormGroup className={email?.mailType && email?.title ? "fadeIn" : "fadeOut"}>
                  <FormLabel htmlFor="emailTitle" >{t("emailSetting:event")}</FormLabel>
                  {email?.new ? (
                    <>
                      {/* <span>{email?.event}</span> */}
                      <Coselect
                        id="emailTitle"
                        name="event"
                        // value={email?.event || "rzetre"}
                        placeholder={t("emailSetting:SelectionnerUnType")}
                        onChange={(e) => eventChangeHandler(e)}
                        isLoading={!email?.event ? true : false}
                        isClearable={true}
                        isRtl={false}
                        options={eventForSelect}>
                      </Coselect>
                    </>
                  ) :
                    <FormInput
                      style={{ textAlign: "left" }}
                      type="text"
                      name="title"
                      id="emailTitle"
                      value={t(`emailConfig:${getLabelWithKey(email?.event)}`) || ""}
                    // onChange={(e) => emailChangeHandler(e)}
                    // onBlur={(e) => checkExistEmailHandler(e)}
                    // autoComplete="off"
                    />
                  }
                </FormGroup>
              </ESCol>

              <ESCol>
                <FormGroup className={email?.mailType || email?.new ? "fadeIn" : "fadeOut"}>
                  <FormLabel htmlFor="emailTitle" >{t("emailSetting:Titre")}</FormLabel>
                  {email?.new ? (
                    <FormInput
                      style={{ textAlign: "left" }}
                      type="text"
                      name="title"
                      id="emailTitle"
                      value={email?.title || ""}
                      onChange={(e) => emailChangeHandler(e)}
                      onBlur={(e) => checkExistEmailHandler(e)}
                      autoComplete="off"
                    />
                  ) : (
                    <Coselect
                      className="basic-single"
                      classNamePrefix="select"
                      id="emailTitle"
                      name="title"
                      //value={titlesForSelect || "titre"}
                      placeholder={t("emailSetting:SelectionnerUnType")}
                      onChange={(e) => titleChangeHandler(e)}
                      isLoading={!email?.title ? true : false}
                      isClearable={true}
                      isRtl={false}
                      options={titlesForSelect}>
                    </Coselect>
                  )}

                </FormGroup>



              </ESCol>
            </ESForm>
            <ESViewSmal className={email?.title && email?.event ? "fadeIn" : "fadeOut"}>
              <ESViewHeader style={{ padding: "1rem" }}>
                <ESSubject >
                  <FormLabel htmlFor="emailSubject" >{t("emailSetting:Titre")}:</FormLabel>
                  <FormInput
                    style={{ textAlign: "left" }}
                    id="emailSubject"
                    type="text"
                    name="fr_subject"
                    value={email?.title}
                  // onChange={(e) => emailChangeHandler(e)}
                  // autoComplete="off"
                  />
                </ESSubject>
                <ESViewActions>
                  {!viewingEmail ? (<>
                    <BtnOutlineAction disabled={!email?.fr_value}
                      color={theme.colors.danger}
                      border={theme.colors.danger}
                      onClick={() => deleteTitle()}>
                      {t("btn:Supprimer")}
                    </BtnOutlineAction>
                    <BtnOutlineAction
                      color={theme.colors.primary}
                      border={theme.colors.primary}
                      disabled={!email?.fr_value}
                      onClick={() => setViewingEmail(true)}>
                      {t("btn:Visualiser")}
                    </BtnOutlineAction>
                  </>
                  ) : (
                    <BtnOutlineAction 
                      color={theme.colors.primary}
                      border={theme.colors.primary}
                      disabled={!email?.fr_value}
                      onClick={() => setViewingEmail(false)}>
                      {t("btn:Editer")}
                    </BtnOutlineAction>
                  )}
                </ESViewActions>
              </ESViewHeader>
            </ESViewSmal>
            <ESView className={email?.title && email?.event ? "fadeIn" : "fadeOut"}>

              <ESViewHeader>
                <ESSubject style={{}}>
                  <FormLabel htmlFor="emailSubject" >{t("emailSetting:Sujet")}</FormLabel>
                  <FormInput
                    style={{ textAlign: "left" }}
                    id="emailSubject"
                    type="text"
                    name="fr_subject"
                    value={email?.fr_subject || ""}
                    onChange={(e) => emailChangeHandler(e)}
                    autoComplete="off"
                  />
                </ESSubject>
                <ESViewActions>

                  {email?.new ?
                    eventslist?.map((item, i) => (
                      <CTooltip title={item.description} placement="right">
                        <span style={{ fontSize: "1rem", marginRight: "10px" }} key={i}> {item.variable}  </span>
                      </CTooltip>



                    ))
                    : eventForExistingEmail?.map((item, i) => (
                      <CTooltip title={item.description} placement="right">
                        <span style={{ fontSize: "1rem", marginRight: "10px" }} key={i}> {item.variable}  </span>
                      </CTooltip>

                    ))
                  }


                </ESViewActions>
              </ESViewHeader>
              {viewingEmail ? (
                <EmailPreview
                  emailParams={emailParams}
                  imageHeader={imageHeader}
                  imageFooter={imageFooter}
                  convertedContent={email?.fr_value || ""}
                  event={email?.event} />
              ) : (
                <EmailEditor
                  content={email?.fr_value || ""}
                  addTextHandler={emailContentChangeHandler} />
              )}
            </ESView>
          </ESContent>

          {/*  //marzouk parametre template dans un modal
          
          {showModalParametre && (
            <EmailParametersModal
              show={showModalParametre}
              closeModal={() => setShowModalParametre(false)}
            />
          )} */}
        </ESBody>
      </ESContainer>
    </>
  );
}
