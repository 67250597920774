import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CIContainer = styled.div`
    width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    background-color: #FFFFFF;
`;

export const CITitle = styled.span`
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
`

export const CIContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 0.9rem;

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        font-size: 0.8rem;
    }
`

export const CIData = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
`

export const CIActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0.5rem;
`

// export const CIUsersLink = styled(Link)`
//     background-color: ${({theme}) => theme.colors.greenLight};
//     color: ${({theme}) => theme.colors.success};
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     /* font-size: 1rem; */
//     font-weight: 500;
//     padding: 0.125rem 1.2rem;
//     text-decoration: none;
//     border-radius: 8px;
//     width: fit-content;

//     &:hover {
//         color: ${({theme}) => theme.colors.success};
//     }

//     @media (max-width: ${({theme}) => theme.size.laptopL}) {
       
//     }
// `

// export const CIDeleteBtn = styled.button`
//     display: flex;
//     justify-content: center ;
//     align-items: center;
//     margin-left: 1rem;
//     /* padding: 0.5rem; */
//     border: 2px solid ${({border, theme}) => border ? border : theme.colors.redLight};
//     border-radius: 8px;
//     color: ${({color, theme}) => color || theme.colors.redLight};
//     cursor: pointer;
//     /* font-size: 1rem; */
//     background-color: #FFFFFF;

//     & span {
//         color: ${({color, theme}) => color || theme.colors.redLight};
//     }

//     &:hover {
//         background-color: ${({theme}) => theme.colors.redLight};
//         color: #FFFFFF;
//     }
// `;

export const DocDawnload = styled(Link)`
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    cursor: pointer;
`
export const CIDeleteBtn = styled.span`
    color: ${({theme}) => theme.colors.redLight};
    text-decoration: none;
    cursor: pointer;
`

export const CIUsersLink = styled(Link)`
    color: ${({theme}) => theme.colors.primary};  
    
    text-decoration: none;
    cursor: pointer;
`
export const CIOwnerLink = styled(Link)`
    color: ${({theme}) => theme.colors.success};  
    
    text-decoration: none;
    cursor: pointer;
`