import React, { useEffect, useState } from 'react'
import MessageIcon from '@mui/icons-material/Message';
import {formatDate, getTimeDate} from '../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';

function MessageEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={false} >
                        <MessageIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle >
                            {t("contract:comment")}
                        </TETitle>
                        <TETime>
                            {t("mandat:on")} {formatDate(+eventData?.creationDate)} {t("mandat:at", "à")} {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            <MessageWrapper>
                                <MessageFrom>
                                    {t("mandat:message", "Message de ")}  
                                    {(
                                        eventData?.user?.first_name 
                                        || eventData?.user?.last_name 
                                        ? 
                                        (
                                            eventData?.user?.first_name 
                                            ? eventData?.user?.first_name + ' ' 
                                            : null
                                        ) + (
                                            eventData?.user?.last_name ? 
                                                eventData?.user?.last_name 
                                            : null
                                            ) : eventData?.user?.login 
                                    )}
                                </MessageFrom>
                                <MessageContent>
                                    {eventData?.message?.slice(0, 128)}
                                    {eventData?.message?.length > 128 ? '...' : null}
                                </MessageContent>
                            </MessageWrapper>
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default MessageEvent