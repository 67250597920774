import React, {useEffect, useMemo, useState} from 'react';
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import {HelmetProvider} from 'react-helmet-async';
import GlobalStyles from './styles/Global';
import theme from './styles/theme';
import darkTheme from './styles/DarkTheme';
import Auth from './containers/Auth/Auth';
import AccountRequest from './components/AccountRequest/AccountRequest';
import ResetPassword from './components/Signin/ResetPassword/ResetPassword';
import ForgotPassword from './components/Signin/ForgottenPassword/ForgotPassword';
import OwnerRoot from './containers/AccesRoots/OwnerRoot/OwnerRoot';
import OwnerRootStandard from './containers/AccesRoots/OwnerRoot/OwnerRootStandard';
import SupplierRoot from './containers/AccesRoots/SupplierRoot/SupplierRoot';
import ClientRoot from './containers/AccesRoots/ClientRoot/ClientRoot'; 
import ContractToken from './containers/ContractToken/ContractToken';
import './App.css'


function App(props) {
  const {currentTheme, onTryAutoSignup, isAuthenticated, userType, userLevel, authRedirectPath, loading} = props;
  const accessTokenList = ["document", "forgotPassword", "account", "campaign", "invoiceToken"];
  let location = useLocation();
  const navigate = useNavigate();
  const userRole = localStorage.getItem('role');

  useEffect(() => {
    onTryAutoSignup()
  }, []);

  useEffect(() => {
    const accessToken = accessTokenList.includes(location.pathname.split("/")[1]);
    if(authRedirectPath && !accessToken) 
      navigate('/');
  }, [authRedirectPath]);

  let routes;

  if(isAuthenticated) {
    switch (userType) {
      // case "supplier":
      //   routes = (
      //     <SupplierRoot />
      //   )
      //   break;
      case "client":
        routes = (
          <ClientRoot />
        )
        break;
      case "owner":
        
        routes = (
          ((userRole == "superadmin") || (userRole == "admin")) ? 
          <OwnerRoot /> :
          <OwnerRootStandard /> 
        )
        break;
    
      default:
        break;
    }
  } else {
    routes = (
      <>
        <Routes>
          <Route path="/newAccountRequest" element={<AccountRequest />}/>
          <Route path="/forgotPassword" element={<ForgotPassword />}/> 
          <Route path="/document/:token" element={<ContractToken />}/>
          <Route path="/account/:token" element={<ResetPassword />}/>          
          <Route path="/" element={<Auth />} />
        </Routes>
      </>
    )
  }

  return (
    <ThemeProvider theme={currentTheme === 'dark' ? darkTheme : theme}>
      <HelmetProvider>
        <GlobalStyles />
        <div className="app">
          {routes}
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    currentTheme: state.app.theme,
    // isAuthenticated: localStorage.getItem('token') !== null,
    isAuthenticated: state.auth.token !== null,
    loading : state.auth.loading,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    userRole: state.auth.userRole,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(App);
