import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { HomeContainer, HomeCardsLeftSide, HomeCardsRightSide, HomeHeader, HeaderInfos, HeaderLocation, HeaderTitle, HeaderActions, HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, HomeInvoices } from './Home.styled'
import Select from '../../components/UI/Select/Select';
import Card from '../../components/Home/Card/Card';
import LineChart from '../../components/Home/LineChart/LineChart';
import DelaySignatures from '../../components/Home/DelaySignatures/DelaySignatures';

import Events from '../../components/Home/Events/Events';
import LastInvoices from './LastInvoices/LastInvoices';
import { useTheme } from 'styled-components';
import { getNotyfObject } from '../../shared/utility';
import { Link } from 'react-router-dom';
import { SupplierLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
import { formatDateEur } from '../../shared/utility'
import { BtnAction } from '../../styles/Common';
import { useTranslation } from 'react-i18next';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PendingIcon from '@mui/icons-material/Pending';
import BusinessIcon from '@mui/icons-material/Business';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ArticleIcon from '@mui/icons-material/Article';
import TaskIcon from '@mui/icons-material/Task';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import SyncIcon from '@mui/icons-material/Sync';

function Home(props) {
    const { logout } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const notyf = getNotyfObject();
    const [value, setValue] = useState('annee');
    const [activeSuppliers, setActiveSuppliers] = useState(0);
    const [inProgressSuppliers, setInProgressSuppliers] = useState(0);
    const [scheduledContracts, setScheduledContracts] = useState(0);
    const [inprogressContracts, setInProgressContracts] = useState(0);
    const [completedContracts, setCompletedContracts] = useState(0);
    const [refusedContracts, setRefusedContracts] = useState(0);
    const [notPaidContracts, setNotPaidContracts] = useState(0);
    const [litigationContracts, setLitigationContracts] = useState(0);
    const defaultTime = new Date();
    defaultTime.setFullYear(defaultTime.getFullYear() - 1);
    const [startDate, setStartDate] = useState(formatDateEur(defaultTime.getTime()));
    const [endDate, setEndDate] = useState(formatDateEur(new Date().getTime()));
    const [eventData, setEventData] = useState();
    const [KPIs, setKPIs] = useState({
        totalSent: '',
        totalSigned: '',
        totalNotSigned: '',
        totalDocsForOwner: '',
        countDocumentsWaitting: '',
        totalNotSignedByEntity: [],
        totalNotSignedByEntityRelaunched: [],
        avgDays: '',
        avgDaysEntities: []
    });

    const options = [
        { label: t("dashboard:thisYear", "Cette année"), value: 'annee' },
        { label: t("dashboard:thisMonth", "Ce mois"), value: 'mois' }
    ];

    const eventsList = [
        { color: theme.colors.success, value: 'Fournisseur 1', date: '5 minuites' },
        { color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites' },
        { color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites' },
        { color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites' },
        { color: theme.colors.success, value: 'Campagne 1', date: '5 minuites' },
        { color: theme.colors.danger, value: 'Transdev', date: '5 minuites' },
        { color: theme.colors.success, value: 'Transdev', date: '5 minuites' },
    ]

    const interval = (date) => {

        return formatDateEur(date)
    }

    const selectChange = (value) => {
        const Landing = new Date();
        const dateNow = Landing.getTime();

        setValue(value);
        switch (value) {
            case "annee":
                Landing.setFullYear(Landing.getFullYear() - 1);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "mois":
                Landing.setDate(Landing.getDate() - 30);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "jour":
                Landing.setDate(Landing.getDate() - 1);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            default:
                break;
        }

    }

    const getSuppliersCount = (status) => {
        Axios.get("/entity//suppliersCount", {
            params: {
                status: status,
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.suppliersCount) {
                switch (status) {
                    case 'active':
                        setActiveSuppliers(res.data.suppliersCount)
                        break;
                    case 'inProgress':
                        setInProgressSuppliers(res.data.suppliersCount)
                        break;
                    default:
                        break;
                }
            }
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getContractsCount = (status) => {
        Axios.get("/contract//contractsCount", {
            params: {
                status: status,
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            // console.log(res.data.contractsCount);
            if (res?.data?.contractsCount) {
                switch (status) {
                    case 'SCHEDULED':
                        setScheduledContracts(res.data.contractsCount)
                        break;
                    case 'INPROGRESS':
                        setInProgressContracts(res.data.contractsCount)
                        break;
                    case 'COMPLETED':
                        setCompletedContracts(res.data.contractsCount)
                        break;
                    case 'REFUSED':
                        setRefusedContracts(res.data.contractsCount)
                        break;
                    default:
                        break;
                }
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

    const getKPIS = () => {
        Axios.get("/contract//statKPIs", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            setKPIs(res?.data)
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

    const getNotPaidContracts = () => {
        Axios.get("/contract//notPaidContracts", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.count) {
                setNotPaidContracts(res.data.count)
            }
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getLitigationContracts = () => {
        Axios.get("/contract//litigationContracts", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.count) {
                setLitigationContracts(res.data.count)
            }
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }
    const getEventEntity = () => {
        Axios.get("/entityevent/").then(res => {

            setEventData(res.data.events)
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const insertFakeInvoice = () => {
        Axios.post("/invoice//fakeInvoice").then(res => {
            if (res?.data?.success)
                notyf.success("Facture créée!");

        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const lunchOcr = () => {
        Axios.post("/invoice//lunchOcr").then(res => {
            if (res?.data?.success)
                notyf.success("succés!");

        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    useEffect(() => {
        getEventEntity();
        // getSuppliersCount("active");
        // getSuppliersCount("inProgress");
        getNotPaidContracts()
        getLitigationContracts()
        getContractsCount("SCHEDULED")
        getContractsCount("INPROGRESS")
        getContractsCount("COMPLETED")
        getContractsCount("REFUSED")
        getKPIS()        
    }, [value]);

    useEffect(() => {        
        const timer = setTimeout(() => {
            logout()
        }, 1800000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <HomeContainer>
                <TitleHelmet title={"OPTICAL CENTER | " + t("menu:home", "Accueil")} />
                <HomeHeader>
                    <HeaderInfos>
                        <HeaderLocation>{t("menu:home", "Accueil")}</HeaderLocation>
                        <HeaderTitle>{t("menu:portal", "Portail")}</HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <HeaderAction>
                            <Select
                                options={options}
                                value={options.find(option => option.value === value)?.label}
                                onChange={selectChange}
                            />
                        </HeaderAction>
                        {/* <HeaderAction>
                        <BtnAction
                            onClick={lunchOcr}
                            color={theme.colors.success}
                        >
                            {t("menu:launch", "Lancer l'OCR")}
                        </BtnAction>
                    </HeaderAction> */}
                    </HeaderActions>
                </HomeHeader>
                <HomeBody>
                    <HomeCards>
                        <HomeCardsLeftSide>
                           
                            <Card
                                icon={<InsertDriveFileIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:totalDocument")}
                                value={<SupplierLink
                                    to="/gestionContract"
                                >{KPIs.totalDocument}</SupplierLink>}
                            />    
                            <Card
                                icon={<InsertDriveFileIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:totalSent", "Nombre de documents envoyés")}
                                value={<SupplierLink
                                    to="/gestionContract/1"
                                >{KPIs.totalScheduled}</SupplierLink>}
                            />
                            <Card
                                icon={<SyncIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:totalInProgress", "Nombre de documents en cours")}
                                value={<SupplierLink
                                    to="/gestionContract/2"
                                >{KPIs.totalInProgress}</SupplierLink>}
                            /> 
                            <Card
                                icon={<TaskIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:totalCompleted", "Nombre de documents terminés")}
                                value={<SupplierLink
                                    to="/gestionContract/3"
                                >{KPIs.totalCompleted}</SupplierLink>}
                            />
                            {/* lockpad */}
                            <Card
                                icon={<ArchiveIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:totalComp", "Nombre de documents archivés")}
                                value={<p
                                    to="/gestionContract/3"
                                >{KPIs.movedToSafe}</p>}
                            />
                            <Card
                                icon={<ContentPasteOffIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:totalSigned", "Nombre de documents refusés")}
                                value={<SupplierLink
                                    to="/gestionContract/4"
                                >{KPIs.totalRefused}</SupplierLink>}
                            />
                             <Card
                                icon={<HourglassTopIcon style={{ color: "#0090FF" }} />}
                                title={t("KPIs:avgDays", "Délai de traitement moyen")}
                                value={KPIs.avgDays}
                            />                          
                            
                                        
                        </HomeCardsLeftSide>
                        <DelaySignatures usersSignatureDelay={KPIs?.delaiSignatureQr}/>

                        
                        {/* <Card
                            icon={<BusinessIcon style={{ color: "#0090FF" }} />}
                            title={t("KPIs:totalNotSignedByEntity", "Nombre de clients actifs")}
                            value={KPIs.totalNotSignedByEntity}
                        /> */}
                        {/* <Card
                            icon={<BusinessIcon style={{ color: "#0090FF" }} />}
                            title={t("KPIs:totalNotSignedByEntityRelaunched", "Nombre de clients actifs")}
                            value={KPIs.totalNotSignedByEntityRelaunched}
                        /> */}
                        {/* <Card
                            icon={<BusinessIcon style={{ color: "#0090FF" }} />}
                            title={t("KPIs:avgDaysEntities", "Nombre de clients actifs")}
                            value={KPIs.avgDaysEntities}
                        /> */}

                        {/* <Card
                            icon={<BusinessIcon style={{ color: "#0090FF" }} />}
                            title={t("dashboard:numberOfActifCustomers", "Nombre de clients actifs")}
                            value={<SupplierLink
                                to="/referentielClient"
                                state={{
                                    filtersProps: {
                                        status: 'ACTIVE',
                                        type: 'client'
                                    }
                                }}
                            >{activeSuppliers}</SupplierLink>}
                        />
                        <Card
                            icon={<HomeRepairServiceIcon style={{ color: "#0090FF" }} />}
                            title={t("dashboard:customersInTheProcessOfBoarding", "Clients en cours d’embarquement")}
                            value={<SupplierLink
                                to="/referentielClient"
                                state={{
                                    filtersProps: {
                                        status: 'INPROGRESS',
                                        type: 'supplier'
                                    }
                                }}
                            >{inProgressSuppliers}</SupplierLink>}
                        />
                        <Card
                            icon={<HourglassTopIcon style={{ color: "#F9B959" }} />}
                            title={t("dashboard:currentContract", "Contrats en cours")}
                            value={<SupplierLink
                                to="/gestionContract"
                                state={{
                                    filtersProps: {
                                        status: 'INPROGRESS',
                                        type: 'supplier'
                                    }
                                }}
                            >{notPaidInvoices}</SupplierLink>}
                        />
                        <Card
                            icon={<ReportProblemIcon style={{ color: "#F9B959" }} />}
                            title="Anomalies"
                            value={litigationContracts}
                        /> */}
                    </HomeCards>
                    <HomeStats>
                        <LineChart period={value} />
                        <Events events={eventData} />
                    </HomeStats>
                    

                    {/* <HomeInvoices>
                    <LastInvoices entityFilter={"supplier"}/>
                </HomeInvoices> */}
                </HomeBody>
            </HomeContainer>
        </>
    );
}
const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

