import React, { useState, useCallback, useEffect } from 'react'
import { Notyf } from 'notyf';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { SGBody, SGCol, SGColTitle, SGContainer, SGFormGroup, SGSection, Input, CFFormGroup } from './FlowsSettings.styled';
import { BtnOutlineAction, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';


function FlowSettingsSignature({updated, editSignatureDimension, code, documentsType}) {
    
    const { t } = useTranslation();
    const theme = useTheme()
    const [newDimension, setNewDimension] = useState()
    const[showDimension, setShowDimension] = useState(false)
    const [stringSignatureDiension, setStringSignatureDiension] = useState()
    useEffect(() => {
        let height = documentsType?.filter(item=> item?.code == code)[0]?.height
        let width = documentsType?.filter(item=> item?.code == code)[0]?.width
        setNewDimension({...newDimension,
            height: height,
            width: documentsType?.filter(item=> item?.code == code)[0]?.width
        })
        setStringSignatureDiension("Hauteur: " + height + "px, Largeur: " + width + "px")
    }, [documentsType])


    // useEffect(() => {
    //     let height = documentsType?.filter(item=> item?.code == code)[0]?.height
    //     let width = documentsType?.filter(item=> item?.code == code)[0]?.width
    //     setStringSignatureDiension("Hauteur: " + height + "px, Largeur: " + width + "px")
    // }, [updated])


    function dimensionInputChangeHandler(e){
        let name = e?.target?.name
        let value = e?.target?.value
        console.log(name)
        console.log(value)
        setNewDimension({...newDimension,
            [name] : value
        })
    }

    
    const checkInputValidity = (e) => {
        let value = e?.target?.value
        let name = e?.target?.name
        if(name == "width"){
            if(value < 30 ) value = 30
            if(value > 80) value = 80 
        }else{
            if(value < 6 ) value = 6
            if(value > 16) value = 16 
        }
        setNewDimension({
            ...newDimension,
            [name]: value
        })
    }

    return (
        <>
            <div className="mandatClause__content" style={{marginTop:"15px"}}>
                <div style={{display:"flex", color: "gray"}}>
                    <h5>Fichier signature: </h5>
                    <EditIcon style={{width:"20px", cursor:"pointer"}} onClick={e => setShowDimension(!showDimension)}/>
                </div> 
                <span>{stringSignatureDiension}</span>
            </div>
            {
                showDimension && 
                <div style={{display:"flex", border:"2px solid #efe8df", width:"40%", borderRadius:"5px", padding:"10px"}}>
                    <CFFormGroup>
                        <FormLabel htmlFor="height" >{t("contract:titl", "Hauteur")} </FormLabel>
                        <Input
                            type="number"
                            placeHolder="height"
                            value={ newDimension?.height}
                            onChange={e => dimensionInputChangeHandler(e)}
                            maxLength={255}
                            name="height"
                            onBlur={e => checkInputValidity(e)}
                        />
                    </CFFormGroup>
                    <CFFormGroup>
                        <FormLabel htmlFor="width" >{t("contract:titl", "Largeur")} </FormLabel>
                        <Input
                            type="number"
                            placeHolder="width"
                            value={newDimension?.width}
                            onChange={e => dimensionInputChangeHandler(e)}
                            maxLength={255}
                            name="width"
                            onBlur={e => checkInputValidity(e)}
                        />
                    </CFFormGroup>
                    <BtnOutlineAction
                        color={theme.colors.success}
                        border={theme.colors.success}
                        onClick={(e) => { setShowDimension(!showDimension); editSignatureDimension(newDimension, code) }}
                        >
                        <span>{t("contract:si", "Sauvegarder")}</span>
                    </BtnOutlineAction>
                </div>
            }
        </>
  )
}

export default FlowSettingsSignature