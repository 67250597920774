import React from 'react'
import './TablePagination.css';
import Pagination from "react-js-pagination";
import { useTranslation } from 'react-i18next';
import { PerPageGroup, PerPageLabel, PerPageSelect, TPContainer } from './TablePagination.styled';
import { FormGroup, FormLabel, FormSelect } from '../../../styles/Common';
import  ListeMenu from '../DensityAndColumns/ListeMenu'
import ShowColumns from '../DensityAndColumns/ShowColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faUsers, faUsersRays } from '@fortawesome/free-solid-svg-icons'

function TablePagination(props) {
    const {t} = useTranslation();
    const {currentPage, pageChange, totalElement, perPage, perPageChange,setDensity,columns,setColumns, userType, navTitre, loadColumns, setLoadColumns, showAll, setShowAll, setShowAllContacts, showAllContacts, inactiveContacts} = props;
    const pageNumbers = [];
    const pageElement = perPage || 10;
    for (let i = 1; i <= Math.ceil(totalElement / pageElement); i++) {
        pageNumbers.push(i);
    }
    console.log(inactiveContacts)

  return (
      <TPContainer>
          {
            <>
            <div style={{display: 'flex'}}>

                {perPageChange && (
                    <PerPageGroup>
                        <PerPageLabel htmlFor="perPage">{t("global:lines", 'Lignes')}</PerPageLabel>
                        <PerPageSelect 
                            id="perPage" 
                            value={perPage}
                            onChange={(e) => perPageChange(e.target.value)}
                        >
                            <option value="2">2</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </PerPageSelect>
                        { setDensity?
                        <ListeMenu setDensity={setDensity}/>:null}
                        {columns?
                        <ShowColumns columns={columns} setColumns={setColumns} userType={userType} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}/>:null}
                    </PerPageGroup>
                )}
                {showAllContacts != undefined &&  
                    <div style={{width: "107px",
                                display: "flex",
                                padding: "7px 9px",
                                marginLeft: "15px",
                                borderRadius: "7px",
                                border: "3px solid #F5FBFF"}}>
                            <input
                                type="checkbox"
                                onChange={(e) => setShowAllContacts(!showAllContacts)}
                                checked={showAllContacts}
                                className="form-check-input"
                                style={{ margin: 0 }}
                                id="exampleCheck1"
                            />
                            {
                                showAllContacts 
                                ?   <FontAwesomeIcon icon={faUsers} style={{color:"#0d6efd",padding: "0px 0px 0px 13px"}} />
                                :   <FontAwesomeIcon icon={faUsersRays} style={{color:"gray",padding: "0px 0px 0px 13px"}} />
                            }
                            <span style={{color:"gray",padding: "0px 0px 0px 13px",margin: "-4px auto"}}>
                                {inactiveContacts}
                            </span>
                            {/* <FormLabel className="form-check-label" for="exampleCheck1">Toutes</FormLabel> */}
                    </div>
                }
            </div>
            </>
          }
        
        <nav className="usersMAnag__paginationContainer">
            <div className="usersMAnag__paginationList">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={pageElement}
                    totalItemsCount={totalElement}
                    pageRangeDisplayed={5}
                    onChange={(page) => pageChange(page)}
                    innerClass={"usersMAnag__pagination"}
                    itemClass={"usersMAnag__page_item"}
                    linkClass={"usersMAnag__page_link"}
                    activeClass={"usersMAnag__active"}
                    disabledClass={"usersMAnag__disabled"}
                />
                <span className="usersMAnag__pagination__total">{totalElement} {t('global:result', {defaultValue: "résultat(s)"})} </span>
            </div>
            
        </nav>
    </TPContainer>
  )
}

export default TablePagination
