import React from 'react'
import ContractTokenDetail from './ContractTokenDetail'
import { CTBody, CTContainer } from './ContractToken.styled'


function ContractToken() {

    console.log("ContractToken");
    return (
      <CTContainer>
          <CTBody>
              <ContractTokenDetail />
          </CTBody>
      </CTContainer>
    )
  }
  
  export default ContractToken