import React, { useMemo, useState, useEffect, useRef } from 'react'
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import Recipient from '../../components/ContractForm/Recipient/Recipient';
import User from '../../components/ContractForm/User/User';
import RepertoireModal from '../../components/ContractForm/RepertoireModal/RepertoireModal';
import Axios from '../../axios-proas'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Attachment from '../../components/ContractForm/Attachment/Attachment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { convertFileToBase64, getNotyfObject, formatDate } from '../../shared/utility';
import Spinner from 'react-bootstrap/Spinner'
import { AddRecipientsBtn, AttachementsContainer, CFBody, CFContainer, CFFormGroup, CFFormClearIcon, CFFormSection, CFHeaderInfo, CFHeaderInfoText, CFFormWrapper, CFHeader, CFHeaderProgressBar, CFHeaderActions, CFHeaderTitle, CFHeaderWrapper, CFSection, CFFormLabel, WrapperCanvas, CSContainer, CSNavContent, CFHeaderWrapperPart, CFFormGroupPart } from './ContractToken.styled';
import { BtnOutlineAction, FormInput, FormLabel } from '../../styles/Common';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import { DivLabel, DivGlp, ContentPiecesOrDest } from '../../components/NewDocumentation/EntitiesComponent/EntitiesComponent.Styled'
import Entity from '../Entity/Entity';
import { DocVisu, NoContent } from '../NewDocumentation/NewDocumentation.styled';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import StatusProgressLine from '../../components/Contracts/StatusProgressLine/StatusProgressLine';
// import ModificationsChat from '../../components/Contracts/ContractBody/ModificationsChat/ModificationsChat';
// import TimeLine from '../../components/Contracts/ContractBody/Timeline/TimeLine';
import SignatureCanvas, { getTrimmedCanvas, toDataURL, fromDataURL } from 'react-signature-canvas';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../styles/Table.styled';
import { getTime } from 'date-fns';
import DoneIcon from '@mui/icons-material/Done';
import "./ContractToken.css"
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationModal from '../../components/UI/ConfirmationModal/ConfirmationModal';
import UsersTable from '../../components/ContractForm/UsersTable/UsersTable';
import { flexbox } from '@mui/system';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import LanguageList from '../../components/LanguageList/LanguageList';


function ContractTokenDetail(props) {
    const {
        userLevel,
        userRole
    } = props;

    let { uid, token } = useParams()
    let { useParams1 } = useParams()
    let notyf = getNotyfObject()
    const [url, setUrl] = useState('')
    const [users, setUsers] = useState([])
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()
    const [file, setFile] = useState("")
    const currentDate = new Date()
    let date = currentDate.getDate()
    let month = currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    const [pdfNumPages, setPdfNumPages] = useState(0)
    const userDocSecret = localStorage.getItem('secret')
    const [scrolledDocument, setScrolledDocument] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = React.useState(currentDate)
    const [pdfFile, setPdfFile] = useState()
    const [confirmationModalShow, setConfirmationModalShow] = useState(false)
    const [confirmationModalShow2, setConfirmationModalShow2] = useState(false)
    const [errors, setErrors] = useState([])
    const [signatureDataB64, setSignatureDataB64] = useState(false)
    const [tabSideActive, setTabSideActive] = useState("signature")
    const [nbrMessages, setNbrMessages] = useState()
    const [signatureOld, setSignatureOld] = useState(null)
    const [signatureChanged, setSignatureChanged] = useState(false)
    const [uidContract, setUidContract] = useState()
    const [tokenLoading, setTokenLoading] = useState(false)
    const [userUid, setUserUid] = useState()
    const [userTele, setUserTele] = useState()
    const [userType, setUserType] = useState()
    const [userDivers, setUserDivers] = useState()
    const [autorisationSignature, setAutorisationSignature] = useState(false)
    const [signContractLoading, setSignContractLoading] = useState(false)

    const [contract, setContract] = useState({
        startDate: selectedStartDate.getTime(),
        attachments: [],
        recipients: [],
        title: '',
        text: '',
        status: '',
        fileType: '',
        entityRegion: '',
        step: '',
        users: []
        // secret: false
    })
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([])
    const [messagesLoading, setMessagesLoading] = useState(false)
    const messagesRef = useRef()
    const [attachmentFileData, setAttachmentFileData] = useState([])
    const [contractData, setContractData] = useState([])
    const [signatureDetected, setSignatureDetected] = useState(false)

    const randSmsCode = useMemo(() => Math.floor(100000 + Math.random() * 900000), [])
    const [smsCode, setSmsCode] = useState("")
    const [sendSmsLoading, setSendSmsLoading] = useState(true)
    const [docName, setDocName] = useState("")
    const [imageURL, setImageURL] = useState(null)
    const sigCanvas = useRef({})
    const [matchedSmsCode, setMatchedSmsCode] = useState(false);/// to false
    const [docApproved, setDocApproved] = useState(false)
    const [entityRegion, setentityRegion] = useState("")
    const save = () => sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    const [saveSignature, setsaveSignature] = useState(false)
    const [delegationId, setDelegationId] = useState()
    const [connectedUserId, setConnectedUserId] = useState()
    const [nbrSignWaitting, setNbrSignWaitting] = useState()

    useEffect(() => {
        if (token) {
            Axios.get(`/userContractToken/${token}/userContractDataByToken`).then(response => {
                setUserDivers(response?.data?.userDivers)
                if (response?.data?.contractUid) {
                    Axios.put('/contractentity/' + response?.data?.result?.id + '/firstAccessDate', { userUid: response?.data?.userUid }).then(response => {
                        if (response.data.success) {
                        }
                    })

                    console.log(response)
                    setContract(response?.data?.result)
                    setContractData(response?.data?.result)
                    setUidContract(response?.data?.contractUid)
                    setUserUid(response?.data?.userUid)
                    setUserTele(response?.data?.userTele)
                    setUserType(response?.data?.userType)
                    setAttachmentFileData(response?.data?.attachement)
                    getAttachmentDoc(response?.data?.attachement[0].uid)
                    setDocName(response?.data?.attachement[0].attachment)
                    setentityRegion(response?.data?.entityRegion);
                    setSignatureDetected(response?.data?.ownerSignature)
                    setDelegationId(response?.data?.idDelegation)
                    setConnectedUserId(response?.data?.result?.connectedUserId)
                    setNbrSignWaitting(response?.data?.contractStatus['signed'])
                    //getTeleNumber(response?.data?.userUid)
                    // getNbrMessages(response?.data?.contractUid);
                    if (response?.data?.userType == "client") {
                        if (response?.data?.contractSignatureStatus)
                            setAutorisationSignature(true)
                    } else {
                        if (response?.data?.result?.fileType == 'DIVERS') {
                            if (response?.data?.checkAll?.nbs != 0)
                                setAutorisationSignature(true)
                        } else if (response?.data?.result?.fileType == 'CF') {
                            if (response?.data?.checkAll?.nbs == 0 && response?.data?.result?.lastSignature !== response?.data?.userUid) {
                                console.log(response?.data?.result?.connectedUserRole == 'standard')
                                if (response?.data?.result?.connectedUserRole == 'standard') {
                                    setAutorisationSignature(true)
                                }
                            }
                            //pour LL signe apres sas
                            if ((response?.data?.entityRegion == "IL" || response?.data?.result?.connectedUserId == response?.data?.idDelegation) && response?.data?.result.lastSignature == null) {
                                setAutorisationSignature(false);
                            }
                            //pour LL ne peut pas signer si il existe un délégué
                            if (response?.data?.idDelegation !== null && response?.data?.result.lastSignature !== null) {
                                setAutorisationSignature(false)
                                //pour délégué peut signer
                                if (response?.data?.idDelegation == response?.data?.result?.connectedUserId)
                                    setAutorisationSignature(true)
                            }
                        } else {
                            //fileType 'AOCM'
                            if (response?.data?.result?.fileType == 'AOCM' && response?.data?.entityRegion == 'FR' && response?.data?.result.lastSignature == null && response?.data?.checkAll.nbs == 0)
                                setAutorisationSignature(true)
                            //fileType 'DIP' without délégué par token
                            if (response?.data?.result?.fileType == 'DIP' && response?.data?.entityRegion == 'IL' && response?.data?.result.lastSignature == null && response?.data?.checkAll.nbs == 0)
                                setAutorisationSignature(true)
                            //fileType 'DIP' with délégué par token
                            if (response?.data?.result?.fileType == 'DIP' && response?.data?.result?.connectedUserId == response?.data?.idDelegation && response?.data?.result.lastSignature == null && response?.data?.checkAll.nbs == 0)
                                setAutorisationSignature(true)
                        }
                    }
                    if (response?.data?.result?.status == "COMPLETED" || response?.data?.result?.status == "REFUSED") {
                        setAutorisationSignature(false);
                    }
                    console.log(randSmsCode);
                }
                setTokenLoading(true)
            }).catch(response => {
                setTokenLoading(true)

                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }
    }, [])

    // useEffect(() => {
    //     if (autorisationSignature)
    //         getTeleNumber(userUid)
    //     console.log(autorisationSignature)
    // }, [autorisationSignature])

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const addSignaturePdf = (owner) => {
        setSignContractLoading(true);

        let imageURL = null
        let tmp_errors = checkValidityForm()
        setErrors(tmp_errors)
        let terminated, inprogress = false

        if ((owner && signatureDataB64) || (tmp_errors?.length == 0 && !owner)) {
            imageURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
            setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))

            if (contract?.fileType == "FREE") {
                if ((contract?.signatoriesOrder?.length - 1) > +contract?.step) {
                    inprogress = editContractInprogressHandler(uidContract)
                } else {
                    terminated = editContractTermineHandler(uidContract)
                }
            } else if (contract.fileType == "DIVERS") {
                terminated = editContractTermineHandler(uidContract)
            } else {
                if (!owner) {
                    if (!tmp_errors || tmp_errors.length == 0) {
                        if (contract.fileType == "CF" || (contract.fileType == "CSCF" && +nbrSignWaitting > 1) || contract.fileType == "AOCM" || contract.fileType == 'DIP') {
                            inprogress = editContractInprogressHandler(uidContract)
                        } else {
                            terminated = editContractTermineHandler(uidContract)
                        }
                    }
                } else {
                    if ((entityRegion == "IL" || (delegationId == connectedUserId)) || (entityRegion == "FR" && contract.fileType == "AOCM") || contract.fileType == "DIP") {
                        if (signatureDataB64) {
                            terminated = editContractTermineHandler(uidContract)
                        } else {
                            notyf.error(t("contract:noSignatureDetected", "No signature detected"))
                        }
                    } else {
                        if (signatureDataB64) {
                            inprogress = editContractInprogressHandler(uidContract)
                        } else {
                            notyf.error(t("contract:noSignatureDetected", "No signature detected"))
                        }
                    }
                }
            }
        }

    }

    const editContractTermineHandler = (uidContract) => {
        contract.status = 'COMPLETED'

        Axios.put(`/contract/${uidContract}/updateStatusContract`, {
            status: 'COMPLETED'
        }).then(response => {
            if (response?.data?.success) {
                postStatus('COMPLETED')
            } else {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            }
        })
    }

    const editContractInprogressHandler = (uidContract) => {

        contract.status = "INPROGRESS"
        Axios.put(`/contract/${uidContract}/updateStatusContract`, {
            status: contract.status,
            endDate: 0
        }).then(response => {
            console.log(response)
            if (response?.data?.success) {
                postStatus('INPROGRESS')
            } else {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            }
        })

    }

    const editContractRefuse = (uidContract) => {
        setContract({ ...contract, status: 'REFUSED' })
        Axios.put(`/contract/${uidContract}/updateStatusContract`, {
            status: 'REFUSED',
            contract: contract,
            userUid: userUid
        }).then(response => {
            postStatus('REFUSED')
            navigate(-1)
            // notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const getAttachmentDoc = (attachmentUid) => {

        Axios.get(`/contract/${attachmentUid}/attachmentDoc`)
            .then(response => {
                setPdfFile(_base64ToArrayBuffer(response.data.pdfFile))
            }).catch(err => {
                notyf.error('Une erreur s\'est produite !')
            })
    }

    const downAttachmentPdf = (uidContract) => {
        Axios.get(`/contract/${uidContract}/attachmentDoc`)
            .then(response => {
                const linkSource = 'data:application/octet-stream;base64,' + response.data.pdfFile;
                const link = document.createElement("a");
                link.href = linkSource;
                link.download = response.data.attachmentName;
                link.click();
            }).catch(err => {
                notyf.error('Une erreur s\'est produite !')

            })
    }

    const getStatusContract = (uidContract) => {
        Axios.get("contract/" + uidContract + "/statusContract").then(response => {
            getStatusContract(response?.data?.res)
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const postStatus = (status) => {
        Axios.post("contractevent", {
            contractUid: uidContract,
            message: '',
            type: "STEP_STATUS",
            snapshot: status,
            userUid: userUid
        }).then(response => {
            if (status !== 'REFUSED') {

                Axios.put(`/contract//signaturePdf`, {
                    contractUid: uidContract,
                    docName: docName,
                    imgURL: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
                    userUid: userUid,
                    saveSignature: saveSignature,
                    status: status
                }).then(response => {
                    response?.data?.success ?
                        navigate(0) :
                        notyf.error('Une erreur s\'est produite !')
                }
                )

                notyf.success("Vous venez de signer le contrat!")
            } else {
                navigate(0)
            }
        })
    }

    const loadMessages = () => {
        setMessagesLoading(true)
        Axios.get("contractevent", {
            params: {
                contract: uidContract,
                type: "USER_CHAT_EVENT"
            }
        }).then(response => {
            setMessagesLoading(false)
            setMessages(response?.data?.events.reverse())
            messagesRef?.current?.scrollTo({
                top: messagesRef.current.scrollHeight,
                left: 0
            })
        }).catch(response => {
            setMessagesLoading(false)
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getNbrMessages = (uidContract) => {
        setMessagesLoading(true)
        Axios.get("/contractevent/" + uidContract + "/nbrMessages").then(response => {
            setNbrMessages(response?.data?.result['nbrMessages'])
        }).catch(response => {
        })
    }

    const getTeleNumber = (userUid) => {
        if (userUid != null && userTele != null) {
            Axios.get("contract/" + userUid + "/userTeleNum").then(response => {
                if (autorisationSignature) {
                    sendSmsCode(response?.data?.res?.telephone_number)
                }
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        } else {
            notyf.error(t("mandat:generatedErrorMessage", "Vous n'avez pas de numéro de téléphone"))
        }
    }

    const getSignature = (userUid) => {
        Axios.get("user/" + userUid + "/signatureOwner").then(response => {
            setSignatureOld('data:image/png;base64,' + response?.data?.signature)
            sigCanvas.current.fromDataURL('data:image/png;base64,' + response?.data?.signature, { width: 300, height: 133, className: 'sigCanvas' })
            // beginSignatureCanvas('data:image/png;base64,' + response?.data?.signature)
            if (!sigCanvas.current.isEmpty() && (response?.data?.signature != null && response?.data?.signature != "")) {
                setSignatureDataB64(true)
            }
        }).catch(response => {
            // notyf.error(t("mandat:generatedErMessage", "Une erreur s'est produite"))
        })
    }

    const sendSmsCode = (phoneNumber) => {
        Axios.get('/contract/' + phoneNumber + '/sendSmsToSign', {
            params: {
                smsMessage: t("global:msgText", "Bonjour, Votre code de confirmation pour signature est ") + randSmsCode + t("global:valid")
            }
        }).then(response => {
            notyf.success(t("mandat:sendMessageText"))
            setSendSmsLoading(false)
        }).catch(response => {
            setSendSmsLoading(false)
            notyf.error(t("mandat:failedSendingMessageText"));
        })
        const timer = setTimeout(() => {
            navigate(-1)
        }, 120000);
        return () => clearTimeout(timer);

    }

    const pdfScrollHandler = (e) => {
        let docPages = e?.doc?._pdfInfo?.numPages
        //let pdfContainer = document.querySelector(".rpv-default-layout-body")
        setPdfNumPages(docPages)
        if (docPages === 1)
            setScrolledDocument(true)
        else
            setScrolledDocument(false)
    }
    const pageChangeHandler = (e) => {
        let currentPage = e?.currentPage + 1
        let docPages = e?.doc?._pdfInfo?.numPages
        if (currentPage === docPages) {
            setScrolledDocument(true)
        } else {
            //setAdmScrolledEnd(false)
        }
    }

    const smsCodeChangeHandler = (e) => {
        let code = e.target.value;
        if (code.length < 7) {
            setSmsCode(code)
            if (code.length == 6 && +code == randSmsCode) {
                setMatchedSmsCode(true)
            }
        }
        getSignature(userUid);
    }

    const checkValidityForm = () => {
        let errors = []
        if (!scrolledDocument) {
            errors.push(t("contract:readDocumentMessage"));
        }
        if (!docApproved) {
            errors.push(t("contract:approuveDocMessage"));
        }
        if (!matchedSmsCode) {
            errors.push(t("contract:checkCodeMessage"));
        }
        if (!signatureDataB64) {
            errors.push(t("contract:noSignDetectedMessage"));
        }
        return errors;
    }

    const endSignatureCanvas = (e) => {
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        if (!sigCanvas.current.isEmpty()) {
            setSignatureDataB64(true)
            setSignatureChanged(true)
        }
    }

    const clearCanvas = (e) => {
        sigCanvas.current.clear();
        setSignatureDataB64(false)
        setSignatureChanged(false)
    }

    const setTabActive = (activeTab) => {
        setTabSideActive(activeTab)
        activeTab != "signature" ? setSignatureDataB64(false) : null
    }

    // console.log("Contracttokent detail userUid" )
    // console.log(userUid)
    return (
        <>
            {!uidContract && tokenLoading ?

                <>
                    <CFContainer>
                        <TitleHelmet title={"OPTICAL CENTER | " + "Document non trouvé"} />

                        <CFHeaderWrapper>
                            <CFHeader>

                                <CFHeaderTitle style={{ margin: "10px 30px", wordBreak: 'break-word', maxWidth: '350px' }}>{t("documentDownload:notFound", "Document non trouvé")}</CFHeaderTitle>

                            </CFHeader>
                        </CFHeaderWrapper>
                    </CFContainer>
                </>
                :
                <>
                    <CFContainer>
                        <TitleHelmet title={"OPTICAL CENTER | " + contract?.title} />

                    </CFContainer>
                    <CFHeaderWrapper>
                        <CFHeader>

                            <CFHeaderTitle style={{ margin: "10px 30px", wordBreak: 'break-word', maxWidth: '350px' }}>{t("global:title", "Titre")} : {contract?.title}</CFHeaderTitle>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <CFHeaderInfo>
                                    <span>{t("global:type")}</span>
                                    <CFHeaderInfoText>{ contract?.fileType == "FREE" ? contract?.freeType : t("contract:" + contract.fileType)}</CFHeaderInfoText>
                                </CFHeaderInfo>

                                <CFHeaderInfo>
                                    <span>{t("contract:dateCreation")}</span>
                                    <CFHeaderInfoText>{formatDate(parseInt(contract.creationDate))}</CFHeaderInfoText>
                                </CFHeaderInfo>
                                <CFHeaderInfo>
                                    <span>{t("contract:recipient")}</span>
                                    <CFHeaderInfoText>{contract.fileType == 'DIVERS' ? userDivers.first_name + ' ' + userDivers.last_name : contract.destinataire}</CFHeaderInfoText>
                                </CFHeaderInfo>
                                <LanguageList />
                            </div>
                        </CFHeader>
                        <CFHeaderProgressBar>
                            <div
                                style={{ display: 'flex', marginLeft: "30px" }}>
                                {contract.status !== '' ?
                                    <StatusProgressLine
                                        fileType={contract?.fileType}
                                        step={contract?.step}
                                        status={contract?.status}
                                        signatoriesOrder={contract?.signatoriesOrder}
                                        userType={userType}
                                    />
                                    : null
                                }
                            </div>
                        </CFHeaderProgressBar>
                    </CFHeaderWrapper>
                    <CFBody>
                        <CFSection>
                            <CFFormWrapper>
                                <CFFormSection style={{ paddingTop: "0.5rem" }}>
                                    <ContentPiecesOrDest
                                        style={{ display: "flex", justifyContent: "space-between" }}>
                                        {attachmentFileData?.map(entity => (
                                            <AddRecipientsBtn key={entity.uid} style={{ textAlign: 'center' }} onClick={() => downAttachmentPdf(entity.uid)}>
                                                {t("contract:download", "Télécharger")}
                                            </AddRecipientsBtn>
                                        ))
                                        }
                                    </ContentPiecesOrDest>
                                    {
                                        pdfFile ? (
                                            <DocVisu>
                                                <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js' />
                                                <Viewer fileUrl={pdfFile}
                                                    onDocumentLoad={(e) => pdfScrollHandler(e)}
                                                    onPageChange={(e) => pageChangeHandler(e)} />

                                            </DocVisu>
                                        ) : (
                                            <NoContent>
                                                {t('global:previewArea')}
                                            </NoContent>
                                        )
                                    }
                                </CFFormSection>
                            </CFFormWrapper>
                            {((autorisationSignature && contract?.fileType != "FREE") || (contract?.fileType == "FREE" && contract?.canISign)) ?
                                <CFFormWrapper style={{ justifyContent: "flex-start", border: "1px solid #D9E1E7CC", borderRadius: "18px" }}>
                                    <>
                                        <ul className="nav nav-tabs" style={{ marginBottom: "10px" }}>
                                            <li className="nav-item">
                                                <span className={"nav-link" + (tabSideActive === "signature" ? " active" : "")} data-toggle="tab" onClick={() => { setTabActive("signature") }} >{t('contract:signature', "Signatures")} </span>
                                            </li>
                                        </ul>
                                        <div id="myTabContent" className="tab-content time-Line-Content" >
                                            {
                                                tabSideActive === "signature" ?
                                                    <div className="tab-pane show active fade modification-bloc">
                                                        <CFFormSection style={{ border: "none" }}>
                                                            {
                                                                errors && errors.length > 0 ? (
                                                                    <div className="alert alert-danger mt-2" role="alert">
                                                                        <ul>
                                                                            {
                                                                                errors.map(error => (<li>{error}</li>))
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                ) : (null

                                                                )
                                                            }
                                                            <CFHeaderWrapperPart style={{ border: 'none' }}>
                                                                <CFFormGroupPart style={{ border: 'none' }}>

                                                                    <div className="signatureTab__step">
                                                                        <div className="singatureTab__step_header">
                                                                            {
                                                                                scrolledDocument ?
                                                                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                    :
                                                                                    <div className="signatureTab__stepNumber"><span>1</span></div>
                                                                            }
                                                                            <div className="step__header__title">
                                                                                {t('contract:pdfLectureM', "Scroller le document jusqu’en bas, pour justifier de votre lecture ")}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="signatureTab__step">
                                                                        <div className="singatureTab__step_header">
                                                                            {
                                                                                docApproved ?
                                                                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                    :
                                                                                    <div className="signatureTab__stepNumber"><span>2</span></div>
                                                                            }
                                                                            <div className="signatureTab__stepCheckbox__container">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="step__checkbox__input"
                                                                                    id="docConfirmCheckbox"
                                                                                    checked={docApproved}
                                                                                    onChange={(e) => setDocApproved(e.target.checked)}
                                                                                />
                                                                                <label
                                                                                    className="step__checkbox__label"
                                                                                    for="docConfirmCheckbox">
                                                                                    {t('contract:approvePdfContent', "J’ai pris connaissance du document et j’approuve le contenu")}
                                                                                </label>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="signatureTab__step">
                                                                        <div className="singatureTab__step_header">
                                                                            {
                                                                                matchedSmsCode ?
                                                                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                    :
                                                                                    <div className="signatureTab__stepNumber"><span>3</span></div>
                                                                            }
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div className="step__header__title">
                                                                                    {t('contract:enterSmsCode', "Entrez le code reçu par SMS")}
                                                                                </div>
                                                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: 'smaller' }}>
                                                                                    <label
                                                                                        style={{ color: "#482D1C", cursor: "pointer", textDecoration: 'underline' }}
                                                                                        onClick={() => { getTeleNumber(userUid) }}>{t("contract:code", "Renvoyer code its")} {userTele ? ("******" + userTele.substring(9, 14)) : <PhoneDisabledIcon></PhoneDisabledIcon>}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="signatureTab__stepContent">
                                                                            <div className="stepContent__identityContent">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control form-control-sm "
                                                                                    placeholder={t('contract:receivedCode', "Code reçu")}
                                                                                    value={smsCode || ''}
                                                                                    disabled={matchedSmsCode}
                                                                                    onChange={(e) => smsCodeChangeHandler(e)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CFFormGroupPart>
                                                            </CFHeaderWrapperPart>
                                                            {scrolledDocument && matchedSmsCode && docApproved ?
                                                                <CFHeaderWrapperPart style={{ border: 'none' }}>

                                                                    <div className="singatureTab__step_header">
                                                                        {
                                                                            signatureDataB64 ?
                                                                                <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                :
                                                                                <div className="signatureTab__stepNumber"><span>4</span></div>
                                                                        }
                                                                        <div className="step__header__title">
                                                                            {t('contract:handSign', "Dessiner ou déposer votre signature dans le carré ci-dessous")}
                                                                            <CFFormClearIcon style={{ marginLeft: "55px" }} onClick={e => clearCanvas(e)}><ClearIcon /></CFFormClearIcon>
                                                                        </div>

                                                                    </div>

                                                                    <WrapperCanvas style={{ paddingTop: '3px', paddingLeft: '3px', marginLeft: '79px' }}>
                                                                        <SignatureCanvas
                                                                            ref={sigCanvas}
                                                                            backgroundColor="white"
                                                                            canvasProps={{ width: 300, height: 133, className: 'sigCanvas' }}
                                                                            // onBegin={e => beginSignatureCanvas(e)}
                                                                            onEnd={e => endSignatureCanvas(e)} />
                                                                    </WrapperCanvas>
                                                                    {signatureChanged ?
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={e => setsaveSignature(!saveSignature)} />
                                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                                Souhaitez vous enregistrer votre signature
                                                                            </label>
                                                                        </div>
                                                                        : null
                                                                    }
                                                                </CFHeaderWrapperPart>
                                                                : null
                                                            }
                                                        </CFFormSection>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                tabSideActive === "signature" ?
                                                    <div style={{ marginLeft: "16px" }}>
                                                        {
                                                            (contract?.fileType == "FREE" && contract?.scheduledDate != null) ?
                                                            <p style={{ fontSize: "11px", marginLeft: "20px" }}>* Date de signature programmée : {formatDate(+contract?.scheduledDate)} </p>
                                                            : null
                                                        }
                                                        <div style={{ display: "flex", marginLeft: "16px", marginBottom: '20px' }}>

                                                            <BtnOutlineAction
                                                                color={signatureDataB64 ? theme.colors.success : theme.colors.disabledBtn}
                                                                border={signatureDataB64 ? theme.colors.success : theme.colors.disabledBtn}
                                                                onClick={() => { setConfirmationModalShow(true) }}
                                                                disabled={!signatureDataB64}>
                                                                <span>{t("contract:sign", "Signer")}</span>
                                                            </BtnOutlineAction>

                                                            <BtnOutlineAction
                                                                color={signatureDataB64 ? theme.colors.danger : theme.colors.disabledBtn}
                                                                border={signatureDataB64 ? theme.colors.redLight : theme.colors.disabledBtn}
                                                                onClick={() => { setConfirmationModalShow2(true) }}
                                                                disabled={!signatureDataB64}>
                                                                <span>{t("contract:refuse")}</span>
                                                            </BtnOutlineAction>
                                                        </div>
                                                    </div> : null
                                            }
                                            <ConfirmationModal
                                                signContractLoading={signContractLoading}
                                                show={confirmationModalShow ? confirmationModalShow : confirmationModalShow2}
                                                modalClosed={() => confirmationModalShow ? setConfirmationModalShow(false) : setConfirmationModalShow2(false)}
                                                confirm={() => confirmationModalShow ? addSignaturePdf(userType != "owner" ? false : true) : editContractRefuse(uidContract)}
                                                cancel={() => confirmationModalShow ? setConfirmationModalShow(false) : setConfirmationModalShow2(false)}
                                                title={"Confirmation"}
                                                message={confirmationModalShow ? t("confirmationModal:signature") + contractData.title + " ?" : t("confirmationModal:refuse") + contractData.title + " ?"}
                                            />
                                        </div>
                                    </>
                                </CFFormWrapper>
                                : null
                            }

                        </CFSection>
                    </CFBody>
                </>

            }

        </>
    )
}

const mapStateToProps = (state) => ({
    loading: state.contracts.loading,
    contractsData: state.contracts.data,
    count: state.contracts.count,
    currentPage: state.contracts.currentPage,
    pageSize: state.contracts.pageSize,
    sortQuery: state.contracts.sortQuery,
    reverse: state.contracts.reverse,
    filtersQuery: state.contracts.filtersQuery,
    showFilters: state.contracts.showFilters,
    userRole: state.auth.role,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel
})
const mapDispatchToProps = dispatch => ({
    getContractsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.contractsGetPage(page, filters, sort, reverse, pageSize)),
    setContractsPage: (page) => dispatch(actions.contractsSetPage(page)),
    setContractsPageSize: (pageSize) => dispatch(actions.contractsSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.contractsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.contractsSetReverseSort(reverseS)),
    setContractsFilters: (filters) => dispatch(actions.contractsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.contractsUpdateShowFilters(show))
})
export default connect(mapStateToProps, mapDispatchToProps)(ContractTokenDetail)


