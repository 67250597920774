import React from 'react'
import { DelaySignaturesContainer } from './DelaySignature.Styled';
import './DelaySignature.css'

function DelaySignatures({usersSignatureDelay}) {

    function getRole (userLevel, userType){
        if(userLevel == "admin" && userType =="client") return "Gérant"
        if(userLevel == "cog" && userType =="client") return "Co-gérant"
        if(userLevel == "standard" && userType =="userDivers") return "Divers"
        if(userLevel == "admin" && userType =="owner") return "Signataire"
        if(userLevel == "standard" && userType =="owner") return "Gestionnaire"
    }


    let signatuyreDelayForm = usersSignatureDelay?.map(signatureDelay => (

        <div className="row " style={{borderBottom: '1px solid',color:"#482D1C", fontize:"0.9rem"}}> 
            <small className="col-md-5" style={{fontSize: "13px"}} >{signatureDelay?.last_name +" "+ signatureDelay?.first_name}</small>
            <small className="col-md-3 ellipsisForm" style={{fontSize: "13px"}}>{getRole(signatureDelay?.level, signatureDelay?.type)}</small>
            <small className="col-md-4" style={{fontSize: "13px"}}>{signatureDelay?.delay}</small>
        </div>
    ))


    return (
        <>

            <DelaySignaturesContainer >
                <p>Top 10 : durée moyenne signature</p>
                <div className="row" style={{marginBottom: "10px", border: '1px solid' }}> 
                    <small className="col-md-5 ellipsisForm" >Nom/Prenom</small>
                    <small className="col-md-3">Rôle</small>
                    <small className="col-md-4">Delai </small>
                </div>
                <div style={{maxHeight: "165px", overflow: "auto"}}>
                    { signatuyreDelayForm }
                </div>

            </DelaySignaturesContainer>
        </>
    )


}

export default DelaySignatures