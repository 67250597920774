import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import { LabelDensityAndColumns } from '../TablePagination/TablePagination.styled';
import { BtnOutlineAction } from '../../../styles/Common'
export default function ListeMenu({ setDensity }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (a) => {
    setDensity(a)
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

        <BtnOutlineAction
          border="#FFF"
          onClick={handleClick}
          size="small"
          sx={{ ml: 3 }}>
          <DensitySmallIcon fontSize="small" color="primary" />
          <LabelDensityAndColumns >{t("global:density")}</LabelDensityAndColumns>
        </BtnOutlineAction>

      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleMenuItemClick("1rem")}>
          <ListItemIcon>
            <DensitySmallIcon fontSize="small" />
          </ListItemIcon>
          <LabelDensityAndColumns > Compact</LabelDensityAndColumns>
        </MenuItem>

        <MenuItem onClick={() => handleMenuItemClick("2.25rem")}>
          <ListItemIcon>
            <DensityMediumIcon fontSize="small" />
          </ListItemIcon>
          <LabelDensityAndColumns > Standard</LabelDensityAndColumns>
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuItemClick("3.3rem")} >
          <ListItemIcon>
            <DensityLargeIcon fontSize="small" />
          </ListItemIcon>
          <LabelDensityAndColumns > Confortable</LabelDensityAndColumns>
        </MenuItem>
      </Menu>

    </React.Fragment>
  );
}
