import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { AppBody, AppContainer, AppContent } from '../../../styles/App.styled';
import Menu from '../../Menu/Menu';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeSupplier from '../../HomeSupplier/HomeSupplier';
import { connect } from 'react-redux';
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import Documentation from '../../Documentation/Documentation';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import UploadInvoice from '../../UploadInvoice/UploadInvoice';
import UploadDoc from '../../UploadDocument/UploadDoc';
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielFournisseur from '../../ReferentielFournisseur/ReferentielFournisseur';
import * as actions from '../../../store/actions/';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import GestionContract from '../../GestionContract/GestionContract';
import ContractForm from '../../ContractForm/ContractForm';
import BorderColorIcon from '@mui/icons-material/BorderColor';


function SupplierRoot({ entityFirst, status }) {
  const { t } = useTranslation();
  const [access, setAccess] = useState(true)
  const [uid, setUid] = useState()
  const menuItems = useMemo(() => ([
    { isLink: true, label: 'Accueil', pathname: '/', icon: <HomeIcon /> },
    { isLink: true, label: 'Référentiel', pathname: `/referentiel`, icon: <BusinessIcon /> },
    { isLink: true, label: t("global:gestionContract"), pathname: '/gestionContract', icon: <BorderColorIcon /> },
    { isLink: true, label: 'Suivi des factures', pathname: '/invoices', icon: <DescriptionIcon /> },
    { isLink: true, label: 'Documentation', pathname: '/documentation', icon: <ArticleIcon /> }
  ]), [t])
  const menuItemsForNotAccess = useMemo(() => ([
    { isLink: true, label: 'Référentiel', pathname: '/entity/' + entityFirst, icon: <BusinessIcon /> },
  ]), [entityFirst])



  return (
    <>

      <AppContainer>
        <Header />
        <AppBody>
          <Menu items={status !== "INPROGRESS" ? menuItems : menuItemsForNotAccess} />
          <AppContent>
            <Routes>
              {status !== "INPROGRESS" ?
                <>
                  <Route path="/invoices" element={<SuiviInvoices entityFilter={"supplier"} />} />
                  <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
                  <Route path="/upload" element={<UploadInvoice />} />
                  <Route path="/UploadDoc" element={<UploadDoc />} />
                  <Route path="/UploadDoc/:uid" element={<UploadDoc />} />
                  <Route path="/documentation" element={<Documentation />} />
                  <Route path="/referentiel" element={<ReferentielFournisseur entityFilter={"supplier"} />} />
                  <Route path="/entity/:uid" element={<Entity />} />
                  <Route path="/entity" element={<Entity />} />
                  <Route path="/userForm/:uid" element={<UserForm />} />
                  <Route path="/userForm" element={<UserForm />} />
                  <Route path="/editPassword" element={<EditPassword />} />
                  <Route path="/gestionContract" element={<GestionContract />} />
                  <Route path="/contractForm" element={<ContractForm />} />
                  <Route path="/contractForm/:uidContract" element={<ContractForm />} />
                  <Route path="/" element={<HomeSupplier />} />
                </>
                : <>
                  <Route path="/userForm/:uid" element={<UserForm />} />

                  <Route path="/upload" element={<UploadInvoice />} />
                  <Route path="/entity/:uid" element={<Entity />} />
                  <Route path="/" element={<Navigate replace to={'/entity/' + entityFirst} />} />

                </>}
            </Routes>
          </AppContent>

        </AppBody>

      </AppContainer>

    </>
  )
}

// const mapStateToProps = (state) => ({
//   userType : state.auth.userType,
//   login : state.auth.login
// })

// const mapDispatchToProps = dispatch => ({
// })
// export default connect(mapStateToProps, mapDispatchToProps) (SupplierRoot)



const mapStateToProps = (state) => ({
  loading: state.suppliers.loading,
  suppliersData: state.suppliers.data,
  count: state.suppliers.count,
  entityFirst: state.auth.entityFirst,
  status: state.auth.status,
  userUid : state.auth.userUid
})
const mapDispatchToProps = dispatch => ({
  getSuppliersPage: (page, filters, sort, reverse, searchField, pageSize) => dispatch(actions.suppliersGetPage(page, filters, sort, reverse, searchField, pageSize)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SupplierRoot)