import React, { useMemo, useState, useEffect, useRef } from 'react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-proas'

function UsersTable({ entity, radioChangeHandler2 }) {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    useEffect(() => {
        // Axios.get("/user//diversTo", {
        //     params: {
        //         recipientUid: entity.uid,
        //         diversType : 'owner'
        //     }
        // }).then(response => {
        //     setUsers(response?.data?.users)
        //     console.log(response?.data?.users)
        // }).catch(response => {
        //     notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        // })
        Axios.get('/user//ownersListForDivers').then(res => {
            setUsers(res.data?.users);
        })
    }, [])

    function getRole (userLevel, userType){
        if(userLevel == "admin" && userType =="client") return "Gérant"
        if(userLevel == "cog" && userType =="client") return "Co-gérant"
        if(userLevel == "admin" && userType =="owner") return "Signataire"
        if(userLevel == "standard" && userType =="userDivers") return "Divers"
        if(userLevel == "standard" && userType =="owner") return "Gestionnaire"
    }

    return (
        <>
            {
                users?.length > 0 ? users.map(user => (
                    <div className="row" style={{ background: "#efe8df", color: "#807b7b", borderBottom: "1px solid azure"}}>
                        <div className="col-sm-1">
                            <input type="radio" className="form-check-input" name="userSelection" id={user?.id} value={user?.id} onChange={e => radioChangeHandler2(e)} />
                        </div>
                        <div className="col-sm-3">{user?.first_name}</div>
                        <div className="col-sm-3">{user?.last_name}</div>
                        <div className="col-sm-2">{getRole(user?.level, user?.type)}</div>
                        <div className="col-sm-3">{entity?.name || user?.entitiesName} </div>
                        {/* <div className="col-sm-2">{entity.code} </div> */}
                    </div>
                )) : <div style={{ whiteSpace: "nowrap" }}>
                    {t("repertoireModal:noUsers")}
                </div>
            }


        </>
    )
}

export default UsersTable
