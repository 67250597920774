import React, { useMemo, useState } from 'react';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from './ExtractionItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import { formatDate } from '../../../shared/utility';
import { useTranslation } from 'react-i18next';
//en premiere
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

//en derniere 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BtnIcon, BtnOutlineAction, BtnOutlineLink} from '../../../styles/Common';
import ConfirmDelete from '../ExtractionModal/ConfirmDelete';
import Axios from '../../../axios-proas'

function ExtractionItem({data}) {
const [showModal,setShowModal]=useState(false)
    const {t} = useTranslation();
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
    // console.log("campagnItem")
    
    // const formatCombinedDate = (date) => {
    //     if(date){
    //         const [start, end] = date.split(':')
    //         let startDate = formatDate(+start)
    //         let endDate = formatDate(+end)
    //         return `${startDate}-${endDate}`
    //     }else 
    //         return ''
    // }
    const formatCombinedDateStart= (date) => {
        if(date){
            const [start, end] = date.split(':')
            let startDate =+start
           
            return startDate
        }else 
            return ''
    }
    const formatCombinedDateEnd = (date) => {
        if(date){
            const [start, end] = date.split(':')
           // let startDate = formatDate(+start)
            let endDate = +end
            return endDate
        }else 
            return ''
    }
    const deleteExtraction =(item)=>{
        console.log(item)
        Axios.delete('extraction/'+item+'/deleteExtractionByUid').then(response => {
            
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })

    }
    return (
        <CIContainer>
            <CITitle>
                Extraction : {(data?.titel)} 
            </CITitle>
            <CIContent>
                <CIData>
                        <DateProgressLine
                            titel={data?.titel}
                            creationDate={+data?.creationDate}
                            campaignDate={formatCombinedDateStart(data?.invoiceCreationDate)}
                            endDate={formatCombinedDateEnd(data?.invoiceCreationDate)}
                        />
                     
                </CIData>
                <CIActions>
                    {/* <CIUsersLink 
                        to={`#`}  
                    >
                            {data?.recipients?.split(',').length} 
                        <GroupIcon style={{fontSize: "1.2rem", marginLeft: "2px"}} />
                    </CIUsersLink> */}
                    <CIDeleteBtn  color="#482D1C" border="#482D1C">
                        Télecharger
                    </CIDeleteBtn>

                    <CIDeleteBtn onClick={() => setShowModal(true)} >
                        Supprimer
                    </CIDeleteBtn>

                    {
                        showModal?(
                            <ConfirmDelete handleClose={()=>setShowModal(false)}
                                           show={showModal}
                                           item={data?.uid}
                                           deleteExtraction={deleteExtraction}/>
                        ):null
                    }
                    <BtnOutlineLink
                        style={{height: "0px"}}
                        color="#06152B"
                        border="#FFF"
                        to={"/extractionForm/"+data.uid}
                        state= {{extractionData: data}}
                        >
                        Voir  <ArrowRightIcon/>
                    
                    </BtnOutlineLink>
                 

                    
                </CIActions>
            </CIContent>
        </CIContainer>
    );
}

export default ExtractionItem;
