import React, { useMemo } from 'react'
import { connect } from 'react-redux';
import Header from '../../../components/Header/Header'
import { AppBody, AppContainer, AppContent } from '../../../styles/App.styled';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Menu from '../../Menu/Menu'
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielFournisseur from '../../ReferentielFournisseur/ReferentielFournisseur';
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import ReferentielClient from '../../ReferentielClient/ReferentielClient';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import GestionContract from '../../GestionContract/GestionContract';
import ContractForm from '../../ContractForm/ContractForm';
import Documentation from '../../Documentation/Documentation';
import NewDocumentation from '../../NewDocumentation/NewDocumentation';
import UploadInvoice from '../../UploadInvoice/UploadInvoice';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import Extraction from '../../Extraction/Extraction';
import ExtractionForm from '../../ExtractionForm/ExtractionForm';
import Campaigns from '../../Campaigns/Campaigns';
import CampaignForm from '../../CampaignForm/CampaignForm';
import EntitiesManager from '../../EntitiesManager/EntitiesManager';
import EmailsSettings from '../../EmailsSettings/EmailsSettings';
import FlowsSettings from '../../FlowsSettings/FlowsSettings';
import Settings from '../../Settings/Settings';
import KPISupplier from '../../KPISupplier/KPISupplier';
import Home from '../../Home/Home';
import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
// import ArticleIcon from '@mui/icons-material/Article';
import Signature from '@mui/icons-material/BorderColor';
// import signature from '../../../assets/images/signature2.png'
import SaveIcon from '@mui/icons-material/Save';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from 'react-i18next';
import ClientHome from '../../ClientHome/ClientHome'
import HomeSupplier from '../../HomeSupplier/HomeSupplier';
import UploadDoc from '../../UploadDocument/UploadDoc';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Profile from '../../UserProfile/UserProfile';
import EmailTemplate from '../../../components/EmailsSettings/EmailTemplate/EmailTemplate';

import ConfigurationMail from '../../ConfigMail/ConfigurationMail';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

function OwnerRoot({ userUid, userType }) {
    const { t } = useTranslation();
    const menuItems = useMemo(() => ([
        { isLink: true, label: t("menu:home", "Accueil"), pathname: '/', icon: <HomeIcon /> },
        // {
        //     isLink: false, label: 'Fournisseur', key: 'supplier', icon: <MoveToInboxIcon />, subMenu: [
        //         { path: '/referentielFournisseur', label: t('navbar:referentielFournisseur', 'Référentiel') },
        //         { path: '/fournisseurInvoices', label: t('navbar:invoiceTracking', 'Suivi des factures') },
        //         { path: '/kpiSupplier', label: t('navbar:kpi', 'KPI') },
        //         { path: '/embarquementSupplier', label: t('navbar:embarquement', 'Embarquement') }
        //     ]
        // },
        {
            isLink: true, label: t("menu:clients", "Sites"), pathname: '/referentielClient', icon: <BadgeIcon />
            // , subMenu: [
            //     { path: '/referentielClient', label: t('navbar:referentielClient', 'Référentiel') },
            //     { path: '/clientInvoices', label: t('navbar:invoiceTracking', 'Suivi des factures') },
            //     { path: '/kpiClient', label: t('navbar:kpi', 'KPI') },
            //     { path: '/embarquementClient', label: t('navbar:embarquement', 'Embarquement') }
            // ]
        },

        { isLink: true, label: t("global:gestionContract", "Gestion contrat"), pathname: '/gestionContract', icon: <Signature /> },


        // { isLink: true, label: 'Documentation', pathname: '/documentation', icon: <ArticleIcon /> },
        // {
        //     isLink: false, label: 'Communication', key: 'communication', icon: <ChatBubbleIcon />, subMenu: [
        //         { path: '/suiviCampagnes', label: t('navbar:suiviCampagnes', 'Suivi des campagnes') }
        //     ]
        // },
        // { isLink: true, label: 'Extraction', pathname: '/extraction', icon: <SaveIcon /> },
        {
            isLink: false, label: 'Administration', key: 'administration', icon: <AdminPanelSettingsIcon />, subMenu: [
                { path: '/entitiesManager', label: t('navbar:entitiesManager', 'Gestion des groupes') },
                { path: '/emailsContentSettings', label: t('navbar:emails', 'Emails') },
                { path: '/emailsSettings', label: t('navbar:emailsSettings', 'Configuration envoi mail') },
                { path: '/configurationMail', label: t("navbar:ConfigurationMail", "Configuration mail") },
                { path: '/flowsSettings', label: t('navbar:flowsSettings', 'Configuration type document') }
            ]
        }
    ]), [t])

    return (
        <>
            <AppContainer>
                <Header />
                <AppBody>
                    <Menu items={menuItems} />
                    <AppContent>
                        <Routes>
                            <Route path="/editPassword" element={<EditPassword />} />
                            {/* <Route path="/referentielFournisseur" element={<ReferentielFournisseur entityFilter={"supplier"} />} />
                            <Route path="/fournisseurInvoices" element={<SuiviInvoices entityFilter={"supplier"} />} />
                            <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
                            <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail />} />
                            <Route path="/embarquementSupplier" element={<Campaigns />} />
                            <Route path="/kpiSupplier" element={<HomeSupplier />} /> */}

                            <Route path="/entity" element={<Entity newAccountRequest={false} />} />
                            <Route path="/entity/:uid" element={<Entity newAccountRequest={false} />} />
                            <Route path="/Profile" element={<Profile userUid={userUid} userType={userType} />} />
                            <Route path="/userForm/:uid" element={<UserForm userType={userType} />} />
                            <Route path="/userForm" element={<UserForm userType={userType} />} />
                            <Route path="/referentielClient" element={<ReferentielClient entityFilter={"client"}/>} />
                            <Route path="/clientInvoices" element={<SuiviInvoices entityFilter={"client"} />} />
                            <Route path="/gestionContract" element={<GestionContract />} />
                            <Route path="/gestionContract/:contractsFilter" element={<GestionContract />} />
                            <Route path="/contractForm" element={<ContractForm />} />
                            <Route path="/contractForm/:uidContract" element={<ContractForm />} />
                            <Route path="/documentation" element={<Documentation />} />
                            <Route path="/addDocumentation" element={<NewDocumentation />} />
                            <Route path="/upload" element={<UploadInvoice />} />
                            <Route path="/UploadDoc/:uid" element={<UploadDoc />} />
                            <Route path="/extraction" element={<Extraction />} />
                            <Route path="/extractionForm" element={<ExtractionForm />} />
                            <Route path="/extractionForm/:uid" element={<ExtractionForm />} />
                            <Route path="/suiviCampagnes" element={<Campaigns />} />
                            <Route path="/newCampaign" element={<CampaignForm />} />
                            <Route path="/newCampaign/:uid" element={<CampaignForm />} />
                            <Route path="/entitiesManager" element={<EntitiesManager />} />
                            <Route path="/emailsContentSettings" element={<EmailsSettings />} />
                            <Route path="/emailsSettings" element={<Settings />} />
                            <Route path="/flowsSettings" element={<FlowsSettings />} />
                            <Route path="/embarquementClient" element={<Campaigns />} />
                            <Route path="/kpiClient" element={<ClientHome />} />
                            <Route path="/emailsTemplate" element={<EmailTemplate />} />
                            <Route path="/configurationMail" element={<ConfigurationMail />} />
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </AppContent>
                </AppBody>
            </AppContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    login: state.auth.login,
    entityUid: state.auth.entityUid,
    userUid: state.auth.userUid,
    userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnerRoot)