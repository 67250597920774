import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';


import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';
import { showColumn } from '../../../shared/utility'

function TableFilters(props) {
    const { formatDate, convertDate, showFilters, filters, filtersChange, applyFilters, userType, userLevel, userRole, columns, setActiveTab } = props;
    const { t } = useTranslation();
    const [show, setShow] = useState(null)
    const [rangeShow, setRangeShow] = useState(false);
    const [rangeBTShow, setRangeBTShow] = useState(false);
    const [dateField, setDateField] = useState('')


    const inputChangeHandler = (e) => {       
        let key   = e.target.name;
        let value = e.target?.value
        let newFilters = {
            ...filters,
            [key]: value
        }
        // 5 est le statut movedToSafe  
        if(key == 'status') {
            if(+value == 5) setActiveTab(3) 
            else setActiveTab(+value) 
        }
        filtersChange(newFilters);
        applyFilters(newFilters);
    }
    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters)
    }
    let [dateInputValue, setDateInputValue] = useState({
        creationDate: filters?.creationDate ? convertDate(filters.creationDate) : ''
    });
    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const getCreationDate = () => {
        return filters?.creationDate ? convertDate(filters.creationDate) : [];
    }

    const getRelanceDate = () => {
        return filters?.dateRelance ? convertDate(filters.dateRelance) : [];
    }

    return (
        <>
            <tr className="tableFilters" style={{ display: showFilters ? 'table-row' : 'none' }}>
                {userType == "owner" ?
                    null
                    // < TD style={{ display: !showColumn(columns, "visible") && 'none' }}>
                    //     <FilterContainer>
                    //         <SelectFilter
                    //             onChange={(e) => inputChangeHandler(e)}
                    //             value={filters?.visible || ''}
                    //             name="visible">
                    //             <Option key={12}></Option>
                    //             <Option key={1} value="1">{t("global:yes")}</Option>
                    //             <Option key={0} value="0">{t("global:no")}</Option>
                    //         </SelectFilter >
                    //     </FilterContainer>
                    // </TD>
                    :
                    < TD style={{ display: !showColumn(columns, "lu") && 'none' }}>
                        <FilterContainer>
                            <SelectFilter
                                onChange={(e) => inputChangeHandler(e)}
                                value={filters?.firstAccessDate || ''}
                                name="firstAccessDate">
                                <Option key={12}></Option>
                                <Option key={1} value="1">{t("global:yes")}</Option>
                                <Option key={0} value="0">{t("global:no")}</Option>
                            </SelectFilter >
                        </FilterContainer>
                    </TD>
                }
                <TD style={{ display: !showColumn(columns, "creationDate") && 'none', padding: 0 }}>
                    <FilterContainer>

                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationDate()[0] || ''}
                            to={getCreationDate()[1] || ''}
                            name="creationDate"
                            activeReset={filters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) => dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "title") && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.title || ''}
                            name="title"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "fileType") && 'none' }}>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.fileType || ''}
                            name="fileType">
                            <Option key={99}></Option>
                            <Option value="MSEPA">{t("contract:MSEPA", "Mandats Sepa")}</Option>
                            <Option value="CF">{t("contract:CF", "Contrat de franchise")}</Option>
                            <Option value="DIP"> {t("contract:DIP", "Document d'information pré-contractuelle")}</Option>
                            <Option value="AOCM"> {t("contract:AOCM", "Avenant OC Mobile")}</Option>
                            <Option value="DIVERS"> {t("contract:internal", "Divers")}</Option>
                            <Option value="CSCF">{t("contract:CSCF", "Caution solidaire")}</Option>
                            <Option value="FREE">{t("contract:FREE", "Document libre")}</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "freeType") && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.freeType || ''}
                            name="freeType"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "recipients") && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.recipients || ''}
                            name="recipients"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "status") && 'none' }}>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.status || ''}
                            name="status">
                            <Option key={12}></Option>
                            <Option key={3} value="4">{t("contractList:refused")}</Option>
                            <Option key={2} value="3">{t("contractList:completed")}</Option>
                            <Option key={11} value="5">{t("contractList:movedToSafe")}</Option>
                            <Option key={1} value="2">{t("contractList:inprogress")}</Option>
                            <Option key={0} value="1">{userType == "owner" ? t("contractList:scheduledowner") : t("contractList:scheduled")}</Option>
                        </SelectFilter >
                    </FilterContainer>
                </TD>

                <TD style={{ display: !showColumn(columns, "nextSigner") && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.nextSigner || ''}
                            name="nextSigner"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "nbRelance") && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.nbRelance || ''}
                            name="nbRelance"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "dateRelance") && 'none', padding: 0 }}>
                    <FilterContainer>

                        <DateFilter
                            modalTitle={t("invoiceList:dateRelance", "Date de relance")}
                            from={getRelanceDate()[0] || ''}
                            to={getRelanceDate()[1] || ''}
                            name="dateRelance"
                            activeReset={filters?.dateRelance ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.dateRelance}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) => dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>



            </tr>
        </>
    )
}

export default TableFilters
