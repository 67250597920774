import React from 'react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AddIcon from '@mui/icons-material/Add';

function EntitiesTable({entities, selectEntity, selectedEntities, type}) {
    return (
        <>
            <table class="table repertoireModal__entities_table">
                <thead class="thead-light">
                    <tr>
                    <th scope="col" style={{width: "50px"}}></th>
                    <th scope="col">Raison sociale </th>
                    <th scope="col">Code </th>
                    <th scope="col">Adresse</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {
                        entities?.length > 0 ? entities.map(entity => (
                            <tr>
                                <td>
                                    <div className="repertoireModal__entities_selectIcon" onClick={() => selectEntity({uid : entity.uid, name : entity.name, new : true, type})}>
                                        {
                                            selectedEntities.some(row => row.uid === entity.uid) ? (
                                                <ThumbUpIcon style={{cursor: "pointer", color: "green"}} />
                                            ) : <AddIcon style={{cursor: "pointer", color: "blue"}} />
                                        }
                                    </div>
                                </td>
                                <td>{entity.name} </td>
                                <td>{entity.code} </td>
                                <td>{entity.address} </td>
                            </tr>
                        )) : <div style={{whiteSpace: "nowrap"}}>
                                    Aucune entreprise à afficher
                            </div>
                    }
                </tbody>
            </table>
        </>
    )
}

export default EntitiesTable
