import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../axios-proas'
import Spinner from 'react-bootstrap/Spinner'
import MessageEvent from './TimelineCards/MessageEvent/MessageEvent';
import StatusEvent from './TimelineCards/StatusEvent/StatusEvent';
import { TLContainer } from './Timeline.styled';

function TimeLine(props) {
    const {
        userUid,
        userUidFromToken,
        userType,
        contractUid,
        token,
        delegationId
    } = props

    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const [timelineData, setTimelineData] = useState(null);
    const [loading, setLoading] = useState(false)
    const [comments, setComments] = useState()
    const [events, setEvents] = useState()

    const getTimelineData = () => {
        setLoading(true)
        if (token == null) {
            Axios.get("contractevent", {
                params: {
                    contract: contractUid,
                    userUid: userUid
                }
            }).then(response => {
                console.log(response)
                setLoading(false)
                setTimelineData(response?.data?.events)
                // console.log(response?.data?.events.filter(event => (event?.type == "STEP_STATUS" || event?.type == "STEP_REMINDER")))
                setEvents(response?.data?.events.filter(event => (event?.type == "STEP_STATUS" || event?.type == "STEP_REMINDER")))
                setComments(response?.data?.events.filter(event => event?.type == "USER_CHAT_EVENT"))
            }).catch(response => {
                setLoading(false)
                notyf.error(t("mandat:generatedErrorMessage", "une erreur s'est produite"));
            })
        } else {
            if (contractUid && userUidFromToken) {
                Axios.get('contractevent?contract=' + contractUid + '&user=' + userUidFromToken).then(response => {
                    setLoading(false)
                    setTimelineData(response?.data?.events)
                    setEvents(response?.data?.events.filter(event => (event.type == "STEP_STATUS" || event.type == "STEP_REMINDER")))
                    setComments(response?.data?.events.filter(event => event.type == "USER_CHAT_EVENT"))
                }).catch(response => {
                    setLoading(false)
                    notyf.error(t("mandat:generatedErrorMessage", "une erreur s'est produite"));
                })
            }

        }
    }

    useEffect(() => {
        getTimelineData()
    }, [contractUid, userUidFromToken])

    let timelineJsx = null;
    let commentsJsx = null;

    if (loading)
        commentsJsx = (<Spinner />)
    else if (timelineData && timelineData?.length > 0) {
        commentsJsx = comments?.map((comment, index) => {
            var eventJsx = null;
            let isLast = index === 0
            eventJsx = (<MessageEvent
                eventData={comment}
                isLast={isLast}
            />)
            return eventJsx
        })
    }
    if (loading)
        timelineJsx = (<Spinner />)
    else if (timelineData && timelineData?.length > 0) {
        timelineJsx = events?.map((timelineEvent, index) => {
            var eventJsx = null;
            let isLast = index === 0
            let isCreator = userUid === timelineEvent?.user?.userUid;
            eventJsx = (<StatusEvent
                eventData={timelineEvent}
                isCreator={isCreator}
                isLast={isLast}
                userType={userType}
                delegationId={delegationId}
            />)
            return eventJsx
        })
    }


    return (
        <TLContainer style={userType == 'owner' ? { maxHeight: "660px" } : { maxHeight: "760px" }}>
            {timelineJsx}
            {commentsJsx}
        </TLContainer>
    )
}
const mapStateToProps = (state) => ({
    userUid: state.auth.userUid,
    userType: state.auth.userType
    // loading: state.mandat.loadingTimeline,
    // timelineData: state.mandat.timelineData,
    // error: state.mandat.errorTimeline,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
    // getMandatTimelineEvents: (mandatUid) => dispatch(actions.getMandatTimelineEvents(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status))
})
export default connect(mapStateToProps, mapDispatchToProps)(TimeLine)