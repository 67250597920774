import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SendButton = styled.button`
    background-color: #ffffff;
    color: ${({ theme }) => theme.colors.sendBtn};
    padding: 1rem;
    border: 2px solid ${({ theme }) => theme.colors.sendBtn};
    cursor: pointer;
    border-radius: 16px;
    margin-left: 2rem;
    width: 152px;
`;

export const Header = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 3px solid #F5FBFF;
`

export const HeaderInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
`

export const HeaderLocation = styled.h3`
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 0.5rem;
`

export const HeaderTitle = styled.h4`
    display: flex;
    justify-content: flex-start;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    color: #482D1C;
`

export const HeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;

    @media ${({ theme }) => theme.device.laptop} {
        /* flex-direction: column; */
    }
`

export const HeaderAction = styled.div`
    /* width: 100%; */
    height: 50px;
    margin-left: 1rem;
`;

export const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;

    &:focus-within {
        border: 2px solid #2174B9;
        color: #2174B9;
    }
`

export const SearchInput = styled.input`
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.9rem;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.text};
    }
`

export const BtnAction = styled.button`  
    display: flex;
    justify-content: center ;
    align-items: center;
    height: 50px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid ${({ bg }) => bg ? bg : '#D9E1E7'};
    border-radius: 16px;
    color: ${({ color }) => color || "#D9E1E7"};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: ${({ bg }) => bg ? bg : '#FFFFFF'};

    & span {
        color: ${({ color }) => color || 'black'};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.btnHover};
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

export const BtnIcon = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 0.5rem;
`

export const BtnOutlineAction = styled.button`
    display: flex;
    justify-content: center ;
    align-items: center;
    height: 50px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid ${({ border }) => border ? border : '#D9E1E7'};
    border-radius: 16px;
    color: ${({ color }) => color || "#D9E1E7"};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;

    & span {
        color: ${({ color }) => color || '#D9E1E7'};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.btnHover};
    }
`;

export const BtnSmOutlineAction = styled.button`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 1rem;
    /* padding: 0.5rem; */
    border: 2px solid ${({ border, theme }) => border ? border : theme.colors.redLight};
    border-radius: 8px;
    color: ${({ color, theme }) => color || theme.colors.secondary};
    cursor: pointer;
    font-size: 1rem;
    background-color: #FFFFFF;

    & span {
        color: ${({ color, theme }) => color || theme.colors.secondary};
    }

    &:hover {
        background-color: ${({ color, theme }) => color || theme.colors.btnHover};
        color: #FFFFFF;
    }
`;
export const FormRadioWrapper = styled.div`
    display: flex;
    align-items: baseline;
    position: relative;
    border-radius: 2px;
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;

export const FormRadio = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #1E85D9;
    border: 1px solid #2196F3;
    &::after {
        content: '';
       display: flex;
       width: 10px;
      height: 10px;
      margin: 3px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: #fff;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #2196F3;
    border: 1px solid #2196F3;
    &::after {
        content: '';
       display: flex;
       width: 10px;
      height: 10px;
      margin: 3px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: #fff;
    }
  }
`;

export const BtnOutlineLink = styled(Link)`
    display: flex;
    justify-content: center ;
    align-items: center;
    height: 50px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid ${({ border }) => border ? border : '#D9E1E7'};
    border-radius: 16px;
    color: ${({ color }) => color || "#D9E1E7"};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;
    text-decoration: none;

    & span {
        color: ${({ color }) => color || '#D9E1E7'};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.btnHover};
        color: ${({ theme }) => theme.colors.primary};
    }

    &:hover span {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #F5FBFF;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

`

export const FormLabel = styled.label`
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const FormInput = styled.input`
    /* flex: 1; */
    border: none;
    outline: none;
    flex: 1;
    /* width: 170px; */
    text-align: right;
`;

export const FormSelect = styled.select`

    cursor: pointer;
    border: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: right;
`;

export const FormInterval = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.25rem;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`

export const FormIntervalLabel = styled.label`
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #F5FBFF;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 1rem;
`;

export const FormIntervalValues = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const FormIntervalStart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
`

export const FormIntervalEnd = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
`

export const FormEntCanal = styled.div`
    width: 100%;
    border-bottom: 1px solid #F5FBFF;
    padding-bottom: 1rem;
`

export const FormECLabel = styled.label`
    color: ${({ theme }) => theme.colors.secondary};
    padding-left: 0;
    margin: 0.5rem 0;
    white-space: nowrap;
`
export const FormECListContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border: 1px solid #482D1C66;
    border-radius: 18px;
    padding: 0.5rem;
    margin: 0.5rem 0;
`

export const FormECItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 15%;
    color: ${({ theme }) => theme.colors.secondary};
`

export const FormECILabel = styled.label`
    margin: 0;
`

export const FormECIValue = styled.div`
    margin: 0;
`

