import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from 'react-i18next';
import { TH, THContainer, THead } from '../../../styles/Table.styled';

function TableHead(props) {
    const { columns, columnClickHandler, reverse, userRole, userLevel } = props;
    const { t } = useTranslation();

    return (
        <THead>
            <tr>
                {columns.filter(row => row?.show == true).map(column => (
                    <TH key={column?.field}
                        scope="col"
                        width={column?.width}
                        onClick={() => column?.field != 'lu' && column?.field != 'visible' && column?.field != 'operation' ? (columnClickHandler(column?.field, reverse)) : null}>
                        <THContainer

                            style={{ justifyContent: 'flex-start', cursor: column?.field != 'lu' && column?.field != 'visible' && column?.field != 'operation' ? 'pointer' : 'default' }}
                        >
                            <span>
                                {column?.title}
                            </span>
                            {column?.field != 'lu' && column?.field != 'visible' && column?.field != 'operation' ?
                                <div className="columnHead__sort">
                                    <ArrowUpwardIcon style={{ fontSize: 20, display: reverse[column?.field] == true ? 'block' : 'none' }} />
                                    <ArrowDownwardIcon style={{ fontSize: 20, display: reverse[column?.field] == false ? 'block' : 'none' }} />
                                </div>
                                : null
                            }
                        </THContainer>
                    </TH>
                ))}
            </tr>
        </THead>
    )
}

export default TableHead
