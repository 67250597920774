import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2'


export const UsersContainer = styled.div`
    margin: 5px 0px 0px 15px;
`;

export const PFContainer = styled.div`
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
`;

export const PFHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const PFHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1rem;
`;

export const PFHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
`

export const PFHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const PFBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 3rem;
`
export const PFSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const PFFormWrapper = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.9rem;
    /* align-items: space-between; */
`

export const PFFormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`

export const PFFormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`;


export const PFFormLabel = styled.label`
    cursor: pointer;
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.secondary};

    &:hover {
        color: orange;
    }
`;

export const AddRecipientsBtn = styled.button`
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 0.125rem 1rem;
    border-radius: 8px;
    background-color: white;
    font-size: 0.8rem;
    display: flex;
    
    &:hover {
        background-color: ${props => props.theme.colors.primary};
        color: #ffffff;
    }
`;

export const WrapperCanvas = styled.div`
    position: relative;
    width: 308px;
    height: 141px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #efe8df;    
    border : 1px solid aliceblue;        
`

export const TelInput = styled(PhoneInput)`
    width: 180px;

    input {
        width: 100% !important;
    }
`