import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import ModificationsChat from './ModificationsChat/ModificationsChat';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { NavTabsLi, NavTabsLink, NavTabsUl } from '../../../../containers/Entity/Entity.styled';
import { useParams, useLocation } from 'react-router-dom';
import { ISContainer, ISNavContainer, ISNavContent } from './InvoiceSide.styled';
import Fields from './Fields/Fields';

function InvoiceSide(props) {
    const {
        tabSideActive,
        setTabSideActive,
        invoiceData,
        setInvoiceData,
        userType
    } = props
    let notyf = getNotyfObject();
    const {t} = useTranslation();

    useEffect(() => {
        if(invoiceData && (invoiceData?.status === 'ERROR' && userType === 'supplier')) {
            setTabSideActive('fields')
        }
    }, [invoiceData])

  
    return (
        <ISContainer>  
            <ISNavContainer>
                <NavTabsUl>
                    {
                        invoiceData && (invoiceData?.status === 'ERROR') && userType === 'supplier' &&  (
                            <NavTabsLi>
                                <NavTabsLink 
                                    active={tabSideActive === "fields"} 
                                    onClick={() => setTabSideActive("fields")}
                                    to="#"
                                >
                                        {t("mandat:fields", "Formulaire")} 
                                </NavTabsLink>
                            </NavTabsLi>
                        )
                    }
                    <NavTabsLi>
                        <NavTabsLink 
                            active={tabSideActive === "timeline"} 
                            onClick={() => setTabSideActive("timeline")}
                            to="#"
                        >
                                {t("mandat:timeline", "TimeLine")} 
                        </NavTabsLink>
                    </NavTabsLi>
                    <NavTabsLi>
                        <NavTabsLink 
                            active={tabSideActive === "comments"} 
                            onClick={() => setTabSideActive("comments")}
                            to="#"
                            >
                                {t('mandat:comments', "Commentaires")} 
                        </NavTabsLink>
                    </NavTabsLi>
                    
                </NavTabsUl>
            </ISNavContainer>
            <ISNavContent>
                {tabSideActive === "fields" && invoiceData && (invoiceData?.status === 'ERROR') && userType === 'supplier' && (
                        <Fields  
                            invoiceData={invoiceData}
                            setInvoiceData={setInvoiceData}
                        />
                    )
                }
                {tabSideActive === "timeline" && 
                        <TimeLine />
                }
                {tabSideActive === "comments" && 
                        <ModificationsChat />
                }
            </ISNavContent>
        </ISContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    tabSideActive: state.invoiceDetail.tabSideActive,
    invoiceData: state.invoiceDetail.invoiceData,
})
const mapDispatchToProps = dispatch => ({
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    setInvoiceData: (invoiceData) => dispatch(actions.invoiceDataSuccess(invoiceData))
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSide)
