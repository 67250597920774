import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Axios from '../../axios-proas';
import { useTranslation } from 'react-i18next';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { BtnAction, FormGroup, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';
import { EPBody, EPContainer, EPFormWrapper } from './EditPassword.styled';
import { useTheme } from 'styled-components';

function EditPassword() {
    const notyf = new Notyf();
    const uid = localStorage.getItem('userId');
    const [error, setError] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const { t } = useTranslation();
    const theme = useTheme()
    const navigate = useNavigate();


    const ResetPassword = (e) => {
        e.preventDefault();
        setError(null)
        if (password === confirmedPassword) {
            editPassword(password, currentPassword);
        } else {
            setError("authentication:differentPasswords")
        }
    }

    const editPassword = useCallback((newPassword, currentPassword) => {
        setError(null)
        Axios.put("/user/" + uid + "/changePassword", {
            password: newPassword, currentPassword: currentPassword
        }).then(response => {
            notyf.success(t("authentication:passwordResetSuccess"));
            setCurrentPassword('');
            setPassword('');
            setConfirmedPassword('');
            navigate("/");
        }).catch(err => {
            if (err.response?.data?.hasOwnProperty("passwordMach"))
                setError('Mot de passe saisi ne correspond pas au mot de passe actuel')
            else
                notyf.error(t("authentication:passwordResetFail"))
        })
    }, [password])


    return (
        <EPContainer>
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("authentication:changePassword")}</HeaderLocation>
                </HeaderInfos>
                <HeaderActions>
                    <BtnAction
                        color={theme.colors.primary}
                        border={theme.colors.primary}
                        onClick={() => navigate("/")}
                    >
                        <span>{t("global:back", "RETOUR")}</span>
                    </BtnAction>
                    <BtnAction
                        color={theme.colors.success}
                        bg={theme.colors.greenLight}
                        onClick={(e) => ResetPassword(e)}
                        disabled={!currentPassword || !password || password !== confirmedPassword}
                    >
                        {t("global:save", 'Sauvegarder')}
                    </BtnAction>
                </HeaderActions>
            </Header>
            <EPBody>
                {
                    error ?
                        <div class="alert alert-danger" role="alert">
                            {error}
                        </div>
                        : null
                }
                <EPFormWrapper>
                    <FormGroup>
                        <FormLabel htmlFor="currentPassword" >{t("authentication:currentPassword")}</FormLabel>
                        <FormInput
                            id="currentPassword"
                            type="password"
                            name="currentPassword"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            value={currentPassword}
                            required
                            autoFocus
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="password" >{t("authentication:newPassword")}</FormLabel>
                        <FormInput
                            id="password"
                            type="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            autoFocus
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="confirmedPassword" >{t("authentication:confirmPassword")}</FormLabel>
                        <FormInput
                            id="confirmedPassword"
                            type="password"
                            name="confirmedPassword"
                            onChange={(e) => setConfirmedPassword(e.target.value)}
                            value={confirmedPassword}
                            required
                        />
                    </FormGroup>

                </EPFormWrapper>
            </EPBody>
        </EPContainer>
    )
}

export default EditPassword
