import React, { useEffect, useState } from "react";
import Axios from "../../axios-proas";
import { formatDate, formatNumber, getNotyfObject, getLabelWithKey } from "../../shared/utility";
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';


import {
  Content,
  EFContainer,
  EFCol6,
  EFRow,
  EFBody,
} from "./ConfigurationMail.styled";
import {
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  BtnAction,
  FormGroup,
  FormLabel,
  FormSelect,
  BtnOutlineAction
} from "../../styles/Common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DPContainer } from "../CampaignForm/CampaignForm.styled";
import { useTheme } from "styled-components";
import { useTranslation } from 'react-i18next';


function ConfigurationMail() {
  const { t } = useTranslation();

  let notyf = getNotyfObject();
  const theme = useTheme();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [mail, setMail] = useState(null);
  const [defaultMail, setDefaultMail] = useState(null);
  const [emailupdate, setEmailupdate] = useState([]);
  let location = useLocation();
  let { uid } = useParams();







  useEffect(() => {
    Axios.get("settingsemail//defaultConfiguration")
      .then((response) => {

        setData(response.data.data.data)
        setMail(response.data.mail)
        setDefaultMail(response.data.event)
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
      });
  }, []);



  const invoiceTypeChangeHandler = (e) => {
    const result = emailupdate?.filter(i => i["event_id"] !== e.target.name);
    let p = {
      event_id: e.target.name,
      email_default: e.target.value
    }
    setEmailupdate(result.concat(p));
  };


  const submitEmailClickHandler = () => {

    console.log(emailupdate)
    Axios.put("settingsemail//defaultConfiguration", emailupdate)
      .then((response) => {
        console.log(response?.data.success)
        if (response?.data?.success) {
          notyf.success("Modification terminée");

        } else {
          notyf.error("Modification echouée");
        }
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
        setCreateExtractionLoading(false);
      });

  };







  function HelloWorld(a) {

    let options = mail?.filter(item => item["event"] === a);

    return <>
      {options.map((i, index) => (
        <option value={i["id"]}>{i["title"]}</option>
      ))
      }
    </>





  }

  return (
    <EFContainer>
      <TitleHelmet title={"OPTICAL CENTER | Emails"} />
      <Header>
        <HeaderInfos>
          <HeaderLocation>
            {" "}

            <span>{t("global:ConfigurationEmailsDefault")}</span>

          </HeaderLocation>
        </HeaderInfos>
        <HeaderActions>


          <BtnOutlineAction onClick={() => submitEmailClickHandler()}>
            <span>{t("global:save")}</span>
          </BtnOutlineAction>

        </HeaderActions>
      </Header>












      <EFBody>
        {data !== null && mail !== null && (
          <EFRow>
            {data.map((item, index) => (
              <EFCol6>
                <Content>

                  <FormLabel border={true} htmlFor="titel">{t(`emailConfig:${getLabelWithKey(item.type["events"])}`)}</FormLabel>
                  {item.events.map((item, index) => (
                    <FormGroup className="mb-4">
                      <FormLabel htmlFor="family">{t(`emailConfig:${getLabelWithKey(item["event"])}`)}</FormLabel>
                      {/* <span>{item["event"]}</span> */}
                      <FormSelect
                        id="family"
                        name={item["id"]}
                        value={emailupdate?.filter(i => i["event_id"] == item["id"])[0]?.email_default || item["default_mail"]}
                        onChange={(e) => invoiceTypeChangeHandler(e)}
                      >
                        {HelloWorld(item["id"])}
                      </FormSelect>
                    </FormGroup>
                  ))
                  }



                </Content>
              </EFCol6>
            ))}










            {/* <EFCol6>           
                    <Content>
                        <FormGroup>
                            <FormLabel><strong>{t('extraction:clientHeader')}</strong></FormLabel>
                        </FormGroup>
                    </Content>
            </EFCol6>
            <EFCol6>           
                    <Content>
                        <FormGroup>
                        <FormLabel><strong>{t('extraction:clientHeader')}</strong></FormLabel>
                        </FormGroup>
                    
                    </Content>
            </EFCol6> */}
          </EFRow>
        )
        }
      </EFBody>
    </EFContainer>
  );
}

export default ConfigurationMail;
{/* <FormGroup className="mb-4">
<FormLabel htmlFor="titel">{t('extraction:title')}</FormLabel>
<FormInput
id="titel"
style={{ textAlign: "end" }}
type="text"
name="titel"
value={extractionData?.titel || extraction.titel || ""}
onChange={(e) => invoiceNumberChangeHandler(e)}
/>
</FormGroup> */}