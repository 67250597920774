import axios from "axios";
import i18next from "i18next";
//import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Axios from './axios-proas'
import en from "./locales/en.json";
import fr from "./locales/fr.json";

// import de from "./locales/de.json";
// import es from "./locales/es.json";
// import it from "./locales/it.json";

//const apiKey = "svW_t3PmWk6tYnNtj7dVKA";
//const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;



const resources = {
  en: { translation: en },
  fr: { translation: fr }
}
i18next
  //.use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: "fr",
    // interpolation: {
    //   escapeValue: false
    // },

    /*ns: ["global","menu","userList","user","invoiceList","dashboard","clientList","client","supplier","logs","settings"],
    defaultNS: "global",*/
    keySeparator: ":",
    supportedLngs: ["fr", "en"],

    detection: {
      order: ['localStorage', 'path', 'cookie', 'navigator', 'htmlTag']
    }/*,
    backend: {
      loadPath: loadPath,
      crossDomain: false
    }*/
  })