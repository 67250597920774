import React, { useEffect, useState } from 'react'
import Axios from '../../axios-proas'
import { Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common'
import { HomeBody, HomeCards, HomeContainer, HomeHeader } from '../Home/Home.styled'
import Card from '../../components/Home/Card/Card'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { getNotyfObject } from '../../shared/utility'
import { KSBody, KSCards, KSContainer, KSStats } from './KPISupplier.styled'
import LineChart from '../../components/Home/LineChart/LineChart'
import Events from '../../components/Home/Events/Events'
import { SupplierLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled'
import { useTheme } from 'styled-components'

function KPISupplier() {
    const notyf = getNotyfObject();
    const theme = useTheme();
    const [activeSuppliers, setActiveSuppliers] = useState(0);
    const [inProgressSuppliers, setInProgressSuppliers] = useState(0);
    const [notPaidContracts , setNotPaidContracts] = useState(0);
    const [litigationContracts, setLitigationContracts] = useState(0);

    const eventsList = [
        {color: theme.colors.success , value: 'Fournisseur 1', date: '5 minuites'},
        {color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites'},
        {color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites'},
        {color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites'},
        {color: theme.colors.success, value: 'Campagne 1', date: '5 minuites'},
        {color: theme.colors.danger, value: 'Transdev', date: '5 minuites'},
        {color: theme.colors.success, value: 'Transdev', date: '5 minuites'},
    ]

    const getSuppliersCount = (status) => {
        Axios.get('/entity//suppliersCount', {
            params: {
                status: status
            }
        }).then(res => {
            if(res?.data?.suppliersCount){
                switch (status) {
                    case 'active':
                        setActiveSuppliers(res.data.suppliersCount) 
                        break;
                    case 'inProgress':
                        setInProgressSuppliers(res.data.suppliersCount)
                        break;
                    default:
                        break;
                }
            }
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getNotPaidContracts = () => {
        Axios.get('/contract//notPaidContracts').then(res => {
            if(res?.data?.count){
                setNotPaidContracts(res.data.count)
            }
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getLitigationContracts = () => {
        Axios.get('/contract//litigationContracts').then(res => {
            if(res?.data?.count){
                setLitigationContracts(res.data.count)
            }
        }).catch(res => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    useEffect(() => {
        getSuppliersCount("active");
        getSuppliersCount("inProgress");
        getNotPaidContracts()
        getLitigationContracts()
    }, []);

  return (
    <KSContainer>
            <Header>
                <HeaderInfos>
                    <HeaderLocation>KPI</HeaderLocation>
                </HeaderInfos>
                <HeaderActions>
                </HeaderActions>
            </Header>
            <KSBody>
                <KSCards>
                    <Card 
                        icon={<ShoppingCartIcon style={{color: "#0090FF"}} />} 
                        title="Nombre de fournisseurs actifs" 
                        value={<SupplierLink 
                            to="/referentielFournisseur"
                            state={{
                                filtersProps : {
                                    status: 'ACTIVE',
                                    type: 'supplier'
                                }
                            }}
                        >{activeSuppliers}</SupplierLink>}
                    />
                    <Card 
                        icon={<CreditCardIcon style={{color: "#3A36DB"}} />}  
                        title="Fournisseurs en cours d’embarquement " 
                        value={<SupplierLink 
                            to="/referentielFournisseur"
                            state={{
                                filtersProps : {
                                    status: 'INPROGRESS',
                                    type: 'supplier'
                                }
                            }}
                        >{inProgressSuppliers}</SupplierLink>}
                    />
                    <Card 
                        icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
                        title="Factures non payés" 
                        value={notPaidContracts}
                    />
                    <Card 
                        icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
                        title="Anomalies" 
                        value={litigationContracts}
                    />
                </KSCards>
                <KSStats>
                    <LineChart />
                    <Events events={eventsList} />
                </KSStats>
            </KSBody>
        </KSContainer>
  )
}

export default KPISupplier