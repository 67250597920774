import React, { useEffect, useState } from 'react'
import './EmailPreview.css'
import Axios from '../../../axios-proas'
import DOMPurify from 'dompurify';
import { getNotyfObject } from '../../../shared/utility';

function EmailPreview({convertedContent,event}) {
    const notyf = getNotyfObject()
    const [isLoading, setIsLoading] = useState(false);
    const [emailPreview, setEmailPreview] = useState('');

    const getEmailTemplateHtml = () => {
        setIsLoading(true);
        Axios.put('/emailtemplate//emailTemplateHtml', {
            emailContent : convertedContent,
            event:event
        }) 
        .then(res => {
            setIsLoading(false);
            const emailhtml = res?.data?.html ? res.data.html : '';
            setEmailPreview(emailhtml);
        })
        .catch(err => {
            setIsLoading(false);
            notyf.error("une erreur est survenue lors de la récupération du template");
        });
    }

    useEffect(() => {
        getEmailTemplateHtml();
    }, [])

    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
      }

  return (
      <>
        <div className="EPBody">
        <center class="wrapper">
            <div className="preview" dangerouslySetInnerHTML={createMarkup(emailPreview)}></div>               
        </center>
        </div>
      </>
  )
}

export default EmailPreview