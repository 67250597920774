import React, { useCallback, useRef, useState, useEffect } from 'react'
import Axios from '../../../axios-proas';
import FilterListIcon from '@mui/icons-material/FilterList';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ReplayIcon from '@mui/icons-material/Replay';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';


function TableActions(props) {
    const { updateShowFilters, filterState, selectedUsers, entityUid, userRole, origin, usersData, addAutor, addManager } = props;
    const [csvData, setCsvData] = useState();
    const csvBtn = useRef();
    const { t } = useTranslation();
    const theme = useTheme();    

    const showFilters = () => {
        updateShowFilters(!filterState)
    }

    const csvHeaders = [
        { label: 'Prénom', key: 'first_name' },
        { label: 'Nom', key: 'last_name' },
        { label: 'Email', key: 'login' },
        { label: 'Télephone', key: 'telephone_number' },
        { label: 'Active', key: 'active' },
        { label: 'Type', key: 'type' },
        { label: 'Rôle', key: 'level' },
        { label: 'Level', key: 'role' },
        { label: origin == 'fromClient' ? 'Sites' : 'Groupes', key: 'entities' }
    ]
    const exportSelectionCsv = useCallback(() => {
        let csvDataTemp = null;
        Axios.get('/user//csvCompressedDocument', {
            params: {
                selection: selectedUsers.toString()
            }
        })
            .then((response) => {
                csvDataTemp = response.data.content.map(row => {
                    console.log(row)
                    return {
                        ...row,
                        // issuingDate: formatDate(+row.issuingDate),
                        // dueDate: formatDate(+row.dueDate),
                        active: row?.active === "1" ? "O" : "N",
                        level: row?.level == "standard" ? "Gestionnaire" : row?.type == "owner" ? "Signataire" : row?.level == "admin" ? "Gérant" : "Co-gérant",
                        role: row?.role == "standard" ? " " : row?.role
                    }
                })
                setCsvData(csvDataTemp);
                csvBtn.current.link.click();
            }).catch(err => console.log(err.data))
    }, [selectedUsers])


    const showCsvExport = () => {
        return selectedUsers.length > 0
    }


    return (
        <TAContainer>
            <TableAction active={filterState} onClick={() => showFilters()} >
                <span>{t('tableAction:filtres', "Filtres")}</span>
                <FilterListIcon />
            </TableAction>
            {
                showCsvExport() ? (
                    <TableAction onClick={() => exportSelectionCsv()} >
                        <span>{t("global:exportCsv", "Exporter Csv")}</span>
                        <ImportExportIcon />
                    </TableAction>
                ) : null
            }
            {
                (((userRole == 'superadmin') || (userRole == 'admin' && origin == 'fromClient')) ) &&
                <TableActionLink
                    color={theme.colors.primary}
                    to="/userForm"
                    state={{ entityUid: entityUid }}
                >
                    <span>{t('tableAction:addUser', "Ajouter un utilisateur")}</span>
                    <AddIcon />
                </TableActionLink>
            }

            {csvData ? (<CSVLink
                ref={csvBtn}
                data={csvData}
                filename={"UtilisateursCsv.csv"}
                headers={csvHeaders}
                separator={";"}>
            </CSVLink>) : null}
        </TAContainer>
    )
}

const mapStateToProps = (state) => ({
    selectedUsers: state.users.selectedUsers,
    filterState: state.users.showFilters,
    usersData: state.users.data
})
const mapDispatchToProps = dispatch => ({
    updateShowFilters: (show) => dispatch(actions.usersUpdateShowFilters(show))
})
export default connect(mapStateToProps, mapDispatchToProps)(TableActions)
