import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';

import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Link, NavLink } from 'react-router-dom';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled.js';
import { useTheme } from 'styled-components';

function TableActions(props) {
    const theme = useTheme();
    const { resetFilters, setShowFilters, showFilters, userType, userRole } = props;
    const { t } = useTranslation();
    return (
        <TAContainer>
            <TableAction active={showFilters} onClick={() => setShowFilters(!showFilters)}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>


            {/* <div className="tableActions__action">
                <CTooltip title={t("global:resetFilters")} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>
                        <ReplayIcon onClick={() => resetFilters()} fontSize="small"  />
                    </CTooltip>
                </div>
                <div className="tableActions__action">
                    <CTooltip title={t("global:filters")} className="tableActions__icon"  style={{color: showFilters ? 'rgb(38, 146, 235)' : ''}}>
                        <FilterListIcon onClick={() => setShowFilters(!showFilters)} fontSize="small"  />
                    </CTooltip>
                </div> */}
            {
                ((userRole == 'admin') || (userRole == 'superadmin')) &&
                <TableActionLink
                    color={theme.colors.primary}
                    to="/entity"
                    state={{ entityType: "client" }}
                >
                    <span>{t('tableAction:addEntity', `Ajouter un ${"client"}`)}</span>
                    <AddIcon />
                </TableActionLink>

            }

        </TAContainer>
    )
}

export default TableActions
