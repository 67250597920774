import React from 'react'
import './DropDownInfo.css'
import InfoIcon from '@mui/icons-material/Info';
import DropDown from '../../UI/DropDown/DropDown';

function DropDownInfo({min, max}) {
    return (
        <div className="dropDownInfo_container">
            <DropDown
                icon={<InfoIcon style={{width: "15px", marginLeft: "4px"}} className='infoIcon' />} 
                zindex={"100"}>
                    <div className="dropDownInfo_content">
                        <div className="dropDownInfo_element">
                            <div className="dropDownInfo_label">Minimum :</div>
                            <div className="dropDownInfo_value">{min || ''}</div>
                        </div>
                        <div className="dropDownInfo_element">
                            <div className="dropDownInfo_label">Maximum :</div>
                            <div className="dropDownInfo_value">{max || ''}</div>
                        </div>
                    </div>
            </DropDown>
        </div>
    )
}

export default DropDownInfo
