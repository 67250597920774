import React, { useMemo } from 'react';
import { formatDate } from '../../../shared/utility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SIProgressLine, SIProgressLineBar, SIProgressLineItem, SIProgressLineItemTitle } from './StatusProgressLine.styled';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

function StatusProgressLine({status}) {
    const theme = useTheme();
    const {t} = useTranslation()
    const statusList = useMemo(() => [
        {title: t("campaignList:scheduled", "Programmées"), value: "scheduled"},
        {title: t("campaignList:inProgress", "En cours"), value: "inProgress"},
        {title: t("campaignList:completed", "Terminés"), value: "completed"},
    ], [t]);

    const getStatusColor = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase())
        if(status.toUpperCase() === "completed".toUpperCase())
            return theme.colors.success;

        if(value.toUpperCase() === status.toUpperCase())
            return theme.colors.warning;

        if(index < statusIndex)
            return theme.colors.success;

        return theme.colors.secondary;
    }

    const showProgressLineBar = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase());
        return index <= statusIndex;
    }
    
  return (
    <SIProgressLine>
        {
            statusList.map((item, index) => (
                <SIProgressLineItem 
                    key={index}
                >
                    <SIProgressLineItemTitle
                        color={getStatusColor(item.value, index)}
                    >
                        <span>
                            {item.title} 
                        </span>
                        <CheckCircleOutlineIcon fontSize="small" />
                    </SIProgressLineItemTitle>
                    {
                        showProgressLineBar(item.value, index) ? (
                            <SIProgressLineBar 
                                isFirst={index === 0}
                                isCurrent={item.value.toUpperCase() === status.toUpperCase()}
                                color={getStatusColor(item.value, index)}
                            >
                            </SIProgressLineBar>
                        ) : null
                    }
                </SIProgressLineItem>
            ))
        }
    </SIProgressLine>
  );
}

export default StatusProgressLine;
