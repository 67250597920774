import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessIcon from '@mui/icons-material/Business';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Select from '../../components/UI/Select/Select';
import Card from '../../components/Home/Card/Card';
import { useTheme } from 'styled-components';
import { formatNumber, getNotyfObject } from '../../shared/utility';
import { Link } from 'react-router-dom';
import { SupplierLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
import {formatDateEur} from '../../shared/utility'
import { HomeSBody, HomeSCards, HomeSContainer, HomeSHeader, HomeSStats } from './HomeSupplier.styled';
import { HeaderAction, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import InvoicesPerStatus from '../../components/HomeSupplier/InvoicesPerStatus/InvoicesPerStatus';
import InvoicesPerCanal from '../../components/HomeSupplier/InvoicesPerCanal/InvoicesPerCanal';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function HomeSupplier({userType}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const notyf = getNotyfObject()
    const [period, setPeriod] = useState('');
    const [sentInvoicesCount, setSentInvoicesCount] = useState(0);
    const [uploadedDocsCount, setUploadedDocsCount] = useState(0);
    const [invoicedEntitiesCount, setInvoicedEntitiesCount] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    const options = [
        {label: t("accueil:all"), value: ''},
        {label: t("accueil:thisyear"), value: 'y'},
        {label: t("accueil:thismonth"), value: 'm'}
    ];

    const selectChange = (val) => {
        setPeriod(val);
    }

    const getSentInvoicesCount = () => {
        Axios.get("/invoice//supplierSentInvoices", {
            params: {
                period : period
            },
        }).then((response) => {
            let count = response?.data?.totalDocs ? response.data.totalDocs : 0;
            setSentInvoicesCount(count);
        }).catch(res => {
          notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    };

    const getUploadedInvoicesCount = () => {
        Axios.get("/invoiceupload//uploadedInvoicesCount", {
            params: {
                period : period
            },
        }).then((response) => {
            let count = response?.data?.totalDocs ? response.data.totalDocs : 0;
            setUploadedDocsCount(count);
        }).catch(res => {
          notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    };

    const getInvoicedEntitiesCount = () => {
        Axios.get("/invoice//supplierEntitiesCount", {
            params: {
                period : period
            },
        }).then((response) => {
            let count = response?.data?.totalEntities ? response.data.totalEntities : 0;
            setInvoicedEntitiesCount(count);
        }).catch(res => {
          notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    };

    const getTotalSales = () => {
        Axios.get("/invoice//supplierTotalSales", {
            params: {
                period : period
            },
        }).then((response) => {
            let count = response?.data?.totalSales ? response.data.totalSales : 0;
            setTotalSales(count);
        }).catch(res => {
          notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    };

    useEffect(() => {
        getSentInvoicesCount();
        getUploadedInvoicesCount();
        getInvoicedEntitiesCount();
        getTotalSales();
    }, [period]);

  return (
      <>
        <HomeSContainer>
            <HomeSHeader>
                <HeaderInfos>
                    <HeaderLocation>
                        {userType === 'owner' ? 'KPI' : 'Accueil'} 
                    </HeaderLocation>
                    <HeaderTitle>
                        {userType === 'owner' ? '' : 'Portail'} 
                    </HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                    <HeaderAction>
                        <Select 
                            options={options} 
                            value={options.find(option => option.value === period)?.label} 
                            onChange={selectChange} 
                        />
                    </HeaderAction>
                </HeaderActions>
            </HomeSHeader>
            <HomeSBody>
                <HomeSCards>
                    <Card 
                        icon={<DescriptionIcon style={{color: "#0090FF"}} />} 
                        title="Factures émises" 
                        value={sentInvoicesCount}
                    />
                    <Card 
                        icon={<DescriptionIcon style={{color: "#0090FF"}} />} 
                        title="Factures en cours de traitement" 
                        value={uploadedDocsCount}
                    />
                    <Card 
                        icon={<BusinessIcon style={{color: "#3A36DB"}} />} 
                        title="Entités facturées" 
                        value={invoicedEntitiesCount}
                    />
                    <Card 
                        icon={<DateRangeIcon style={{color: "#F9B959"}} />} 
                        title="Total des ventes" 
                        value={formatNumber(+totalSales)}
                    />
                </HomeSCards>
                <HomeSStats>
                    <InvoicesPerStatus period={period} />
                    <InvoicesPerCanal period={period} />
                </HomeSStats>
            </HomeSBody>
        </HomeSContainer>
      </>
  );
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login
})

const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (HomeSupplier)
