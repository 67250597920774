import React from 'react'
import './DocumentSignatories.css';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';

function DocumentSignatories({selectedSignatories, signatories, updateSignatories, deleteSignatory}) {
    const {t} = useTranslation();

    const handleOnDragEnd = (result) => {
        if(result?.destination){
            const signatoriesTemp = Array.from(selectedSignatories);
            const [reorderedItem] = signatoriesTemp.splice(result.source.index, 1);
            signatoriesTemp.splice(result.destination.index, 0, reorderedItem);
            let signatoriesToUpdate = signatoriesTemp.map((signatory,index) => {
                let signatoryTemp = {...signatory, order : index }
                return signatoryTemp
            })

            updateSignatories(signatoriesToUpdate)
        }
    }

    return (
        <DragDropContext onDragEnd={handleOnDragEnd} >
            <Droppable droppableId="selectedSignatories">
                {(provided) => (
                    <div 
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="mandatClauses__container">
                        {
                            selectedSignatories?.length > 0 ?
                            selectedSignatories?.map((signatory,index) => (
                                <Draggable key={signatory.uid} draggableId={signatory.uid} index={+signatory.order} isDragDisabled={false}>
                                    {(provided) => (
                                        <div 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} 
                                            className="mandatClauses__itemContainer">
                                            {/* <DragHandleIcon className="mandatClause__dragIcon" fontSize="small" /> */}
                                            <div className="mandatClause__content">
                                                {t("documentDetails:"+signatory?.signatory)}
                                            </div>
                                            {/* { signatory && <DeleteForeverIcon 
                                                className="mandatClause__deleteIcon" 
                                                fontSize="small" 
                                                onClick={() => deleteSignatory(signatory)}
                                                />} */}
                                        </div>
                                    )}
                                </Draggable> 
                            ))
                            : <p>{t("contract:noSignDetectedMessage", "Aucun signataires")}</p>
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            
        </DragDropContext>
        
    )
}

export default DocumentSignatories
