import React, { useCallback, useEffect, useState } from 'react';

import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TableActions from '../../components/ClientsTable/TableActions/TableActions';
import TableHead from '../../components/ClientsTable/TableHead/TableHead';
import TableBody from '../../components/ClientsTable/TableBody/TableBody';
import TableFilters from '../../components/ClientsTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import Axios from '../../axios-proas';

import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer } from './ReferentielClient.Styled';
import { Spinner } from 'react-bootstrap';

function ReferentielClient(props) {
    const {
        loading,
        clientsData,
        count,
        currentPage,
        pageSize,
        getClientsPage,
        setClientsPage,
        setClientsPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        updateShowFilters,
        setClientsFilters,
        setSortQuery,
        setReverse,
        density,
        setDensity,
        logout
    } = props;
    const { t } = useTranslation();
    const userType = localStorage.getItem('type');
    const userRole = localStorage.getItem('role');
    // const [density, setDensity] = useState("2.25rem")
    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const navTitre = "referentiel";


    const [columns, setColumns] = useState([]);

    useEffect(() => {
        Axios.get('/user/' + navTitre + '/navColumns')
            .then(response => {
                let tempColums = response?.data?.columns
                let columnsCopy = [
                    { title: t("refClient:client"), field: 'code', show: tempColums.code },
                    { title: t("refClient:clientName"), field: 'name', show: tempColums.name },
                    { title: t("refClient:codePostal"), field: 'zipCode', show: tempColums.zipCode },
                    { title: t("refClient:city"), field: 'city', show: tempColums.city },
                    // { title: t("refClient:dematType"), field: 'active', show: true },
                    { title: t("refClient:creationDate"), field: 'creationDate', show: tempColums.creationDate },
                    // { title: t("supplier:status", "Statut"), field: 'status', show: tempColums.status },
                    { title: t("refClient:visualiser"), field: 'visu', show: tempColums.visu }
                ];

                setColumns(columnsCopy);
                columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);

            })
    }, [userType, t, loadColumns])

    useEffect(() => {
        localStorage.setItem("origin", "fromClient")
        getClientsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    // useEffect(() => {
    //     resetFilters()
    //     // updateShowFilters(false)
    // }, [])

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getClientsPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setClientsPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getClientsPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const resetFilters = () => {
        setClientsFilters(null);
        setClientsPage(1)
        getClientsPage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent>
        <span>{t("clientList:noClients")}</span>
    </NoContent>;

    if (!loading && clientsData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody columns={columns} clientsData={clientsData} userRole={userRole} />)
    }

    useEffect(() => {        
        const timer = setTimeout(() => {
            logout()
        }, 1800000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <RFContainer>
            <TitleHelmet title={"OPTICAL CENTER | Sites"} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("menu:clients", "Sites")}</HeaderLocation>
                    {/* <HeaderTitle>{t("client:client", "Client")}Référentiel</HeaderTitle> */}
                </HeaderInfos>
                <HeaderActions>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        userType={userType}
                        userRole={userRole}
                    />
                </HeaderActions>
            </Header>
            <RFBody>
                <RFTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setClientsPageSize}
                    />
                    <Table>
                        <TableHead
                            userType={userType}
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                        />
                        <TBody height={density}>
                            <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                clientsFilters={filtersQuery}
                                filtersChange={setClientsFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setClientsPageSize}
                    />
                </RFTableContainer>
            </RFBody>
        </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.clients.loading,
    clientsData: state.clients.data,
    count: state.clients.count,
    currentPage: state.clients.currentPage,
    pageSize: state.clients.pageSize,
    sortQuery: state.clients.sortQuery,
    reverse: state.clients.reverse,
    filtersQuery: state.clients.filtersQuery,
    showFilters: state.clients.showFilters,
    userRole: state.auth.role,
    density: state.contracts.density

})
const mapDispatchToProps = dispatch => ({
    getClientsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.clientsGetPage(page, filters, sort, reverse, pageSize)),
    setClientsPage: (page) => dispatch(actions.clientsSetPage(page)),
    setDensity: (density) => dispatch(actions.contractsSetDensity(density)),
    setClientsPageSize: (pageSize) => dispatch(actions.clientsSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.clientsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.clientsSetReverseSort(reverseS)),
    setClientsFilters: (filters) => dispatch(actions.clientsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.clientsUpdateShowFilters(show)),
    logout: () => dispatch(actions.logout())
})
export default connect(mapStateToProps, mapDispatchToProps)(ReferentielClient)
