import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EMContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const EMBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const EMTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
