import styled from 'styled-components';

export const UMContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
export const CFFormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`

export const UMBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const UserStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    border-radius: 8px;
    background-color: ${({bg}) => bg ? bg : "white"};
    color: ${({color}) => color ? color : "black"};
`