import React, { useEffect, useState } from 'react';
import { Notyf } from 'notyf';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {formatDate, formatNumber, getNotyfObject} from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { BtnOutlineAction } from '../../styles/Common';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderWrapper, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper } from './InvoiceDetail.styled';
import IDProgressLine from '../../components/InvoiceDetail/IDProgressLine/IDProgressLine';
import InvoiceView from '../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView';
import InvoiceSide from '../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide';

function InvoiceDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        invoiceError,
        getInvoiceData,
        resetAdmToInitialState
    } = props
    const theme = useTheme()
    let {uid, token} = useParams();
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    let location = useLocation();
    let navigate = useNavigate()
    
    useEffect(() => {
        if(token) {
            getInvoiceDataByToken(token)
        } else {
            getInvoiceData(uid)
        }
        return () => {
            resetAdmToInitialState()
        }
    }, [uid, token])

    const editStatus = (status, comment = null,  reason = null) => {
        Axios.put('/invoice//updateStatus', 
              { 
                  "InvoiceId": uid,
                  "status" : status,
                  reason,
                  comment
        }).then(response => {
            getInvoiceData(uid)
            notyf.success("Statut modifié")
        }).catch(err => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }
    
    let mandatJsx = null;

    // if(error) {
    //     notyf.error(t("mandat:generatedErrorMessage"));
    // }

    if(loadingData) mandatJsx = (<Spinner />)
    
    else if (invoiceData)
        mandatJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <IDHeaderTitle>
                                Facture : {invoiceData?.number}
                                ({invoiceData?.supplierName} - {invoiceData?.clientName})
                            </IDHeaderTitle>
                            {
                                invoiceData?.status !== "ERROR" &&
                                    <IDHeaderTitle>
                                        {formatNumber(invoiceData?.inclusiveTaxAmount)} TTC
                                    </IDHeaderTitle>
                            }
                        </IDHeader>
                        <IDProgressLineWrapper>
                            <IDProgressLine
                                status={invoiceData?.status}
                                editStatus={editStatus}
                                entityFilter={location?.state?.entityFilter}
                                accessToken={token}
                            />
                            {
                                invoiceData?.status !== "ERROR" &&
                                    <IDInvoiceDate>
                                        <IDInvoiceDateLabel>Date de facturation : </IDInvoiceDateLabel>
                                        <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue>
                                    </IDInvoiceDate>
                            }
                        </IDProgressLineWrapper>
                    </IDHeaderContainer>
                    {
                        !token && 
                            <IDHeaderActions>
                                    <BtnOutlineAction
                                        color={theme.colors.danger}
                                        border={theme.colors.danger}
                                        onClick={() => navigate(-1)}
                                    >
                                        RETOUR
                                    </BtnOutlineAction>
                            </IDHeaderActions>
                    }
                </IDHeaderWrapper>

                <IDBody accessToken={token}>
                    <InvoiceView />
                    {
                        !token ?
                            <InvoiceSide />
                        : null
                    }
                </IDBody>
            </>
        )

    return (
        <>
            <IDContainer>
                {mandatJsx}
            </IDContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingData: state.invoiceDetail.loadingData,
    invoiceData: state.invoiceDetail.invoiceData,
    invoiceError: state.invoiceDetail.invoiceError,
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState : () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail)
