import React, { useEffect } from 'react'
import StatHeader from '../../components/ClientHome/StatHeader/StatHeader';
import { HomeContainer, HomeHeader, HeaderInfos, HeaderLocation, HeaderTitle, HeaderActions, HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, HomeInvoices } from '../Home/Home.styled'
import BarChart from '../../components/ClientHome/StatBody/Charts/BarChart'
import { connect } from 'react-redux';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';

function ClientHome({ userType, logout }) {
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            logout()
        }, 1800000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <HomeContainer>
                <TitleHelmet title={"OPTICAL CENTER | " + t("menu:home", "Accueil")} />
                <HomeHeader>
                    <HeaderInfos>
                        <HeaderLocation>{t("menu:home", "Accueil")}</HeaderLocation>
                        <HeaderTitle>{t("menu:portal", "Portail")}</HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <HeaderAction>
                            {/* <Select 
                            options={options} 
                            value={options.find(option => option.value === value)?.label} 
                            onChange={selectChange} 
                        /> */}
                        </HeaderAction>
                    </HeaderActions>
                </HomeHeader>
                <HomeBody>
                    <HomeCards style={{justifyContent: "flex-start"}}>
                        <StatHeader />
                    </HomeCards>
                    {/* <HomeStats style={{height:"380px"}}>
                     <BarChart/>
                </HomeStats> */}
                </HomeBody>
            </HomeContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    login: state.auth.login
})

const mapDispatchToProps = dispatch => {
    return {

        logout: () => dispatch(actions.logout())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientHome)
