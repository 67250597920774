import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { TH, THContainer, THead } from '../../../styles/Table.styled';

function TableHead(props) {
    const {
        columns,
        reverse,
        setSortQuery,
        setReverse,
        getData,
        selectedAllRows,
        setSelectedAllRows,
        userRole,
        origin
    } = props;
    const { t } = useTranslation();
    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getData(field, newReverse);
    }

    const selectAllRowsHandler = (e) => {
        //console.log(selectedAllRows)
        setSelectedAllRows(!selectedAllRows);
    }

    return (
        <>
            <THead>
                <tr>
                    <TH key={"column123"} scope="col" width="50px" >
                        <THContainer style={{ justifyContent: "center" }}>
                            <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
                        </THContainer>
                    </TH>
                    {columns.map(column => (
                        <TH
                            key={column.field}
                            scope="col"
                            id={column.field}
                            width={column.width}
                            onClick={() => columnClickHandler(column.field)}
                        >
                            <THContainer>
                                <span>
                                    {t(`tableHead:${column.field}`, column.title)}
                                </span>
                                <ArrowUpwardIcon style={{ fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none' }} />
                                <ArrowDownwardIcon style={{ fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none' }} />
                            </THContainer>
                        </TH>

                    ))}
                    {((userRole == 'superadmin') || (userRole == 'admin' && origin == 'fromClient')) ?
                        <TH key={"column1453"} scope="col" width="100px" >
                            <THContainer style={{ cursor: "default" }}>
                                <span>{t('tableHead:actions', 'Actions')} </span>
                            </THContainer>
                        </TH>
                        : null
                    }
                </tr>
            </THead>
        </>
    )
}

export default TableHead
