import styled from 'styled-components/macro';

export const DelaySignaturesContainer = styled.div`
    height: 100%;
    width: 21.5%;
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    color: #482D1C;
    font-size:"0.9rem";

    @media (max-width: ${({theme}) => theme.size.laptopL}) {
        padding: 1rem 1rem;
        width: 28%;
    }
    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 80%;
    }

`