import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';
import { showColumn } from '../../../shared/utility'
function TableFilters(props) {
    const { showFilters, clientsFilters, filtersChange, applyFilters, columns } = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const { t } = useTranslation();

    
    
    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...clientsFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    let [dateInputValue, setDateInputValue] = useState({
        creationDate: clientsFilters?.creationDate ? convertDate(clientsFilters.creationDate) : ''
    });

    const getCreationdate = () => {
        return clientsFilters?.creationDate ? convertDate(clientsFilters.creationDate) : [];
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...clientsFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...clientsFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    

    return (
        <>
            <tr className="tableFilters" style={{ display: showFilters ? 'table-row' : 'none' }}>

                <TD style={{ display: !showColumn(columns, "code") && 'none' }}>
                    <FilterContainer>

                        <InputFilter
                            placeholder={t("refClient:client")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={clientsFilters?.code || ''}
                            name="code"
                            type="text"
                            autoComplete="off" />

                    </FilterContainer>
                </TD>
                {/* <TD>
                    <FilterContainer>
                    
                        <InputFilter  
                        placeholder={t("Nom")} 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={clientsFilters?.code || ''} 
                        name="code" 
                        type="text" 
                        autoComplete="off" />
                
                     </FilterContainer>
                </TD> */}
                <TD style={{ display: !showColumn(columns, "name") && 'none' }}>
                    <FilterContainer>

                        <InputFilter
                            placeholder={t("refClient:clientName", "Nom")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={clientsFilters?.name || ''}
                            name="name"
                            type="text"
                            autoComplete="off" />

                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "zipCode") && 'none' }}>
                    <FilterContainer>

                        <InputFilter
                            placeholder={t("refClient:codePostal", "code postal")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={clientsFilters?.zipCode || ''}
                            name="zipCode"
                            type="text"
                            autoComplete="off" />

                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "city") && 'none' }}>
                    <FilterContainer>

                        <InputFilter
                            placeholder={t("refClient:city", "ville")}
                            className="filter__input"
                            onChange={(e) => inputChangeHandler(e)}
                            value={clientsFilters?.city || ''}
                            name="city"
                            type="text"
                            autoComplete="off" />

                    </FilterContainer>
                </TD>
                {/* <TD style={{ display: !showColumn(columns, "active") && 'none' }}>
                    <FilterContainer>

                        <InputFilter
                            placeholder={t("refClient:dematType", "type de démat")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={clientsFilters?.family || ''}
                            name="family"
                            type="text"
                            autoComplete="off" />

                    </FilterContainer>
                </TD> */}
                {/* <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.countryCode || ''} name="countryCode" type="text" autoComplete="off" />
                    </div>
                </td> */}
                <TD style={{ display: !showColumn(columns, "creationDate") && 'none' }}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("refClient:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={clientsFilters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) => dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "status") && 'none' }}>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={clientsFilters?.status || ''}
                            name={t("refClient:status", "status")}
                        >
                            <Option value=""></Option>
                            <Option value="ACTIVE">{t("refClientFields:actif")}</Option>
                            <Option value="INACTIVE">{t("refClientFields:inactif")}</Option>
                            {/* <Option value="INPROGRESS"> {t("refClientFields:inprogress")}</Option>
                            <Option value="ARCHIVED">{t("refClientFields:archive")}</Option> */}
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <td></td>
                {/* <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.businessCode || ''} name="businessCode" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.businessName || ''} name="businessName" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={clientsFilters?.receivesAttachment || ''} name="receivesAttachment" style={{height:"35px", fontSize: "13px"}}>
                            <option value=""></option>
                            <option value="1">{t("global:yes")}</option>
                            <option value="0">{t("global:no")}</option>
                        </select>
                    </div>
                </td>
                <td></td> */}
            </tr>
        </>
    )
}

export default TableFilters
