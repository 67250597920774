import React, { useMemo, useState, useEffect, useRef } from 'react'
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import Recipient from '../../components/ContractForm/Recipient/Recipient';
import User from '../../components/ContractForm/User/User';
import RepertoireModal from '../../components/ContractForm/RepertoireModal/RepertoireModal';
import VisuModal from '../../components/ContractForm/VisuModal/VisuModal';
import Axios from '../../axios-proas'
import fr from 'react-phone-input-2/lang/fr.json'

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Attachment from '../../components/ContractForm/Attachment/Attachment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { convertFileToBase64, getNotyfObject, formatDate, checkValidity } from '../../shared/utility';
import Spinner from 'react-bootstrap/Spinner'
import { AddRecipientsBtn, AttachementsContainer, CFBody, CFContainer, CFFormGroup, CFFormClearIcon, CFFormSection, CFHeaderInfo, CFHeaderInfoText, CFFormWrapper, CFHeader, CFHeaderProgressBar, CFHeaderActions, CFHeaderTitle, CFHeaderWrapper, CFSection, CFFormLabel, WrapperCanvas, CSContainer, CSNavContent, CFHeaderWrapperPart, CFFormGroupPart, TelInput } from './ContractForm.styled';
import { BtnOutlineAction, FormInput, FormLabel } from '../../styles/Common';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import { DivLabel, DivGlp, ContentPiecesOrDest } from '../../components/NewDocumentation/EntitiesComponent/EntitiesComponent.Styled'
import Entity from '../Entity/Entity';
import { DocVisu, NoContent } from '../NewDocumentation/NewDocumentation.styled';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import StatusProgressLine from '../../components/Contracts/StatusProgressLine/StatusProgressLine';
import ModificationsChat from '../../components/Contracts/ContractBody/ModificationsChat/ModificationsChat';
import TimeLine from '../../components/Contracts/ContractBody/Timeline/TimeLine';
import SignatureCanvas, { getTrimmedCanvas, toDataURL, fromDataURL } from 'react-signature-canvas';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../styles/Table.styled';
import { getTime } from 'date-fns';
import DoneIcon from '@mui/icons-material/Done';
import "./ContractForm.css"
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationModal from '../../components/UI/ConfirmationModal/ConfirmationModal';
import UsersTable from '../../components/ContractForm/UsersTable/UsersTable';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';


import { flexbox } from '@mui/system';

function ContractForm(props) {
    const {
        userType,
        userUid,
        userLevel,
        userRole
    } = props;
    let { uidContract } = useParams()
    const location = useLocation()
    let notyf = getNotyfObject()
    const [url, setUrl] = useState('')
    const [users, setUsers] = useState([])
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()
    const [file, setFile] = useState("")
    const currentDate = new Date()
    let date = currentDate.getDate()
    let month = currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    const [pdfNumPages, setPdfNumPages] = useState(0)
    const userDocSecret = localStorage.getItem('secret')
    const [scrolledDocument, setScrolledDocument] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = React.useState(currentDate)
    const [pdfFile, setPdfFile] = useState()
    const [signatureClient, setSignatureClient] = useState(false)
    const [signatureCog, setSignatureCog] = useState(false)
    const [showRepertoireModal, setShowRepertoireModal] = useState(false)
    const [confirmationModalShow, setConfirmationModalShow] = useState(false)
    const [confirmationModalShow2, setConfirmationModalShow2] = useState(false)
    const [showBlocChat, setShowBlocChat] = React.useState(true)
    const [showBlocStatus, setShowBlocStatus] = React.useState(false)
    const [isActiveClient, setIsActiveClient] = useState(true)
    const [entities, setEntities] = useState([]);
    const [entitiesType, setEntitiesType] = useState('client')
    const [errors, setErrors] = useState(null)
    const [signatureDataB64, setSignatureDataB64] = useState(false)
    const [tabSideActive, setTabSideActive] = useState("status")
    const [signatureOld, setSignatureOld] = useState(null)
    const [signatureChanged, setSignatureChanged] = useState(false)
    const [contractViewLoading, setContractViewLoading] = useState(false)
    const [divers, setDivers] = useState({
        destination: '',
        status: false,
        users: []
    })
    const [contract, setContract] = useState({
        startDate: selectedStartDate.getTime(),
        attachments: [],
        recipients: [],
        title: '',
        text: '',
        status: '',
        fileType: '',
        entityRegion: '',
        step: '',
        users: [],
        width: '',
        height: ''
        // secret: false
    });
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([])
    const [messagesLoading, setMessagesLoading] = useState(false)
    const messagesRef = useRef()
    const [attachmentFileData, setAttachmentFileData] = useState([])
    const [contractData, setContractData] = useState([])
    const [nameSender, getNameSender] = useState([])
    const [docControlErrors, setDocControlErrors] = useState([])
    const [signatureDetected, setSignatureDetected] = useState(false)
    const [contractErrors, setContractErrors] = useState([])
    const [createContractLoading, setCreateContractLoading] = useState(false)
    const [signContractLoading, setSignContractLoading] = useState(false)
    const [userDivers, setUserDivers] = useState()

    const randSmsCode = useMemo(() => Math.floor(100000 + Math.random() * 900000), [])
    const [smsCode, setSmsCode] = useState("")
    const [sendSmsLoading, setSendSmsLoading] = useState(true);
    const [confirmIdentity, setConfirmIdentity] = useState(false);
    // const [attachmentNamePreview, setAttachmentNamePreview] = useState("");
    const [docName, setDocName] = useState("");
    const [attachmentUid, setAttachmentUid] = useState("");
    const [isDocx, setIsDocx] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const [checkAllSigs, setCheckAllSigs] = useState();
    const [autorisationAdmin, setAutorisationAdmin] = useState(false);
    const [autorisationCog, setAutorisationCog] = useState(false);
    const [autorisationOwner, setAutorisationOwner] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const sigCanvas = useRef({});
    const [matchedSmsCode, setMatchedSmsCode] = useState(false);/// to false
    const [docApproved, setDocApproved] = useState(false);
    const [entityRegion, setentityRegion] = useState("");
    const save = () => sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const [saveSignature, setsaveSignature] = useState(false)
    const [importAutorisation, setImportAutorisation] = useState(false);
    const [delegationId, setDelegationId] = useState();
    const [connectedUserId, setConnectedUserId] = useState();
    const [nbrSignWaitting, setNbrSignWaitting] = useState()
    const [userTele, setUserTele] = useState()
    const [sizeSelected, setSizeSelected] = useState('')
    const [showVisuModal, setShowVisuModal] = useState(false)
    const [emailFormat, setEmailFormat] = useState(false)
    const [diversType, setDiversType] = useState('')
    const [canISign, setCanISign] = useState(false)

    const [waitingForResponse, setWaitingForResponse]= useState(false)



    useEffect(() => {
        if (uidContract != null) {
            Axios.get("contract/" + uidContract + "/contractByUid").then(response => {
                setUserDivers(response?.data?.userDivers)
                setContract(response?.data?.result)
                // setSignatoriesOrder(response?.data?.result)
                setContractData(response?.data?.result)
                setAttachmentFileData(response?.data?.attachement)
                getAttachmentDoc(response?.data?.attachement[0].uid)
                setAttachmentUid(response?.data?.attachement[0].uid)
                setDocName(response?.data?.attachement[0].attachment)
                setentityRegion(response?.data?.entityRegion);
                setCheckAllSigs(response?.data?.checkAll.nbs);
                setSignatureDetected(response?.data?.ownerSignature)
                setDelegationId(response?.data?.idDelegation)
                setConnectedUserId(response?.data?.result?.connectedUserId)
                setNbrSignWaitting(response?.data?.contractStatus['signed'])
                setUserTele(response?.data?.userTele)
                loadMessages()

                if (userType == 'client') {
                    setSignatureClient(response?.data?.contractSignatureStatus)
                    // check there is a tag waitting et admin didn't sign
                    if (response?.data?.contractSignatureStatus) {
                        setAutorisationAdmin(true);
                    }
                    // if (response?.data?.result?.fileType == "CSCF")
                    //     setAutorisationAdmin(false);
                    if (response?.data?.result?.status == "REFUSED") {
                        setAutorisationAdmin(false);
                    }
                }

                if (userType == 'client' && userLevel == 'cog') {
                    setSignatureCog(response?.data?.contractSignatureStatus)
                    if (response?.data?.result?.fileType == "CSCF" && response?.data?.checkGer == 0)
                        setAutorisationCog(true);

                    // Cog sign after Admin and Cog didn't sign
                    if (response?.data?.checkAdmin?.nbs == 1 && response?.data?.contractSignatureStatus) {
                        setAutorisationCog(true);
                    }
                    if (response?.data?.result?.connectedUserId == response?.data?.result?.diversUserRef && response?.data?.result?.fileType == "DIVERS") {
                        setAutorisationCog(true)
                    }
                    //Cog can't sign 2  times
                    if (response?.data?.checkCog?.nbs >= 1) {
                        setAutorisationCog(false);
                    }
                    if (response?.data?.result?.status == "REFUSED") {
                        setAutorisationCog(false);
                    }
                    if (response?.data?.result?.fileType == "AOCM")
                        setAutorisationCog(false);
                }
                // Type CSCF (only cog sign)
                // if (response.data.result.fileType == "CSCF" && response?.data?.checkCog.nbs == 0) {
                //     setAutorisationCog(true);
                // }
                // check Owner sign at last
                if (userType == 'owner') {
                    if (response?.data?.result?.fileType == "DIVERS") {
                        if (response?.data?.result?.connectedUserId == response?.data?.result?.diversUserRef) {
                            setAutorisationOwner(true)
                        }
                        if (response?.data?.checkAll?.nbs == 0) {
                            setAutorisationOwner(false);
                        }
                        if (response?.data?.checkAll.nbs == 0 && response?.data?.result.lastSignature !== userUid && response?.data?.contractSignatureStatus) {
                            setAutorisationOwner(true);
                        }
                        // if (response?.data?.result?.connectedUserId == response?.data?.result?.diversUserRef) {
                        //     setAutorisationOwner(true)
                        // }

                    } else {
                        if (response?.data?.checkAll?.nbs == 0 && response?.data?.result?.lastSignature !== userUid && response?.data?.result?.fileType !== "DIP") {
                            if (response?.data?.result?.connectedUserRole == 'standard')
                                setAutorisationOwner(true);
                        }
                        //pour LL signe apres sas
                        if ((response?.data?.entityRegion == "IL" || response?.data?.result?.connectedUserId == response?.data?.idDelegation) && response?.data?.result.lastSignature == null) {
                            setAutorisationOwner(false);
                        }
                        //pour LL ne peut pas signer si il existe un délégué (Type CF)
                        if (response?.data?.idDelegation !== null && response?.data?.result.lastSignature !== null) {
                            setAutorisationOwner(false)
                            //pour délégué peut signer
                            if (response?.data?.idDelegation == response?.data?.result?.connectedUserId)
                                setAutorisationOwner(true)
                        }
                        //pour LL ne peut pas signer si il existe un délégué (Type DIP)     
                        if (response?.data?.result?.fileType == "DIP" && response?.data?.checkAll?.nbs == 0) {
                            if (response?.data?.entityRegion == "IL") {
                                setAutorisationOwner(true)
                            }
                            if (response?.data?.idDelegation !== null) {
                                if (response?.data?.idDelegation == response?.data?.result?.connectedUserId) {
                                    setAutorisationOwner(true)
                                } else {
                                    setAutorisationOwner(false)
                                }
                            }
                        }

                        if (response?.data?.result?.fileType == "AOCM" && response?.data?.entityRegion == "FR" && response?.data?.checkAll?.nbs == 0) {
                            setAutorisationOwner(true);
                        }

                    }
                    if (response?.data?.result?.status == "COMPLETED" || response?.data?.result?.status == "REFUSED") {
                        setAutorisationOwner(false);
                    }
                }
                console.log(randSmsCode);
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }
    }, [])

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const addRecipientsHandler = (newRecipients) => {
        setDocControlErrors([])
        const allClients = newRecipients.find(row => row.uid === "allClients")
        let contractRecipientsTemp = contract?.recipients ? [...contract?.recipients] : []
        const newRecipientsClients = newRecipients.filter(row => row.type === "client")
        const recipientsAllClientsIndex = contractRecipientsTemp.findIndex(row => row.uid === "allClients")

        if (allClients) {
            contractRecipientsTemp = contractRecipientsTemp.filter(row => row.type !== "client");
            if (recipientsAllClientsIndex < 0)
                contractRecipientsTemp.push(allClients)
        } else {
            if (newRecipientsClients.length > 0) {
                if (recipientsAllClientsIndex >= 0)
                    contractRecipientsTemp.splice(recipientsAllClientsIndex, 1)
                let recipientsClient = newRecipients.filter(row => row.type === "client")
                recipientsClient.map(row => {
                    let existAlready = contractRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
                    if (!existAlready)
                        contractRecipientsTemp.push(row)
                })
            }
        }
        if (contract.fileType !== "DIVERS") {
            setContract({
                ...contract,
                recipients: contractRecipientsTemp
            })
        } else {
            getDiversSite()
        }
    }

    const deleteRecipientHandler = (recipientUid) => {
        let recipientsTemp = [...contract?.recipients];
        let recipientIndex = recipientsTemp.findIndex(row => row.uid === recipientUid);
        if (recipientIndex >= 0)
            recipientsTemp.splice(recipientIndex, 1)
        setContract({
            ...contract,
            recipients: recipientsTemp
        })
    }

    const addAttachmentHandler = (attachment, fileToverifyResponse = null) => {
        let attachmentsTemp = [...contract?.attachments, attachment]
        setContract({
            ...contract,
            attachments: attachmentsTemp,
            gerTag: fileToverifyResponse?.SIGNATUREGERANT,
            cogTag: fileToverifyResponse?.SIGNATURECOGERANT,
            sasTag: fileToverifyResponse?.SIGNATURESAS,
            llTag: fileToverifyResponse?.SIGNATURELL,
        })
    }

    const deleteAttachmentHandler = (attachmentName) => {
        let attachmentsTemp = [...contract?.attachments];
        let attachmentIndex = attachmentsTemp.findIndex(row => row.name === attachmentName);
        if (attachmentIndex >= 0)
            attachmentsTemp.splice(attachmentIndex, 1)
        setContract({
            ...contract,
            attachments: attachmentsTemp
        });
        setUrl('')

    }

    const addSignaturePdf = (owner) => {
        setSignContractLoading(true)
        let imageURL = null
        let errors = []
        let terminated, inprogress = false
        imageURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

        if (contract.fileType == "FREE") {
            if ((contract?.signatoriesOrder?.length - 1) > +contract?.step) {
                inprogress = editContractStatusHandler(uidContract, imageURL, 'INPROGRESS')
            } else {
                terminated = editContractStatusHandler(uidContract, imageURL, 'COMPLETED')
            }
        } else if (contract.fileType == "DIVERS") {
            terminated = editContractStatusHandler(uidContract, imageURL, 'COMPLETED')
        } else {
            if (!owner) {
                let errors = checkValidityForm()
                if (errors && errors.length > 0) {
                    setErrors(errors)
                } else {
                    setErrors(null)
                    userLevel == "admin" ? setSignatureClient(true) : setSignatureCog(true)
                    if (contract.fileType == "CF" || (contract.fileType == "CSCF" && +nbrSignWaitting > 1) || contract.fileType == "AOCM" || contract.fileType == 'DIP') {
                        inprogress = editContractStatusHandler(uidContract, imageURL, 'INPROGRESS')
                    } else {
                        terminated = editContractStatusHandler(uidContract, imageURL, 'COMPLETED')
                    }
                }
            } else {
                if ((entityRegion == "IL" || (delegationId == connectedUserId) || contract.fileType == "AOCM" || contract.fileType == 'DIP')) {
                    terminated = editContractStatusHandler(uidContract, imageURL, 'COMPLETED')
                    // } else {
                    //     notyf.error(t("contract:noSignatureDetected", "No signature detected"))
                    // }
                } else {
                    inprogress = editContractStatusHandler(uidContract, imageURL, 'INPROGRESS')
                    // } else {
                    //     notyf.error(t("contract:noSignatureDetected", "No signature detected"))
                    // }
                }
            }
        }
    }

    const getEntitiesList = () => {
        let filter = {
            type: 'owner'
        }
        Axios.get('/client//modalEntities', {
            params: filter
        }).then(res => {
            setEntities(res.data?.result)
        })
    }

    const radioChangeHandler = (e) => {
        setImportAutorisation(false);
        setUsers([])
        setUserDivers()
        const target = e?.target?.value
        setDivers({ ...divers, destination: target, users: [] })
        // setEntitiesType(target == 'site' ? 'client' : 'owner')
        if (target == 'owner') {
            setEntitiesType('owner')
            getEntitiesList()
            getDiversOwner()
        } else if (target == 'site') {
            setEntitiesType('client')
            setContract({
                ...contract,
                recipients: []
            });
            // setDivers({ ...divers, users: [] })
        } else {
            setEntitiesType('new')
        }
    }

    const radioChangeHandler2 = (e) => {
        const userId = e?.target.value
        let recipientsClient = divers.users.filter(user => user.id === userId)

        setContract({
            ...contract,
            users: recipientsClient
        })
        setImportAutorisation(true);

    }

    const editContractStatusHandler = (uidContract, imageURL, status) => {

        Axios.put(`/contract//signaturePdf`, {
            contractUid: uidContract,
            docName: docName,
            imgURL: imageURL,
            autorisationOwner: autorisationOwner,
            userUid: userUid,
            saveSignature: saveSignature,
            status: status
        }).then(response => {
            if (response?.data?.success) {
                Axios.put(`/contract/${uidContract}/updateStatusContract`, {
                    status: status
                }).then(response => {
                    if (response?.data?.success) {
                        postStatus(status)
                    }
                    else {
                        notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
                        return false
                    }
                })
                navigate(-1)
            } else {
                notyf.error('Une erreur s\'est produite !')

            }
        }
        )
    }

    const fileChangeHandler = (e) => {
        setDocControlErrors([])
        // console.log("je suis là")
        let file = e.target.files[0]
        let fileB64 = "";
        let fileToverify = null
        
        if (file) {
            let existFile = contract?.attachments.find(row => row.name === file.name)
            if (existFile) {
                notyf.error("Un fichier avec le même nom a déjà été ajouté")
            } else {
                // if (file.size > 5242880)
                //     notyf.error("La taille du fichier ne doit pas dépasser 5 MO")

                convertFileToBase64(file).then(result => {
                    fileB64 = result;
                    fileToverify = {
                        name: file.name,
                        type: file.type,
                        content: fileB64
                    }
                    if (contract?.fileType == "FREE") {
                        setWaitingForResponse(true)
                        Axios.post('/contract//verifyContract', fileToverify
                        ).then(response => {
                            console.log(response?.data[0]?.message)
                            // fileToverifyResponse = response

                            addAttachmentHandler({
                                name: file.name,
                                type: file.type,
                                content: fileB64
                            },
                                response?.data[0]?.message
                            )
                            setWaitingForResponse(false)
                        }).catch(response => {
                            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
                        })
                    } else {
                        addAttachmentHandler({
                            name: file.name,
                            type: file.type,
                            content: fileB64
                        })
                    }



                }).catch(err => {
                    notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
                })
            }
        }

        if (e?.target.files && e?.target.files[0]) {
            setUrl(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0].name)
        }
        let tmpDoc = {
            ...contract,
            formData: e?.target.files[0],
            fileName: e?.target.files[0].name
        }
        setContract(tmpDoc)
    }

    const titleChangeHandler = (e) => {
        const value = e.target.value

        if (value.length == 0) {
            setUserDivers()
            setContract({
                ...contract,
                attachments: [],
                recipients: [],
                fileType: '',
                users: [],
                title: value
            })
            setUrl('')
        } else {
            setContract({
                ...contract,
                title: value
            })
        }
    }

    const userChangeHandler = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        // console.log(e.target.value)

        let userTemp = {
            ...userDivers,
            [name]: value
        }
        setUserDivers(userTemp)
        
        // console.log(value)
        
        if (name == 'login') {
            // if (((userTemp?.first_name?.length && userTemp?.last_name?.length && userTemp?.login?.length) !== 0) && (userTemp?.telephone_number?.length == 13)) {
            //     setImportAutorisation(true)
            // } else {
            //     setImportAutorisation(false)
            // }

            // console.log(value)
            // console.log(userDivers?.hasOwnProperty('login'))
            // console.log(checkValidity(value, { required: true, isEmail: true }))

            if ( name != 'login' || !checkValidity(value, { required: true, isEmail: true })) {
                setEmailFormat(false)
            } else {
                setEmailFormat(true)
                Axios.get(`/user/${value}/userByLogin`)
                    .then(response => {
                        let userTemp = {
                            ...userDivers,
                            [name]: value,
                            first_name: response?.data?.userDivers[0]['first_name'],
                            last_name: response?.data?.userDivers[0]['last_name'],
                            telephone_number: response?.data?.userDivers[0]['telephone_number'],
                            type: response?.data?.userDivers[0]['type']
                        }
                        setUserDivers(userTemp)
                        setDiversType(response?.data?.userDivers[0]['type'] !== 'userDivers' ? 'userSysteme' : 'userDivers')
                        checkDestinaireForm(userTemp)

                    }).catch(err => {
                        let userTemp = {
                            ...userDivers,
                            [name]: value,
                            first_name: '',
                            last_name: '',
                            telephone_number: '',
                            type: ''
                        }
                        setUserDivers(userTemp)
                        setDiversType('')
                        checkDestinaireForm(userTemp)
                    })
            }
        } else {
            let userTemp = {
                ...userDivers,
                [name]: value
            }
            setUserDivers(userTemp)
            checkDestinaireForm(userTemp)

        }
        setContract({
            ...contract,
            users: userTemp
        })

        // checkDestinaireForm(userTemp)


    }

    const phoneInputChangeHandler = (value) => {
        let userTemp = {
            ...userDivers,
            telephone_number: value
        }
        setUserDivers(userTemp)
        setContract({
            ...contract,
            users: userTemp
        })

        checkDestinaireForm(userTemp)
    }

    const checkDestinaireForm = (userTemp) => {
        if (((userTemp?.first_name?.length && userTemp?.last_name?.length && userTemp?.login?.length) !== 0) && (userTemp?.telephone_number?.length == 13)) {
            setImportAutorisation(true)
        } else {
            setImportAutorisation(false)
        }
    }

    const checkContractForm = () => {
        let errors = []
        if (contract?.fileType != "DIVERS") {
            if (contract?.recipients?.length == 0)
                errors.push(t("contract:recipientErrorMessasge", "Veuillez désigner un déstinataire"))
        }
        // si le destinataire est un owner ?
        
        if (contract?.fileType == "FREE") {
            console.log(contract?.freeType)
            if(contract?.freeType){
                if (contract?.freeType == "")
                    errors.push(t("contract:fre", "Veuillez renseigner le type de contrat libre"))
            }else{
                errors.push(t("contract:fre", "Veuillez renseigner le type de contrat libre"))
            }
        }
        if (contract?.attachments?.length == 0)
            errors.push(t("contract:fileErrorMessasge", "Veuillez choisir un document"))
        if (contract?.title?.length === 0)
            errors.push(t("contract:titleErrorMessasge", "Veuillez saisir le titre"))

        if (contract?.fileType == "DIVERS" && entitiesType == 'new') {
            if (!userDivers.hasOwnProperty('login') || !checkValidity(userDivers?.login, { required: true, isEmail: true })) {
                errors.push(t("masterData:error_userEmail", "Le champ 'Email' est vide ou non valide"));
            }
        }

        return errors;
    }

    const createContract = () => {
        setCreateContractLoading(true)
        Axios.post('/contract', contract).then(response => {
            setCreateContractLoading(false);
            if (response?.data?.docErrors) {
                setDocControlErrors(response?.data.docErrors)
                //notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            } else if (response?.data?.userError) {
                notyf.error(t("mandat:generatedErrorMge", "Cette email est déjà attribué, veuillez choisir dans la liste"))

            } else {
                notyf.success(t("notyf:successContractCreation"))
                navigate(-1);

            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            setCreateContractLoading(false)
        })
    }

    const inputChangeHandler = (e) => {
        setDocControlErrors([])
        const value = e.target.value

        let height = 0;
        let width = 0;


        Axios.get('/contract//sizeSignature', {
            params: value
        }).then(res => {
            setContract({
                ...contract,
                attachments: [],
                height: res?.data[0]?.height,
                width: res?.data[0]?.width,
                fileType: value
            })
            setUrl('')

        })
        if (value == "DIVERS") setDivers({ ...divers, status: true })
        else setDivers({ ...divers, status: false, destination: "" })

        setEntitiesType('client')
    }

    const editContractRefuse = (uidContract) => {
        setSignContractLoading(true)
        setContract({ ...contract, status: 'REFUSED' })
        Axios.put(`/contract/${uidContract}/updateStatusContract`, {
            status: 'REFUSED',
            contract: contract,
            userUid: userUid
        }).then(response => {
            postStatus('REFUSED')
            navigate(-1)
            // notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const createContractClickHandler = () => {

        let errors = checkContractForm()
        if (errors.length > 0)
            setContractErrors(errors)
        else {
            setContractErrors([]);
            createContract()
        }
        // if (isDocx) {
        //     getReplacements();
        // }
    }

    const createContractPreview = () => {
        console.log(contract)
        setContractViewLoading(true)
        Axios.put(`/contract//contractPreview`, {
            contract: contract
        }).then(response => {
            setContractViewLoading(false)
            setShowVisuModal(true)
            setPdfFile(_base64ToArrayBuffer(response?.data[0]))
        }).catch(err => {
            setContractViewLoading(false)
            notyf.error('Une erreur s\'est produite !')
        })
    }

    const getAttachmentDoc = (uidContract) => {
        Axios.get(`/contract/${uidContract}/attachmentDoc`)
            .then(response => {
                setPdfFile(_base64ToArrayBuffer(response.data.pdfFile))
            }).catch(err => {
                notyf.error('Une erreur s\'est produite !')
            })
    }

    const downAttachmentPdf = (uidContract) => {
        Axios.get(`/contract/${uidContract}/attachmentDoc`)
            .then(response => {
                const linkSource = 'data:application/octet-stream;base64,' + response.data.pdfFile;
                const link = document.createElement("a");
                link.href = linkSource;
                link.download = response.data.attachmentName;
                link.click();
            }).catch(err => {
                notyf.error('Une erreur s\'est produite !')

            })
    }

    const getStatusContract = (uidContract) => {
        Axios.get("contract/" + uidContract + "/statusContract").then(response => {
            getStatusContract(response?.data?.res)
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const postStatus = (status) => {
        Axios.post("contractevent", {
            contractUid: uidContract,
            message: '',
            type: "STEP_STATUS",
            snapshot: status,
            userUid: userUid
        }).then(response => {
            loadMessages();
            setMessage("");
        })
    }

    const getDiversSite = () => {
        console.log("userssListForDivers")
        Axios.get("/user//usersListForDivers", {
            params: {
                recipientUid: contract?.recipients[0]?.uid
                // diversType: divers.destination
            }
        }).then(response => {
            setDivers({ ...divers, users: response?.data?.users })
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getDiversOwner = () => {
        console.log("ownersListForDivers")
        Axios.get("/user//ownersListForDivers").then(response => {
            setDivers({ ...divers, users: response?.data?.users })
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const loadMessages = () => {
        setMessagesLoading(true)
        Axios.get("contractevent", {
            params: {
                contract: uidContract,
                type: "USER_CHAT_EVENT"
            }
        }).then(response => {
            setMessagesLoading(false)
            setMessages(response?.data?.events.reverse())
            messagesRef?.current?.scrollTo({
                top: messagesRef.current.scrollHeight,
                left: 0
            })
        }).catch(response => {
            setMessagesLoading(false)
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
        })
    }

    const getTeleNumber = (userUid) => {
        if (userUid != null && userTele != null) {
            Axios.get("contract/" + userUid + "/userTeleNum").then(response => {
                if ((userType == "client" && userLevel == 'cog' && autorisationCog) || (userType == "client" && userLevel == 'admin' && autorisationAdmin) || (userType == 'owner' && autorisationOwner))
                    sendSmsCode(response?.data?.res?.telephone_number)
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        } else {
            notyf.error(t("mandat:generatedErrorMessage", "Vous n'avez pas de numéro de téléphone"))
        }
    }

    const getSignature = (userUid) => {
        Axios.get("user/" + userUid + "/signatureOwner").then(response => {
            setSignatureOld('data:image/png;base64,' + response?.data?.signature)
            sigCanvas.current.fromDataURL('data:image/png;base64,' + response?.data?.signature, { width: 300, height: 133, className: 'sigCanvas' })
            // beginSignatureCanvas('data:image/png;base64,' + response?.data?.signature)
            if (!sigCanvas.current.isEmpty() && (response?.data?.signature != null && response?.data?.signature != "")) {
                setSignatureDataB64(true)
            }
        }).catch(response => {
            // notyf.error(t("mandat:generatedErMessage", "Une erreur s'est produite"))
        })
    }

    const sendSmsCode = (phoneNumber) => {
        Axios.get('/contract/' + phoneNumber + '/sendSmsToSign', {
            params: {
                smsMessage: t("global:msgText", "Bonjour, Votre code de confirmation pour signature est ") + randSmsCode + t("global:valid")
            }
        }).then(response => {
            notyf.success(t("mandat:sendMessageText"))
            setSendSmsLoading(false)
        }).catch(response => {
            setSendSmsLoading(false)
            notyf.error(t("mandat:failedSendingMessageText"));
        })
        const timer = setTimeout(() => {
            navigate(-1)
        }, 120000);
        return () => clearTimeout(timer);

    }

    const currentEntitiesType = (e) => {
        let entityType = e?.target?.value
        setEntitiesType(entityType)
        if (entityType == 'client')
            setIsActiveClient(true)
        else
            setIsActiveClient(false)
    }

    const inputSizeChangeHandler = (e) => {
        let value = e?.target?.value
        let name = e?.target?.name
        setContract({
            ...contract,
            [name]: value
        })
    }


    const checkInputValidity = (e) => {
        let value = e?.target?.value
        let name = e?.target?.name
        if (name == "width") {
            if (value < 30) value = 30
            if (value > 80) value = 80
        } else {
            if (value < 6) value = 6
            if (value > 16) value = 16
        }
        setContract({
            ...contract,
            [name]: value
        })
    }



    const selectEntity = (entity) => {
        let selectedTemp = [...contract.recipients];
        let entityIndex = selectedTemp.findIndex(row => row.uid === entity.uid);
        if (entityIndex >= 0) {
            selectedTemp.splice(entityIndex, 1)
            setContract({
                ...contract,
                recipients: [...selectedTemp]
            })
        }
        else {
            selectedTemp.filter(row => row.type === (isActiveClient ? "client" : "supplier")).length < 1 ?
                setContract({
                    ...contract,
                    recipients: [...selectedTemp, entity]
                })
                : null
        }
    }

    const pdfScrollHandler = (e) => {
        let docPages = e?.doc?._pdfInfo?.numPages
        //let pdfContainer = document.querySelector(".rpv-default-layout-body")
        setPdfNumPages(docPages)
        if (docPages === 1)
            setScrolledDocument(true)
        else
            setScrolledDocument(false)
    }
    const pageChangeHandler = (e) => {
        let currentPage = e?.currentPage + 1
        let docPages = e?.doc?._pdfInfo?.numPages
        if (currentPage === docPages) {
            setScrolledDocument(true)
        } else {
            //setAdmScrolledEnd(false)
        }
    }

    const smsCodeChangeHandler = (e) => {
        let code = e.target.value;
        if (code.length < 7) {
            setSmsCode(code)
            if (code.length == 6 && +code == randSmsCode) {
                setMatchedSmsCode(true)
            }
        }
        getSignature(userUid);


    }

    const checkValidityForm = () => {
        let errors = []
        if (!scrolledDocument) {
            errors.push(t("contract:readDocumentMessage"));
        }
        if (!docApproved) {
            errors.push(t("contract:approuveDocMessage"));
        }
        if (!matchedSmsCode) {
            errors.push(t("contract:checkCodeMessage"));
        }
        if (!signatureDataB64) {
            errors.push(t("contract:noSignDetectedMessage"));
        }
        return errors;
    }

    const endSignatureCanvas = (e) => {
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        if (!sigCanvas.current.isEmpty()) {
            setSignatureDataB64(true)
            setSignatureChanged(true)
        }
    }

    const clearCanvas = (e) => {
        sigCanvas.current.clear();
        setSignatureDataB64(false)
        setSignatureChanged(false)
    }

    const setTabActive = (activeTab) => {
        setTabSideActive(activeTab)
        activeTab != "signature" ? setSignatureDataB64(false) : null
    }


    const freeTypeChangeHandler = (e) => {
        const value = e?.target.value
        setContract({
            ...contract,
            freeType: value
        })
    }


    const selectedTagsForFreeDocType = () => {
        if (contract?.gerTag || contract?.cogTag || contract?.sasTag || contract?.llTag)
            return true
        else return false
    }

    const signatureDateChangeHandler = (e) => {
        const name = e?.target?.name
        const value = e?.target?.value

        setContract({
            ...contract,
            [name]: value
        })
        // console.log(contract)
    }



    return (
        <>
            <CFContainer>
                {uidContract ?
                    <TitleHelmet title={"OPTICAL CENTER | " + contract?.title} />
                    :
                    <TitleHelmet title={"OPTICAL CENTER | " + t("contract:newContract")} />
                }

                <CFHeaderWrapper>
                    <CFHeader>
                        {uidContract ?
                            <CFHeaderTitle style={{ margin: "10px 30px", wordBreak: 'break-word', maxWidth: '350px' }}>{t("global:title", "Titre")} : {contract?.title}</CFHeaderTitle>
                            : <CFHeaderTitle style={{ margin: "10px 30px", wordBreak: 'break-word', maxWidth: '350px' }}>{t("contract:newContract", "Nouveau contrat")}</CFHeaderTitle>
                        }
                        {uidContract ?

                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <CFHeaderInfo>
                                    <span>{t("global:type")}</span>
                                    <CFHeaderInfoText>{ contract?.fileType == "FREE" ? contract?.freeType : t("contract:" + contract.fileType)  }</CFHeaderInfoText>
                                </CFHeaderInfo>

                                <CFHeaderInfo>
                                    <span>{t("contract:dateCreation")}</span>
                                    <CFHeaderInfoText>{formatDate(parseInt(contract.creationDate))}</CFHeaderInfoText>
                                </CFHeaderInfo>
                                <CFHeaderInfo>
                                    <span>{t("contract:recipient")}</span>
                                    <CFHeaderInfoText>{contract.fileType == 'DIVERS' ?
                                        userDivers?.first_name + ' ' + userDivers?.last_name + ' [' + userDivers?.login
                                        + '] (' + userDivers?.telephone_number + ')'
                                        : contract.destinataire}</CFHeaderInfoText>
                                </CFHeaderInfo>
                            </div>
                            :
                            null
                        }


                        <CFHeaderActions>
                            <div style={{ display: "flex" }}>

                                {!uidContract && (
                                    createContractLoading ? (
                                        <Spinner animation="border" variant="primary" />
                                    )
                                        :
                                        <>
                                            <BtnOutlineAction
                                                color={(contract.width !== '' && contract.height !== '' && url !== '') ? theme.colors.success : theme.colors.disabledBtn}
                                                border={(contract.width !== '' && contract.height !== '' && url !== '') ? theme.colors.success : theme.colors.disabledBtn}
                                                onClick={() => { createContractPreview() }}
                                                disabled={contract.width == '' || contract.height == '' || url == ''}
                                            >
                                                <span>{t("contract:preview", "Pré-Visualiser")}</span>
                                                {
                                                    contractViewLoading &&
                                                    <Spinner animation="border" variant="success" style={{ marginLeft: "20px" }} />
                                                }
                                            </BtnOutlineAction>

                                            <BtnOutlineAction
                                                color={theme.colors.success}
                                                border={theme.colors.success}
                                                onClick={() => createContractClickHandler()}
                                            >
                                                <span>{t("contract:send", "Envoyer")}</span>
                                            </BtnOutlineAction>
                                        </>
                                )
                                }
                                <BtnOutlineAction
                                    color={theme.colors.primary}
                                    border={theme.colors.primary}
                                    onClick={() => navigate("/gestionContract")}
                                >
                                    <span>{t("contract:return", "RETOUR")}</span>
                                </BtnOutlineAction>
                            </div>

                        </CFHeaderActions>
                    </CFHeader>
                    <CFHeaderProgressBar>
                        <div
                            style={{ display: 'flex', marginLeft: "30px" }}>
                            {contract.status !== '' ?
                                <StatusProgressLine
                                    fileType={contract?.fileType}
                                    step={contract?.step}
                                    status={contract?.status}
                                    signatoriesOrder={contract?.signatoriesOrder}
                                    userType={userType}
                                />
                                : null
                            }
                        </div>
                    </CFHeaderProgressBar>
                </CFHeaderWrapper>

                <CFBody>
                    <CFSection>
                        <CFFormWrapper>
                            <CFFormSection style={{ paddingTop: "0.5rem" }}>
                                <ContentPiecesOrDest
                                    style={{ display: "flex", justifyContent: "space-between" }}>
                                    {attachmentFileData?.map(entity => (
                                        <AddRecipientsBtn key={entity.uid} style={{ textAlign: 'center' }} onClick={() => downAttachmentPdf(entity.uid)}>
                                            {t("contract:download", "Télécharger")}
                                        </AddRecipientsBtn>
                                    ))
                                    }

                                    {
                                        contract?.fileType != "FREE" ?
                                            (userType == "client" && userLevel == 'cog' && autorisationCog) || (userType == "client" && userLevel == 'admin' && autorisationAdmin) || (userType == 'owner' && autorisationOwner) ?
                                                <AddRecipientsBtn
                                                    onClick={() => setTabActive("signature")}>
                                                    {t("contract:sign", "Signer")}
                                                </AddRecipientsBtn>
                                                : null
                                            : null
                                    }
                                    {
                                        (contract?.fileType == "FREE" && contract?.canISign) &&
                                        <AddRecipientsBtn
                                            onClick={() => setTabActive("signature")}>
                                            {t("contract:sign", "Signer")}
                                        </AddRecipientsBtn>
                                    }

                                </ContentPiecesOrDest>
                                {uidContract ?
                                    pdfFile ? (
                                        <DocVisu>
                                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                            <Viewer fileUrl={pdfFile}
                                                onDocumentLoad={(e) => pdfScrollHandler(e)}
                                                onPageChange={(e) => pageChangeHandler(e)} />

                                        </DocVisu>
                                    ) : (
                                        <NoContent>
                                            {t('global:previewArea')}
                                        </NoContent>
                                    )
                                    :
                                    url ? (
                                        <DocVisu>
                                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                            <Viewer fileUrl={url} />
                                        </DocVisu>
                                    ) : (
                                        <NoContent>
                                            {t('global:previewArea')}
                                        </NoContent>
                                    )
                                }
                            </CFFormSection>
                        </CFFormWrapper>

                        <CFFormWrapper style={{ justifyContent: "flex-start", border: "1px solid #D9E1E7CC", borderRadius: "18px" }}>
                            {
                                contractErrors && contractErrors.length > 0 ? (
                                    <div className="alert alert-danger mt-2 campaignForm__errors_container" role="alert" style={{ width: "95%", margin: "auto", marginBottom: "0" }}>
                                        <ul style={{ marginTop: "20px" }}>
                                            {
                                                contractErrors.map(error => (<li key={error}>{error}</li>))
                                            }
                                        </ul>
                                    </div>
                                ) : null
                            }


                            {/* {userType != "owner" ?
                            <> */}
                            {
                                uidContract ?
                                    <>
                                        <ul className="nav nav-tabs" style={{ marginBottom: "10px" }}>
                                            <li className="nav-item">
                                                <span className={"nav-link" + (tabSideActive === "status" ? " active" : "")} data-toggle="tab" onClick={() => setTabActive("status")}>{t('contract:status', "Statut")} </span>
                                            </li>
                                            <li className="nav-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className={"nav-link" + (tabSideActive === "comment" ? " active" : "")} data-toggle="tab" onClick={() => setTabActive("comment")}>{t("contract:timeline", "Commentaires")} <b>({messages.length})</b> </span>
                                            </li>
                                            {
                                                contract?.fileType != "FREE" ?
                                                    (userType == "client" && userLevel == 'cog' && autorisationCog) || (userType == "client" && userLevel == 'admin' && autorisationAdmin) || (userType == "owner" && autorisationOwner) ?
                                                        <li className="nav-item">
                                                            <span className={"nav-link" + (tabSideActive === "signature" ? " active" : "")} data-toggle="tab" onClick={() => { setTabActive("signature") }} >{t('contract:signature', "Signatures")} </span>
                                                        </li>
                                                        : null
                                                    : null
                                            }

                                            {
                                                contract?.fileType == "FREE" && contract?.canISign ?
                                                    <li className="nav-item">
                                                        <span className={"nav-link" + (tabSideActive === "signature" ? " active" : "")} data-toggle="tab" onClick={() => { setTabActive("signature") }} >{t('contract:signature', "Signatures")} </span>
                                                    </li>
                                                    : null
                                            }
                                        </ul>
                                        <div id="myTabContent" className="tab-content time-Line-Content" >
                                            {
                                                tabSideActive === "comment" ?
                                                    <div className="tab-pane fade show active time-line-bloc">
                                                        <CSNavContent style={{ padding: "1.5rem 2rem" }}>
                                                            <ModificationsChat userUid={userUid} loadMessages={loadMessages} messages={messages} setMessages={setMessages} />
                                                        </CSNavContent>
                                                    </div> : null
                                            }
                                            {
                                                tabSideActive === "status" ?
                                                    <div className="tab-pane fade show active time-line-bloc">
                                                        <CSNavContent style={{ padding: "1.5rem 2rem" }}>
                                                            <TimeLine
                                                                contractUid={uidContract}
                                                                userUid={userUid}
                                                                delegationId={delegationId}
                                                            />
                                                        </CSNavContent>
                                                    </div> : null
                                            }
                                            {
                                                tabSideActive === "signature" ?
                                                    (
                                                        (
                                                            (
                                                                (userType == "client" && contract.status !== "REFUSED")
                                                                ||
                                                                (userType == "owner" && autorisationOwner)
                                                            )
                                                            && contract?.fileType != "FREE"
                                                        )

                                                        || (contract?.fileType == "FREE" && contract?.canISign)) ?

                                                        <div className="tab-pane show active fade modification-bloc">
                                                            <CFFormSection style={{ border: "none" }}>
                                                                {
                                                                    errors && errors.length > 0 ? (
                                                                        <div className="alert alert-danger mt-2" role="alert">
                                                                            <ul >
                                                                                {
                                                                                    errors.map(error => (<li>{error}</li>))
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    ) : (null
                                                                        // <div className="alert alert-light" role="alert">
                                                                        //     {t('mandat:signatureAlert', "Avant de poursuivre, assurez vous d'avoir bien verifier et respecter toutes les étapes")}
                                                                        // </div>
                                                                    )
                                                                }
                                                                <CFHeaderWrapperPart style={{ border: 'none' }}>
                                                                    <CFFormGroupPart style={{ border: 'none' }}>

                                                                        <div className="signatureTab__step">
                                                                            <div className="singatureTab__step_header">
                                                                                {
                                                                                    scrolledDocument ?
                                                                                        <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                        :
                                                                                        <div className="signatureTab__stepNumber"><span>1</span></div>
                                                                                }
                                                                                <div className="step__header__title">
                                                                                    {t('contract:pdfLectureM', "Scroller le document jusqu’en bas, pour justifier de votre lecture ")}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="signatureTab__step">
                                                                            <div className="singatureTab__step_header">
                                                                                {
                                                                                    docApproved ?
                                                                                        <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                        :
                                                                                        <div className="signatureTab__stepNumber"><span>2</span></div>
                                                                                }
                                                                                <div className="signatureTab__stepCheckbox__container">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="step__checkbox__input"
                                                                                        id="docConfirmCheckbox"
                                                                                        checked={docApproved}
                                                                                        onChange={(e) => setDocApproved(e.target.checked)}
                                                                                    />
                                                                                    <label
                                                                                        className="step__checkbox__label"
                                                                                        for="docConfirmCheckbox">
                                                                                        {t('contract:approvePdfContent', "J’ai pris connaissance du document et j’approuve le contenu")}
                                                                                    </label>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="signatureTab__step">
                                                                            <div className="singatureTab__step_header">
                                                                                {
                                                                                    matchedSmsCode ?
                                                                                        <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                        :
                                                                                        <div className="signatureTab__stepNumber"><span>3</span></div>
                                                                                }
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <div className="step__header__title">
                                                                                        {t('contract:enterSmsCode', "Entrez le code reçu par SMS")}
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: 'smaller' }}>
                                                                                        {/* <label>{t("contract:nocode")}</label> */}
                                                                                        <label
                                                                                            style={{ color: "#482D1C", cursor: "pointer", textDecoration: 'underline' }}
                                                                                            onClick={() => { getTeleNumber(userUid) }}>{t("contract:code", "Renvoyer code its")} {userTele ? ("******" + userTele.substring(9, 14)) : <PhoneDisabledIcon></PhoneDisabledIcon>}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="signatureTab__stepContent">
                                                                                <div className="stepContent__identityContent">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-control-sm "
                                                                                        placeholder={t('contract:receivedCode', "Code reçu")}
                                                                                        value={smsCode || ''}
                                                                                        disabled={matchedSmsCode}
                                                                                        onChange={(e) => smsCodeChangeHandler(e)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {/* <div style={{ paddingBottom: '15mm !important', width: '50%', margin: 'auto' }}>
                                                                        <FormLabel
                                                                            style={{
                                                                                paddingBottom: '2rem', width: '70%', margin: 'auto'
                                                                            }}
                                                                        >{t("contract:codeConfirmation", "Code Confirmation")}</FormLabel>
                                                                        <FormInput
                                                                            id="codeSms"
                                                                            type="text"
                                                                            style={{ borderBottom: '1px solid #dbdbdb', textAlign: 'center' }}
                                                                            placeHolder="Code confirmation"
                                                                            onChange={e => codeSmsChangeHandler(e)}
                                                                            maxLength={6}
                                                                            name="codeSms"
                                                                        />
                                                                    </div> */}

                                                                        {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '2rem' }}>
                                                                        <AddRecipientsBtn
                                                                            style={{ width: '70%', display: 'block', textAlign: 'center', fontSize: 'medium' }}
                                                                            onClick={() => editCodeConfirmation()}>{t("contract:check", "Confirmer")}</AddRecipientsBtn>
                                                                    </div> */}

                                                                    </CFFormGroupPart>
                                                                </CFHeaderWrapperPart>
                                                                {scrolledDocument && matchedSmsCode && docApproved ?
                                                                    <CFHeaderWrapperPart style={{ border: 'none' }}>

                                                                        <div className="singatureTab__step_header">
                                                                            {
                                                                                signatureDataB64 ?
                                                                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                                                                    :
                                                                                    <div className="signatureTab__stepNumber"><span>4</span></div>
                                                                            }
                                                                            <div className="step__header__title">
                                                                                {t('contract:handSign', "Dessiner ou déposer votre signature dans le carré ci-dessous")}
                                                                                <CFFormClearIcon style={{ marginLeft: "55px" }} onClick={e => clearCanvas(e)}><ClearIcon /></CFFormClearIcon>
                                                                            </div>

                                                                        </div>

                                                                        <WrapperCanvas style={{ paddingTop: '3px', paddingLeft: '3px', marginLeft: '79px' }}>
                                                                            <SignatureCanvas
                                                                                ref={sigCanvas}
                                                                                backgroundColor="white"
                                                                                canvasProps={{ width: 300, height: 133, className: 'sigCanvas' }}
                                                                                // onBegin={e => beginSignatureCanvas(e)}
                                                                                onEnd={e => endSignatureCanvas(e)} />
                                                                        </WrapperCanvas>
                                                                        {signatureChanged ?
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={e => setsaveSignature(!saveSignature)} />
                                                                                <label className="form-check-label" for="flexCheckDefault">
                                                                                    Souhaitez vous enregistrer votre signature
                                                                                </label>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </CFHeaderWrapperPart>
                                                                    : null
                                                                }
                                                            </CFFormSection>
                                                        </div>
                                                        : null
                                                    : null

                                            }
                                            {
                                                tabSideActive === "signature" ?
                                                    (
                                                        (
                                                            (
                                                                (userType == "client" && contract.status !== "REFUSED")
                                                                ||
                                                                (userType == "owner" && autorisationOwner)
                                                            )
                                                            && contract?.fileType != "FREE"
                                                        )

                                                        || (contract?.fileType == "FREE" && contract?.canISign)
                                                    ) ?
                                                        <div style={{ marginLeft: "16px"}}>
                                                            {
                                                                (contract?.fileType == "FREE" && contract?.scheduledDate!=null) &&
                                                                    <p style={{fontSize:"11px", marginLeft:"20px"}}>* Date de signature programmée : {formatDate(+contract?.scheduledDate)} </p>
                                                            }
                                                            <div style={{ display: "flex", marginBottom: '20px' }}>
                                                                <BtnOutlineAction
                                                                    color={signatureDataB64 ? theme.colors.success : theme.colors.disabledBtn}
                                                                    border={signatureDataB64 ? theme.colors.success : theme.colors.disabledBtn}
                                                                    onClick={() => { setConfirmationModalShow(true) }}
                                                                    disabled={!signatureDataB64}>
                                                                    <span>{t("contract:sign", "Signer")}</span>
                                                                </BtnOutlineAction>
                                                                
                                                                <BtnOutlineAction
                                                                    color={signatureDataB64 ? theme.colors.danger : theme.colors.disabledBtn}
                                                                    border={signatureDataB64 ? theme.colors.redLight : theme.colors.disabledBtn}
                                                                    onClick={() => { setConfirmationModalShow2(true) }}
                                                                    disabled={!signatureDataB64}>
                                                                    <span>{t("contract:refuse")}</span>
                                                                </BtnOutlineAction>
                                                            </div>
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                            <ConfirmationModal
                                                signContractLoading={signContractLoading}
                                                show={confirmationModalShow ? confirmationModalShow : confirmationModalShow2}
                                                modalClosed={() => confirmationModalShow ? setConfirmationModalShow(false) : setConfirmationModalShow2(false)}
                                                confirm={() => confirmationModalShow ? addSignaturePdf(userType != "owner" ? false : true) : editContractRefuse(uidContract)}
                                                cancel={() => confirmationModalShow ? setConfirmationModalShow(false) : setConfirmationModalShow2(false)}
                                                title={"Confirmation"}
                                                message={confirmationModalShow ? t("confirmationModal:signature") + contractData.title + " ?" : t("confirmationModal:refuse") + contractData.title + " ?"}
                                            />
                                        </div>
                                    </>
                                    : null

                            }

                            {userType == "owner" && !uidContract ?

                                <CFFormSection style={{ border: "none" }}>

                                    <CFFormGroup>
                                        <FormLabel htmlFor="title" >{t("contract:title", "Titre")} </FormLabel>
                                        <FormInput
                                            id="title"
                                            type="text"
                                            placeHolder="Titre"
                                            value={contractData?.title || contract?.title || ""}
                                            onChange={e => titleChangeHandler(e)}
                                            maxLength={255}
                                            name="title"
                                        />
                                    </CFFormGroup>
                                    {contract?.title ?

                                        <CFFormGroup style={{ display: "flex", justifyContent: "space-between" }}>
                                            <FormLabel >{t("contract:documentType", "Type de fichier")}</FormLabel>
                                            <FilterContainer style={{ width: "300px" }}>
                                                <SelectFilter
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    value={contract?.fileType || ''}
                                                    name={t("contract:FileType", "FileType")}
                                                >
                                                    <Option value=""></Option>
                                                    <Option value="MSEPA">{t("contract:MSEPA", "Mandats Sepa")}</Option>
                                                    <Option value="CF">{t("contract:CF", "Contrat de franchise")}</Option>
                                                    <Option value="DIP"> {t("contract:DIP", "Document d'information pré-contractuelle")}</Option>
                                                    <Option value="CSCF">{t("contract:CSCF", "Caution solidaire")}</Option>
                                                    {
                                                        +userDocSecret &&
                                                        <Option value="DIVERS"> {t("contract:DIVERS", "Divers")}</Option>

                                                    }
                                                    <Option value="AOCM">{t("contract:AOCM", "Avenant OC Mobile")}</Option>
                                                    <Option value="FREE">{t("contract:FREE", "Document libre")}</Option>
                                                </SelectFilter>
                                            </FilterContainer>
                                        </CFFormGroup>
                                        :
                                        null
                                    }
                                    {
                                        contract?.title && divers?.status &&
                                        <>
                                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                                <div className="form-check" style={{ marginRight: "20px" }}>
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios3" value="new" onChange={e => radioChangeHandler(e)} />
                                                        Nouvel utilisateur
                                                    </label>
                                                </div>
                                                <div className="form-check" style={{ marginRight: "20px" }}>
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="owner" onChange={e => radioChangeHandler(e)} />
                                                        Groupe
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios2" value="site" onChange={e => radioChangeHandler(e)} />
                                                        Site
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {contract?.title && contract?.fileType == "DIVERS" && entitiesType == "owner" ?
                                        <div style={{ paddingBottom: '1rem' }}>
                                            <div className="row" style={{ background: "#efe8df", color: "#807b7b", borderBottom: "1px solid azure" }}>
                                                <div className="col-sm-1"></div>
                                                <div className="col-sm-3">Prénom</div>
                                                <div className="col-sm-3">Nom</div>
                                                <div className="col-sm-2">Rôle</div>
                                                <div className="col-sm-3">Entité</div>
                                                {/* <div className="col-sm-2">Code</div> */}
                                            </div>
                                            {
                                                <UsersTable
                                                    divers={divers}
                                                    radioChangeHandler2={radioChangeHandler2}
                                                />
                                            }
                                        </div>
                                        : null
                                    }

                                    {

                                        (contract?.title && contract?.fileType == "FREE") &&
                                        <CFFormGroup style={{ marginBottom: "33px" }}>
                                            <FormLabel htmlFor="freetype" >{t("contract:freetype", "Libellé du type")} </FormLabel>
                                            <FormInput
                                                id="freeType"
                                                type="text"
                                                placeHolder="Type"
                                                value={contract?.freeType || ""}
                                                onChange={e => freeTypeChangeHandler(e)}
                                                maxLength={255}
                                                name="freeType"
                                            />
                                        </CFFormGroup>

                                    }

                                    {contract?.title && ((contract?.fileType !== "" && contract?.fileType !== "DIVERS") || (divers.destination !== '' && contract?.fileType == "DIVERS" && entitiesType == "client")) ?
                                        <div style={{ background: "#f8f9fa", border: "1px solid rgba(217, 225, 231, 0.8)", padding: "10px 3px 0px 5px", marginBottom: "2rem" }}>
                                            {(contract?.fileType !== "" && contract?.fileType !== "DIVERS") || (divers.destination !== '' && contract?.fileType == "DIVERS") ?
                                                // (divers?.destination == "site" || (contract?.fileType != "DIVERS" && contract?.fileType !== "")) ?
                                                <CFFormGroup style={{ margin: "0px" }}>
                                                    <FormLabel >{t("contract:recipient", "Destinataires :")} </FormLabel>
                                                    <AttachementsContainer style={{ justifyContent: 'flex-start' }}>
                                                        {
                                                            contract?.recipients?.length > 0 ?
                                                                contract.recipients.map(recipient => (
                                                                    <Recipient style={{ marginBottom: '0' }}
                                                                        key={recipient.uid}
                                                                        recipient={recipient}
                                                                        deleteRecipient={deleteRecipientHandler}
                                                                    />
                                                                ))
                                                                : null
                                                        }
                                                    </AttachementsContainer>

                                                    {contract.recipients.length == 0 ?

                                                        <AddRecipientsBtn onClick={() => setShowRepertoireModal(true)}>
                                                            <span>{t("contract:add", "Ajouter")}</span>
                                                            <PersonAddIcon style={{ fontSize: '1.2rem', marginLeft: '0.5rem' }} />
                                                        </AddRecipientsBtn>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        showRepertoireModal ? (
                                                            <RepertoireModal
                                                                show={showRepertoireModal}
                                                                handleClose={() => setShowRepertoireModal(false)}
                                                                addRecipientsHandler={addRecipientsHandler}
                                                                selectedEntities={contract.recipients}
                                                                selectEntity={selectEntity}
                                                                isActiveClient={isActiveClient}
                                                                currentEntitiesType={currentEntitiesType}
                                                                entitiesType={entitiesType}
                                                            />
                                                        ) : null
                                                    }
                                                </CFFormGroup>
                                                : null
                                            }

                                            {(contract.fileType == "DIVERS" && divers.destination != "owner" && contract?.recipients.length > 0 && divers?.users?.length > 0) ?
                                                <div style={{ padding: "6px 7px", margin: "12px 0px" }}>
                                                    {/* <div>Liste des personnes destinataires</div> */}
                                                    <div className="row" style={{ background: "#efe8df", color: "#807b7b", borderBottom: "1px solid azure" }}>
                                                        <div className="col-sm-3"></div>
                                                        <div className="col-sm-3">Prénom</div>
                                                        <div className="col-sm-3">Nom</div>
                                                        <div className="col-sm-2">Rôle</div>
                                                        {/* <div className="col-sm-3">Entité</div> */}
                                                    </div>
                                                    <div className="row" style={{ background: "#efe8df", color: "#807b7b", borderBottom: "1px solid azure" }}>
                                                        {
                                                            divers?.users?.length > 0 ?
                                                                divers.users.map(user => (
                                                                    <User
                                                                        style={{ marginBottom: '0' }}
                                                                        key={user.uid}
                                                                        user={user}
                                                                        radioChangeHandler2={radioChangeHandler2}
                                                                    // deleteRecipient={deleteRecipientHandler}
                                                                    />
                                                                ))
                                                                : null
                                                        }

                                                    </div>
                                                </div>
                                                : null

                                            }

                                        </div>
                                        : null
                                    }



                                    {contract?.title && contract?.fileType == "DIVERS" && entitiesType == "new" ?
                                        <>
                                            < CFFormGroup >
                                                <FormLabel htmlFor="login" >{t("contract:login", "E-mail")} </FormLabel>
                                                <FormInput
                                                    id="login"
                                                    type="text"
                                                    value={userDivers?.login || ""}
                                                    onChange={e => userChangeHandler(e)}
                                                    maxLength={255}
                                                    name="login"
                                                />
                                            </ CFFormGroup >
                                            {emailFormat ?
                                                <>
                                                    < CFFormGroup >
                                                        <FormLabel htmlFor="first_name" >{t("contract:first_name", "Prénom")} </FormLabel>
                                                        <FormInput
                                                            id="first_name"
                                                            type="text"
                                                            value={userDivers?.first_name || ""}
                                                            onChange={e => userChangeHandler(e)}
                                                            maxLength={255}
                                                            disabled={diversType !== ''}
                                                            name="first_name"
                                                        />
                                                    </ CFFormGroup >
                                                    < CFFormGroup >
                                                        <FormLabel htmlFor="last_name" >{t("contract:last_name", "Nom")} </FormLabel>
                                                        <FormInput
                                                            id="last_name"
                                                            type="text"
                                                            value={userDivers?.last_name || ""}
                                                            onChange={e => userChangeHandler(e)}
                                                            maxLength={255}
                                                            disabled={diversType !== ''}
                                                            name="last_name"
                                                        />
                                                    </ CFFormGroup >
                                                    < CFFormGroup >

                                                        <FormLabel style={{ minWidth: "200px" }} htmlFor="telephone_number" >{t("global:phoneNumberTmp")}</FormLabel>
                                                        <TelInput
                                                            localization={fr}
                                                            country={'fr'}
                                                            dropdownStyle={{ width: "auto" }}
                                                            preferredCountries={['fr', 'us']}
                                                            enableSearch={false}
                                                            inputProps={{ name: "telephone_number" }}
                                                            placeholder={"33 0 00 00 00 00"}
                                                            value={userDivers?.telephone_number?.substr(2) || ''}
                                                            onChange={phone => phoneInputChangeHandler("00" + phone)}
                                                            disabled={diversType == 'userSysteme'}
                                                            name="telephone_number"

                                                        />
                                                    </ CFFormGroup >
                                                </>
                                                : null}
                                        </>
                                        : null
                                    }




                                    {contract?.title && ((contract?.fileType !== "" && contract?.fileType !== "DIVERS") || (contract?.fileType == "DIVERS" && importAutorisation)) ?
                                        < CFFormGroup >
                                            <FormLabel >{t("contract:attachment", "Pièces jointes")}</FormLabel>
                                            <AttachementsContainer style={{ justifyContent: 'flex-start' }}>
                                                {
                                                    contract?.attachments.length > 0 ?
                                                        contract?.attachments.map(attachment => (
                                                            <Attachment
                                                                key={attachment.name}
                                                                attachment={attachment}
                                                                deleteAttachement={deleteAttachmentHandler}
                                                            />
                                                        ))
                                                        : null
                                                }
                                            </AttachementsContainer>
                                            {contract?.attachments.length == 0 ?
                                                <FileUploaderBtn
                                                    btnLabel={t("contract:import", "Importer")}
                                                    handleChange={fileChangeHandler}
                                                    name="file"
                                                    icon={<CloudUploadIcon style={{ fontSize: '1.2rem', marginLeft: '0.5rem' }} />}
                                                />
                                                : null
                                            }
                                        </CFFormGroup>
                                        : null
                                    }

                                    {
                                        (contract?.fileType == "FREE" && waitingForResponse)  ?
                                        <div style={{ display: 'flex',justifyContent: 'center' }}>
                                            <Spinner animation="border" variant="primary" /> 

                                        </div> :
                                        (contract?.title && (contract?.fileType == "FREE" && contract?.attachments.length > 0)) ?

                                                <>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p>Liste des Tags trouvés</p>

                                                        {/* <input
                                                            type="date"
                                                            value={contract?.scheduledSignatureDate}
                                                            name="scheduledSignatureDate"
                                                            onChange={e => signatureDateChangeHandler(e)}
                                                            style={{ border: "none", paddingBottom: "16px" }}
                                                        /> */}

                                                    </div>
                                                    <div style={{ margin: "0 0px 25px 7px" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" name="gerTag" type="checkbox" checked={contract?.gerTag} />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    SIGNATUREGERANT
                                                                </label>
                                                            </div>
                                                            {
                                                        contract?.gerTag &&
                                                        <input
                                                            type="date"
                                                            value={contract?.gerDate}
                                                            name="gerDate"
                                                            onChange={e => signatureDateChangeHandler(e)}
                                                            style={{ border: "none" }}
                                                        />
                                                    }
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" name="cogTag" type="checkbox" checked={contract?.cogTag} />
                                                                <label class="form-check-label" for="flexCheckChecked">
                                                                    SIGNATURECOGERANT
                                                                </label>
                                                            </div>
                                                            {
                                                        contract?.cogTag &&
                                                        <input
                                                            type="date"
                                                            name="cogDate"
                                                            value={contract?.cogDate}
                                                            onChange={e => signatureDateChangeHandler(e)}
                                                            style={{ border: "none" }}
                                                        />
                                                    }

                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" name="sasTag" type="checkbox" checked={contract?.sasTag} />
                                                                <label class="form-check-label" for="flexCheckChecked">
                                                                    SIGNATURESAS
                                                                </label>
                                                            </div>
                                                            {
                                                        contract?.sasTag &&
                                                        <input
                                                            type="date"
                                                            name="sasDate"
                                                            value={contract?.sasDate}
                                                            onChange={e => signatureDateChangeHandler(e)}
                                                            style={{ border: "none" }}
                                                        />
                                                    }
                                                        </div>

                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" name="llTag" type="checkbox" checked={contract?.llTag} />
                                                                <label class="form-check-label" for="flexCheckChecked">
                                                                    SIGNATURELL
                                                                </label>
                                                            </div>
                                                            {
                                                        contract?.llTag &&
                                                        <input
                                                            type="date"
                                                            name="llDate"
                                                            value={contract?.llDate}
                                                            onChange={e => signatureDateChangeHandler(e)}
                                                            style={{ border: "none" }}
                                                        />
                                                    }
                                                        </div>
                                                    </div>
                                                </>
                                            : null
                                    }

                                    {contract?.attachments.length !== 0 && ((contract?.fileType == "FREE" && selectedTagsForFreeDocType()) || contract?.fileType !== "FREE") ?
                                        <div style={{ justifyContent: 'space-between' }}>
                                            <FormLabel >{t("contract:sizeTag", "Taille de signature")}:</FormLabel>
                                            <div style={{ display: 'flex', justifyContent: "space-around", backgroundColor: "#F5FBFF", padding: "10px", border: "1px solid rgba(217, 225, 231, 0.8)" }}>
                                                <div style={{ alignItems: 'baseline', marginRight: '10px', minWidth: '100px' }}>
                                                    <span>Largeur: </span>
                                                    <input
                                                        min='30'
                                                        max='80'
                                                        type="number"
                                                        className="form-control form-control-sm "
                                                        placeholder='30 ~ 80'
                                                        name="width"
                                                        value={contract.width || ''}
                                                        onChange={(e) => inputSizeChangeHandler(e)}
                                                        onBlur={e => checkInputValidity(e)} />
                                                </div>
                                                <div style={{ alignItems: 'baseline', minWidth: '100px' }}>
                                                    <span>Hauteur: </span>
                                                    <input
                                                        min='6'
                                                        max='16'
                                                        type="number"
                                                        className="form-control form-control-sm "
                                                        placeholder='6 ~ 16'
                                                        name="height"
                                                        value={contract.height || ''}
                                                        onChange={(e) => inputSizeChangeHandler(e)}
                                                        onBlur={e => checkInputValidity(e)} />
                                                </div>
                                            </div>

                                        </div>
                                        : null
                                    }
                                    {
                                        docControlErrors.length > 0 &&
                                        <ul style={{ marginTop: "20px" }}>
                                            {
                                                docControlErrors.map(err => {
                                                    return <li style={{ color: "red" }}>{err}</li>
                                                })
                                            }
                                        </ul>
                                    }


                                    {
                                        showVisuModal ? (
                                            <VisuModal
                                                show={showVisuModal}
                                                handleClose={() => setShowVisuModal(false)}
                                                pdfFile={pdfFile} />

                                        ) : null
                                    }


                                </CFFormSection>
                                : null
                            }



                        </CFFormWrapper>
                    </CFSection>
                </CFBody>
            </CFContainer >
        </>
    )
}


const mapStateToProps = (state) => ({
    loading: state.contracts.loading,
    contractsData: state.contracts.data,
    count: state.contracts.count,
    currentPage: state.contracts.currentPage,
    pageSize: state.contracts.pageSize,
    sortQuery: state.contracts.sortQuery,
    reverse: state.contracts.reverse,
    filtersQuery: state.contracts.filtersQuery,
    showFilters: state.contracts.showFilters,
    userRole: state.auth.role,
    userType: state.auth.userType,
    userUid: state.auth.userUid,
    userLevel: state.auth.userLevel
})
const mapDispatchToProps = dispatch => ({
    getContractsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.contractsGetPage(page, filters, sort, reverse, pageSize)),
    setContractsPage: (page) => dispatch(actions.contractsSetPage(page)),
    setContractsPageSize: (pageSize) => dispatch(actions.contractsSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.contractsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.contractsSetReverseSort(reverseS)),
    setContractsFilters: (filters) => dispatch(actions.contractsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.contractsUpdateShowFilters(show)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ContractForm)
