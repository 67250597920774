import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { RCPContainer, RCPIcon, RCPValue } from './Recipient.styled';

function Recipient({recipient, deleteRecipient}) {
    return (
        <RCPContainer>
            <RCPValue> {recipient.name} </RCPValue>
            <RCPIcon>
                <CloseIcon 
                    style={{fontSize: '0.9rem'}}
                    onClick={() => deleteRecipient(recipient.uid)} 
                />
            </RCPIcon>
        </RCPContainer>
    )
}

export default Recipient
