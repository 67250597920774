import React from 'react'
import GroupIcon from '@mui/icons-material/Group';
import { TD } from '../../../styles/Table.styled';
import { SupplierLink, SupplierUsersLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { getEntityStatusWithKey,showColumn } from '../../../shared/utility';

function TableBody(props) {
    const {suppliersData, formatDate, entityFilter,setEntitydetail,columns} = props;

    return (
        <>
            {
                suppliersData ? suppliersData.map(row => (
                    <tr key={row.id} >
                        <TD style={{display:  !showColumn(columns,"code") && 'none'}}>
                            {row.code}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"name") && 'none'}}>
                            <div className="tableBody__companyName">
                                <SupplierLink 
                                    to={`/entity/${row.uid}`}
                                    state={{entityType: 'supplier'}}
                                >
                                    {row.name}
                                </SupplierLink>
                            </div>
                        </TD>
                        <TD style={{display:  !showColumn(columns,"vat")&& 'none'}}> 
                           {row?.vat} 
                        </TD>
                        <TD style={{display:  !showColumn(columns,"status") && 'none'}}>
                            { getEntityStatusWithKey(row.status)}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"creationDate") && 'none'}}>
                            {formatDate(+row.creationDate)}
                        </TD>
                        <TD >
                            <SupplierUsersLink 
                                to={`/entity/${row.uid}`}
                                state={{tab: 'contacts', entityType: 'supplier'}}    
                            >
                                {row?.totalUsers}
                                <GroupIcon style={{fontSize: "1rem", marginLeft: "2px"}} />
                            </SupplierUsersLink>
                        </TD>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
