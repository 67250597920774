import React, { useState, useCallback, useEffect } from 'react'
import Axios from '../../axios-proas';
import { Notyf } from 'notyf';
import { useTranslation } from 'react-i18next';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { SGBody, SGCol, SGColTitle, SGContainer, SGFormGroup, SGSection, Input, CFFormGroup } from './FlowsSettings.styled';
import { BtnOutlineAction, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';
import { useTheme } from 'styled-components';
import Select from '../../components/UI/Select/Select';
import { Option, SelectFilter } from '../../styles/Table.styled';
import DocumentSignatories from '../../components/DocumentSignatories/DocumentSignatories'
import AddIcon from '@mui/icons-material/Add';
import { removeObject } from '../../shared/utility';
import EditIcon from '@mui/icons-material/Edit';
import FlowSettingsSignature from "./FlowSettingsSignature"

function FlowsSettings() {
    const [settings, setSettings] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [documentsType, setDocumentsTypes] = useState([])
    const [documentSignatories, setDocumentSignatories] = useState([])
    const [selectedDocType, setSelectedDocType] = useState(1)
    const [selectedSignatories, setSelectedSignatories] = useState()
    const [selectedSignatories1, setSelectedSignatories1] = useState()
    const [selectedSignatories2, setSelectedSignatories2] = useState()
    const [selectedSignatories3, setSelectedSignatories3] = useState()
    const [selectedSignatories5, setSelectedSignatories5] = useState()
    const [showMSEPADimension, setShowMSEPADimension] = useState(false)
    const [showDIPDimension, setShowDIPDimension] = useState(false)
    const [showCFDimension, setShowCFDimension] = useState(false)
    const [showAOCMDimension, setShowAOCMDimension] = useState(false)
    const [showCSCFDimension, setShowCSCFDimension] = useState(false)

    
    const [msepaDim, setMsepaDim] = useState() 
    const [dipDim, setDipDim] = useState() 
    const [cfDim, setCfDim] = useState() 
    const [aocmDim, setAocmDim] = useState() 
    const [cscfDim, setCscfDim] = useState() 
    const [diversDim, setDiversDim] = useState() 
    const notyf = new Notyf();
    const { t } = useTranslation();
    const theme = useTheme()
    


    useEffect(() => {
        console.log("second")
        Axios.get('/settings').then(response => {
            let docTypes = response?.data.documentsType
            setDocumentsTypes(response?.data.documentsType)
            // console.log("je sui la")
            // console.log(response?.data.documentsType)
            setDocumentSignatories(response?.data?.documentSignatories)
            let tmpDocSize = docTypes.filter(item => item?.code == "MSEPA")
            console.log(tmpDocSize)
            setMsepaDim("Hauteur: " + tmpDocSize[0]?.height + "px, Largeur: " + tmpDocSize[0]?.width + "px")
            tmpDocSize = docTypes.filter(item => item?.code == "CSCF")
            setCscfDim("Hauteur: " + tmpDocSize[0]?.height + "px, Largeur: " + tmpDocSize[0]?.width + "px")
            tmpDocSize = docTypes.filter(item => item?.code == "CF")
            setCfDim("Hauteur: " + tmpDocSize[0]?.height + "px, Largeur: " + tmpDocSize[0]?.width + "px")
            tmpDocSize = docTypes.filter(item => item?.code == "DIP")
            setDipDim("Hauteur: " + tmpDocSize[0]?.height + "px, Largeur: " + tmpDocSize[0]?.width + "px")
            tmpDocSize = docTypes.filter(item => item?.code == "AOCM")
            setAocmDim("Hauteur: " + tmpDocSize[0]?.height + "px, Largeur: " + tmpDocSize[0]?.width + "px")
            tmpDocSize = docTypes.filter(item => item?.code == "DIVERS")
            setDiversDim("Hauteur: " + tmpDocSize[0]?.height + "px, Largeur: " + tmpDocSize[0]?.width + "px")

            // setSelectedSignatories(response?.data?.documentSignatories.filter(signatory => signatory.documentType == selectedDocType))
            setSelectedSignatories1(_.sortBy(response?.data?.documentSignatories.filter(signatory => signatory.documentType == 1)), "order")
            setSelectedSignatories2(_.sortBy(response?.data?.documentSignatories.filter(signatory => signatory.documentType == 2)), "order")
            setSelectedSignatories3(_.sortBy(response?.data?.documentSignatories.filter(signatory => signatory.documentType == 3)), "order")
            setSelectedSignatories5(_.sortBy(response?.data?.documentSignatories.filter(signatory => signatory.documentType == 5)), "order")
            let tsettings = response?.data?.tsettings;
            let tsettingsMail = response?.data?.tsettingsMail;
            let newTSettings = {}
            let newTSettingsM = {}
            tsettings.map(row => {
                let key = row.key;
                let value = row.value
                newTSettings = {
                    ...newTSettings,
                    [key]: value
                }
            })
            tsettingsMail.map(row => {
                let key = row.key;
                let fr_value = row.fr_value;
                let en_value = row.en_value;
                let de_value = row.de_value;
                let it_value = row.it_value;
                let es_value = row.es_value;
                newTSettingsM = {
                    ...newTSettingsM,
                    [key]: {
                        de_value,
                        en_value,
                        fr_value,
                        it_value,
                        es_value
                    }
                }
            })
            setSettings({
                tsettings: newTSettings,
                tsettingsMail: newTSettingsM,
                documentsType: [],
                documentSignatories: response?.data?.documentSignatories
            })
        })


    }, [updated])


    // useEffect(() => {
    //     console.log("first")
    //     setDocumentSignatories(documentSignatories);
    //     // setSelectedSignatories(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == selectedDocType), "order"))
    //     setSelectedSignatories1(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == 1), "order"))
    //     setSelectedSignatories2(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == 2), "order"))
    //     setSelectedSignatories3(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == 3), "order"))
    //     setSelectedSignatories5(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == 5), "order"))
    // }, [documentSignatories])





    const updateSignatoriesHandler = (signatoriesToUpdate) => {
        console.log("updateSignatoriesHandler")
        console.log(documentSignatories)
        let tempDocumentSigs = documentSignatories
        signatoriesToUpdate.map(signatoryToUp => {
            tempDocumentSigs.map(signatory => {
                if (signatoryToUp.signatory == signatory.signatory && signatoryToUp.documentType == signatory.documentType)
                    signatory.order = signatoryToUp.order
            })
        })
        // console.log("order")        
        // console.log( _.sortBy( tempDocumentSigs, 'order' ))
        setDocumentSignatories(tempDocumentSigs)
        // setSelectedSignatories(_.sortBy(signatoriesToUpdate, 'order'))
        setSelectedSignatories1(_.sortBy(tempDocumentSigs.filter(signatory => signatory.documentType == 1), "order"))
        setSelectedSignatories2(_.sortBy(tempDocumentSigs.filter(signatory => signatory.documentType == 2), "order"))
        setSelectedSignatories3(_.sortBy(tempDocumentSigs.filter(signatory => signatory.documentType == 3), "order"))
        setSelectedSignatories5(_.sortBy(tempDocumentSigs.filter(signatory => signatory.documentType == 5), "order"))
        console.log(tempDocumentSigs)
    }

    const handleDocumentTypeChange = (e) => {
        let documentType = e?.target?.value
        setSelectedDocType(documentType)
        setSelectedSignatories(_.sortBy((documentSignatories.filter(signatory => signatory.documentType == documentType)), "order"))
    }

    const deleteSignatoryHandler = (signatoryToDelete) => {
        // console.log(signatoryToDelete)
        // let temp = {...documentSignatories}
        //removeObject(temp ,signatoryToDelete)
        let sigIndex = -1
        let signatoriesTemp = [...documentSignatories];
        documentSignatories.map((sig, index) => { if (sig.uid == signatoryToDelete.uid) sigIndex = index })
        signatoriesTemp.splice(sigIndex, 1)
        let signatoriesToUpdate = signatoriesTemp.map((signatory, index) => {
            let signatoryTemp = { ...signatory, order: index }
            return signatoryTemp
        })
        // if(sigIndex != -1){
        //     let tsettingsNew = {
        //         ...settings,
        //             documentSignatoriesToDelete : signatoryToDelete
        //     }
        //     setSettings( tsettingsNew )
        // }
        setDocumentSignatories(signatoriesToUpdate)
        // setDocumentSignatories(signatoriesTemp)
        // setSelectedSignatories(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == selectedDocType), "order"))
        setSelectedSignatories1(_.sortBy(signatoriesToUpdate.filter(signatory => signatory.documentType == 1), "order"))
        setSelectedSignatories2(_.sortBy(signatoriesToUpdate.filter(signatory => signatory.documentType == 2), "order"))
        setSelectedSignatories3(_.sortBy(signatoriesToUpdate.filter(signatory => signatory.documentType == 3), "order"))
        setSelectedSignatories5(_.sortBy(signatoriesToUpdate.filter(signatory => signatory.documentType == 5), "order"))
        setSettings({
            ...settings,
            documentSignatories: signatoriesToUpdate
        })
        //updateSignatoriesHandler(signatoriesToUpdate)
        // if(!signatoryToDelete.hasOwnProperty("agencyUid"))
        //     deleteMandat(signatoryToDelete.uid)  
    }




    let jsxDocumentation = documentsType.map(docType => {
        return (
            <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <SGColTitle>{t("contract:" + docType.type)}</SGColTitle>

                    {/* <div className="btn-group" role="group">
                        <AddIcon
                            style={{ cursor: "pointer" }}
                            className=" dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        />
                        <div className="dropdown-menu">
                            <span className="dropdown-item" name="Tiers Franchisé" value={1} onClick={e => addSignatoryHandler(e, docType.id)}>{t("documentDetails:franchise", "Tiers Franchisé")}</span>
                            <span className="dropdown-item" name="Co-gérant tiers franchisé" value={2} onClick={e => addSignatoryHandler(e, docType.id)}>{t("documentDetails:comanager", "Co-gérant tiers franchisé")} </span>
                            <span className="dropdown-item" name="OCF" value={3} onClick={e => addSignatoryHandler(e, docType.id)}>OCF</span>
                            <span className="dropdown-item" name="LLRV" value={4} onClick={e => addSignatoryHandler(e, docType.id)}>LLRV</span>
                        </div>
                    </div> */}
                </div>
                <DocumentSignatories
                    selectedSignatories={(docType.id == 1 && selectedSignatories1)
                        || (docType.id == 2 && selectedSignatories2)
                        || (docType.id == 3 && selectedSignatories3)
                        || (docType.id == 5 && selectedSignatories5)}
                    signatories={documentSignatories}

                    updateSignatories={updateSignatoriesHandler}
                    deleteSignatory={deleteSignatoryHandler}
                />
                
            </SGCol>
        )
    })
 

    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let tsettingsNew = settings?.tsettings ? {
            ...settings.tsettings,
            [name]: value
        } : { [name]: value }
        setSettings({
            ...settings,
            tsettings: tsettingsNew
        })
    }

    const editSettings = useCallback((e, settings, newLogo) => {
        e.preventDefault()
        Axios.put('/settings//settings', settings).then(response => {
            setUpdated(true)
            notyf.success(t("settings:savedSettings"));
        })
    }, [settings])

    const signatoryAlreadyIn = (signatory, selectedDocType) => {
        let exist = false
        documentSignatories.map(sig => {
            if (sig.documentType == selectedDocType && sig.signatory == signatory) exist = true
        })
        return exist
    }

    const getMaxOrder = (tab) => {
        var max = 0
        //sss = Math.max(tab.map(sig => sig.order)) 
        tab.map(element => {
            // console.log(element.order)
            element.order > max ? max = parseInt(element.order) : null
        });
        console.log("max :" + max)
        return max
    }

    const addSignatoryHandler = (e, selectedDocType) => {
        let trouve = signatoryAlreadyIn(e?.target?.getAttribute('name'), selectedDocType)
        if (!trouve) {
            let signatoriesTemp = Array.from(documentSignatories)
            console.log()
            let temp = documentSignatories.filter(signatory => signatory.documentType == selectedDocType)
            console.log(temp)
            let orderNumber = temp.length > 0 ? (getMaxOrder(temp) + 1) : 0
            console.log("lastOrderNumber: " + orderNumber)


            let signatory = {
                creationDate: null,
                documentType: selectedDocType,
                id: random_integer(3),
                order: orderNumber,
                signatory: e?.target?.getAttribute('name'),
                uid: random_hexadecimal(20)
            }
            signatoriesTemp.push(signatory)
            // console.log("addSignatoryHandler")
            // console.log(signatoriesTemp)
            setDocumentSignatories(signatoriesTemp)
            // setSelectedSignatories(_.sortBy(documentSignatories.filter(signatory => signatory.documentType == selectedDocType), "order"))
            setSelectedSignatories1(_.sortBy(signatoriesTemp.filter(signatory => signatory.documentType == 1), "order"))
            setSelectedSignatories2(_.sortBy(signatoriesTemp.filter(signatory => signatory.documentType == 2), "order"))
            setSelectedSignatories3(_.sortBy(signatoriesTemp.filter(signatory => signatory.documentType == 3), "order"))
            setSelectedSignatories5(_.sortBy(signatoriesTemp.filter(signatory => signatory.documentType == 5), "order"))
            setSettings({
                ...settings,
                documentSignatories: signatoriesTemp
            })

        } else
            notyf.error("signatory already exist");

    }

    function random_hexadecimal(length) {
        var result = '';
        var characters = 'abcdef0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    function random_integer(length) {
        var result = '';
        var characters = '123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return parseInt(result);
    }


    // function getSignatureSizeByDocType (code){
    //     let tmpDocSize = documentsType.filter(item => item?.code == code)
    //     console.log(tmpDocSize)
    //     return "Hauteur: " + tmpDocSize?.height + "px, Largeur: " + tmpDocSize?.width + "px"     
    // }

    function editSignatureDimension (newDimension, code){
        Axios.put('/settings/'+code+'/newSignatureDimension', newDimension).then(response => {
            if(response?.data?.success){
                setUpdated(!updated)
                notyf.success(t("settings:savedSettings"));
            }else notyf.error("error");
        })
    }


    return (
        <>
            <SGContainer>
                <TitleHelmet title={"OPTICAL CENTER | Flux"} />
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("navbar:flowsSettings", "")}</HeaderLocation>
                    </HeaderInfos>
                    {/* <HeaderActions>
                        <BtnOutlineAction
                            color={theme.colors.success}
                            border={theme.colors.success}
                            onClick={(e) => editSettings(e, settings)}
                        >
                            <span>{t("global:save")}</span>
                        </BtnOutlineAction>
                    </HeaderActions> */}
                </Header>

                <SGBody>
                    <SGSection>

                        {/* <SGCol style={{ minWidth: "398px" }}>
                            <SGColTitle>{t("documentDetails:docsType", "")}</SGColTitle>
                            <div style={{ display: "flex" }}>
                                <SelectFilter
                                    onChange={(e) => handleDocumentTypeChange(e)}
                                    value={selectedDocType}
                                >
                                    {
                                        documentsType.map(docType => {
                                            return <Option value={docType.id}>{docType.type}</Option>
                                        })
                                    }
                                </SelectFilter>
                                <div className="btn-group" role="group">
                                    <AddIcon
                                        style={{ cursor: "pointer" }}
                                        className=" dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    />
                                    <div className="dropdown-menu">
                                        <span className="dropdown-item" name="Tiers Franchisé" value={1} onClick={e => addSignatoryHandler(e)}>{t("documentDetails:franchise", "Tiers Franchisé")}</span>
                                        <span className="dropdown-item" name="Co-gérant tiers franchisé" value={2} onClick={e => addSignatoryHandler(e)}>{t("documentDetails:comanager", "Co-gérant tiers franchisé")} </span>
                                        <span className="dropdown-item" name="OCF" value={3} onClick={e => addSignatoryHandler(e)}>OCF</span>
                                        <span className="dropdown-item" name="LLRV" value={4} onClick={e => addSignatoryHandler(e)}>LLRV</span>
                                    </div>
                                </div>
                            </div>
                            <DocumentSignatories
                                selectedSignatories={selectedSignatories}
                                signatories={documentSignatories}
                                updateSignatories={updateSignatoriesHandler}
                                deleteSignatory={deleteSignatoryHandler}
                            />
                        </SGCol> */}


                        {/* {
                            jsxDocumentation
                        } */}
                        <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <SGColTitle>{t("contract:MSEPA")}</SGColTitle>
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:franchise")}
                            </div>
                            {/* <div style={{display:"flex", width:"40%", border:"2px solid #efe8df", borderRadius:"5px", padding:"10px"}}> */}

                                <FlowSettingsSignature  
                                    code={"MSEPA"}
                                    documentsType={documentsType}
                                    editSignatureDimension={editSignatureDimension}
                                    stringSignatureDiension={"Hauteur: " + documentsType?.filter(item=> item?.code == "MSEPA")[0]?.height + "px, Largeur: " + documentsType?.filter(item=> item?.code == "MSEPA")[0]?.width + "px"}
                                    />
                            {/* </div> */}

                            
                        </SGCol>
                        <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <SGColTitle>{t("contract:CF")}</SGColTitle>
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:franchise")}
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:comanager")} (facultatif)
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:OCF")}
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:LLRV")}
                            </div>
                            <FlowSettingsSignature    
                                code={"CF"}
                                documentsType={documentsType}
                                editSignatureDimension={editSignatureDimension}
                                stringSignatureDiension={"Hauteur: " + documentsType?.filter(item=> item?.code == "CF")[0]?.height + "px, Largeur: " + documentsType?.filter(item=> item?.code == "CF")[0]?.width + "px"}
                                />
                        </SGCol>
                        <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <SGColTitle>{t("contract:DIP")}</SGColTitle>
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:franchise")}
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:LLRV")}
                            </div>
                            <FlowSettingsSignature    
                                code={"DIP"}
                                documentsType={documentsType}
                                editSignatureDimension={editSignatureDimension}
                                stringSignatureDiension={"Hauteur: " + documentsType?.filter(item=> item?.code == "DIP")[0]?.height + "px, Largeur: " + documentsType?.filter(item=> item?.code == "DIP")[0]?.width + "px"}
                                />
                        </SGCol>
                        <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <SGColTitle>{t("contract:CSCF")}</SGColTitle>
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:franchise")}
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:comanager")} (facultatif)
                            </div>
                            <FlowSettingsSignature    
                                code={"CSCF"}
                                documentsType={documentsType}
                                editSignatureDimension={editSignatureDimension}
                                stringSignatureDiension={"Hauteur: " + documentsType?.filter(item=> item?.code == "CSCF")[0]?.height + "px, Largeur: " + documentsType?.filter(item=> item?.code == "CSCF")[0]?.width + "px"}
                                />
                        </SGCol>
                        <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <SGColTitle>{t("contract:AOCM")}</SGColTitle>
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:franchise")}
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:OCF")}
                            </div>
                            <FlowSettingsSignature    
                                code={"AOCM"}
                                documentsType={documentsType}
                                editSignatureDimension={editSignatureDimension}
                                stringSignatureDiension={"Hauteur: " + documentsType?.filter(item=> item?.code == "AOCM")[0]?.height + "px, Largeur: " + documentsType?.filter(item=> item?.code == "AOCM")[0]?.width + "px"}
                                />
                        </SGCol>
                        <SGCol style={{ minWidth: "398px", marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <SGColTitle>{t("contract:DIVERS")}</SGColTitle>
                            </div>
                            <div className="mandatClause__content">
                                {t("documentDetails:arecipient")}
                            </div>
                            <FlowSettingsSignature    
                                code={"DIVERS"}
                                documentsType={documentsType}
                                editSignatureDimension={editSignatureDimension}
                                stringSignatureDiension={"Hauteur: " + documentsType?.filter(item=> item?.code == "DIVERS")[0]?.height + "px, Largeur: " + documentsType?.filter(item=> item?.code == "DIVERS")[0]?.width + "px"}
                                />
                        </SGCol>

                    </SGSection>
                </SGBody>
            </SGContainer>
        </>
    )
}

export default FlowsSettings
