import { Link } from 'react-router-dom';
import styled from 'styled-components';


// export const SpinnerContainer = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 1rem 0;
// `;
// export const EIFormLabel = styled.label`
//     margin: 0;
//     padding: 0;
//     color: ${({theme}) => theme.colors.secondary};
//     font-size:0.9rem;
// `;
// export const EIFormGroup = styled.div`
//     width: 100%;
//     display: flex;
//     padding-bottom: 0.25rem;
//     margin-bottom: 1rem;
//     border-bottom: 2px solid #F5FBFF;
// `
// export const EIFormGroupChecked = styled.div`
//     width: 100%;
//     display: flex;

// `
// export const Input = styled.input`
//     border: none;
//     outline: none;
//     width: 98%;
//     text-align:end;
//     color: ${({color}) => color || "#482D1C"};
    
//     font-size:1.0rem;
//     &:hover {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }

//     &:focus {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }
// `;
// export const InputCheckbox = styled.input`
//     border: none;
//     outline: none;
//     margin-top: 3px;
//     font-size:1.3rem;
//     &:hover {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }

//     &:focus {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }
// `;




// export const ISContainer = styled.div`
//     width: 100%;
//     border: 1px solid ${({theme}) => theme.colors.secondary};
//     border-radius: 18px;
//     padding: 2rem 1.5rem;
//     height: 400px;
//     display: flex;
//     flex-direction: column;
// `;

export const DocVisu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 800px;
    max-height: 850px;
`;
export const NoContent = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    justify-content: center;
    height: 700px;
    font-size: 1rem;
    border-radius: 18px;
    color: ${({theme}) => theme.colors.secondary};
    
`;
export const NDContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
   
`
export const EIFormGroupChecked = styled.div`
    width: 100%;
    display: flex;

`
export const InputCheckbox = styled.input`
    border: none;
    outline: none;
    margin-top: 3px;
    font-size:1.3rem;
    &:hover {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }

    &:focus {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }
`;
export const Input = styled.input`
    border: none;
    outline: none;
    width: 98%;
    text-align:end;
    color: ${({color}) => color || "#482D1C"};
    
    font-size:1.0rem;
    &:hover {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }

    &:focus {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }
`;
export const EIFormLabel = styled.label`
margin: 0;
padding: 0;
color: ${({theme}) => theme.colors.secondary};
font-size:0.9rem;
`;
export const EIFormGroup = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #F5FBFF;
`
export const ISContainer = styled.div`
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 2rem 1.5rem;
    
    display: flex;
    flex-direction: column;
`;
export const EntitiesContainer = styled.div`
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 8px;
    margin-bottom:4px;
    display: flex;
    border: 1px solid #482D1C;
    max-height: 150px;
    overflow: auto;

`;
export const ToggleDiv = styled.div`
display: flex;
width: 100%;
justify-content: end;
`;

export const NDCol6  = styled.div`
    width: 48%;
    margin-top: 1rem;
   
`

export const NDrow  = styled.div`
 display: flex;
    width: 100%;
    justify-content: space-between;
   
`