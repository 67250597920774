import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { RCPContainer, RCPIcon, RCPValue } from './User.styled';
import { useTranslation } from 'react-i18next';


function User({ user, deleteUser, radioChangeHandler2 }) {
    const { t } = useTranslation()

    return (
        <>
            {/* // <RCPContainer>
            //     <RCPValue> {user.first_name} {user.last_name} {user.level} </RCPValue>

            //     <RCPIcon>
            //         {/* <CloseIcon 
            //             style={{fontSize: '0.9rem'}}
            //             onClick={() => deleteRecipient(user.uid)} 
            //         /> 
            //     </RCPIcon>
            // </RCPContainer> */}
            <div className="col-sm-3">
                <input type="radio" className="form-check-input" name="userSelection" id={user?.id} value={user?.id} onChange={e => radioChangeHandler2(e)} />
            </div>
            <div className="col-sm-3">{user.first_name}</div>
            <div className="col-sm-3">{user.last_name}</div>
            <div className="col-sm-3">{t("userTable:"+user.level)}</div>
        </>
    )
}

export default User
