import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import {formatDate, getStatusWithKey, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { TEContainer, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';

function StatusEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = (value) => {
        switch(value) {
            case 'FILED':
            case 'NEW':
            case 'TO_BE_PAID':
            case 'PAID':
            case 'IMPORTED':
            case 'INTEGRATED':
                return theme.colors.success;
            case 'PENDING':
                return theme.colors.warning;
            case 'ERROR':
            case 'LITIGATION':
                return theme.colors.danger;
            default:
                return theme.colors.success;
        }
    }

    return (
        <>
            <TEContainer >
                <TEHeader disabled={true}>
                    <TEIcon borderBold={true} bdColor={getStatusColor(eventData?.snapshot)} color={getStatusColor(eventData?.snapshot)} >
                        <CheckIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor(eventData?.snapshot)}>
                            Statut {getStatusWithKey(eventData?.snapshot)}
                        </TETitle>
                        <TETime>
                            le {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
            </TEContainer>
        </>
    )
}

export default StatusEvent