import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2'

export const UsersContainer = styled.div`
    margin: 5px 0px 0px 15px;
`;

export const UFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
`

export const UFBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`

export const TelInput = styled(PhoneInput)`
    width: 180px;

    input {
        width: 100% !important;
    }
`