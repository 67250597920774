import React from 'react'
import './VisuModal.css'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DocVisu } from '../../../containers/NewDocumentation/NewDocumentation.styled';
import { Viewer, Worker } from '@react-pdf-viewer/core';



function VisuModal({ handleClose, show,  pdfFile }) {
    const { t } = useTranslation();
    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>{t("VisuModal:Visualisation", "Prévisualisation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="repertoireModal__body" >
                    <DocVisu>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                        <Viewer fileUrl={pdfFile}/>
                    </DocVisu>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default VisuModal
