import React, { useCallback, useEffect, useState } from 'react'
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import TableActions from '../../components/UsersManagTable/TableActions/TableActions';
import TableHead from '../../components/UsersManagTable/TableHead/TableHead';
import TableBody from '../../components/UsersManagTable/TableBody/TableBody';
import TableFilters from '../../components/UsersManagTable/TableFilters/TableFilters';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { checkValidity, getNotyfObject } from '../../shared/utility';
import { useLocation, useParams } from 'react-router-dom';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle, FormInput, FormLabel  } from '../../styles/Common';
import { UMBody, UMContainer, CFFormGroup } from './UsersManagement.styled';


function UsersManagement(props) {
    let {
        userType,
        token,
        loading,
        usersData,
        count,
        currentPage,
        pageSize,
        setUsersPageSize,
        setUsersPage,
        getUsersPage,
        setUsersFilters,
        filtersQuery,
        filtering,
        sortQuery,
        reverse,
        setSortQuery,
        setReverse,
        updateShowFilters,
        showFilters,
        selectedUsers,
        selectUserRow,
        unselectUserRow,
        selectedAllRows,
        selectAllUsersRows,
        emptySelectedUsers,
        userRole,
        origin,
        update,
        setUpdate,
        usersUpdates,
        userManagerUpdates
    } = props;
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const [showAllContacts, setShowAllContacts] = useState(false)
    let location = useLocation();
    let { uid: entityUid } = useParams();

    // const entityUid = location.state?.entityUid;
    // IfAdminOfThisEntity

    const columns = [
        { title: t("global:firstName", 'Prénom'), field: 'first_name' },
        { title: t("global:lastName", 'Nom'), field: 'last_name' },
        { title: t("global:email", 'Email'), field: 'login' },
        { title: t("global:phoneNumber", 'Télephone'), field: 'telephone_number' },
        { title: t("global:active", 'Active'), field: 'active', width: "100px" },
        { title: t("global:role", 'Rôle'), field: 'level' },
        { title: t("global:level", 'Level'), field: 'role' }
        // {title: t("global:supplier", 'Fournisseurs'), field: 'entities'}
        //{title: 'Litige', field: 'litigation'} 
    ]

    useEffect(() => {
        // updateShowFilters(false)
        let filtersTemp
        if (entityUid)
            filtersTemp = {
                entityUid
            }
        else
            filtersTemp = {
                entityFilter: userType,
            }
        setUsersFilters(filtersTemp)
        setUsersPage(1)
        getUsersPage(1, filtersTemp, sortQuery, reverse, pageSize);
    }, [getUsersPage, entityUid, pageSize])

    const isEmptyFilters = () => {
        const filtersList = columns.map(column => column.field);
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            handleReset()
        }
    }, [showFilters]);

    const handleShowFilters = () => {
        updateShowFilters(!showFilters)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setUsersPage(newPage);
        getUsersPage(newPage, filtersQuery, sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort, newReverse) => {
        getUsersPage(currentPage, filtersQuery, sort, newReverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getUsersPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);


    const handleReset = useCallback(() => {
        let filtersTemp
        if (entityUid)
            filtersTemp = {
                entityUid
            }
        else
            filtersTemp = {
                entityFilter: userType,
            }
        setSortQuery("first_name")
        setReverse({ first_name: false })
        setUsersFilters(filtersTemp);
        setUsersPage(1);
        emptySelectedUsers()
        getUsersPage(1, filtersTemp, "first_name", { first_name: false }, pageSize);
    }, [])

    const setUsersType = (type) => {
        let filtersTemp = {
            usersType: type
        }
        setSortQuery("first_name")
        setReverse({ first_name: false })
        setUsersFilters(filtersTemp);
        setUsersPage(1);
        emptySelectedUsers()
        getUsersPage(1, filtersTemp, "first_name", { first_name: false }, pageSize)
    }

    const enableUser = (userUid) => {
        Axios.put(`user/${userUid}/enableUser`, { entityUid: entityUid }).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("default:userAdded", "L'utilisateur a été activé"))
                getUsersPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
                setUpdate(!update)
            } else if (response.data.hasOwnProperty('errorCog')) {
                notyf.error(t("global:cogAlreadyExist", "Already cog exist and active"))
            } else if (response.data.hasOwnProperty('errorAdmin')) {
                notyf.error(t("global:manAlreadyExist", "Already gérant exist and active"))
            } else if (response.data.hasOwnProperty('errorAdminNotActif')) {
                notyf.error(t("global:errorAdminNotActif", "Gérant n'est pas actif"))
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const disableUser = (userUid) => {
        Axios.put(`user/${userUid}/disableUser`).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("default:userAdded", "L'utilisateur a été désactivé"))
                getUsersPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
                setUpdate(!update)
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const deleteUser = (userUid) => {
        Axios.delete(`user/${userUid}/deleteUser`).then(response => {
            // if (response.data.hasOwnProperty('success')) {
            //     notyf.success(t("default:userAdded", "L'utilisateur a été désactivé"));
            //     getUsersPage(currentPage, filtersQuery, sortQuery, reverse, pageSize);
            // }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("userList:noUsers", "Aucun utilisateur à afficher")} </span>
        </NoContent>
    )

    if (!loading && usersData?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody
                columns={columns}
                data={!showAllContacts ? usersData.filter((user) => Number(user?.active) === 1) : usersData}
                // data={usersData}
                formatDate={formatDate}
                selectedUsers={selectedUsers}
                selectUserRow={selectUserRow}
                unselectUserRow={unselectUserRow}
                enableUser={enableUser}
                disableUser={disableUser}
                deleteUser={deleteUser}
                userType={userType}
                entityUid={entityUid}
                userRole={userRole}
                origin={origin}
                blocUserManagerUpdates={userManagerUpdates}
                blocUsersUpdates={usersUpdates}
            />
        )
    }
    return (
        <UMContainer>
            <TitleHelmet title={"OPTICAL CENTER | " + t('menu:userManagement')} />
            <UMBody>
                
                <TablePagination
                    setShowAllContacts={setShowAllContacts}
                    showAllContacts={showAllContacts}
                    inactiveContacts={usersData?.filter(item => Number(item.active) === 0).length}
                    currentPage={currentPage}
                    pageChange={handleChangePage}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setUsersPageSize}
                />
                <Table>
                    <TableHead
                        columns={columns}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        setSortQuery={setSortQuery}
                        setReverse={setReverse}
                        getData={handleSort}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={selectAllUsersRows}
                        userRole={userRole}
                        origin={origin}
                    />

                    <TBody>
                        <TableFilters
                            userType={userType}
                            show={showFilters}
                            formatDate={formatDate}
                            filters={filtersQuery}
                            filtersChange={setUsersFilters}
                            applyFilters={handleApplyFiltering}
                        />
                        {tBody}
                    </TBody>
                </Table>
                {tSpinner}
                <TablePagination
                    currentPage={currentPage}
                    pageChange={handleChangePage}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setUsersPageSize}
                />
            </UMBody>
        </UMContainer>
    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    loading: state.users.loading,
    usersData: state.users.data,
    count: state.users.count,
    currentPage: state.users.currentPage,
    pageSize: state.users.pageSize,
    filtersQuery: state.users.filtersQuery,
    filtering: state.users.filtering,
    sortQuery: state.users.sortQuery,
    reverse: state.users.reverse,
    selectedUsers: state.users.selectedUsers,
    showFilters: state.users.showFilters,
    selectedAllRows: state.users.selectedAllRows,
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getUsersPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.usersGetPage(page, filters, sort, reverse, pageSize)),
    setUsersPage: (page) => dispatch(actions.usersSetPage(page)),
    setUsersPageSize: (pageSize) => dispatch(actions.usersSetPageSize(pageSize)),
    setUsersFilters: (filters) => dispatch(actions.usersSetFilterQuery(filters)),
    setSortQuery: (sortQ) => dispatch(actions.usersSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.usersSetReverseSort(reverseS)), 
    updateShowFilters: (show) => dispatch(actions.usersUpdateShowFilters(show)),
    getDocumentFile: (uid, type) => dispatch(actions.invoiceGetDocumentFile(uid, type)),
    selectAllUsersRows: (selectAll) => dispatch(actions.setSelectedAllRows(selectAll)),
    selectUserRow: (uid) => dispatch(actions.selectUser(uid)),
    unselectUserRow: (uid) => dispatch(actions.unselectUser(uid)),
    getCompressedDoc: (selection) => dispatch(actions.invoiceGetCompressedDocument(selection)),
    emptySelectedUsers: () => dispatch(actions.emptySelectedUsers())
})
export default connect(mapStateToProps, mapDispatchToProps)(UsersManagement)
