import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Navigate } from 'react-router-dom';
import Export from '../../components/UI/Export/Export';
import Axios from '../../axios-proas'
import fr from 'react-phone-input-2/lang/fr.json'
import { convertFileToBase64, getNotyfObject, isToday, isYesterday } from '../../shared/utility';
import SignatureCanvas, { getTrimmedCanvas, toDataURL, fromDataURL } from 'react-signature-canvas';
import { WrapperCanvas, PFContainer, PFHeaderWrapper, PFHeader, PFHeaderTitle, PFHeaderActions, PFBody, PFSection, PFFormWrapper, PFFormSection, PFFormGroup, AddRecipientsBtn, PFFormLabel, TelInput, UsersContainer } from './UserProfile.styled';
import { BtnAction, FormGroup, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common';
import { EIFormCol, EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../Entity/Entity.styled';
import UsersTable from './UsersTable/UsersTable';

function UserProfile(props) {
    const {
        userUid,
        userType
    } = props;
    let notyf = getNotyfObject();
    const sigCanvas = useRef({});
    const { t } = useTranslation();
    const clear = () => sigCanvas.current.clear();
    const save = () => sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const [signatureOld, setSignatureOld] = useState(null);
    const [signatureNew, setSignatureNew] = useState('');
    const [telephoneOld, setTelephoneOld] = useState(null);
    const [delegationStatus, setDelegationStatus] = useState(false);
    const [delegationStatusOriginal, setDelegationStatusOriginal] = useState(false);

    const [idDelegationNow, setIdDelegationNow] = useState();
    const [entities, setEntities] = useState([]);
    const [userErrors, setUserErrors] = useState([]);
    const [ifLL, setIfLL] = useState(false);

    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        telephone_number: '',
        delegation: { active: false, user: null },
        type: "owner",
        level: "standard"
    });
    const [users, setUsers] = useState([])

    const theme = useTheme();
    const navigate = useNavigate();
    const [origin, setOrigin] = useState(localStorage.getItem("origin"))

    useEffect(() => {
        if (userUid) {
            Axios.get('/user/' + userUid + '/user').then((response) => {
                //console.log("current user")
                //console.log(response?.data?.user)
                setIdDelegationNow(response?.data?.user?.idDelegation)
                setUser(response?.data?.user);
                // setDelegationStatus(response?.data?.user?.idDelegation != null)
                // setDelegationStatusOriginal(response?.data?.user?.idDelegation != null)
                if (response?.data?.user?.signature !== null) {
                    setSignatureOld('data:image/png;base64,' + response?.data?.user?.signature)
                }
                setTelephoneOld(response?.data?.user?.telephone_number);
                setIfLL(response?.data?.user?.ifLL)
            }).catch(err => {
                console.log(err);
            })
        } else {
            if (location?.state?.entityUid) {
                const entityUid = location.state.entityUid;
                Axios.get('/entity/' + entityUid + '/object').then((response) => {
                    setEntity(response?.data?.entity);
                    let newUser = {}
                    if (response?.data?.entity?.type == 'owner' || response?.data?.entity?.type == 'client')
                        newUser.entities = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
                    // else if(response.data?.entity?.type=='supplier')
                    //     newUser.suppliers = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
                    // else if(response.data?.entity?.type=='client')
                    //     newUser.clients = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
                    newUser.type = response?.data?.entity?.type
                    newUser.level = 'standard'
                    setUser(newUser)
                }).catch(err => {
                    console.log(err);
                })
            }
        }
        Axios.get('/user//ownerUsers').then(res => {
            setUsers(res.data?.users);
        })
    }, [])

    // const delegationActive = (e) => {
    //     console.log(e.target.checked)
    //     if (!e?.target?.checked) {
    //         if (user?.idDelegation) {
    //             setDelegationStatus(false)
    //             user.idDelegation = null
    //         } else
    //             setUser({ ...user, delegation: { ...user.delegation, active: e?.target?.checked } })
    //     } else {
    //         if (user?.idDelegation) {
    //             setDelegationStatus(true)
    //         } else
    //             setUser({ ...user, delegation: { ...user.delegation, active: e?.target?.checked } })
    //     }

    //     // console.log(user)
    //     // setUser({ ...user, delegation: { ...user.delegation, active: e?.target?.checked } })
    // }

    const updateSignature = () => {
        let imageURL = null
        imageURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        setSignatureNew(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
        if (!sigCanvas.current.isEmpty()) {
            Axios.put(`/user/${userUid}/signatureOwner`, {
                signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
            clear()
        }
    }

    // const titleChangeHandler = (e) => {
    //     console.log(e)
    //     const value = e.target.value
    //     console.log(e)
    //     setUser({
    //         ...user,
    //         telephone_number: value
    //     })
    // }

    const phoneInputChangeHandler = (value) => {
        let userTemp = {
            ...user,
            telephone_number: value
        }
        setUser(userTemp)
    }

    const updateTelephone = () => {
        Axios.put(`/user/${userUid}/telephone`, {
            telephone_number: user.telephone_number
        }).catch(response => {
        })
    }

    // const updateDelegation = () => {
    //     let delegationTemp = null
    //     if (user?.idDelegation == null) {
    //         delegationTemp = user?.delegation?.user
    //     } else if (user?.idDelegation != null && delegationStatus) {
    //         delegationTemp = user?.idDelegation
    //         console.log(delegationTemp)
    //     }

    //     Axios.put(`/user/${userUid}/delegation`, {
    //         idDelegation: delegationTemp,
    //         currentDelegation: idDelegationNow
    //     }).catch(response => {

    //     })
    // }

    // const checkContractForm = () => {
    //     let errors = []

    //     if (user?.telephone_number == telephoneOld && sigCanvas.current.isEmpty()) {
    //     } else if (!sigCanvas.current.isEmpty()) {
    //         notyf.success(t("notyf:signatureModification"))
    //     } else {
    //         notyf.success(t("notyf:telephoneModification", "Le numéro de téléphone a bien été modifiée"))
    //     }


    //     return errors;
    // }

    const updateHandler = () => {

        // let errors = checkContractForm()
        // if (errors.length > 0) {
        //     errors.map(error => {
        //         notyf.error(error)
        //     })
        // }
        // else {
        //     updateSignature();

        // }


        if (user?.telephone_number !== telephoneOld) {
            updateTelephone()
        }
        if (!sigCanvas.current.isEmpty()) {
            updateSignature();
        }

        // if ((user?.delegation?.user == undefined && idDelegationNow !== null && idDelegationNow !== user?.idDelegation) || (user?.delegation?.user !== undefined && user?.delegation?.user !== idDelegationNow)) {
        //     updateDelegation();
        // }

        navigate("/")

    }
    const radioChangeHandler2 = (e) => {
        const userId = e?.target?.value
        setUser({ ...user, delegation: { ...user.delegation, user: userId } })
    }

    return (
        <>
            <PFContainer>
                <PFHeaderWrapper>
                    <PFHeader>
                        <PFHeaderTitle>Profile</PFHeaderTitle>
                        <PFHeaderActions>
                            <BtnAction
                                color={theme.colors.primary}
                                border={theme.colors.primary}
                                onClick={() => navigate("/")}
                            >
                                <span>{t("global:back", "RETOUR")}</span>
                            </BtnAction>
                            <BtnAction
                                color={theme.colors.success}
                                bg={theme.colors.greenLight}
                                onClick={() => updateHandler()}
                            >
                                <span>{t("userProfile:save", "SAVE")}</span>
                            </BtnAction>
                        </PFHeaderActions>
                    </PFHeader>
                </PFHeaderWrapper>

                <PFBody>
                    <EntFormList style={{ paddingBottom: '20px' }}>

                        {
                            user?.entities?.length > 0 ? user?.entities.map((entity) => {
                                return <EntFormItem key={entity.uid} >
                                    <span>{entity.name + " - " + entity.code}</span>
                                </EntFormItem>

                            })
                                : null

                        }

                        {
                            user?.clients?.length > 0 ? user?.clients.map(client => {
                                return (
                                    <EntFormItem key={client.uid} >
                                        <span>{client.name + " - " + client.code}</span>
                                    </EntFormItem>

                                )
                            })
                                :
                                null
                        }
                    </EntFormList>
                    <PFFormWrapper>

                        <PFFormGroup style={{ justifyContent: 'flex-start' }}>
                            <FormLabel style={{ minWidth: "200px" }}>{t("userProfile:firstName", "Prénom:")}</FormLabel>
                            <span>{user?.first_name}</span>
                        </PFFormGroup>

                        <PFFormGroup style={{ justifyContent: 'flex-start' }}>
                            <FormLabel style={{ minWidth: "200px" }}>{t("userProfile:lastName", "Nom:")} </FormLabel>
                            <span>{user?.last_name}</span>
                        </PFFormGroup>
                        {user?.type == 'owner' ?


                            <PFFormGroup style={{ justifyContent: 'flex-start' }}>
                                <FormLabel style={{ minWidth: "200px" }}>{t("userProfile:Role", "Role:")} </FormLabel>
                                <span>{user?.role}</span>
                            </PFFormGroup>
                            :
                            <PFFormGroup style={{ justifyContent: 'flex-start' }}>
                                <FormLabel style={{ minWidth: "200px" }}>{t("userProfile:Role", "Role:")} </FormLabel>
                                {user?.level == 'admin' ?
                                    <span>{t("global:manager", "Gérant")}</span>
                                    :
                                    <span>{t("global:cog", "Co-gérant")}</span>
                                }
                            </PFFormGroup>
                        }

                        {/* <PFFormGroup style={{ justifyContent: 'flex-start' }}>
                            <FormLabel style={{ minWidth: "200px" }}>{t("userProfile:teleNumber", "Numéro de téléphone:")} </FormLabel>
                            <FormInput
                                id="telephone_number"
                                type="text"
                                style={{ textAlign: 'left' }}
                                value={user.telephone_number}
                                onChange={e => titleChangeHandler(e)}
                                maxLength={255}
                                name="telephone_number"
                            />
                        </PFFormGroup> */}

                        <PFFormGroup style={{ justifyContent: 'flex-start', flexWrap: 'nowrap'}}>
                            <FormLabel style={{ minWidth: "200px" }} htmlFor="telephone_number" >{t("global:phoneNumberTmp")}</FormLabel>
                            <TelInput
                                style={{width:"180px"}}
                                localization={fr}
                                country={'fr'}
                                dropdownStyle={{ width: "auto" }}
                                preferredCountries={['fr', 'us']}
                                enableSearch={false}
                                inputProps={{ name: "telephone_number" }}
                                placeholder={"33 0 00 00 00 00"}
                                value={user?.telephone_number?.substr(2) || ''}
                                onChange={phone => phoneInputChangeHandler("00" + phone)}
                            />
                        </PFFormGroup>
                        {user?.role !== 'standard' &&
                            <PFFormGroup style={{ justifyContent: 'flex-start' }}>
                                <FormLabel style={{ minWidth: "200px" }}>{t("userProfile:secret", "Document confidentiel")}</FormLabel>
                                {(Number(user.secret) === 1 ? t("global:yes", "Oui") : t("global:no", "Non"))}
                            </PFFormGroup>
                            // <PFFormGroup>
                            //     <FormLabel htmlFor="first_name" >{t("userProfile:fir", "Document confidentiel")}</FormLabel>
                            //     {(Number(user.secret) === 1 ? t("global:yes", "Oui") : t("global:no", "Non"))}
                            //     <FormInput
                            //         id="secret"
                            //         type="checkbox"
                            //         name="secret"
                            //         // placeholder={t("userProfile:firstName")}
                            //         disabled={+user?.secret}
                            //         checked={+user?.secret}
                            //     // onChange={(e) => secretInputChangeHandler(e)}
                            //     />
                            // </PFFormGroup>
                        }
                        {/* { ( user?.role == "superadmin" || +ifLL ) ?
                            <>
                                <PFFormGroup style={{ justifyContent: 'flex-start', marginBottom: "0px" }}>
                                    <FormInput style={{ width: "50px", maxWidth: "50px", marginBottom: "6px" }}
                                        id="secret"
                                        type="checkbox"
                                        name="delegation"
                                        // placeholder={t("userProfile:firstName")}
                                        checked={user?.delegation?.active || (user?.idDelegation && delegationStatus)}
                                        onChange={(e) => delegationActive(e)}
                                    />
                                    <FormLabel htmlFor="first_name" >{t("userProfile:fdsqr", "Délégation signature Laurent Levy")}</FormLabel>
                                    {
                                        (user?.idDelegation != null && delegationStatus) &&
                                        <span>: &nbsp;{user?.userRefdelegation}</span>
                                    }
                                </PFFormGroup>
                                {user?.delegation?.active &&
                                    <UsersContainer style={{ margin: "5px 0px 0px 11px" }}>
                                        < div className="row" style={{ background: "#efe8df", color: "#482D1C", borderBottom: "1px solid azure", width: "700px" }}>
                                            <div className="col-sm-1"></div>
                                            <div className="col-sm-3">Prénom</div>
                                            <div className="col-sm-3">Nom</div>
                                            <div className="col-sm-2">Rôle</div>
                                            <div className="col-sm-3">Nom Entité</div>
                                            <div className="col-sm-2">Code</div> 
                                        </div>


                                        {
                                            <UsersTable
                                                users={users}
                                                radioChangeHandler2={radioChangeHandler2}
                                            />
                                        }

                                    </UsersContainer>
                                }

                            </>
                            : null
                        } */}
                        <div style={{ display: "flex", justifyContent: 'space-between', width: '80%', marginTop: '30px' }}>
                            <div>
                                <FormLabel>{t("userProfile:currentSignature", "Signature actuelle")}</FormLabel>
                                <PFFormGroup style={{ paddingBottom: '0' }}>
                                    <WrapperCanvas style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={signatureOld} />
                                    </WrapperCanvas>
                                </PFFormGroup>
                            </div>
                            <div>
                                <div style={{ display: "flex", width: '100%', justifyContent: 'space-between' }}>
                                    <FormLabel>{t("userProfile:newSignature", "Nouvelle Signature")}</FormLabel>
                                    <PFFormLabel onClick={clear}>{t("userProfile:clear", "Effacer")}</PFFormLabel>
                                </div>
                                <PFFormGroup style={{ paddingBottom: '0' }}>
                                    <WrapperCanvas style={{ paddingTop: '3px', paddingLeft: '3px' }}>
                                        <SignatureCanvas
                                            ref={sigCanvas}
                                            backgroundColor="white"
                                            canvasProps={{ width: 300, height: 133, className: 'sigCanvas' }}
                                        />
                                    </WrapperCanvas>
                                </PFFormGroup>
                            </div>
                        </div>
                    </PFFormWrapper>
                </PFBody>
            </PFContainer>
        </>
    )
}
export default UserProfile
