import React, { useCallback, useEffect, useState } from 'react';
import './ResetPassword.Styled.js'
import Axios from '../../../axios-proas';
import { Link, useParams } from 'react-router-dom';
import LOGO from '../../../assets/images/optical-center-logo.png';
import '../ForgottenPassword/ForgottenPassword.css'
import { useTranslation } from 'react-i18next';
import {RSTBody, RSTitle, FormInput, FormLabel} from './ResetPassword.Styled'
import {FormGroup , BtnOutlineLink  , BtnOutlineAction  } from '../../../styles/Common'
function ResetPassword() {
    let {token} = useParams();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [alert, setAlert] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        Axios.get('/user/'+token+"/checkExistToken").then(response =>{

        }).catch(err => {
            setAlert("authentication:invalidToken");
        })
    }, [token])

    const ResetPassword = (e) => {
        e.preventDefault();
        setError(null)
        if (password === confirmedPassword) {
            editPassword(password);
        } else {
           
            setError("authentication:differentPasswords")
        }
    }

    const editPassword = useCallback((newPassword) => {
        Axios.put("/user/"+token+"/password", {
            password : newPassword
        }).then(response => {
            setAlert("authentication:passwordResetSuccess")
        }).catch(err => {
            setError("authentication:passwordResetFail")
        })
    }, [password])

    
    let content = (<>
        <RSTBody>
        <Link to="/" className="signin__Icon">
                <img src={LOGO} alt="logo" style={{maxWidth:"200px"}} />
            </Link>
                <RSTitle >{t("authentication:resetPassword")}</RSTitle>
                
                {
                error && error.length > 0 ? (
                    <RSTitle className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                        <ul>
                            {
                                error
                            }
                        </ul>
                    </RSTitle>
                ) : null
            }
                    <FormGroup>
                        <FormLabel htmlFor='user' >{t("authentication:newPassword")}</FormLabel>
                        <FormInput id='user' type="password" name="password" onChange={(e) => setPassword(e.target.value)} value={password} required autoFocus />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor='username'>{t("authentication:confirmPassword")}</FormLabel>
                        <FormInput  id="username" type="password" name="confirmedPassword" onChange={(e) => setConfirmedPassword(e.target.value)} value={confirmedPassword} required/>
                    </FormGroup>
                    {/* <span className="signin__error"> {t(error)} </span> */}
                   
                        <BtnOutlineAction style={{alignSelf:"end"}}color="#000" onClick={(e) => ResetPassword(e)}  >{t("global:validate")}</BtnOutlineAction>
                    
                
        
        </RSTBody>
    </>)
    
    if (alert) {
        content = (
        <RSTBody style={{textAlign: "center"}}>
           
            <RSTitle>{t(alert)}</RSTitle>
            <BtnOutlineLink to={"/"}  
                            color="#06152B"
                            border="#FFF">
                                {t("authentication:signIn")}
            </BtnOutlineLink>
        </RSTBody>
        )
    }

    return (
        <div className="forgotPassword__container">
           
            {content}
        </div>
    )
}

export default ResetPassword
