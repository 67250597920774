
import "./AccountRequest.css";
import React, { useEffect, useState, useRef } from 'react'
import { SendButton } from "../../styles/Common";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/optical-center-logo.png'
import { useTranslation } from 'react-i18next';
import LanguageList from '../LanguageList/LanguageList';

import EntitiesDropDown from "./EntitiesDropDown/EntitiesDropDown";
import CloseIcon from '@mui/icons-material/Close';
import Axios from '../../axios-proas';
import { useLocation, useNavigate } from 'react-router-dom';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import InformationModal from '../UI/ConfirmationModal/InformationModal';

import { useTheme } from 'styled-components';
function AccountRequest() {
    const { t } = useTranslation();
    const [entity, setEntity] = useState({
        countryCode: "fr",
        family: "ADMINISTRATION",
        lang: "fr"
    });
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const [selectedEntities, setSelectedEntities] = useState([]);

    const handleInputChange = (e) => {

        const key = e.target.name;
        var value = e.target.value;

        if (key == "actif") {
            e.target.checked ? value = 1 : value = 0;
        }

        const newSupplier = { ...entity, [key]: value };
        setEntity(newSupplier);


    }

    const ownersChangeHandler = (ownersList) => {
        let entityTemp = {
            ...entity,
            owners: ownersList
        }
        setEntity(entityTemp)
    }
    const retour = () => {
        setConfirmationModalShow(false)
        navigate("/");
    }


    const requestSupplierclickHandler = () => {

        const entityToAdd = {
            ...entity,
            type: "supplier"
        }
        Axios.post('entity//request', entityToAdd).then(response => {
            // if (response.data.hasOwnProperty('success')) {
            setConfirmationModalShow(true)
            notyf.success(t("default:userRequested", "La création de l'entité a bien été demandée"))
            setEntity({
                countryCode: "fr",
                family: "ADMINISTRATION",
                lang: "fr"
            });
            navigate("/");
            //}
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }
    const deleteOwnerclickHandler = (owner) => {

        let entityTemp = { ...entity }
        if (entity?.id && !owner?.new) {
            entityTemp = {
                ...entityTemp,
                ownersToDelete: entityTemp?.ownersToDelete ? [...entityTemp?.ownersToDelete, owner.uid] : [owner.uid]
            }
        }
        let entityOwners = [...entity?.owners]
        let ownerIndex = entityOwners.findIndex(ent => ent.uid === owner.uid);
        if (ownerIndex >= 0) {
            entityOwners.splice(ownerIndex, 1)
        }
        setEntity({
            ...entityTemp,
            owners: entityOwners
        })
    }
    const disableCanal = (e) => {
        setEntity({ ...entity, [e]: false });
    }
    const enableCanal = (e) => {
        setEntity({ ...entity, [e]: true });
    }

    return (
        <div className="accountRequest__container">
            <div className="accountRequest__FromWrap">
                <div className="accountRequest__fromContent">
                    {/* <div className="signin_language_container">
                    <LanguageList
                    // iconColor="#1e98d7"
                    iconColor="#6C5CE7"
                    />
                </div> */}
                    <div className="accountRequest__header">
                        <Link to="/" className="accountRequest__Icon">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <form className="accountRequest__form" action="#">
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                name="name"
                                value={entity?.name || ""}
                                placeholder={t("global:name", "Nom")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.siren || ""}
                                name="siren"
                                placeholder={t("global:idt1") + ": N° Siren"}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.siret || ""}
                                name="siret"
                                placeholder={t("global:idt2") + ": N° Siret"}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.vat || ""}
                                name="vat"
                                placeholder={"N° " + t("supplier:vta")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                name="address"
                                value={entity?.address || ""}
                                placeholder={t("global:address", "Adresse")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.addressComplement || ""}
                                name="addressComplement"
                                placeholder={t("global:additionalAddress", "Adresse complément")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                name="zipCode"
                                value={entity?.zipCode || ""}
                                placeholder={t("supplier:zipCode", "Code postal")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                name="city"
                                value={entity?.city || ""}
                                placeholder={t("supplier:city", "Ville")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__formGroup">
                            <select
                                id="countryCode"
                                className="accountRequest__formInput"
                                value={entity?.countryCode || ""}
                                name="countryCode"
                                onChange={handleInputChange}
                            >
                                <option value="fr">FRANCE</option>
                            </select>
                        </div>
                        <div className="accountRequest__formGroup">
                            <input
                                className="accountRequest__formInput"
                                type="text"
                                name="entityAdmin"
                                value={entity?.entityAdmin || ""}
                                placeholder={t("global:adminContact", "Contact administrateur")}
                                onChange={handleInputChange}

                            />
                        </div>
                        <div className="accountRequest__canalContainer">
                            <label className="accountRequest__canalLabel">
                                {t("global:Exchangechannel", "Canal d'échange")}
                            </label>
                            <div className="accountRequest__canalWrap">
                                <div className="accountRequest__canalItem">
                                    <label className="accountRequest__canalItemLabel">
                                        {t("global:email", "Email")}
                                    </label>
                                    <div className="accountRequest__canalItemValue">
                                        {
                                            entity?.sendMail ? (
                                                <ToggleOnIcon
                                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                    fontSize="small"
                                                    name="edi"
                                                    onClick={() => disableCanal("sendMail")}

                                                />

                                            ) :
                                                <ToggleOffIcon
                                                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                    fontSize="small"
                                                    name="edi"
                                                    onClick={() => enableCanal("sendMail")}
                                                />

                                        }
                                        {/* <input  checked={entity?.email}  type="checkbox" name="email" onChange={(e) => handleInputChange(e)} aria-label="Checkbox for following text input"></input>       */}
                                    </div>
                                </div>
                                <div className="accountRequest__canalItem ">
                                    <label className="accountRequest__canalItemLabel">
                                        {t("global:paper", "Papier")}
                                    </label>
                                    <div className="accountRequest__canalItemValue">
                                        {
                                            entity?.paper ? (
                                                <ToggleOnIcon
                                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                    fontSize="small"
                                                    name="paper"
                                                    onClick={() => disableCanal("paper")}
                                                />
                                            ) :
                                                <ToggleOffIcon
                                                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                    fontSize="small"
                                                    name="edi"
                                                    onClick={() => enableCanal("paper")}
                                                />
                                        }
                                        {/* <input  checked={entity?.papier} type="checkbox" name="papier" onChange={(e) => handleInputChange(e)} aria-label="Checkbox for following text input"></input>  */}
                                    </div>
                                </div>
                                <div className="accountRequest__canalItem">
                                    <label className="accountRequest__canalItemLabel">
                                        {t("signin:edi", "EDI")}
                                    </label>
                                    <div className="accountRequest__canalItemValue">
                                        {
                                            entity?.edi ? (
                                                <ToggleOnIcon
                                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                    fontSize="small"
                                                    name="edi"
                                                    onClick={() => disableCanal("edi")}
                                                />
                                            ) :
                                                <ToggleOffIcon
                                                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                    fontSize="small"
                                                    name="edi"
                                                    onClick={() => enableCanal("edi")}
                                                />

                                        }
                                        {/* <input  checked={entity?.edi} type="checkbox" name="edi" onChange={(e) => handleInputChange(e)} aria-label="Checkbox for following text input"></input>                  */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <EntitiesDropDown deleteOwnerclickHandler={deleteOwnerclickHandler} selectedEntities={selectedEntities} setSelectedEntities={setSelectedEntities} addEntity={ownersChangeHandler} />
                        </div>

                        <div className="accountRequest__formButtons">
                            <span className="accountRequest__cancelButton" onClick={() => retour()}>
                                {t("global:cancel")}
                            </span>

                            <span className="accountRequest__cancelButton" onClick={() => requestSupplierclickHandler()}>
                                {t("global:sendRequest")}
                            </span>
                        </div>

                    </form>
                    <div className="accountRequest__footer">
                        {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                    </div>
                </div>
            </div>

            <InformationModal
                show={confirmationModalShow}
                modalClosed={() => retour(false)}
                confirm={() => retour(document)}
                cancel={() => retour(false)}
                title={"Confirmation de création"}
                message={`Vous recevrez un message électronique vous invitant à réinitialiser votre mot de passe.`}
            />
        </div>
    )
}

export default AccountRequest;
