import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Axios from '../../axios-proas';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import { checkValidity, formatDate, getNotyfObject, verificationData, validatDocs } from '../../shared/utility';
import EntitiesComponent from '../../components/UI/EntitiesComponent/EntitiesComponent';
import UsersManagement from '../UsersManagement/UsersManagement';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import Download from '../../components/Download/Download';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { EntContainer, EntContent, EntHeader, EntHeaderAction, EntHeaderActions, EntHeaderTitle, EntHeaderWrapper, NavTabsLi, NavTabsLink, NavTabsUl } from './Entity.styled'
import { useTheme } from 'styled-components';
import Infos from '../../components/Entity/Infos/Infos';
import { BtnAction, BtnOutlineAction } from '../../styles/Common';
import TableActions from '../../components/UsersManagTable/TableActions/TableActions';
import { TableActionLink } from '../../styles/Table.styled';
import axios from 'axios';
import { SpinnerContainer } from '../../styles/Table.styled';
import { Spinner } from 'react-bootstrap';
import SuspendedDocsModal from '../../components/Entity/Infos/SuspendedDocsModal/SuspendedDocsModal';

function Entity({ userType }) {
    const [origin, setOrigin] = useState(localStorage.getItem("origin"))
    const theme = useTheme()
    let { uid } = useParams();
    const navigate = useNavigate();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const userRole = localStorage.getItem('role');
    const [addAutor, setAddAutor] = useState();
    const [addManager, setAddManager] = useState();
    const [usersUpdates, setUsersUpdates] = useState();
    const [userManagerUpdates, setUserManagerUpdates] = useState();
    const [errors, setErrors] = useState();


    let location = useLocation();
    const [entity, setEntity] = useState({
        countryCode: "FR",
        family: "ADMINISTRATION",
        lang: "fr",
        type: location?.state?.entityType ? location?.state?.entityType : "owner",
    });
    const [navTabsList, setNavTabsList] = useState([

        { label: "adminInformation", value: 'infos' },
        { label: "contacts", value: 'contacts' }
    ])
    const canalList = useMemo(() => ([
        { field: 'sendMail', label: t("signin:canalEmail", "Email") },
        { field: 'accessPortal', label: t("signin:accessPortal", "Portail") },
        { field: 'edi', label: t("signin:edi", "EDI") }
    ]), [t]);
    const [tab, setTab] = useState(localStorage.getItem("supplierActiveTab") ? localStorage.getItem("supplierActiveTab") : "infos");
    const [validate, setValidate] = useState(false)
    const [checked, setChecked] = useState(false)
    const [valideSirenError, setValideSirenError] = useState(false)
    const typeUser = localStorage.getItem("type");
    const [update, setUpdate] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [suspendedDocs, setSuspendedDocs] = useState([]);
    const [showSuspendedDocsModal, setShowSuspendedDocsModal] = useState(false);

    const checkValidInfo = () => {
        setShowSpinner(true)
        setTimeout(function () {
            let token = "5bee708d-0add-378a-ac81-47b6ee0b73de";
            let siren = "441136785"
            axios.get("https://api.insee.fr/entreprises/sirene/V3/siren?q=siren:" + siren, { headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/json' } }).then(response => {
                console.log(response)
                // verificationData(response.data,entity);
                checked_siren();
                setChecked(true)
                setValideSirenError(false)
                setShowSpinner(false)
            }).catch(error => {
                setValideSirenError(true)
                setShowSpinner(false)
            });

        }, 5000);

    }

    const updateStatus = () => {
        let params = {
            "user_uid": entity?.admins[0]?.uid,
            "entity_id": entity?.id
        }
        Axios.put('/entity/' + entity?.uid + '/validate_entity', params).then(response => {
            setValidate(true)
            notyf.success("validation terminé");
            navigate("/referentielFournisseur/")
        }).catch(err => {
            console.error(err);
        })
    }
    const checked_siren = () => {
        Axios.put('/entity/' + entity?.uid + '/checked_siren', entity).then(response => {
            notyf.success(t("siren Verifié"));
        }).catch(err => {
            console.error(err);
        })
    }

    useEffect(() => {
        Axios.get('/entity/' + uid + '/canUpdateUsers').then((response) => {
            console.log(response)
            setUsersUpdates(response?.data?.blocUsersUpdates)
            setUserManagerUpdates(response?.data?.blocUserMangerUpdates)
        }).catch(err => {
            console.log(err);
        })
        Axios.get('/entity/' + uid + '/nbrCog').then((response) => {
            setAddAutor(response?.data?.addAutor)
            setAddManager(response?.data?.addManager)
        }).catch(err => {
            console.log(err);
        })
    }, [update])

    //jai fait ca car je veux pas rafraicher useEffect avec la dependence update
    useEffect(() => {
        return () => {
            setTab("infos")
            localStorage.removeItem("supplierActiveTab");
            localStorage.removeItem("show");
        }
    }, [uid, validate, checked])

    useEffect(() => {
        var tabProps = "";
        if (typeof location?.state?.tabIndex !== 'undefined') {
            tabProps = location.state?.tabIndex;
        } else {
            tabProps = location.state?.tab;
        }

        if (tabProps) {
            setTab(tabProps)
        }
        const entityUid = uid;

        if (entityUid) {
            Axios.get('/entity/' + entityUid + '/object').then((response) => {
                if (response?.data?.entity?.type === "supplier") {
                    if (response?.data?.entity?.status == "INPROGRESS") {
                        setNavTabsList([
                            { label: "adminInformation", value: 'infos' }

                        ])
                    } else {
                        setNavTabsList([
                            { label: "adminInformation", value: 'infos' },
                            { label: 'contacts', value: 'contacts' }
                        ])
                    }
                } else {
                    setNavTabsList([
                        { label: "adminInformation", value: 'infos' },
                        { label: 'contacts', value: 'contacts' }
                    ])
                }
                setEntity(response.data.entity);
                setUpdate(false)
            }).catch(err => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"));
            })

        } else {
            setNavTabsList([
                { label: "adminInformation", value: 'infos' }
            ])
        }
        let locale = localStorage.getItem("show")


        // return () => {
        //     setTab("infos")
        //     localStorage.removeItem("supplierActiveTab");
        //     let local=localStorage.getItem("show")
        //     if(local=="document"){
        //         changeTab("documents")
        //         localStorage.removeItem("show");
        //         localStorage.removeItem("supplierActiveTab");
        //     }else{
        //         localStorage.removeItem("show");

        //     }

        // }

    }, [uid, validate, checked, update])


    const changeTab = tab => {
        localStorage.removeItem("show");
        setTab(tab);
        localStorage.setItem("supplierActiveTab", tab);
    }

    const handleInputChange = e => {
        const key = e.target.name;
        var value = e.target.value;
        if (key == "actif") {
            e.target.checked ? value = 1 : value = 0;
        }
        const newSupplier = { ...entity, [key]: value };
        setEntity(newSupplier);
    }

    const canalChangeHandler = (field, value) => {
        let entityTmp = { ...entity };
        canalList.forEach(canal => {
            entityTmp[canal.field] = canal.field === field ? value : 0;
        });
        setEntity(entityTmp);
    }

    const checkValidityForm = () => {
        let errors = []
        if (!entity?.name || entity?.name == "") {
            errors.push(t("contract:readDocume", "Nom non conforme"));
        }
        if (!entity?.code || entity?.code == "") {
            errors.push(t("contract:readDocume", "Code non conforme"));
        }
        if (!entity?.siret || entity?.siret == "") {
            errors.push(t("contract:readDocume", "Siret non conforme"));
        }
        if (!entity?.siren || entity?.siren == "") {
            errors.push(t("contract:readDocume", "Siret non conforme"));
        }
        if (!entity?.vat || entity?.vat == "" || entity?.vat.length > 14 ) {
            errors.push(t("contract:readDocume", "TVA non conforme"));
        }
        if (!entity?.telephone || entity?.telephone == "") {
            errors.push(t("contract:readDocume", "Téléphone non conforme"));
        }
        if (!entity?.address || entity?.address == "") {
            errors.push(t("contract:readDocume", "Adresse non conforme"));
        }
        // if (!entity?.addressComplement || entity?.addressComplement == "") {
        //     errors.push(t("contract:readDocumentMessage"));
        // }
        
        if (!entity?.zipCode || entity?.zipCode == "") {
            errors.push(t("contract:readDocume", "Code postal non conforme"));
        }
        if (!entity?.city || entity?.city == "") {
            errors.push(t("contract:readDocume", "Ville non conforme"));
        }
        if (!entity?.countryCode || entity?.countryCode == "") {
            errors.push(t("contract:readDocume", "Code pays non conforme"));
        }
        // if (!entity?.family || entity?.family == "") {
        //     errors.push(t("contract:readDocumentMessage", "Siret non conforme"));
        // }
        // if (!entity?.lang || entity?.lang == "") {
        //     errors.push(t("contract:readDocumentMessage"));
        // }
        if (origin == "fromClient") {
            if(entity?.id){
                if (!entity?.admins[0].login || entity?.admins[0].login == "")
                    errors.push(t("contract:readDocume", "Contact administratif non conforme"));    
            }else if (!entity?.entityAdmin || entity?.entityAdmin == "") {
                errors.push(t("contract:readDocume", "Contact administratif non conforme"));
            }
        }
        // console.log(errors);
        setErrors(errors);

        return errors;
    }


    const addEntityclickHandler = () => {
        const entityToAdd = {
            ...entity
        }
        console.log(entity)
        if (checkValidityForm().length == 0) {
        //     notyf.error(t("global:notComplete"))
        // } else {
            Axios.post('entity//entity', entityToAdd).then(response => {
                if (response.data.hasOwnProperty('success')) {
                    notyf.success(t("default:entityAdded", "L'entité a bien été ajoutée"))
                    setEntity({
                        countryCode: "fr",
                        family: "ADMINISTRATION",
                        lang: "fr"
                    });
                    navigate(-1);
                }
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }
    }

    const editEntityclickHandler = () => {
        let errors = checkValidityForm()

        console.log(errors)
        if(errors.length==0){
            Axios.put(`entity/${entity?.uid}/entity`, entity).then(response => {
                if (response?.data.hasOwnProperty('success')) {
                    notyf.success(t("default:userAdded", "L'entité a bien été modifiée"))
                    navigate(-1)
                }
                if (response?.data.hasOwnProperty('suspendedDocs')) {
                    let suspendedDocsTmp = response?.data?.suspendedDocs;
                    setSuspendedDocs(suspendedDocsTmp);
                    if (suspendedDocsTmp.length > 0)
                        setShowSuspendedDocsModal(true);
                }
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })    
        }
        //else notyf.error(t("global:notComple", "Les informations ne sont pas complètes ou conformes"))
    }


    const saveClickHandler = () => {
        if (entity?.id)
            editEntityclickHandler();
        else
            addEntityclickHandler();
    }

    const deleteOwnerclickHandler = (owner) => {
        let entityTemp = { ...entity }
        if (entity?.id && !owner?.new) {
            entityTemp = {
                ...entityTemp,
                ownersToDelete: entityTemp?.ownersToDelete ? [...entityTemp?.ownersToDelete, owner.uid] : [owner.uid]
            }
        }
        let entityOwners = [...entity?.owners]
        let ownerIndex = entityOwners.findIndex(ent => ent.uid === owner.uid);
        if (ownerIndex >= 0) {
            entityOwners.splice(ownerIndex, 1)
        }
        setEntity({
            ...entityTemp,
            owners: entityOwners
        })
    }

    const ownersChangeHandler = (ownersList) => {
        let entityTemp = {
            ...entity,
            owners: ownersList
        }
        setEntity(entityTemp)
    }

    // const setDownloadDate = document => {
    //     const newEntity = { ...entity };
    //     const newDocuments = newEntity.documents;
    //     const doc = newDocuments.find(d => document.uid === d.uid);
    //     doc.downloadDate = new Date().getTime();
    //     setEntity(newEntity);
    // }

    const clickCondition = () => {
        if (typeUser == "owner")
            return (entity?.status === "INPROGRESS" && parseInt(entity?.checked) === 0) ?
                t("global:check")
                : (entity?.status === "INPROGRESS" && parseInt(entity?.checked) === 1)
                    ? t("global:validate")
                    : t("global:save")
        else
            return t("global:save")
    }

    const clickConditionForCallFunction = () => {
        if (typeUser == "owner")
            return (entity?.status === "INPROGRESS" && parseInt(entity?.checked) === 0) ?
                checkValidInfo()
                : (entity?.status === "INPROGRESS" && parseInt(entity?.checked) === 1) ?
                    updateStatus()
                    :
                    saveClickHandler()
        else
            return saveClickHandler()
    }

    let tSpinner = showSpinner ? (<>
        <SpinnerContainer style={{ display: "flex", flexDirection: "column" }}>
            <Spinner animation="border" variant="primary" />
            <p>verification..</p>
        </SpinnerContainer>
    </>
    ) : null;
    let jSXcontent = null;
    let jsxActions = null;

    switch (tab) {
        case "contacts":
            jsxActions = (<TableActions
                entityUid={uid}
                userRole={userRole}
                origin={origin}
                addAutor={addAutor}
                addManager={addManager}
            />)
            jSXcontent = (
                <UsersManagement
                    entityUid={uid}
                    userRole={userRole}
                    origin={origin}
                    update={update}
                    setUpdate={setUpdate}
                    usersUpdates={usersUpdates}
                    userManagerUpdates={userManagerUpdates}
                />)
            break;
            // case "documents":
            //     jsxActions = (
            //         <TableActionLink
            //             color={theme.colors.primary}
            //             to={"/uploadDoc/" + uid}
            //         >
            //             <span>{t('contract:add', "Ajouter")}</span>
            //             <AddIcon />
            //         </TableActionLink>
            //     )
            //     jSXcontent = (
            //         docTypes &&
            //         <Documents
            //             setUpdate={setUpdate}
            //             documents={entity?.documents}
            //             type={userType}
            //             Admin={entity?.admins}
            //             docTypes={docTypes}
            //             setDownloadDate={setDownloadDate}

            //         />
            //     )
            break;

        default:
            jsxActions = (
                <BtnAction
                    color={theme.colors.success}
                    bg={theme.colors.greenLight}
                    onClick={clickConditionForCallFunction}
                >
                    <span> {clickCondition()}</span>
                </BtnAction>
            )


            jSXcontent = (
                <Infos
                    userRole={userRole}
                    entity={entity}
                    entities={entity?.owners}
                    handleInputChange={handleInputChange}
                    deleteEntity={deleteOwnerclickHandler}
                    addEntity={ownersChangeHandler}
                    entitiesType={"owner"}
                    canalChangeHandler={canalChangeHandler}
                    canalList={canalList}
                />
            )
            break;
    }

    return (
        <EntContainer>
            <TitleHelmet title={"OPTICAL CENTER | " + origin == "fromClient" ? t("clientList:cl", "Site") : t("supplier:sup", "Groupe")} />
            <EntHeaderWrapper>
                <EntHeader>
                    <EntHeaderTitle>
                        {t("global:card", "Fiche")} {origin == "fromClient" ? t("clientList:cl", "site") : t("supplier:sup", "groupe")} :
                        <span style={{ marginLeft: "0.5rem" }}>{entity?.name || ''} {entity?.checked == 1 && entity?.status == "INPROGRESS" && <CheckCircleIcon color="success"></CheckCircleIcon>}</span>
                    </EntHeaderTitle>
                    <EntHeaderActions>
                        <BtnAction
                            color={theme.colors.primary}
                            onClick={() => origin == "fromClient" ? navigate("/referentielClient") : navigate("/entitiesManager")}
                        >
                            <span>{t("global:back", "Retour")}</span>
                        </BtnAction>
                        {
                            jsxActions
                        }
                    </EntHeaderActions>
                </EntHeader>

                <NavTabsUl>
                    {
                        navTabsList.map((tl, index) => (
                            <NavTabsLi>
                                <NavTabsLink
                                    active={tab === tl.value}
                                    onClick={() => changeTab(tl.value)}
                                    to={`#`}
                                >
                                    {t("global:" + tl.label)}
                                </NavTabsLink>
                            </NavTabsLi>
                        ))
                    }
                </NavTabsUl>
            </EntHeaderWrapper>
            {
                valideSirenError ? (
                    <div className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                        <ul>
                            {
                                <li >{t("global:invalidSiren", "Siren number is invalid")}</li>
                            }
                        </ul>
                    </div>
                ) : null
            }
            <EntContent>
                
                {tSpinner}
                {jSXcontent}
                
                <div style={{width: "19%",margin: "auto",marginTop:"25px"}}>
                    {
                        errors?.map(error => {
                            return <><small style={{color:"red", fontSize:"0.9rem"}}>* {error}</small><br/></>
                        })
                    }
                </div>
                
                {/* {
                    showSuspendedDocsModal && (
                        <SuspendedDocsModal
                            show={showSuspendedDocsModal}
                            closeModal={() => setShowSuspendedDocsModal(false)}
                            documents={suspendedDocs}
                        />
                    )
                } */}
            </EntContent>
        </EntContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    login: state.auth.login
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(Entity)
