
import React from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CIDeleteBtn } from '../../Extraction/ExtractionItem/ExtractionItem.styled';
import Spinner from 'react-bootstrap/Spinner'


function ConfirmationModal({ show, modalClosed, title, message, confirm, cancel, signContractLoading, showSpinner }) {
    const { t } = useTranslation();

    return (
        <>
            <Modal show={show} onHide={modalClosed} contentClassName="modal_w_mc" className="modal fade" >
                <Modal.Header closeButton>
                    <Modal.Title> {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{ width: "650px" }}>
                    <p>
                        {message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <CIDeleteBtn style={{ fontSize: "1.3rem" }}
                        onClick={cancel}
                    >
                        {t('confirmationModal:cancel', "Annuler")}
                    </CIDeleteBtn>
                    {signContractLoading || showSpinner ?
                        <Spinner animation="border" variant="primary" />
                        :
                        <CIDeleteBtn style={{ fontSize: "1.3rem" }}
                            onClick={confirm}
                            color="#482D1C" border="#482D1C">
                            {t('confirmationModal:confirm', "Confirmer")}
                        </CIDeleteBtn>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmationModal

