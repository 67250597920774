import React, { useState, useEffect, useCallback, useRef } from 'react'
import ScrollToBottom from 'react-scroll-to-bottom';
import Axios from '../../../../axios-proas'
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import { getNotyfObject, isToday, isYesterday, formatDate } from "../../../../shared/utility";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { MCContainer, MCMessagesContainer, Message, MessageAvatar, MessageContent, MessageDate, MessagesContainer, MessageValue, MsgInput, MsgInputContainer, MsgSendBtn, ScrollToUpBtn } from './ModificationsChat.styled';
import SendIcon from '@mui/icons-material/Send';
import { Spinner } from 'react-bootstrap';
import { NoContent, SpinnerContainer } from '../../../../styles/Table.styled';

function ModificationsChat(props) {
    const {
        userUid,
        userType,
        userUidFromToken,
        loadMessages,
        messages
    } = props
    console.log(messages)
    const [message, setMessage] = useState("");
    const [messagesLoading, setMessagesLoading] = useState(false)
    const { t } = useTranslation();
    let { uidContract } = useParams();
    const notyf = getNotyfObject();
    const messagesRef = useRef();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const generateAvatar = (data) => {
        if (data && data?.first_name && data?.last_name) {
            return data?.first_name.charAt(0).toUpperCase() + data?.last_name.charAt(0).toUpperCase()
        } else if (data && data?.login) {
            return data?.login.charAt(0).toUpperCase()
        } else {
            return "?";
        }
    }

    const generateUserName = (data) => {
        if (data && data?.first_name && data?.last_name) {
            return data?.first_name + " " + data?.last_name
        } else if (data && data?.login) {
            return data?.login
        } else
            return "Inconnu";
    }

    const postMessage = () => {
        if (message) {
            Axios.post("contractevent", {
                contractUid: uidContract,
                message: message,
                type: "USER_CHAT_EVENT",
                userUid : userUid
            }).then(response => {
                loadMessages()
                setMessage("")
            })
        }
        //createMandatTimelineEvent(document.uidContract, {documentUid: document.uidContract, type: "USER_CHAT_EVENT", message: message});
    }

    const messagesScrollUp = () => {
        messagesRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    return (
        <MCContainer
            style={userType == 'owner' ? { maxHeight: "660px" } : { maxHeight: "760px" }}>
            <MCMessagesContainer ref={messagesRef}>
                {
                    messagesLoading ?
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                        :
                        messages.length === 0 ? (
                            <NoContent>
                                <p>{t('mandat:noMessages', "Aucun message à afficher")} </p>
                            </NoContent>
                        )
                            : (
                                <>
                                    <ScrollToUpBtn style={{ marginBottom: "10px" }}>
                                        <ArrowUpwardIcon onClick={messagesScrollUp} />
                                    </ScrollToUpBtn>


                                    <MessagesContainer >
                                        {
                                            messages?.map(m => (
                                                <Message
                                                    key={m.id}
                                                    isMine={userUid === m?.user?.userUid}
                                                >
                                                    {
                                                        userUid !== m?.user?.userUid && (
                                                            <MessageAvatar>
                                                                {generateAvatar(m?.user)}
                                                            </MessageAvatar>
                                                        )
                                                    }
                                                    <MessageContent isMine={userUid === m?.user?.userUid}>
                                                        <MessageValue>
                                                            {m?.message}
                                                        </MessageValue>
                                                        <MessageDate isMine={userUid === m?.user?.userUid} >
                                                            {generateUserName(m?.user) + " - " + formatDate(parseInt(m?.creationDate))}
                                                        </MessageDate>
                                                    </MessageContent>
                                                </Message>
                                            ))
                                        }
                                    </MessagesContainer>
                                </>
                            )
                }
            </MCMessagesContainer>
            <MsgInputContainer>
                <MsgInput
                    type="text"
                    placeholder={t('mandat:messagePlaceHolder', "Tapez votre message")}
                    value={message}
                    onChange={handleMessageChange}
                    maxLength="255"
                />
                <MsgSendBtn onClick={() => { postMessage() }}>
                    <SendIcon />
                </MsgSendBtn>
            </MsgInputContainer>

        </MCContainer>
    )
}

const mapStateToProps = (state) => ({
    // admStatus: state.mandat.admData?.status,
    userUid: state.auth.userUid,
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
    // getMandatTimelineEvents: (mandatUid, type) => dispatch(actions.getMandatTimelineEvents(mandatUid, type)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData))
})
export default connect(mapStateToProps, mapDispatchToProps)(ModificationsChat)
