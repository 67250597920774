import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';


import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';
import {showColumn} from '../../../shared/utility'

function TableFilters(props) {
    const {formatDate, convertDate, showFilters, filters, filtersChange, applyFilters, userType, userLevel, userRole,columns} = props;
    const {t} = useTranslation();
    const [show, setShow] = useState(null)
    const [rangeShow, setRangeShow] = useState(false);
    const [rangeBTShow, setRangeBTShow] = useState(false);
    const [dateField, setDateField] = useState('')
    

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters);
    }
    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters)
    }
    let [dateInputValue,setDateInputValue] = useState({
        creationDate: filters?.creationDate ? convertDate(filters.creationDate) : ''
    });
    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const getCreationDate = () => {
        return filters?.creationDate ? convertDate(filters.creationDate) : [];
    }
      
   

    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                
                <TD style={{display: !showColumn(columns ,"visible") && 'none'}}>
                <FilterContainer>
                        <SelectFilter  
                                onChange={(e) => inputChangeHandler(e)} 
                                value={filters?.visible || ''} 
                                name="visible">
                            <Option key={12}></Option>
                            <Option key={1} value="1">{t("global:yes")}</Option>
                            <Option key={0} value="0">{t("global:no")}</Option>
                        </SelectFilter >
                        </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns ,"creationDate") && 'none',padding: 0}}>
                    <FilterContainer>

                    <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationDate()[0] || ''}
                            to={getCreationDate()[1] || ''}
                            name="creationDate"
                            activeReset={filters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />


                        {/* <div className="filter__amountInterval">
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > {t("global:from")} {getCreationDate()[0] || ''}</h6>
                            <h6 className="filter__input" style={{marginBottom: "5px"}}  > {t("global:to")} {getCreationDate()[1] || ''}</h6>
                        </div>
                        <DateRangeIcon className="filter__icon" style={{color: "#022f67"}} onClick={() => dateShowHandler("creationDate")} />
                        <DateRange format={formatDate} show={show?.creationDate} clicked={closeModalHandler} changed={(date) =>dateChangeHandler(date)} field={dateField} reset={dateResetHandler} />
                        <CloseIcon className="filter__icon" style={{color: "red", display: filters?.creationDate ? "block" : "none"}} onClick={() => dateResetHandler("creationDate")} /> */}
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns ,"docName") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.docName || ''} 
                        name="docName" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns ,"docType") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.docType || ''} 
                        name="docType" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                { userLevel == "owner" ?  
                    <>
                        <TD style={{display: !showColumn(columns ,"entitiesType") && 'none'}}>
                            <FilterContainer>
                                <InputFilter
                                onChange={(e) => inputChangeHandler(e)} 
                                value={filters?.entitiesType || ''} 
                                name="entitiesType" 
                                type="text" 
                                autoComplete="off" />
                            </FilterContainer>
                        </TD> 
                        <TD style={{display: !showColumn(columns ,"entitiesList") && 'none'}}>
                            <FilterContainer>
                                <InputFilter
                                 onChange={(e) => inputChangeHandler(e)} 
                                 value={filters?.entitiesList || ''} 
                                 name="entitiesList" 
                                 type="text" 
                                 autoComplete="off" />
                            </FilterContainer>
                        </TD> 
                    </>
                    :null 
                }

            </tr>
        </>
    )
}

export default TableFilters
