import styled from 'styled-components';

export const UIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
`

export const UIBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`

export const UISection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const UICol = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
`
export const DPContainer = styled.div`

    margin-bottom: 0.5rem;

    & input {
        padding: 0.5rem !important;
        color: ${({ theme }) => theme.colors.secondary};
    }

    & button {
        color: ${({ theme }) => theme.colors.secondary};
    }

    & fieldset {
        border-color: ${({ theme }) => theme.colors.secondary};
    }

    & label {
        color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus-within {
        & input {
            color: ${({ theme }) => theme.colors.primary};
        }
        & button {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`


export const DocVisu = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
`