import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const DocVisu  = styled.div`
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
`;
export const Content  = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #D9E1E7CC;
    justify-content: center;
    font-size: 0.9rem;
    border-radius: 18px;
    color: ${({theme}) => theme.colors.secondary};
    padding: 3rem 2rem;
`;

export const SousContent  = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    align-items: center;
    border: 1px solid #D9E1E7CC;
    justify-content: center;
    font-size: 1rem;
    border-radius: 18px;
    color: ${({theme}) => theme.colors.secondary};
    
`;
export const EFContainer  = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
   
`

export const EFBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`
export const EFRow   = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  
`

export const EIFormGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #F5FBFF;
`

export const EIFormLabel = styled.label`
    margin: 0;
    padding: 0;
    color: ${({theme}) => theme.colors.secondary};
`;

export const EIFormInput = styled.input`
    /* flex: 1; */
    border: none;
    outline: none;
    width: 170px;;
`;

export const EIFormSelect = styled.select`
    border: none;
    outline: none;
    width: 82%;
    text-align: end;
`;
export const EntFormSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;
`;
export const EntFormList = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
`;

export const EntFormItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    padding: 0.25rem;
    background-color: #2174B980;
    color: white;
    border-radius: 8px;
    margin-right: 0.5rem;

    & span {
        margin: 0 0.5rem 0 0;
    }
`
export const CFFormGroup  = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`;

export const EFCol6  = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

