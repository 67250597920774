import React, { useEffect, useState,useCallback, useMemo } from 'react'
import { connect } from 'react-redux';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import StatusModal from './StatusModal/StatusModal';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from 'styled-components';

function IDProgressLine(props) { 
    const {
        userType,
        entityFilter,
        status,
        editStatus,
        accessToken
    } = props
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const theme = useTheme()
    const [showStatusModal, setShowStatusModal] = useState(false);
    const statusList = useMemo(() => [
        {label: status === 'ERROR' ? 'Erreur' : "Importée", value: status === 'ERROR' ? 'ERROR' : "IMPORTED"},
        {label: "Controlée", value: "CONTROLLED"},
        {label: "Intégrée", value: "INTEGRATED"},
        {label: "A payer", value: "TO_BE_PAID"},
        {label: "Payée", value: "PAID"}
    ], [status])

    let actionButton = null;

    const getStatusColors = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value === status)
        if(status === 'ERROR') {
            if(value === status) 
                return [theme.colors.danger, theme.colors.danger]
            if(index < statusIndex)
                return [theme.colors.seconday, theme.colors.seconday]
            if(index === (statusIndex + 1))
                return [theme.colors.seconday, theme.colors.seconday]
        }
            
        if(value === status) 
            // return [theme.colors.seconday, theme.colors.warning]
            return [theme.colors.success, theme.colors.success]
        if(index < statusIndex)
            return [theme.colors.success, theme.colors.success]
        if(index === (statusIndex + 1))
            return [theme.colors.seconday, theme.colors.warning]
        return [null, null]
    }

    if((userType === "client" || userType === "owner") && !accessToken && status !== 'ERROR' ) {
        actionButton = (
            <PLAction onClick={() => setShowStatusModal(true)} >
                <span>
                    {"(Modifier le statut)"}
                </span>
                <EditIcon style={{fontSize: "1.25rem"}}/>
            </PLAction>
        )
    }  

    return ( 
        <IDProgressLineContainer>
            {
                statusList.map((item, index) => (
                    <IDProgressLineItem 
                        color={getStatusColors(item.value, index)[0]} 
                        border={getStatusColors(item.value, index)[1]} 
                    >
                        <IDProgressLineLabel>{item.label}</IDProgressLineLabel>
                        <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                    </IDProgressLineItem>
                ))
            } 
            {actionButton}
            {
                showStatusModal ? (
                    <StatusModal
                        show={showStatusModal}
                        modalClosed={() => setShowStatusModal(false)}
                        statusVal={status}
                        editStatus={editStatus}
                    />
                ) : null
            }
        </IDProgressLineContainer>
        )      
    }
    
const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // mandatUid: state.mandat.admData.uid,
    // status : state.mandat?.admData?.status,
    // connectedUser : state.mandat?.admData?.connectedUser,
    // pdfFile: state.mandat.admData?.pdfFile || null,
    // admData: state.mandat.admData,
    // admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    // getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
