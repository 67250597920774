import React, { useState, useEffect } from 'react'
import Axios from '../../axios-proas';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { checkValidity, getNotyfObject } from '../../shared/utility';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { BtnOutlineAction, FormGroup, FormInput, FormLabel, FormSelect, Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { useTheme } from 'styled-components';
import { TelInput, UFBody, UFContainer, UsersContainer } from './UserForm.styled';
import { EIFormCol, EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../Entity/Entity.styled';
import EntitiesDropDown from '../../components/Entity/EntitiesDropDown/EntitiesDropDown';
import CloseIcon from '@mui/icons-material/Close';
import UsersTable from './UsersTable/UsersTable';
import Spinner  from 'react-bootstrap/Spinner'
// import EntitiesComponent from '../../component/UserForm/EntitiesComponent/EntitiesComponent';

function UserForm(props) {
    const [user, setUser] = useState({})
    const { userType, logout } = props
    const [entity, setEntity] = useState(null);
    const [errors, setErrors] = useState(null);
    const [origin, setOrigin] = useState(localStorage.getItem("origin"))
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    let location = useLocation();
    let entityUid = location.state.entityUid;
    let params = useParams()
    const navigate = useNavigate();
    const theme = useTheme()
    const [ifLL, setIfLL] = useState(false);
    const [users, setUsers] = useState([])
    const [delegationStatus, setDelegationStatus] = useState(false);
    const [idDelegationNow, setIdDelegationNow] = useState();
    const [saveClickHandlerStatus, setSaveClickHandlerStatus] = useState(false)

    useEffect(() => {
        if (params?.uid) {
            const userUid = params?.uid;
            Axios.get('/user/' + userUid + '/user').then((response) => {
                setUser(response?.data?.user);
                setIfLL(response?.data?.user?.ifLL)
                setIdDelegationNow(response?.data?.user?.idDelegation)
                setDelegationStatus(response?.data?.user?.idDelegation != null)
                // setDelegationStatusOriginal(response?.data?.user?.idDelegation != null)
                console.log(response?.data?.user)
            }).catch(err => {
                console.log(err);
            })
            Axios.get('/user//ownerUsers').then(res => {
                setUsers(res.data?.users);
            })
        } else {
            if (location?.state?.entityUid) {
                const entityUid = location.state.entityUid;
                Axios.get('/entity/' + entityUid + '/object').then((response) => {
                    setEntity(response.data?.entity);

                    let newUser = {}
                    if (response.data?.entity?.type == 'owner' || response.data?.entity?.type == 'client')
                        newUser.entities = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid, code: response.data?.entity?.code }]
                    // else if(response.data?.entity?.type=='supplier')
                    //     newUser.suppliers = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
                    // else if(response.data?.entity?.type=='client')
                    //     newUser.clients = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
                    newUser.type = response.data?.entity?.type
                    origin !== "fromClient" ? newUser.level = 'admin' : null
                    // newUser.level = 'standard'

                    console.log(newUser)

                    setUser(newUser)
                }).catch(err => {
                    console.log(err);
                })
            }
        }
    }, [location, params])


    const inputChangeHandler = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        let userTemp = {
            ...user,
            [name]: value
        }
        console.log(userTemp);
        setUser(userTemp)
    }

    const phoneInputChangeHandler = (value) => {
        let userTemp = {
            ...user,
            telephone_number: value
        }
        setUser(userTemp)
    }

    const secretInputChangeHandler = (e) => {
        let value = e.target.checked
        let userTemp = {
            ...user,
            secret: value
        }
        setUser(userTemp)
    }

    // const entitiesRadioChangeHandler = (name, value) => {
    //     let userTemp = {
    //         ...user,
    //         [name]: +value
    //     }
    //     setUser(userTemp)
    // }

    const entitiesChangeHandler = (entitiesList) => {
        let userTemp = {
            ...user,
            entities: entitiesList
        }
        console.log(userTemp)
        setUser(userTemp)
    }

    // const clientsChangeHandler = (clientsList) => {
    //     let userTemp = {
    //         ...user,
    //         clients: clientsList
    //     }
    //     setUser(userTemp)
    // }

    // const suppliersChangeHandler = (suppliersList) => {
    //     let userTemp = {
    //         ...user,
    //         suppliers: suppliersList
    //     }
    //     setUser(userTemp)
    // }

    const deleteEntityClickHandler = (entity) => {
        //console.log("je suis la")
        let userTemp = { ...user }
        if (user?.id && !entity?.new) {
            userTemp = {
                ...userTemp,
                entitiesToDelete: userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
            }
        }
        let userEntities = [...user?.entities]
        let entityIndex = user?.entities.findIndex(ent => ent.uid === entity.uid);
        if (entityIndex >= 0) {
            userEntities.splice(entityIndex, 1)
        }
        //console.log(userTemp)
        setUser({
            ...userTemp,
            entities: userEntities
        })
    }

    // const deleteClientClickHandler = (client) => {
    //     let userTemp = { ...user }
    //     if (user?.id && !client?.new) {
    //         userTemp = {
    //             ...userTemp,
    //             clientsToDelete: userTemp?.clientsToDelete ? [...userTemp?.clientsToDelete, client.uid] : [client.uid]
    //         }
    //     }
    //     let userClients = [...user?.clients]
    //     let clientIndex = user?.clients.findIndex(ent => ent.uid === client.uid);
    //     if (clientIndex >= 0) {
    //         userClients.splice(clientIndex, 1)
    //     }
    //     setUser({
    //         ...userTemp,
    //         clients: userClients
    //     })
    // }

    // const deleteSupplierClickHandler = (supplier) => {
    //     let userTemp = { ...user }
    //     if (user?.id && !supplier?.new) {
    //         userTemp = {
    //             ...userTemp,
    //             suppliersToDelete: userTemp?.suppliersToDelete ? [...userTemp?.suppliersToDelete, supplier.uid] : [supplier.uid]
    //         }
    //     }
    //     let userSuppliers = [...user?.suppliers]
    //     let supplierIndex = user?.suppliers.findIndex(ent => ent.uid === supplier.uid);
    //     if (supplierIndex >= 0) {
    //         userSuppliers.splice(supplierIndex, 1)
    //     }
    //     setUser({
    //         ...userTemp,
    //         suppliers: userSuppliers
    //     })
    // }

    const checkUserFormValidity = (user) => {
        let errors = []
        if (!user.hasOwnProperty('type') || !checkValidity(user?.type, { required: true })) {
            errors.push(t("masterData:error_userType", "Veuillez sélectionner un type pour l'utilisateur"));
        }
        if (!user.hasOwnProperty('level') || !checkValidity(user?.level, { required: true })) {
            errors.push(t("masterData:error_userRole", "Veuillez sélectionner un role pour l'utilisateur"));
        }
        if (!user?.id) {
            if (!user.hasOwnProperty('login') || !checkValidity(user?.login, { required: true, isEmail: true })) {
                errors.push(t("masterData:error_userEmail", "Le champ 'Email' est vide ou non valide"));
            }
        }
        if (showEntitiesListComponent() && +user?.accessOwner !== 1 && (!user.hasOwnProperty('entities') || user?.entities.length == 0)) {
            errors.push(t("masterData:error_userEntity", "Aucune entité interne n'a été sélectionée ! "));
        }
        /*
        if(showClientsListComponent() && +user?.accessClient !== 1 && (!user.hasOwnProperty('clients') || user?.clients.length == 0)){
            errors.push(t("masterData:error_userClients", "Aucun client n'a été sélectioné ! ")) ;
        }
        */
        // if(showSuppliersListComponent() && +user?.accessSupplier !== 1 && (!user.hasOwnProperty('suppliers') || user?.suppliers.length == 0)){
        //     errors.push(t("masterData:error_userSupplier", "Aucun fournisseur n'a été sélectioné ! ")) ;
        // }
        return errors;
    }

    const addUserclickHandler = () => {
        let checkErrors = checkUserFormValidity(user);
        if (checkErrors && checkErrors.length > 0) {
            setErrors(checkErrors)
        } else {
            setErrors(null)
            Axios.post('user', user).then(response => {
                if (response.data.hasOwnProperty('constraintError')) { 
                    notyf.error(response?.data?.constraintError)
                }else if (response.data.hasOwnProperty('error')) {
                    notyf.error(t("default:emailAlreadyExist", "Cet email existe déja"));
                }else if (response.data.hasOwnProperty('errorRole')) {
                    notyf.error(response?.data?.errorRole)
                    // notyf.error(t("default:us", "L'utilisateur a bien été ajouté"))
                    // setUser(null);
                    // navigate(-1);
                }else if (response.data.hasOwnProperty('success')) {
                    notyf.success(t("default:userAdded", "L'utilisateur a bien été ajouté"))
                    setUser(null);
                    navigate(-1);
                }
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }
    }

    const updateDelegation = () => {
        let delegationTemp = null
        if (user?.idDelegation == null) {
            delegationTemp = user?.delegation?.user
        } else if (user?.idDelegation != null && delegationStatus) {
            delegationTemp = user?.idDelegation
            console.log(delegationTemp)
        }
        Axios.put(`/user//delegation`, {
            idDelegation: delegationTemp,
            currentDelegation: idDelegationNow
        }).then(response => {
            if (response.data.hasOwnProperty('success')) {
                setSaveClickHandlerStatus(false)
                notyf.success(t("settings:userModified", "L'utilisateur a bien été modifié"))
                if (user?.role == 'superadmin') {
                    logout()
                    notyf.success(t("settings:reconnexion", "Vos autorisations ont changé, veuillez vous reconnecter."))
                } else {
                    setUser(null);
                    navigate(-1);
                }
            }
        }).catch(err => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }

    const editUserclickHandler = () => {

        let checkErrors = checkUserFormValidity(user);
        if (checkErrors && checkErrors.length > 0) {
            setErrors(checkErrors)
        } else {
            setErrors(null)
            let tempUser = user
            tempUser.entityUid = entityUid

            Axios.put(`user/${user?.uid}/user`, tempUser).then(response => {
                if (response.data.hasOwnProperty('success')) {
                    if ((user?.delegation?.user == undefined && idDelegationNow !== null && idDelegationNow !== user?.idDelegation) || (user?.delegation?.user !== undefined && user?.delegation?.user !== idDelegationNow)) {
                        updateDelegation();
                    }else{
                        setSaveClickHandlerStatus(false)
                        notyf.success(t("settings:userModified", "L'utilisateur a bien été modifié"))
                        if (user?.role == 'superadmin') {
                            logout()
                            notyf.success(t("settings:reconnexion", "Vos autorisations ont changé, veuillez vous reconnecter."))
                        } else {
                            setUser(null);
                            navigate(-1);
                        }
                    }
                }else if(response.data.hasOwnProperty('userLevelAlreadyExist')){
                    setSaveClickHandlerStatus(false)
                    notyf.error(t("settings:userM", "Un utilisateur active dèjà existant avec le même role"))
                }else if(response.data.hasOwnProperty('cantUpdateIfOnlyOne')){
                    setSaveClickHandlerStatus(false)
                    notyf.error(t("settings:userM", "Modification du rôle impossible : un site doit avoir un gérant et/ou un cogérant ou un contrat en cours est en attente de signature pour ce site"))
                }
                
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }

    }

    const saveClickHandler = () => {
        if (user?.id) {
            setSaveClickHandlerStatus(true)
            editUserclickHandler()
        } else {
            addUserclickHandler()
        }
    }

    const showEntitiesListComponent = () => {
        return (user?.type === "owner")
    }

    const delegationActive = (e) => {
        console.log(e.target.checked)
        if (!e?.target?.checked) {
            if (user?.idDelegation) {
                setDelegationStatus(false)
                user.idDelegation = null
            } else
                setUser({ ...user, delegation: { ...user.delegation, active: e?.target?.checked } })
        } else {
            if (user?.idDelegation) {
                setDelegationStatus(true)
            } else
                setUser({ ...user, delegation: { ...user.delegation, active: e?.target?.checked } })
        }
    }

    const radioChangeHandler2 = (e) => {
        const userId = e?.target?.value
        setUser({ ...user, delegation: { ...user.delegation, user: userId } })
    }

    // const showClientsListComponent = () => {
    //     return (user?.type === "owner" && user?.level === "standard")
    //         || (user?.type === "client")
    // }

    // const showSuppliersListComponent = () => {
    //     return (user?.type === "owner" && user?.level === "standard")
    //         || (user?.type === "supplier")
    // }

    return (
        <>
            <UFContainer>
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("global:userCard", "Fiche Utilisateur : ")}</HeaderLocation>
                        <HeaderTitle>{t("global:adminInformation", 'Informations Administratives')}</HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <BtnOutlineAction
                            color={theme.colors.primary}
                            border={theme.colors.primary}
                            onClick={() => navigate("/entity/" + entityUid, {
                                state: {
                                    tabIndex: "contacts",
                                }
                            })}
                        >
                            {t("global:back")}
                        </BtnOutlineAction>
                        <BtnOutlineAction
                            color={theme.colors.success}
                            border={theme.colors.success}
                            onClick={() => saveClickHandler()}
                            disabled={saveClickHandlerStatus}
                        >
                            {
                                saveClickHandlerStatus && <Spinner animation="border" variant="success" style={{marginRight:"10px"}}/>
                            }
                            {t("global:save")}
                        </BtnOutlineAction>
                    </HeaderActions>
                </Header>

                <UFBody>
                    {
                        errors && errors.length > 0 ? (
                            <div className="alert alert-danger mt-2" role="alert">
                                <ul>
                                    {
                                        errors.map(error => (<li key={error}>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                    {/* <EntFormSection>
                        <EntitiesDropDown
                            entitiesType={origin !== "fromClient" ? "owner" : "client"}
                            userEntities={user?.entities}
                            addEntitiesUser={entitiesChangeHandler}
                        />

                        <EntFormList>
                            {
                                user?.entities?.length > 0 ? user?.entities.map((entity) => {
                                    return <EntFormItem key={entity.uid} >
                                        <span>{entity.name + " - " + entity.code}</span>
                                        <CloseIcon style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={() => deleteEntityClickHandler(entity)} />
                                    </EntFormItem>

                                })
                                    : null

                            }

                            {
                                user?.clients?.length > 0 ? user?.clients.map(client => {
                                    return (
                                        <EntFormItem key={client.uid} >
                                            <span>{client.name + " - " + client.code}</span>
                                            <CloseIcon style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={() => deleteEntityClickHandler(client)} />
                                        </EntFormItem>

                                    )
                                })
                                    :
                                    null
                            }
                        </EntFormList>

                    </EntFormSection> */}
                    <EIFormContainer>
                        <EIFormCol>
                            <FormGroup>
                                <FormLabel htmlFor="first_name" >{t("userProfile:firstName")}</FormLabel>
                                <FormInput
                                    id="first_name"
                                    type="text"
                                    name="first_name"
                                    // placeholder={t("userProfile:firstName")}
                                    value={user?.first_name || ""}
                                    onChange={(e) => inputChangeHandler(e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="last_name" >{t("userProfile:lastName")}</FormLabel>
                                <FormInput
                                    id="last_name"
                                    type="text"
                                    name="last_name"
                                    // placeholder={t("userProfile:lastName")}
                                    value={user?.last_name || ""}
                                    onChange={(e) => inputChangeHandler(e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="login" >{t("global:email")}</FormLabel>
                                <FormInput
                                    id="login"
                                    type="text"
                                    name="login"
                                    // placeholder={t("global:email")}
                                    value={user?.login || ""}
                                    onChange={(e) => inputChangeHandler(e)}
                                    disabled={user?.id}
                                />
                            </FormGroup>
                            { ( user?.role == "superadmin" || +ifLL ) ?
                                <>
                                    <FormGroup style={{ justifyContent: 'flex-start', marginBottom: "10px" }}>
                                        <FormInput style={{ width: "50px", maxWidth: "50px", marginBottom: "6px" }}
                                            id="secret"
                                            type="checkbox"
                                            name="delegation"
                                            // placeholder={t("userProfile:firstName")}
                                            checked={user?.delegation?.active || (user?.idDelegation && delegationStatus)}
                                            onChange={(e) => delegationActive(e)}
                                        />
                                        <FormLabel htmlFor="first_name" >{t("userProfile:fdsqr", "Délégation signature")}</FormLabel>
                                        {
                                            (user?.idDelegation != null && delegationStatus) &&
                                            <span>: &nbsp;{user?.userRefdelegation}</span>
                                        }
                                    </FormGroup>
                                    {user?.delegation?.active &&
                                        <UsersContainer style={{ margin: "5px 10px 10px 11px" }}>
                                            < div className="row" style={{ background: "#efe8df", color: "#482D1C", borderBottom: "1px solid azure", width: "700px" }}>
                                                <div className="col-sm-1"></div>
                                                <div className="col-sm-3">Prénom</div>
                                                <div className="col-sm-3">Nom</div>
                                                <div className="col-sm-2">Rôle</div>
                                                <div className="col-sm-3">Nom Entité</div>
                                            </div>
                                            {
                                                <UsersTable
                                                    users={users}
                                                    radioChangeHandler2={radioChangeHandler2}
                                                />
                                            }
                                        </UsersContainer>
                                    }

                                </>
                                : null
                            }
                            {/* {+ifLL ?
                                <FormGroup>
                                    <FormLabel htmlFor="role" >{t("global:delegationSignature", "Délégation de signature")}</FormLabel>
                                    <FormSelect
                                        id="role"
                                        name="role"
                                        value={user?.role || ""}
                                        onChange={(e) => inputChangeHandler(e)}
                                    >
                                        <option value="signataire">{t("global:yes", "Oui")}</option>
                                        <option value="owner">{t("global:no", "No")}</option>
                                        <option value="cog">{t("global:cog", "Co-gérant")}</option>
                                    </FormSelect>
                                </FormGroup>
                                : null
                            } */}
                            <FormGroup style={{ flexWrap: 'nowrap'}}>
                                <FormLabel htmlFor="telephone_number" >{t("global:phoneNumberTmp")}</FormLabel>
                                <TelInput
                                    style={{width:"180px"}}
                                    localization={fr}
                                    country={'fr'}
                                    dropdownStyle={{ width: "auto" }}
                                    preferredCountries={['fr', 'us']}
                                    enableSearch={false}
                                    inputProps={{ name: "telephone_number" }}
                                    placeholder={"33 0 00 00 00 00"}
                                    value={user?.telephone_number?.substr(2) || ''}
                                    onChange={(phone) => phoneInputChangeHandler("00" + phone)}
                                />
                            </FormGroup>
                        </EIFormCol>
                        <EIFormCol>

                            {/* <FormGroup>
                                <FormLabel htmlFor="type" >{t("global:type")}</FormLabel>
                                <FormSelect 
                                    id="type"
                                    name="type"
                                    value={ user?.type || ""}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    disabled={ user?.id || entity }
                                >
                                    <option value="owner">{t("global:internal", "Internale")}</option>
                                    <option value="client">Client</option>
                                    <option value="supplier">Fournisseur</option>
                                </FormSelect>
                            </FormGroup> */}
                            {
                                origin !== "fromClient" ?
                                    <>
                                        <FormGroup>
                                            <FormLabel htmlFor="level" >{t("global:role", "Rôle")}</FormLabel>
                                            <FormSelect
                                                id="level"
                                                name="level"
                                                value={user?.level || ""}
                                                onChange={(e) => inputChangeHandler(e)}
                                            >
                                                <option value="admin">{t("global:signatory", "Signataire")}</option>
                                                <option value="standard">{t("global:Gdoc", "Gestionnaire documents")}</option>
                                                {/* <option value="cog">{t("global:cog", "Co-gérant")}</option> */}
                                            </FormSelect>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="first_name" >{t("userProfile:fir", "Document confidentiel")}</FormLabel>
                                            <FormInput
                                                id="secret"
                                                type="checkbox"
                                                name="secret"
                                                // placeholder={t("userProfile:firstName")}
                                                checked={+user?.secret}
                                                onChange={(e) => secretInputChangeHandler(e)}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="role" >{t("global:Level", "Level")}</FormLabel>
                                            <FormSelect
                                                id="role"
                                                name="role"
                                                value={user?.role || ""}
                                                onChange={(e) => inputChangeHandler(e)}
                                            >
                                                <option value="standard">{("Standard")}</option>
                                                <option value="admin">{("Admin")}</option>
                                                <option value="superadmin">{("Super Admin")}</option>
                                            </FormSelect>
                                        </FormGroup>
                                    </>
                                    :
                                    <FormGroup>
                                        <FormLabel htmlFor="level" >{t("global:role", "Rôle")}</FormLabel>
                                        <FormSelect
                                            id="level"
                                            name="level"
                                            value={user?.level || ""}
                                            onChange={(e) => inputChangeHandler(e)}
                                            // disabled={user?.level == "admin"}
                                        >

                                            <option value="">--- {t("global:selectRole", "Gérant")} ---</option>
                                            <option value="cog">{t("global:cog", "Gérant")}</option>
                                            <option value="admin">{t("global:manager", "Manager")}</option>
                                            {/* {user?.level == "admin" && <option value="admin">{t("global:manager", "Gérant")}</option>} */}
                                        </FormSelect>
                                    </FormGroup>
                            }
                        </EIFormCol>
                    </EIFormContainer>
                </UFBody>
            </UFContainer>
        </>
    )
}

const mapStateToProps = (state) => ({

})
const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserForm)

