import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { formatDate, getContractStatusWithKey, getTimeDate } from '../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { TEContainer, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

function StatusEvent({ isCreator, eventData, isLast, userType, delegationId }) {
    const [showContent, setShowContent] = useState(false)
    const { t } = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if (isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = (value) => {
        switch (value) {
            case 'SCHEDULED':
            case 'INPROGRESS':
                return theme.colors.warning;
                break;

            case 'COMPLETED':
                return theme.colors.success;
                break;

            case 'REFUSED':
                return theme.colors.danger;
                break;
            case 'REMINDER':
                switch (eventData?.comment) {
                    case '1/3':
                        return theme.colors.success;
                        break;

                    case '2/3':
                        return theme.colors.warning;
                        break;

                    case '3/3':
                        return theme.colors.danger;
                        break;

                }
                break;

            default:
                return theme.colors.success;
                break;

        }
    }

    const [listAutor, setListAutor] = useState([]);
    useEffect(() => {
        setListAutor(eventData?.autor?.split(";"), "creationDate")
    }, [])

    return (
        <>
            <TEContainer >
                <TEHeader disabled={true}>
                    <TEPreview>
                        <div className="row">
                            <div className="col-sm-6" style={{ display: "flex" }}>
                                <TEIcon borderBold={true} bdColor={getStatusColor(eventData?.snapshot)} color={getStatusColor(eventData?.snapshot)} >
                                    {eventData?.snapshot !== "REMINDER" ?
                                        <CheckIcon />
                                        :
                                        <NotificationsActiveIcon />
                                    }
                                </TEIcon>
                                {eventData?.type !== "STEP_REMINDER" ?

                                    <TETitle color={getStatusColor(eventData?.snapshot)} style={{ marginTop: "12px" }}>

                                        {t("contract:status") + " "}
                                        {userType == "owner" && eventData?.snapshot?.toLowerCase() == "scheduled" ?
                                            t("contractList:scheduledowner")
                                            :
                                            t("contractList:" + (eventData?.snapshot?.toLowerCase()))
                                        }
                                    </TETitle>
                                    :
                                    <TETitle color={getStatusColor(eventData?.snapshot)} style={{ marginTop: "12px" }}>
                                        {/* Relance {eventData?.comment} */}
                                        {t("contractList:" + (eventData?.comment))}
                                    </TETitle>

                                }

                            </div>
                            <div className="col-sm-6" style={{ marginTop: "12px" }}>
                                <TETime >
                                    {/* Envoyé par {owner} le {date} */}
                                    {/* signé par {user} le {date} */}
                                    {listAutor?.map(autor => {
                                        return (
                                            eventData?.type !== "STEP_REMINDER" ?
                                                <p>
                                                    {
                                                        ((eventData?.snapshot?.toLowerCase() !== "scheduled") ? t("mandat:signedBy") : t("contractList:scheduledowner", "Envoyé") + t("mandat:by")) +
                                                        (
                                                            autor.split(",")[1] || autor.split(",")[2] ?
                                                                (
                                                                    autor?.split(",")[1]
                                                                        ? autor.split(",")[1] + ' '
                                                                        : null
                                                                ) + (
                                                                    autor?.split(",")[2] ?
                                                                        autor.split(",")[2]
                                                                        : null
                                                                ) : eventData?.user?.login
                                                        ) + " " +
                                                        ((eventData?.userId == delegationId) ? ("(par délégation)") : " ") + " " +
                                                        t("mandat:on") +
                                                        formatDate(+autor?.split(",")[0]) +
                                                        t("mandat:at") +
                                                        getTimeDate(+autor?.split(",")[0])
                                                    }
                                                    {
                                                        eventData?.scheduledDate &&
                                                        <small> ( Date programmée : {formatDate(+eventData?.scheduledDate)} )</small>
                                                    }
                                                </p>
                                                :
                                                <p>
                                                    {
                                                        (
                                                            autor.split(",")[1] || autor.split(",")[2] ?
                                                                (
                                                                    autor?.split(",")[1]
                                                                        ? autor.split(",")[1] + ' '
                                                                        : null
                                                                ) + (
                                                                    autor?.split(",")[2] ?
                                                                        autor.split(",")[2]
                                                                        : null
                                                                ) : eventData?.user?.login
                                                        ) + " " +

                                                        t("mandat:on") +
                                                        formatDate(+autor?.split(",")[0]) +
                                                        t("mandat:at") +
                                                        getTimeDate(+autor?.split(",")[0])
                                                    }
                                                    {
                                                        eventData?.scheduledDate &&
                                                        <small> ( Date programmée : {formatDate(+eventData?.scheduledDate)} )</small>
                                                    }
                                                </p>
                                        )
                                    })}
                                </TETime>

                            </div>
                        </div>
                    </TEPreview>
                </TEHeader>
            </TEContainer>
        </>
    )
}

export default StatusEvent