import React, { useCallback, useState } from 'react';
import Axios from '../../../axios-proas';
import LOGO from '../../../assets/images/optical-center-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import './ForgottenPassword.css'
import { useTranslation } from 'react-i18next';
import { RSTBody, RSTitle, RST, FormInput } from '../ResetPassword/ResetPassword.Styled'
import { FormGroup, BtnOutlineLink, BtnAction, BtnOutlineAction, FormLabel } from '../../../styles/Common'
function ForgottenPassword() {
    const [login, setLogin] = useState('');
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [displayMessage, setDisplayMessage] = useState(false)


    const submitHandler = (e) => {
        e.preventDefault();
        checkLogin(login);
        // navigate('/');
        setDisplayMessage(true)
    }
    const checkLogin = useCallback((Login) => {
        setError(null)
        Axios.get('/user/' + login + '/sendResetMail').then(response => {
            setAlert(response.data.message)
        }).catch(error => {
            setError("authentication:unknown");
        })
    }, [login])



    let content = (<>

        <RSTBody>
            <RST >
                <BtnOutlineLink

                    to={"/"}
                    color="#fff"
                    border="#fff">
                    <img src={LOGO} alt="logo" style={{ maxWidth: "95px" }} />
                </BtnOutlineLink>
            </RST>
            {
                error && error.length > 0 ? (
                    <RSTitle className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                        <ul>
                            {
                                <span > {t(error)} </span>
                            }
                        </ul>
                    </RSTitle>
                ) : null
            }

            <FormGroup>
                <FormLabel htmlFor='login'>{t("authentication:enterLogin")}</FormLabel>
                {/* <label className="signin__formLabel" htmlFor='username'>{t("user:username")}</label> */}
                <FormInput id='login' className="signin__formInput" type="email" name="email" onChange={(e) => setLogin(e.target.value)} value={login} required autoFocus />
            </FormGroup>
            {!displayMessage &&
                <BtnAction color="#83abcb"
                    border="#83abcb"
                    onClick={(e) => submitHandler(e)} >
                    {t("global:validate")}
                </BtnAction>
            }

            {(displayMessage && error.length == 0) ?
                <FormGroup style={{ color: 'green', fontSize: '16px', justifyContent: 'center', marginTop: '20px' }}>** Un email de réinitialisation viens de vous être envoyé</FormGroup>:null
            }

        </RSTBody>
    </>)


    if (alert) {
        content = (<div style={{ textAlign: "center" }}>
            <h4>{t(alert)}</h4>
            <Link to="/">{t("authentication:signIn")}</Link>
        </div>)
    }

    return (
        <div className="forgotPassword__container">

            {content}
        </div>
    )
}

export default ForgottenPassword
