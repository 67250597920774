import React, { useState, useEffect, useLayoutEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CIUsersLink } from './ContractItem.styled';
import { TD } from '../../../styles/Table.styled';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import { formatDate, showColumn } from '../../../shared/utility';
import Axios from '../../../axios-proas';
import { useTranslation } from 'react-i18next';
import { Notyf } from "notyf";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import TimerIcon from '@mui/icons-material/Timer';
import { Link } from 'react-router-dom';
import { getTime } from 'date-fns';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';


function ContractItem(props) {
    const { contractsData, handleContractVisibility, handleReadContract, contractData, userRole, userLevel, columns, getContracts, reminder, clientAndNbrelances } = props;
    const { t } = useTranslation();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const notyf = new Notyf();
    const [entitiesArray, setEntitiesArray] = useState(false);
    const currentDate = new Date();
    const [sender, setSender] = useState();
    const [clientAndNbrelance, setClientAndNbrelance] = useState();
    const [showSpinner, setShowSpinner] = useState(false)



    const ShowEntities = (a) => {
        let array = contractData.filter(b => b.id == a)[0].entities
        let words = array.split(',')
        setEntitiesArray(words)
        var x = document.getElementById(a);
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    const formatEntities = (entities) => {
        return entities.split(',')
    }

    const deleteWithClickHandler = (Uid) => {
        setShowSpinner(true)
        Axios.delete(`contract/${Uid}/deleteWith`).then(response => {
            getContracts()
            setConfirmationModalShow(false)
            notyf.success(t("notyf:successContractDeletion"))
            setShowSpinner(false)

        }).catch(response => {

            notyf.error("generatedErrorMessage")
            setShowSpinner(false)

        })
    }

    // const getContractSender = (uid) => {
    //     Axios.get("/contract/" + uid + "/senderByUid").then(response => {

    //         setSender(response?.data?.res.sender);
    //         console.log(response?.data.res.sender);

    //     })
    // }

    // useEffect(() => {
    //     getContractSender(contractData.uid);
    // }, []);



    useEffect(() => {
        let clientAndNbrelance = clientAndNbrelances?.filter(row => row?.id == contractData?.id)
        setClientAndNbrelance(clientAndNbrelance?.[0])
        // console.log(contractData)
    }, []);


    return (
        <>
            <tr>
                {userLevel == "owner" ?
                    // <TD style={{ display: !showColumn(columns, "visible") && 'none' }}>
                    //     (
                    //     {contractData.visible == 1 ?
                    //         <VisibilityIcon className="contract_visibility" onClick={() => handleContractVisibility(contractData.id, 0)} />
                    //         : <VisibilityOffIcon className="contract_visibility" onClick={() => handleContractVisibility(contractData.id, 1)} />
                    //     })
                    // </TD>
                    null
                    // <TD style={{ display: !showColumn(columns, "visible") && 'none' }}>
                    //     (
                    //     {contractData.visible == 1 ?
                    //         <VisibilityIcon className="contract_visibility" onClick={() => handleContractVisibility(contractData.id, 0)} />
                    //         : <VisibilityOffIcon className="contract_visibility" onClick={() => handleContractVisibility(contractData.id, 1)} />
                    //     })
                    // </TD>
                    :
                    <TD style={{ display: !showColumn(columns, "lu") && 'none' }}>
                        (
                        {contractData?.firstAccessDate != null ?
                            <VisibilityIcon />
                            : <VisibilityOffIcon />
                        })
                    </TD>
                }
                <TD style={{ display: !showColumn(columns, "creationDate") && 'none' }}>
                    {formatDate(+contractData?.creationDate)}
                </TD>
                <TD style={{ display: !showColumn(columns, "title") && 'none' }}>
                    <CIUsersLink
                        onClick={() => handleReadContract(contractData?.id)}
                        to={"/contractForm/" + contractData?.uid}>
                        {contractData?.title}
                    </CIUsersLink>
                </TD>
                <TD style={{ display: !showColumn(columns, "fileType") && 'none' }}>
                    {t("contract:" + contractData?.fileType)}
                </TD>
                <TD style={{ display: !showColumn(columns, "freeType") && 'none' }}>
                    {contractData?.freeType}
                </TD>
                {/* {userLevel !== "owner" ?
                    <TD style={{ display: !showColumn(columns, "sender") && 'none' }}>
                        {sender}
                    </TD>
                    : null
                } */}



                <TD style={{ display: !showColumn(columns, "recipients") && 'none' }}>
                    {contractData.recipients ? contractData.recipients : contractData?.diversName}
                </TD>
                <TD style={{ display: !showColumn(columns, "status") && 'none' }}>
                    {userLevel == "owner" && contractData?.status == "SCHEDULED" ?
                        t("contractList:" + (contractData?.status.toLowerCase()) + "owner")
                        :
                        t("contractList:" + (contractData?.status.toLowerCase()) + "Single")
                    }
                    {+contractData?.movedToSafe == 1 && contractData?.status == "COMPLETED" ?
                        <NoEncryptionIcon style={{ color: "gray" }} />
                        : null
                    }
                </TD>

                <TD style={{ display: !showColumn(columns, "nextSigner") && 'none' }}>
                    {
                        (contractData?.nextSigner)
                    }
                </TD>

                <TD style={{ display: !showColumn(columns, "nbRelance") && 'none' }}>
                    {
                        (contractData?.nbRelance)
                    }
                </TD>
                <TD style={{ display: !showColumn(columns, "dateRelance") && 'none' }}>
                    {contractData?.nbRelance !== '0' &&
                        (contractData?.dateRelance)
                    }
                </TD>

                <TD style={{ display: !showColumn(columns, "operation") && 'none' }}>
                    <div style={{ display: "flex" }}>
                        <Link to={"/contractForm/" + contractData?.uid}>
                            <SearchIcon
                                onClick={() => handleReadContract(contractData?.id)}
                            />
                        </Link>

                        {(contractData?.status !== 'COMPLETED') ?
                                (
                                    (+contractData?.step == 0 && (+contractData?.creator || userRole == 'superadmin'))  
                                    || 
                                    ((+contractData?.step > 0  && +contractData?.step < (+contractData?.nbTags+1)) && userRole == 'superadmin')  
                                ) ?
                                    <>
                                        <DeleteForeverIcon onClick={() => setConfirmationModalShow(true)}
                                            style={{ color: 'red', cursor: "pointer" }} />
                                        <ConfirmationModal
                                            show={confirmationModalShow}
                                            showSpinner={showSpinner}
                                            modalClosed={() => setConfirmationModalShow(false)}
                                            confirm={() => deleteWithClickHandler(contractData?.uid)}
                                            cancel={() => setConfirmationModalShow(false)}
                                            title={"Confirmation"}
                                            message={t("confirmationModal:message") + contractData?.title + " ?"}
                                        />
                                    </>
                                : null
                            :null
                        }


                    </div>
                </TD>
            </tr>
        </>
    );
}


export default ContractItem;
