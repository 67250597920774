import React, { useMemo } from 'react'
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { AppBody, AppContainer, AppContent } from '../../../styles/App.styled';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import { connect } from 'react-redux';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '../../Menu/Menu'
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import Documentation from '../../Documentation/Documentation';
import Home from '../../Home/Home';
import ClientHome from '../../ClientHome/ClientHome'
import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielClient from '../../ReferentielClient/ReferentielClient';
import Signature from '@mui/icons-material/BorderColor';
import Campaigns from '../../Campaigns/Campaigns';
import CampaignForm from '../../CampaignForm/CampaignForm';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import GestionContract from '../../GestionContract/GestionContract';
import ContractForm from '../../ContractForm/ContractForm';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Profile from '../../UserProfile/UserProfile';

function ClientRoot({ entityUid, userUid, userType }) {
  const { t } = useTranslation();
  const menuItems = useMemo(() => ([
    { isLink: true, label: t("menu:home", "Accueil"), pathname: '/', icon: <HomeIcon /> },
    { isLink: true, label: t("menu:clients", "Sites"), pathname: `/referentielClient`, icon: <BusinessIcon /> },
    { isLink: true, label:  t("global:gestionContract", "Gestion contrat"), pathname: '/gestionContract', icon: <Signature /> },
    // { isLink: true, label: 'Suivi des factures', pathname: '/invoices', icon: <DescriptionIcon /> },
    // { isLink: true, label: 'Documentation', pathname: '/documentation', icon: <ArticleIcon /> }

  ]), [t])
  return (
    <>
      <AppContainer>
        <Header />
        <AppBody>
          <Menu items={menuItems} />
          <AppContent>
            <Routes>
              <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
              <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail />} />
              <Route path="/referentielClient" element={<ReferentielClient entityFilter={"client"} />} />
              <Route path="/entity/:uid" element={<Entity />} />
              <Route path="/Profile" element={<Profile userUid={userUid} userType={userType}/>} />
              <Route path="/userForm/:uid" element={<UserForm userType={userType} />} />
              <Route path="/userForm" element={<UserForm  userType={userType}/>} />
              <Route path="/invoices" element={<SuiviInvoices entityFilter={"client"} />} />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/editPassword" element={<EditPassword />} />
              <Route path="/" element={<ClientHome />} />
              <Route path="/gestionContract" element={<GestionContract />} />
              <Route path="/contractForm" element={<ContractForm />} />
              <Route path="/contractForm/:uidContract" element={<ContractForm />} />
              <Route path="/suiviCampagnes" element={<Campaigns />} />
              <Route path="/newCampaign" element={<CampaignForm />} />
              <Route path="/newCampaign/:uid" element={<CampaignForm />} />
            </Routes>
          </AppContent>
        </AppBody>
      </AppContainer>
    </>
  )
}


const mapStateToProps = (state) => ({
  userType: state.auth.userType,
  login: state.auth.login,
  entityUid: state.auth.entityUid,
  userUid : state.auth.userUid
})

const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(ClientRoot)