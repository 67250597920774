import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2'

// export const SpinnerContainer = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 1rem 0;
// `;
// export const EIFormLabel = styled.label`
//     margin: 0;
//     padding: 0;
//     color: ${({theme}) => theme.colors.secondary};
//     font-size:0.9rem;
// `;
// export const EIFormGroup = styled.div`
//     width: 100%;
//     display: flex;
//     padding-bottom: 0.25rem;
//     margin-bottom: 1rem;
//     border-bottom: 2px solid #F5FBFF;
// `
// export const EIFormGroupChecked = styled.div`
//     width: 100%;
//     display: flex;

// `
// export const Input = styled.input`
//     border: none;
//     outline: none;
//     width: 98%;
//     text-align:end;
//     color: ${({color}) => color || "#482D1C"};

//     font-size:1.0rem;
//     &:hover {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }

//     &:focus {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }
// `;
// export const InputCheckbox = styled.input`
//     border: none;
//     outline: none;
//     margin-top: 3px;
//     font-size:1.3rem;
//     &:hover {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }

//     &:focus {
//         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
//     }
// `;




// export const ISContainer = styled.div`
//     width: 100%;
//     border: 1px solid ${({theme}) => theme.colors.secondary};
//     border-radius: 18px;
//     padding: 2rem 1.5rem;
//     height: 400px;
//     display: flex;
//     flex-direction: column;
// `;


export const CFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CFHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const CFHeaderWrapperPart = styled.div`
    width: 100%;
    hight: 100%;
    display: flex;
    flex-direction: column;    
    border-bottom: 4px solid #F5FBFF;
`;

export const CFHeader = styled.div`
    width: 100%;
    @media (min-width: 800px) {
        display: flex;
    }
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
`;

export const CFHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
`

export const CFHeaderInfo = styled.div`
    display: grid;
    @media (min-width: 800px) {
        margin-right: 120px;
    }
    font-size: 14px;
`

export const CFHeaderInfoText = styled.span`
    font-size: 13px;
    color: #5e5b5b;
`

export const CFHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
    @media (max-width: 800px) {
        margin-top: -90px;
    }
`

export const CFHeaderProgressBar = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    @media (min-width : 800px) {
        justify-content: flex-start;
    }
    @media (max-width: 800px) {
        justify-content: space-around;
        margin-top: 40px;
    }
`

export const CFBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
`

export const CFSection = styled.div`
    width: 100%;
    @media (min-width: 800px) {
        display: flex;
    }
    justify-content: space-between;
    align-items: flex-start;
`

export const CFEditorWrapper = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
`

export const CFTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #000000;
    margin-bottom: 2rem;
`

export const CFFormWrapper = styled.div`
    width: 48%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.9rem;
    /* align-items: space-between; */
    @media (max-width : 800px) {
        width: 100%;
        margin-bottom: 20px;
    }
`
export const Wrapper = styled.div`
    position: relative;
    width: 400px;
    height: 200px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

export const WrapperCanvas = styled.div`
    position: relative;
    width: 308px;
    height: 141px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: rgb(0, 121, 188);   
    border : 1px solid aliceblue;      
`

export const Img = styled.div`
    position: absolute;
    left: 0;
    top: 0;
`

export const Signaturepad = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width:400px;
    height:200px;
`

export const CFFormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`

export const DPContainer = styled.div`

    margin-bottom: 0.5rem;

    & input {
        padding: 0.5rem !important;
        color: ${({ theme }) => theme.colors.secondary};
    }

    & button {
        color: ${({ theme }) => theme.colors.secondary};
    }

    & fieldset {
        border-color: ${({ theme }) => theme.colors.secondary};
    }

    & label {
        color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus-within {
        & input {
            color: ${({ theme }) => theme.colors.primary};
        }
        & button {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export const ToggleLabel = styled.label`
    margin: 0;
    padding: 0;
    color: ${({ active, theme }) => active ? theme.colors.success : theme.colors.secondary};
`;

export const ToggleIcon = styled.div`
    cursor: pointer;
    color: ${({ active, theme }) => active ? theme.colors.success : theme.colors.secondary};
`;

export const AttachementsContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`

export const AddRecipientsBtn = styled.button`
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 0.125rem 1rem;
    border-radius: 8px;
    background-color: white;
    font-size: 0.8rem;
    display: flex;
    
    &:hover {
        background-color: ${props => props.theme.colors.primary};
        color: #ffffff;
    }
`;

export const CFFormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`;

export const CFFormLabel = styled.label`
    cursor: pointer;
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.secondary};

    &:hover {
        color: orange;
    }
`;

export const CFFormClearIcon = styled.label`
    cursor: pointer;
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.secondary};

    &:hover {
        color: rgb(0, 121, 188);
    }
`;
export const CFFormGroupPart = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #F5FBFF;
`;

export const DocVisu = styled.div`
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
`;
export const NoContent = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    justify-content: center;
    height: 700px;
    font-size: 1rem;
    border-radius: 18px;
    color: ${({ theme }) => theme.colors.secondary};
    
`;
export const NDContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
   
`
export const EIFormGroupChecked = styled.div`
    width: 100%;
    display: flex;

`
export const InputCheckbox = styled.input`
    border: none;
    outline: none;
    margin-top: 3px;
    font-size:1.3rem;
    &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }

    &:focus {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
`;
export const Input = styled.input`
    border: none;
    outline: none;
    width: 98%;
    text-align:end;
    color: ${({ color }) => color || "#482D1C"};
    
    font-size:1.0rem;
    &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }

    &:focus {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
`;
export const EIFormLabel = styled.label`
margin: 0;
padding: 0;
color: ${({ theme }) => theme.colors.secondary};
font-size:0.9rem;
`;
export const EIFormGroup = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #F5FBFF;
`
export const ISContainer = styled.div`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 18px;
    padding: 2rem 1.5rem;
    
    display: flex;
    flex-direction: column;
`;
export const EntitiesContainer = styled.div`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 8px;
    margin-bottom:4px;
    display: flex;
    border: 1px solid #482D1C;
    max-height: 150px;
    overflow: auto;

`;
export const ToggleDiv = styled.div`
display: flex;
width: 100%;
justify-content: end;
`;

export const NDCol6 = styled.div`
    width: 48%;
    margin-top: 1rem;
   
`

export const NDrow = styled.div`
 display: flex;
    width: 100%;
    justify-content: space-between;
   
`

export const CSContainer = styled.div`
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 1.5rem 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const CSNavContainer = styled.div`
    width: 100%;
    margin-bottom: 2rem;
`

export const CSNavContent = styled.div`
    width: 100%;
    height: 90%;
    flex: 1;
`
export const TelInput = styled(PhoneInput)`
    width: 180px;

    input {
        width: 100% !important;
    }
`