import styled from 'styled-components';

export const RCPContainer = styled.div`
    // display: flex;
    // align-items: center;
    // max-width: 160px;
    // border: 1px solid ${({ theme }) => theme.colors.secondary};
    // border-radius: 15px;
    padding: 0 00.25rem;
    margin-left: 0.8rem;
`

export const RCPValue = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    // width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.secondary};
`

export const RCPIcon = styled.div`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.secondary};
    
    &:hover {
        color: ${({ theme }) => theme.colors.danger};
    }
`