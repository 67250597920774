import React, { useEffect } from 'react';
import { HeaderContainer, HeaderLinks, HLang, Hlink, HTheme } from './Header.styled';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import LanguageList from '../LanguageList/LanguageList';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

function Header(props) {
    const { logout, login, setAppTheme, currentTheme, userUid, userType } = props;
    const { t } = useTranslation();

    const toggleTheme = () => {
        if (currentTheme === 'light') {
            setAppTheme('dark');
        } else {
            setAppTheme('light');
        }
    }

    return (
        <>
            <HeaderContainer>
                <HeaderLinks>
                    <Hlink
                        to={{ pathname: "/Profile/" }}
                    >
                        <PeopleOutlineIcon
                            style={{ fontSize: "0.8rem" }}
                        />
                        <span>{login}</span>
                    </Hlink>
                    <Hlink to="/editPassword" >
                        <LockIcon
                            style={{ fontSize: "0.8rem" }}
                        />
                        <span>{t("global:changePwd")}</span>
                    </Hlink>
                    <HLang>
                        <LanguageList />
                    </HLang>
                </HeaderLinks>

                <Hlink
                    to={{ pathname: "/" }}
                    lcolor={"#EE5A5A"}
                    onClick={() => logout()}
                >
                    <LogoutIcon
                        style={{ fontSize: "0.8rem" }}
                    />
                    <span>{t("global:logout")}</span>
                </Hlink>
                {/* <HTheme onClick={() => toggleTheme()} isActive={currentTheme === "dark"} >
                {
                    currentTheme === "dark" ?
                    <ToggleOffIcon />
                    :
                    <ToggleOnIcon />
                }
                <span>{t("global:nightmode")}</span>
            </HTheme> */}

            </HeaderContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        currentTheme: state.app.theme,
        isAuthenticated: state.auth.token !== null,
        login: state.auth.login,
        userUid: state.auth.userUid,
        userType: state.auth.userType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAppTheme: (theme) => dispatch(actions.appSetTheme(theme)),
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
