import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CampContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CampHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const CampHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const CampHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
`

export const CampHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const CampBody = styled.div`
    width: 100%;
`;

export const CampListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Table = styled.table`
    width: 100%;
    /* height: 100%; */
    table-layout: fixed;

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: auto;
    }
`;


export const TD = styled.td`
    padding: 0.25rem;

    & > span {
        display: block;
        width: 100%;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const TBody = styled.tbody`
    width: 100%;
    font-size: 0.9rem;
    color: ${({theme}) => theme.colors.text};

    tr {
        border-bottom: 0.0rem solid white;
    }

    & ${TD} {
        width: 100%;
        vertical-align: middle;
        height: ${({height}) => height ? height : '100%'};
    }
`;

export const GCTableAction = styled.div`
    display: flex;
    justify-content: space-between ;
    align-items: center;
    height: 50px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid #D9E1E7;
    border-radius: 16px;
    color: #D9E1E7;
    cursor: pointer;
    font-size: 0.9rem;
    background-color: ${({active, theme}) => active ? theme.colors.btnHover : '#FFFFFF'};

    & span {
        color: ${({color}) => color || 'black'};
        margin: 0 0.5rem 0 0;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.btnHover};
    }
`;