import React, { useEffect, useState } from 'react'
import Axios from '../../../axios-proas';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index'

import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';

import MailIcon from '@mui/icons-material/Mail';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Card from '../Card/Card';

function StatHeader(props) {
    const [totalDocs, setTotalDocs] = useState(0);
    const [totalYearDocs, setTotalYearDocs] = useState(0);
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [notViewedCount, setNotViewedCount] = useState(0);
    const [viewedCount, setViewedCount] = useState(0);
    const [dateInterval, setDateInterval] = useState(null)
    const [startDate, setStartDate] = useState(0)
    const [endDate, setEndDate] = useState(0)
    const { t } = useTranslation();
    const [value, setValue] = useState('annee');

    const [KPIs, setKPIs] = useState({
        totalSent: '',
        totalSigned: '',
        totalNotSigned: '',
        totalDocsForOwner: '',
        countDocumentsWaitting: '',
        totalNotSignedByEntity: [],
        totalNotSignedByEntityRelaunched: [],
        avgDays: '',
        avgDaysEntities: []
    });

    useEffect(() => {
        const date = new Date();
        var defaultStartDate = new Date();

        const startYear = new Date(date.getFullYear() + "").getTime();
        const endYear = new Date(date.getFullYear() + 1 + "").getTime();
        var d = defaultStartDate.getDate();
        defaultStartDate.setMonth(date.getMonth() - 1);
        if (defaultStartDate.getDate() != d) {
            defaultStartDate.setDate(0);
        }
        defaultStartDate.setHours(0, 0, 0, 0);
        const startDate = dateInterval?.startDate || defaultStartDate.getTime();
        const endDate = dateInterval?.endDate || new Date().getTime();

        let query = '/contract//clientStat?yearInterval=' + startYear + ":" + endYear + '&dateInterval=' + startDate + ":" + endDate;
        Axios.get(query).then((response) => {
            setTotalDocs(response.data.totalDocs);
            setTotalYearDocs(response.data.totalYearDocs);
            setNotViewedCount(response.data.notViewedDocs);
            setViewedCount(totalDocs - notViewedCount);
            setStartDate(startDate);
            setEndDate(endDate);
            //setTotalPurchases(response.data.totalPurchases);
        })

        Axios.get("/contract//statKPIs", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            setKPIs(res?.data)
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }, [dateInterval])

    const handleFilters = (dateInterval, clientFilters) => {
        setDateInterval(dateInterval);
    }

    return (
        <>
            <Card
                value={totalDocs}
                icon={<DescriptionIcon style={{ color: "#0090FF" }} />}
                title={t("dashboard:contractsTotal", "Total de contrats")}
            />
            {/* <Card
                value={KPIs.countTotalNotSignedByClient}
                icon={<DescriptionIcon style={{ color: "#0090FF" }} />}
                title={t("KPIs:totalNotSigned", "Nombre de documents en attente de signature")}
            />
            <Card
                value={KPIs.countTotalSignedByClientButNotFinished}
                icon={<DescriptionIcon style={{ color: "#0090FF" }} />}
                title={t("KPIs:waitting", "En attente de signature par l'autre partie")}
            /> */}
            {/* <Card value={totalPurchases +"€"} 
                  title="Total des achats"
                  icon={<DateRangeIcon style={{color: "#0090FF"}} />} 
                  dateStart={startDate}
                  dateEnd={endDate}  
                  handleFilters={handleFilters} 
                  isClient={true}  
            /> */}
            <Card
                value={notViewedCount}
                icon={<MailIcon style={{ color: "#0090FF" }} />}
                title={t("dashboard:unreadContract", "Contrats non lus")}
                progress={((notViewedCount * 100) / totalDocs).toFixed(2)}
            />
            {/* <Card value={totalPurchases + "€"}
                title="Total des achats"
                icon={<DateRangeIcon style={{ color: "#0090FF" }} />}
                dateStart={startDate}
                dateEnd={endDate}
                handleFilters={handleFilters}
                isClient={true}
            /> */}
            {/* <div className="row">
            <StatHeaderElementU value={totalDocs} icon={<DescriptionIcon />} progress='100' item="Total de documents" percentage="" textColor="#112732" containerBGColor="white" />
            <StatHeaderElementU value={totalYearDocs} icon={<DescriptionIcon />} progress='100' item="Total de documents de cette année" percentage="" textColor="white" containerBGColor="#5243ac" />
            
            <StatHeaderElementSales value={totalPurchases + " €"} item="Total des achats" dateStart={startDate} dateEnd={endDate} handleFilters={handleFilters} isClient={true} />
            <StatHeaderElementU value={notViewedCount} icon={<MailIcon />} progress={((notViewedCount * 100) / totalDocs).toFixed(2)} item="Documents non lus" percentage={((notViewedCount * 100) / totalDocs).toFixed(2) + "%"} textColor="white" containerBGColor="#112732" />
        </div> */}
        </>
    )
}

export default StatHeader
