import React, { useCallback, useEffect, useState } from 'react'
import './RepertoireModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import EntitiesTable from './EntitiesTable/EntitiesTable';

function RepertoireModal({ handleClose, show, addRecipientsHandler, selectEntity, selectedEntities, currentEntitiesType, entitiesType, isActiveClient, selectEntityClient, selectEntitySupplier }) {
    const { t } = useTranslation();
    const [entities, setEntities] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null)

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            type: entitiesType
        }
        Axios.get('/client//modalEntities', {
            params: filter
        })
            .then(res => {
                setEntities(res.data?.result)

            })
    }, [searchQuery, entitiesType])


    useEffect(() => {
        
            getEntitiesList()
        
    }, [searchQuery, entitiesType])

    const addClickHandler = () => {
        addRecipientsHandler(selectedEntities)
        handleClose()
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                    <Modal.Title>{t("repertoireModal:addRecipients")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="repertoireModal__body" >
                    {/* <div className="repertoireModal__entitiesHeader_container">
                        <div className="repertoireModal__entityType">
                            <label for="entityType">Type : </label>
                            <select
                                className="form-control form-select"
                                value={entitiesType}
                                onChange={(e) => currentEntitiesType(e)}
                            >
                                <option value="client">Client</option>
                                <option value="supplier">{t("repertoireModal:supplier")}</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="repertoireModal__entities_container">
                        <div className="repertoireModal__entities_header">
                            <div class=" input-group input-group-sm">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder={t("repertoireModal:search")}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    value={searchQuery || ''}
                                />
                                <div class="input-group-append" >
                                    <span class="input-group-text" id="basic-addon2"><SearchIcon /> </span>
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <p className="productsModal_totalItems">{selectedEntities?.length} entreprise(s) séléctionnée(s)</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="selectAllRegies" onChange={(e) => selectAllEntities(e)} />
                                    <label class="form-check-label" for="selectAllRegies" >
                                        Selectionner tout
                                    </label>
                                </div>
                            </div> */}
                        </div>
                        <div className="repertoireModal__entities_list">
                            {
                                <EntitiesTable
                                    entities={entities}
                                    selectedEntities={selectedEntities}
                                    selectEntity={selectEntity}
                                    type={isActiveClient ? "client" : "supplier"}
                                />
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('repertoireModal:cancel', "Annuler")}
                    </Button>
                    <Button variant="primary" onClick={() => addClickHandler()}>
                        {t('repertoireModal:save', "Sauvegarder")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default RepertoireModal
