import React, { useMemo, useState } from 'react';
import { formatDate } from '../../../shared/utility';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import StatusProgressLine from '../StatusProgressLine/StatusProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from './CampaignItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal'
import Axios from '../../../axios-proas'
import { Notyf } from "notyf";
function CampaignItem({ campaign, getCampaigns }) {
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const notyf = new Notyf();

    const deleteCompagneClickHandler = (Uid) => {

        Axios.delete(`campaign/${Uid}/deleteCompagne`).then(response => {
            getCampaigns()            
            setConfirmationModalShow(false)
            notyf.success("La compagne a bien été supprimé")
        }).catch(response => {

            notyf.error("generatedErrorMessage")
        })
    }
    return (
        <>
            <CIContainer>
                <CITitle>
                    Campagne de {monthList[new Date(+campaign?.campaignDate).getMonth()]?.toLowerCase()}
                </CITitle>
                <CIContent>
                    <CIData>
                        <DateProgressLine
                            creationDate={+campaign?.creationDate}
                            campaignDate={+campaign?.campaignDate}
                            endDate={+campaign?.endDate}
                        />
                        <StatusProgressLine
                            status={campaign?.status}
                        />
                    </CIData>
                    <CIActions>
                        <CIUsersLink
                            to={`#`}
                        >
                            {campaign?.recipients?.split(',').length}
                            <GroupIcon style={{ fontSize: "1.2rem", marginLeft: "2px" }} />
                        </CIUsersLink>

                        <CIDeleteBtn onClick={() => setConfirmationModalShow(true)}>
                            Supprimer
                        </CIDeleteBtn>


                        <CIUsersLink
                            style={{ marginLeft: "5px" }}
                            color="#fff"
                            border="#FFF"
                            to={"/newCampaign/" + campaign?.uid}

                        > Voir  <ArrowRightIcon />

                        </CIUsersLink>


                    </CIActions>
                </CIContent>

            </CIContainer>
            <ConfirmationModal
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => deleteCompagneClickHandler(campaign?.uid)}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation de suppression"}
                message={`Êtes-vous sûr de vouloir supprimer cette compagne ?`}
            />
        </>
    );
}

export default CampaignItem;
