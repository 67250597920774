import React, { useRef, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { TD } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import { UserStatus } from '../../../containers/UsersManagement/UsersManagement.styled';

function TableBody(props) {
    const {
        data,
        selectedUsers,
        selectUserRow,
        unselectUserRow,
        disableUser,
        enableUser,
        deleteUser,
        userType,
        entityUid,
        userRole,
        blocUsersUpdates,
        blocUserManagerUpdates
    } = props;
    let textRef = useRef();
    const [origin, setOrigin] = useState(localStorage.getItem("origin"))
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [confirmationModalShow2, setConfirmationModalShow2] = useState(false);
    const [userToDisable, setUserToDisable] = useState(null)
    const { t } = useTranslation();
    const theme = useTheme();
    const [modalMessage, setModalMessage] = useState();
    const navigate = useNavigate();

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.', ' ')
    }

    const getSelectedRowValue = (userUid) => {
        let isSelected = selectedUsers.findIndex(uid => uid === userUid) >= 0;
        // console.log(selectedUsers)
        // console.log(isSelected)
        return isSelected
    }
    const selectRowHandler = (e) => {
        let uid = e.target.name;
        let val = e.target.checked;
        if (val)
            selectUserRow(uid)
        else
            unselectUserRow(uid)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const disableUserClickHandler = (userUid) => {
        setUserToDisable(userUid);


        setModalMessage(t("global:userActivation", "Êtes-vous sûr de vouloir désactiver cet utilisateur ?"));
        disableUser(userUid)

    }

    const deleteUserClickHandler = (userUid) => {
        setUserToDisable(userUid);

        setModalMessage(t("global:userDelete", "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"));
        deleteUser(userUid)
        // navigate(0);

    }



    const getTypeName = (type) => {
        switch (type) {
            case "owner":
                return "internal"
                break;
            case "client":
                return "client"
                break;
            case "supplier":
                return "Fournisseur"
                break;
            default:
                break;
        }
    }

    const getLevelName = (level) => {
        if (origin == "fromOwner") {
            switch (level) {
                case "admin":
                    return t("global:signatory", "Signataire")
                    break;
                case "standard":
                    return t("global:gestDocLevel", "Gestionnaire")
                    break;
                default:
                    break;
            }
        } else {
            switch (level) {
                case "admin":
                    return t("global:manager", "Gérant")
                    break;
                case "cog":
                    return t("global:cog", "Co-gérant")
                    break;
                default:
                    break;
            }
        }
    }
    const getRoleName = (role) => {
        switch (role) {
            case "admin":
                return "Admin"
                break;
            case "superadmin":
                return "Super Admin"
                break;
            default:
                return "Standard"
                break;
        }
    }
    return (
        <>
            {
                data.map(row => (
                    <tr key={row.id} >
                        <TD style={{ textAlign: 'center' }}>
                            <input
                                type="checkbox"
                                name={row.userUid}
                                onChange={(e) => selectRowHandler(e)}
                                checked={getSelectedRowValue(row.userUid)}
                                className="form-check-input"
                                style={{ margin: 0 }}
                                id="exampleCheck1"
                            />
                        </TD>
                        <TD>
                            {row.first_name}
                        </TD>
                        <TD>
                            {row.last_name}
                        </TD>
                        <TD>
                            {row.login}
                        </TD>
                        <TD >
                            {row.telephone_number}
                        </TD>
                        <TD>
                            <UserStatus
                                bg={Number(row.pending) ? theme.colors.warning : (Number(row.active) === 1 ? theme.colors.greenLight : theme.colors.redLight)}
                                color={Number(row.pending) ? "black" : (Number(row.active) === 1 ? theme.colors.success : theme.colors.danger)}
                            >
                                {Number(row.pending) ? t("global:pending", "En attente") : (Number(row.active) === 1 ? t("global:yes", "Oui") : t("global:no", "Non"))}
                            </UserStatus>
                        </TD>
                        {/* <TD >
                            {t("clientList:" + getTypeName(row.type))}
                        </TD> */}
                        <TD >
                            {getLevelName(row.level)}
                        </TD>

                        <TD >
                            {getRoleName(row.role)}
                        </TD>

                        {/* <TD  >
                            <CTooltip title={row.entities} className="tableActions__icon">
                                <span ref={textRef}>
                                    {row.entities}
                                </span>
                            </CTooltip>
                        </TD> */}
                        {((userRole == 'superadmin') || (userRole == 'admin' && origin == 'fromClient')) ?

                            <TD className="userManag_td">
                                {
                                    // (blocUsersUpdates || (blocUserManagerUpdates && row.level == 'admin')) ?
                                    //     <CTooltip
                                    //         title={t('tableBody:edit', "Modification bloquée, un ou plusieurs contrat(s) en cours")}
                                    //         className="tableActions__icon">
                                    //         <EditIcon className="editIcon" fontSize="small" />
                                    //     </CTooltip> :
                                        <Link
                                            to={`/userForm/${row.userUid}`}
                                            state={{ entityUid: entityUid }}
                                            style={{
                                                color: theme.colors.warning
                                            }}
                                        >
                                            <CTooltip
                                                // title={t('tableBody:edit', "Modification bloquée, un ou plusieurs contrat(s) en cours")}
                                                className="tableActions__icon">
                                                <EditIcon className="editIcon" fontSize="small" />
                                            </CTooltip>
                                        </Link>
                                }

                                {/* {((row?.level == 'cog' && row?.type == 'client') || (row?.type == 'owner' && row?.role !== 'superadmin')) ? */}
                                {
                                    (blocUsersUpdates || (blocUserManagerUpdates && row.level == 'admin')) ? (
                                        <CTooltip
                                            title={t('tableBody:enable', "Un ou plusieurs contrat(s) en cours")}
                                            className="tableActions__icon">
                                            <ToggleOnIcon
                                                style={{ marginLeft: '0.25rem', color: Number(row.active) === 1  && theme.colors.success }}
                                                fontSize="small"
                                                // onClick={() => enableUser(row.userUid)}
                                            />
                                        </CTooltip>

                                    ) :
                                        (Number(row.active) === 1 ? (
                                            <CTooltip
                                                // title={t('tableBody:disable', "Désactiver")}y
                                                className="tableActions__icon">
                                                <ToggleOnIcon
                                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                    fontSize="small"
                                                    onClick={() => {
                                                        setUserToDisable(row.userUid);
                                                        setConfirmationModalShow(true)
                                                    }}
                                                />
                                            </CTooltip>
                                        ) :
                                            <CTooltip
                                                // title={t('tableBody:enable', "Activer")}
                                                className="tableActions__icon">
                                                <ToggleOffIcon
                                                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                    fontSize="small"
                                                    onClick={() => enableUser(row.userUid)}
                                                />
                                            </CTooltip>
                                        )
                                }


                                {/* <DeleteIcon
                                    style={{ marginLeft: '0.25rem', color: theme.colors.danger, cursor: 'pointer' }}
                                    fontSize="small"
                                    onClick={() => {
                                        setUserToDisable(row.userUid);
                                        setConfirmationModalShow2(true)
                                    }}
                                /> */}

                            </TD>
                            : null
                        }

                    </tr>

                ))

            }
            <ConfirmationModal
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => disableUserClickHandler(userToDisable)}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation"}
                message={t("global:userActivation", "Êtes-vous sûr de vouloir désactiver cet utilisateur ?")}
            />
            {/* <ConfirmationModal
                show={confirmationModalShow2}
                modalClosed={() => setConfirmationModalShow2(false)}
                confirm={() => deleteUserClickHandler(userToDisable)}
                cancel={() => setConfirmationModalShow2(false)}
                title={"Confirmation"}
                message={t("global:userDelete", "Êtes-vous sûr de vouloir supprimer cet utilisateur ?")}
            /> */}
        </>
    )
}

export default TableBody
