import React, { useCallback, useEffect, useState, useRef, useMemo } from "react"
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Notyf } from "notyf";
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import Select from '../../components/UI/Select/Select';
import Axios from '../../axios-proas';
import * as actions from '../../store/actions/index';
import { BtnIcon, BtnOutlineLink } from "../../styles/Common";
import { CampBody, CampContainer, CampHeader, CampHeaderActions, CampHeaderTitle, CampHeaderWrapper, GCTableAction } from "./GestionContract.styled";
import { NavTabsLi, NavTabsLink, NavTabsUl } from "../Entity/Entity.styled";
import ContractItem from "../../components/Contracts/ContractItem/ContractItem";
import { Spinner } from "react-bootstrap";
import { NoContent, SpinnerContainer, Table, TBody } from "../../styles/Table.styled";
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import TableHead from '../../components/Contracts/ContractHead/TableHead';
import TableFilters from '../../components/Contracts/ContractTable/TableFilters';

function GestionContract(props) {
    const {
        loading,
        contractsData,
        count,
        currentPage,
        pageSize,
        setContractsPageSize,
        getContractsPage,
        setContractsPage,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userType,
        userUid,
        updateShowFilters,
        setContractsFilters,
        setSortQuery,
        setReverse,
        density,
        setDensity,
        userLevel,
        logout
    } = props;

    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const { contractsFilter } = useParams();
    const navigate = useNavigate();
    const notyf = new Notyf();
    const [value, setValue] = useState('false');
    // const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    const [reminder, setReminder] = useState()
    const userRole = localStorage.getItem('role');
    const [clientAndNbrelances, setclientAndNbrelances] = useState()
    const currentDate = new Date();
    const [tabsList, setTabsListOwner] = useState([
        { title: t("contractList:all", "Tous"), value: "all", number: 0 },
        ('client, supplier'.includes(userType)) ? { title: t("contractList:scheduled", "Reçu"), value: "scheduled", number: 1 } : null,
        userType == 'owner' ? { title: t("contractList:scheduledOwner", "Envoyé"), value: "scheduledOwner", number: 1 } : null,
        { title: t("contractList:inProgress", "En cours"), value: "inProgress", number: 2 },
        { title: t("contractList:completedSingle", "Terminé"), value: "completed", number: 3 },
        { title: t("contractList:refused", "Refusé"), value: "refused", number: 4 }
    ]);

    // const options = [
    //     { label: t("contract:contractNormal", "Contrat normal"), value: 'false' },
    //     { label: t("contract:contractConfidential", "Contrat confidentiel"), value: 'true' }
    // ];

    const [loadColumns, setLoadColumns] = useState(false)
    const [showAll, setShowAll] = useState();
    const navTitre = "gestionContract";

    useEffect(() => {
        Axios.get('/user/' + navTitre + '/navColumns')
            .then(response => {
                setclientAndNbrelances(response?.data[0]?.clientAndNbrelances)

                let tempColums = response?.data?.columns
                let columnsCopy = [
                    // { title: userType == "owner" ? t("documentList:Visible") : t("documentDetails:Lu"), field: userType == "owner" ? 'visible' : "lu", width: "50px", show: true },
                    { title: t("documentDetails:Lu"), field: "lu", width: "50px", show: userType !== 'owner' ? tempColums.lu : false },
                    { title: t("contract:dateCreation"), field: 'creationDate', width: "140px", show: tempColums.creationDate },
                    { title: t("contract:title"), field: 'title', width: "200px", show: tempColums.title },
                    { title: t("contract:fileType", "fileType"), field: 'fileType', width: "200px", show: tempColums.fileType },
                    { title: t("contract:freeType", "freeType"), field: 'freeType', width: "200px", show: tempColums.freeType },
                    { title: userType == "owner" ? t("contract:recipient") : t("contract:society"), field: 'recipients', width: "200px", show: tempColums.recipients },
                    // { title: t("contract:sender"), field: "sender", width: "200px", show: userType !== 'owner' ? true : false },
                    { title: t("contract:status"), field: 'status', width: "100px", show: tempColums.status },
                    { title: t("contract:nextSigner"), field: 'nextSigner', width: "200px", show: tempColums.nextSigner },
                    { title: t("contract:nbRelance"), field: 'nbRelance', width: "120px", show: tempColums.nbRelance },
                    { title: t("contract:dateRelance"), field: 'dateRelance', width: "120px", show: tempColums.dateRelance }
                ];
                if (userType === "owner" && userRole !== "standard") {
                    columnsCopy = [
                        ...columnsCopy,
                        { title: t("contract:operation"), field: 'operation', width: "100px", show: userType == "owner" ? tempColums.operation : false }
                    ]
                }
                setColumns(columnsCopy);
                if (userType == "owner") {
                    columnsCopy.filter(column => column?.show == 0).length > 1 ? setShowAll(false) : setShowAll(true);
                } else {
                    columnsCopy.filter(column => column?.show == 0).length > 0 ? setShowAll(false) : setShowAll(true);

                }

            })
    }, [userType, t, loadColumns])

    // useEffect(() => {
    //     Axios.get('/contract//clientAndNbrelances')
    //         .then(response => {
    //         })
    // }, []);

    useEffect(() => {
        if (contractsFilter == null  ) {
            if(filtersQuery != null && filtersQuery['status'] != undefined)
                setActiveTab(+filtersQuery['status'] == 5 ? 3 : +filtersQuery['status'])
            getContractsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
        } else {
            let tabNumber = tabsList.filter(element => element?.number == contractsFilter)
            setActiveTab(tabNumber[0]?.number)
            let newFilters = {
                ...filtersQuery,
                ["status"]: contractsFilter
            }
            setContractsFilters(newFilters);
            handleApplyFiltering(newFilters);
            getContractsPage(currentPage, newFilters, sortQuery, reverse, pageSize)
        }

    }, [currentPage, pageSize])


    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column?.field)

        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    // useEffect(() => {
    //     getContractsPage(currentPage, null, sortQuery, reverse, pageSize)
    // }, []);

    const resetFilters = () => {
        setContractsFilters(null)
        setActiveTab(0)
        getContractsPage(currentPage, null, sortQuery, reverse, pageSize)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getContractsPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const getContracts = () => {
        getContractsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setContractsPage(newPage);
    }, [currentPage]);

    const tabChangeHandler = (newValue) => {

        setActiveTab(newValue);
        let newFilters = {
            ...filtersQuery,
            status: newValue != "all" ? newValue : null
        }
        setContractsFilters(newFilters);
        handleApplyFiltering(newFilters);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filtersQuery,
            [key]: e.target.value
        }
        setContractsFilters(newFilters);
        handleApplyFiltering(newFilters);
    }

    const handleApplyFiltering = useCallback((filters) => {
        getContractsPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);


    const handleContractVisibility = (id, visible) => {
        Axios.put('/contract/' + id + '/visibility', { visible: visible }
        ).then(response => {
            if (response.data.success) {
                notyf.success(t("notyf:LectureAutoModification"))
                getContractsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
            }
        })
    }

    const handleReadContract = (id) => {
        Axios.put('/contractentity/' + id + '/firstAccessDate').then(response => {
            if (response.data.success) {
                // notyf.success(t("notyf:successContractRead"))
                getContractsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
            }
        })
    }

    const selectChange = (value) => {

        setValue(value);
        switch (value) {
            case "false":
                navigate("/contractForm", {
                    state: { secret: value }
                })
                break;
            case "true":
                navigate("/contractForm", {
                    state: { secret: value }
                })
                break;
            default:
                break;
        }

    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent>{t("contractList:noContract")}</NoContent>;

    if (!loading && contractsData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (
            contractsData.map(contractData => (
                <ContractItem
                    columns={columns}
                    handleContractVisibility={handleContractVisibility}
                    handleReadContract={handleReadContract}
                    userUid={userUid}
                    userLevel={userType}
                    contractData={contractData}
                    userRole={userRole}
                    getContracts={getContracts}
                    reminder={reminder}
                    clientAndNbrelances={clientAndNbrelances}
                />
            ))
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            logout()
        }, 1800000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <CampContainer>
                <TitleHelmet title={"OPTICAL CENTER | " + "GestionContract"} />
                <CampHeaderWrapper>
                    <CampHeader>
                        <CampHeaderTitle>{t("contract:contracts")}</CampHeaderTitle>
                        <CampHeaderActions>
                            {/* <SearchInputContainer >
                                <SearchInput
                                    style={{ padding: "0.3rem" }}
                                    type="text"
                                    placeholder="Rechercher ..."
                                    autoComplete={"off"}
                                    name="title"
                                    value={filtersQuery?.title || ''}
                                    onChange={(e) => inputChangeHandler(e)}

                                />
                                <SearchIcon />
                            </SearchInputContainer> */}
                            <GCTableAction onClick={() => updateShowFilters(!showFilters)}>
                                <span> {t("global:filters")}</span>
                                <FilterListIcon />
                            </GCTableAction>
                            {/* {userType == "owner" ?
                                <div style={{ height: '50px', marginLeft: '1rem' }}>
                                    <Select
                                        options={options}
                                        // value={options.find(option => option?.value === value)?.label}
                                        value={t("contract:newContract")}
                                        onChange={selectChange}
                                    />
                                </div>
                                : null
                            } */}

                            {userType == "owner" && userLevel == "standard" ?
                                <BtnOutlineLink
                                    color="black"
                                    to="/contractForm"
                                >
                                    <span>{t("contract:newContract")}</span>
                                    <BtnIcon>
                                        <AddIcon fontSize="small" />
                                    </BtnIcon>
                                </BtnOutlineLink>
                                : null}
                        </CampHeaderActions>
                    </CampHeader>

                    <NavTabsUl>
                        {
                            tabsList.map((tab, index) => {
                                if (tab != null) {
                                    return (
                                        <NavTabsLi key={index} >
                                            <NavTabsLink
                                                onClick={() => tabChangeHandler(tab.number)}
                                                active={tab.number === activeTab}
                                                to={`#${tab.number}`}
                                            >
                                                {t("contractList:" + tab.value)}
                                            </NavTabsLink>
                                        </NavTabsLi>
                                    )
                                }
                            })

                        }
                    </NavTabsUl>
                </CampHeaderWrapper>

                <CampBody>

                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} userType={userType} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setContractsPageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                            userLevel={userType}
                        />
                        <TBody height={density}>
                            {showFilters && <TableFilters
                                setActiveTab={setActiveTab}
                                columns={columns}
                                showFilters={showFilters}
                                formatDate={formatDate}
                                filters={filtersQuery}
                                filtersChange={setContractsFilters}
                                applyFilters={handleApplyFiltering}
                                userLevel={userType}
                                userType={userType}
                                convertDate={convertDate}
                            />}
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns} userType={userType} navTitre={navTitre} loadColumns={loadColumns} setLoadColumns={setLoadColumns} showAll={showAll} setShowAll={setShowAll}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setContractsPageSize}
                    />
                </CampBody>
            </CampContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    loading: state.contracts.loading,
    contractsData: state.contracts.data,
    count: state.contracts.count,
    currentPage: state.contracts.currentPage,
    pageSize: state.contracts.pageSize,
    sortQuery: state.contracts.sortQuery,
    reverse: state.contracts.reverse,
    filtersQuery: state.contracts.filtersQuery,
    showFilters: state.contracts.showFilters,
    userRole: state.auth.role,
    userLevel: state.auth.userLevel,
    userType: state.auth.userType,
    density: state.contracts.density
})
const mapDispatchToProps = dispatch => ({
    getContractsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.contractsGetPage(page, filters, sort, reverse, pageSize)),
    setContractsPage: (page) => dispatch(actions.contractsSetPage(page)),
    setDensity: (density) => dispatch(actions.contractsSetDensity(density)),
    setContractsPageSize: (pageSize) => dispatch(actions.contractsSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.contractsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.contractsSetReverseSort(reverseS)),
    setContractsFilters: (filters) => dispatch(actions.contractsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.contractsUpdateShowFilters(show)),
    logout: () => dispatch(actions.logout())

})
export default connect(mapStateToProps, mapDispatchToProps)(GestionContract)

